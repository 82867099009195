import { useCallback, useRef, useEffect } from 'react';
import { useBenchmarkDownloadMutation } from './data.graphql';
import { DownloadBenchmarkProps } from './types';
import {
  DOWNLOAD_NUMBER_REQUESTS,
  DOWNLOAD_PARALLEL_REQUESTS,
  DOWNLOAD_REQUEST_SIZE,
} from './constants';

export const DownloadBenchmark = ({ onProgress, onComplete }: DownloadBenchmarkProps) => {
  const [benchmarkDownloadMutation] = useBenchmarkDownloadMutation();

  const shared = useRef({ completeCount: 0, numberErrors: 0, totalBytes: 0, startTime: 0 });

  const runDownloadBenchmarkBatch = useCallback(
    async (batchSize: number, expectedCompleteCount: number) => {
      for (let requestCount = 0; requestCount < batchSize; requestCount += 1) {
        // eslint-disable-next-line no-await-in-loop
        const result = await benchmarkDownloadMutation({
          variables: {
            input: {
              size: DOWNLOAD_REQUEST_SIZE,
            },
          },
        });
        if (result.data?.benchmarkDownload?.data.length === DOWNLOAD_REQUEST_SIZE) {
          shared.current.totalBytes += DOWNLOAD_REQUEST_SIZE;
        } else {
          // error
          shared.current.numberErrors += 1;
        }
        shared.current.completeCount += 1;
        if (shared.current.completeCount < expectedCompleteCount) {
          onProgress(Math.round((shared.current.completeCount / expectedCompleteCount) * 100));
        } else {
          onProgress(100);
          const { startTime, totalBytes, numberErrors } = shared.current;
          const timeElapsed = (performance.now() - startTime) * 0.001;
          const speedMbitPerSec = Math.round((totalBytes * (8 * 0.000001)) / timeElapsed);

          onComplete({
            speedMbitPerSec,
            bytesTransfered: totalBytes,
            numberErrors,
            timeElapsed,
          });
        }
      }
    },
    [shared, benchmarkDownloadMutation, onProgress, onComplete]
  );

  const runAll = useCallback(async () => {
    shared.current.startTime = performance.now();
    const batchSize = Math.floor(DOWNLOAD_NUMBER_REQUESTS / DOWNLOAD_PARALLEL_REQUESTS);
    await Promise.all(
      // eslint-disable-next-line unicorn/new-for-builtins
      [...Array(DOWNLOAD_PARALLEL_REQUESTS)].map(() =>
        runDownloadBenchmarkBatch(batchSize, batchSize * DOWNLOAD_PARALLEL_REQUESTS)
      )
    );
  }, [shared, runDownloadBenchmarkBatch]);

  useEffect(() => {
    runAll();
  }, [runAll]);

  return <></>;
};
