import { SxProps } from '@mui/material';

const drawerWidth = 300;

export const drawerContainerStyles: SxProps = {
  display: 'flex',
  width: '100%',
  height: 'calc(85vh / var(--zoom))',
};

export const drawerMainStyles: SxProps = {
  width: drawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
    position: 'static',
  },
};

export const drawerBodyStyles: SxProps = { overflow: 'auto', mr: '4%' };

export const drawerChildrenContainerStyles: SxProps = { flexGrow: 1, p: 3, overflow: 'auto' };
