import { atom } from 'recoil';
import { PolygonProps } from '@abyss/3d-viewer';
import {
  DataLoaderPointOfInterestQueryObject,
  BlisterToAdd,
  EditPointOfInterest,
  PointOfInterestQuery,
} from '@/types';

export const poiFilteredByCheckbox = atom<PointOfInterestQuery>({
  default: [],
  key: 'AnalysispointOfInterestsFilteredByCheckbox',
});

export const selectedPointOfInterestId = atom<string | undefined>({
  default: undefined,
  key: 'AnalysisSelectedPointOfInterestId',
});

export const editPointOfInterest = atom<EditPointOfInterest>({
  default: { state: 'Inactive' },
  key: 'AnalysisEditPointOfInterest',
});

export const blisterToAdd = atom<BlisterToAdd>({
  default: undefined,
  key: 'AnalysisBlisterToAdd',
});

export const structurePois = atom<DataLoaderPointOfInterestQueryObject>({
  default: [],
  key: 'ViewerPointOfInterests',
});

export const blisterPolygons = atom<PolygonProps[] | undefined>({
  key: 'blisterPolygons',
  default: undefined,
});

export const manualBlisterHeightExists = atom<boolean>({
  default: false,
  key: 'ManualBlisterHeightExists',
});
