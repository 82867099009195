import { useRef, useState, useEffect } from 'react';

interface Position {
  x: number;
  y: number;
}

export const useDragToScroll = () => {
  const containerReference = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [startPosition, setStartPosition] = useState<Position>({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!dragging) return;

      const deltaX = event.clientX - startPosition.x;
      const deltaY = event.clientY - startPosition.y;

      if (containerReference.current) {
        containerReference.current.scrollLeft -= deltaX;
        containerReference.current.scrollTop -= deltaY;
      }

      setStartPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    if (dragging) {
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dragging, startPosition]);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    setDragging(true);
    setStartPosition({ x: event.clientX, y: event.clientY });
  };

  return { containerReference, handleMouseDown };
};
