import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  TextField,
  TextFieldVariants,
  SxProps,
} from '@mui/material';
import { scrollDesign } from '@/components/Analysis/Insights/InsightsExplorer/styles';
import { AutoCompleteOption } from '@/types';

type Props = {
  handleSelected: (value: string[]) => void;
  options: AutoCompleteOption[];
  selectedValues: string[];
  label: string;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  multiple?: boolean;
  variant?: TextFieldVariants;
  sx?: SxProps;
  required?: boolean;
};

export const AbyssAutoComplete = ({
  handleSelected,
  options,
  selectedValues,
  multiple = true,
  label,
  error,
  helperText,
  loading,
  variant = 'outlined',
  sx,
  required = false,
}: Props) => {
  const handleOnChange = (selected: AutoCompleteOption | AutoCompleteOption[] | null) => {
    if (!selected) {
      handleSelected([]);
      return;
    }
    const newSelected = Array.isArray(selected)
      ? selected.map(({ value }) => value)
      : [selected.value];
    handleSelected(newSelected);
  };

  return (
    <MuiAutocomplete
      data-testid="autocomplete"
      fullWidth
      multiple={multiple}
      sx={sx}
      {...(loading ? { loading } : {})}
      options={options}
      getOptionLabel={(option) => option.label}
      value={options.filter((option) => selectedValues.includes(option.value))}
      onChange={(_, value) => handleOnChange(value)}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          variant={variant}
          label={label}
          size="small"
          error={error}
          helperText={helperText}
          required={required && selectedValues.length === 0}
        />
      )}
      ListboxProps={{ sx: { maxHeight: 150, overflow: 'auto', ...scrollDesign } }}
      ChipProps={{ variant: 'outlined' }}
      renderOption={(props, option, { selected }) => (
        <li {...props} id={`option-${option.value}`}>
          <Checkbox checked={selected} />
          {option.label}
        </li>
      )}
    />
  );
};
