import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import { Box, Link, Stack } from '@mui/material';
import { PartSummary } from '@/types';
import { PartTag } from './PartTag';
import * as state from '@/state';
import { GroupOfParts } from './GroupOfParts/GroupOfParts';
import { DeletePartCheckbox } from './PartDelete';
import { PartReviewedCheckbox } from './PartReviewed';
import { DockPanelItem } from '@/components/shared/DockPanelItem';

type Props = {
  parts: PartSummary[];
};

export const SelectedPartItem = ({ parts }: Props) => {
  const [searchedHighlightedTag, setSearchedHighlightedTag] = useRecoilState(
    state.searchedHighlightedTag
  );
  const setAbyssViewerState = useSetRecoilState(state.setAbyssViewerState);
  const singleAssemblyIdForSelectedParts = useRecoilValue(state.singleAssemblyIdForSelectedParts);
  const singleAssemblyInfoForSelectedParts = useRecoilValue(
    state.singleAssemblyInfoForSelectedParts
  );

  const goToIsolatePartMode = useCallback(() => {
    setAbyssViewerState({ mode: 'IsolatePart' });
  }, [setAbyssViewerState]);

  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const PARTS_SELECTION_LIMIT = 20;
  const [shouldHighlightAllPartsWithSameTag, setShouldHighlightPartsWithSameTag] = useRecoilState(
    state.shouldHighlightAllPartsWithSameTag
  );

  const toggleHighlightTag = useCallback(() => {
    setShouldHighlightPartsWithSameTag((current) => !current);
  }, [setShouldHighlightPartsWithSameTag]);

  const selectSameTagParts = useCallback(() => {
    if (singleAssemblyIdForSelectedParts && singleAssemblyInfoForSelectedParts?.tagName) {
      setSearchedHighlightedTag({
        id: singleAssemblyIdForSelectedParts,
        tagName: singleAssemblyInfoForSelectedParts.tagName,
      });
    }
  }, [
    singleAssemblyIdForSelectedParts,
    singleAssemblyInfoForSelectedParts,
    setSearchedHighlightedTag,
  ]);

  const [className, setClassName] = useState<string>('');

  useEffect(() => {
    const selectedAssemblyIds = parts.map((part) => part.partClassName);
    const uniquePartNames = [...new Set(selectedAssemblyIds)];
    if (uniquePartNames.length === 1) {
      setClassName(uniquePartNames[0]);
    } else {
      setClassName('Multiple');
    }
  }, [parts]);

  useEffect(() => {
    if (parts.length >= PARTS_SELECTION_LIMIT) {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <Alert severity="warning">
            {`Selecting more than ${PARTS_SELECTION_LIMIT} parts may result in issues when generating a view link`}
          </Alert>
        ),
      });
    }
  }, [parts.length, setSnackbarMessage]);

  return (
    <>
      {parts.length > 1 && (
        <DockPanelItem>
          <Typography variant="body1">{`${parts.length} parts selected`}</Typography>
        </DockPanelItem>
      )}

      <DockPanelItem>
        <FormControl fullWidth>
          <PartTag selectedParts={parts} />
        </FormControl>
      </DockPanelItem>
      <DockPanelItem>
        <Stack direction="row">
          <Typography variant="caption" sx={{ pt: '4px', mr: 1 }}>
            Part class:{' '}
          </Typography>
          <Typography variant="body1">{className}</Typography>
        </Stack>
      </DockPanelItem>
      <Box sx={{ textAlign: 'center' }}>
        {singleAssemblyIdForSelectedParts && (
          <DockPanelItem>
            <Link component="button" onClick={toggleHighlightTag}>
              {shouldHighlightAllPartsWithSameTag
                ? 'Remove tag highlight'
                : 'Highlight parts with same tag'}
            </Link>
          </DockPanelItem>
        )}
        {singleAssemblyIdForSelectedParts && !searchedHighlightedTag && (
          <DockPanelItem>
            <Link component="button" onClick={selectSameTagParts}>
              Select all parts with same tag
            </Link>
          </DockPanelItem>
        )}
        <GroupOfParts />
        {parts.length === 1 && (
          <DockPanelItem>
            <Link component="button" onClick={goToIsolatePartMode}>
              Isolate part
            </Link>
          </DockPanelItem>
        )}
      </Box>
      <DockPanelItem>
        <PartReviewedCheckbox />
        <DeletePartCheckbox />
      </DockPanelItem>
    </>
  );
};
