import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { summaryStyles, containerStyles, indicatorStyles } from './styles';

type Props = {
  summary: string;
  children: React.ReactNode;
  numericIndicator?: number;
  openByDefault?: boolean;
  handleChecked?: () => void;
  checked?: boolean;
};

export function CheckboxAccordian({
  summary,
  children,
  numericIndicator,
  openByDefault,
  handleChecked,
  checked,
}: Props) {
  return (
    <Accordion elevation={0} sx={summaryStyles} defaultExpanded={openByDefault} disableGutters>
      <AccordionSummary sx={summaryStyles} expandIcon={<ArrowDropDownIcon />}>
        <Stack sx={containerStyles} direction="row" alignItems="center">
          <Checkbox
            onClick={(event) => {
              event.stopPropagation();
              handleChecked?.();
            }}
            checked={checked}
          />
          <Typography variant="body2" sx={{ textWrap: 'nowrap' }}>
            {summary}
          </Typography>
          {numericIndicator !== undefined && <Chip sx={indicatorStyles} label={numericIndicator} />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
