import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import React from 'react';

type ExpandMoreProps = {
  expand: boolean;
} & IconButtonProps;

type CardContainerProps = {
  isLast?: boolean;
};

export const Container = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = muiStyled(Box)(({ isLast }: CardContainerProps) => ({
  margin: isLast ? '9px' : '9px 0px 9px 9px',
  minWidth: '175px',
}));

export const TableTitleContainer = muiStyled(Box)(() => ({
  width: '100%',
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const StyledCollapse = muiStyled(Collapse)(() => ({
  width: '100%',
  '& .MuiCheckbox-root': {
    paddingLeft: 0,
  },
}));

export const NumberFound = styled.div`
  padding-right: 19px;
  font-size: 3.4rem;
  width: 15%;
`;

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: 'transform 0.2s',
}));

export const TableTitleBoxStyle: React.CSSProperties = {
  padding: '5px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  alignItems: 'left',
};
