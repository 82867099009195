import CloseIcon from '@mui/icons-material/Close';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { DialogHeadingContainer, DialogHeadingStyle } from './styles';

type Props = {
  title?: string | null;
  children?: React.ReactNode;
  isFixedHeight?: boolean;
  onClose: () => void;
} & DialogProps;

type DialogTitleProps = {
  children?: React.ReactNode;
  onClose: () => void;
};

const BootstrapDialogTitle = ({ children, onClose }: DialogTitleProps) => {
  return (
    <DialogTitle>
      <DialogHeadingContainer>
        <DialogHeadingStyle>{children}</DialogHeadingStyle>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : undefined}
      </DialogHeadingContainer>
    </DialogTitle>
  );
};

/**
 * Generic dialog with a customised title that contains a close button
 */
export const Dialog = ({ children, title, onClose, isFixedHeight, ...props }: Props) => {
  return (
    <MuiDialog
      PaperProps={isFixedHeight ? { sx: { height: '80%' } } : undefined}
      {...props}
      onClose={onClose}
    >
      <BootstrapDialogTitle onClose={onClose}>{title}</BootstrapDialogTitle>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};
