import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  min-height: 44px;
`;

export const IconContainer = styled.div`
  padding: 0 8px 0 32px;
  display: flex;
  align-items: center;
`;
