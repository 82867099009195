import { SphericalImageProps } from '@abyss/3d-viewer';
import { useCallback, useMemo } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import { poiState } from '@/components/Analysis/modules/pointOfInterest';

export const useFocusOnSelectedAssembly = (selectedSpherical: SphericalImageProps | undefined) => {
  // Removing Temporarily until work for automatically focusing is done
  // const pickingMapsUpdateSequence = useRecoilValue(state.pickingMapsUpdateSequence);
  const callbacks = useRecoilValue(state.globalCallbacks);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const selectedPoiId = useRecoilValue(poiState.selectedPointOfInterestId);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const structureRelationships = useRecoilValue(state.structureRelationship);
  const structureLocations = useRecoilValue(state.structureLocations);

  const annotation3dArray = useMemo(() => {
    if (!selectedAssemblyId) return undefined;
    if (!selectedSpherical) return undefined;
    if (!structureRelationships?.assemblyIdAndLocationIdToAnnotation3dArray) return undefined;

    const locationId = structureLocations?.find(
      (location) => location.name === selectedSpherical.name
    )?.id;
    if (!locationId) return undefined;

    const assemblyEntry =
      structureRelationships?.assemblyIdAndLocationIdToAnnotation3dArray[selectedAssemblyId];

    return assemblyEntry && Object.hasOwn(assemblyEntry, locationId)
      ? assemblyEntry[locationId]
      : [];
  }, [selectedAssemblyId, selectedSpherical, structureRelationships, structureLocations]);

  const focusOnAssembly = useCallback(() => {
    if (selectedPoiId) return;
    if (selectedAssemblyId && annotation3dArray && selectedSpherical && callbacks?.findFocusPoint) {
      const { position } = selectedSpherical;
      const { findFocusPoint } = callbacks;
      const focusPoint = findFocusPoint(position, 'annotation', annotation3dArray);
      if (focusPoint) {
        setCameraTarget({
          position,
          lookAt: focusPoint,
          transitionTime: 0.8,
        });
      }
    }
  }, [
    selectedPoiId,
    selectedAssemblyId,
    annotation3dArray,
    selectedSpherical,
    callbacks,
    setCameraTarget,
  ]);

  return { focusOnAssembly };
};
