import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ControlsType, TransformMode } from '@abyss/3d-viewer';
import * as state from '@/state';
import { CuboidClickMode, CuboidData } from '@/types';

export const useSelectCuboid = () => {
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const setCuboidControlsType = useSetRecoilState(state.cuboidControlsType);
  const setCuboidTransformMode = useSetRecoilState(state.cuboidTransformMode);
  const setCuboidClickMode = useSetRecoilState(state.cuboidClickMode);
  const setIsCuboidEditorEnabled = useSetRecoilState(state.isCuboidEditorEnabled);

  const selectCuboid = useCallback(
    (cuboid: CuboidData) => {
      if (cuboid !== currentCuboid) {
        if (currentCuboid) {
          setCurrentCuboid(undefined);
          setIsCuboidEditorEnabled(false);
          setTimeout(() => {
            setCurrentCuboid(cuboid);
            setCuboidControlsType(ControlsType.AlignedBox);
            setCuboidTransformMode(TransformMode.Translate);
            setIsCuboidEditorEnabled(true);
            setCuboidClickMode(CuboidClickMode.Default);
          }, 200);
        } else {
          setCurrentCuboid(cuboid);
          setCuboidControlsType(ControlsType.AlignedBox);
          setCuboidTransformMode(TransformMode.Translate);
          setIsCuboidEditorEnabled(true);
          setCuboidClickMode(CuboidClickMode.Default);
        }
      }
    },
    [
      currentCuboid,
      setCurrentCuboid,
      setCuboidControlsType,
      setCuboidTransformMode,
      setIsCuboidEditorEnabled,
      setCuboidClickMode,
    ]
  );

  return selectCuboid;
};
