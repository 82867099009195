import styled from 'styled-components';
import { Skeleton, Typography, styled as muiStyled } from '@mui/material';
import { Comment as Icon } from '@mui/icons-material';

export const CommentsListContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 220px;
  text-align: center;
`;

export const commentsListStyle = { width: '100%', flex: '1 1 auto', overflowY: 'auto' };

export const commentTextStyles = {
  boxDecorationBreak: 'clone',
  WebkitBoxDecorationBreak: 'clone',
  marginLeft: '2rem',
};

export const CustomSkeleton = muiStyled(Skeleton)(() => ({
  borderRadius: '2px',
  height: '30px',
}));

export const NoCommentsText = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  paddingTop: 25,
}));

export const CommentIcon = muiStyled(Icon)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '6rem',
}));
