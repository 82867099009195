import { SxProps } from '@mui/material';

export const summaryStyles: SxProps = {
  padding: 0,
  margin: 0,
  '.Mui-expanded': { backgroundColor: '#EDEDED' },
};
export const containerStyles: SxProps = { display: 'flex', alignItems: 'center' };
export const indicatorStyles: SxProps = {
  marginLeft: '5%',
  backgroundColor: '#DDDDDD',
  color: 'white',
};
