import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  ControlsType,
  CuboidStyle,
  CuboidVariants,
  Number3,
  TransformMode,
} from '@abyss/3d-viewer';
import * as state from '@/state';
import { CuboidClickMode, CuboidData } from '@/types';
import { generateMongoObjectId } from '@/utils/databaseHelpers';

export const makeCuboidStyle = (rgb: number): CuboidStyle => ({
  variant: CuboidVariants.LinesAndTransparentMesh,
  lineColor: rgb,
  lineWidth: 1,
  lineOpacity: 0.8,
  fillColor: rgb,
  fillOpacity: 0.02,
});

const CUBOID_COLORS = [0xaa55ff, 0xffff55, 0x55ffff, 0xff55ff, 0x55ffaa];

export const useCreateCuboid = () => {
  const setCurrentCuboid = useSetRecoilState(state.currentCuboid);
  const setIsCuboidEditorEnabled = useSetRecoilState(state.isCuboidEditorEnabled);
  const setCuboidControlsType = useSetRecoilState(state.cuboidControlsType);
  const setCuboidTransformMode = useSetRecoilState(state.cuboidTransformMode);
  const setCuboidClickMode = useSetRecoilState(state.cuboidClickMode);

  const createCuboid = useCallback(
    (position: Number3) => {
      const id = generateMongoObjectId();
      const color = CUBOID_COLORS[Math.trunc(Date.now()) % CUBOID_COLORS.length];
      const newCuboid: CuboidData = {
        id,
        position,
        scale: [1, 1, 1],
        rotation: [0, 0, 0],
        color,
      };
      // Enable edit mode for the new cuboid
      setCurrentCuboid(newCuboid);
      setCuboidControlsType(ControlsType.AlignedBox);
      setCuboidTransformMode(TransformMode.Translate);
      setIsCuboidEditorEnabled(true);
      setCuboidClickMode(CuboidClickMode.Default);
    },
    [
      setCurrentCuboid,
      setCuboidControlsType,
      setCuboidTransformMode,
      setIsCuboidEditorEnabled,
      setCuboidClickMode,
    ]
  );

  return createCuboid;
};
