import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { SideDrawer } from '@/components/shared/SideDrawer';
import { CreatePoi } from '../Forms/CreatePoi';
import { PoiFormState } from '@/types';
import * as poiState from '../../../state';
import { UpdateGeneralFields } from '../Forms/UpdateGeneralFields/UpdateGeneralFields';
import { UpdateTemplateFields } from '../Forms/UpdateTemplateFields';
import { VerifyTemplateFields } from '../Forms/VerifyTemplateFields/VerifyTemplateFields';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';

type PoiFormSelectorProps = {
  formState: PoiFormState | undefined;
};

const PoiFormSelector = ({ formState }: PoiFormSelectorProps) => {
  switch (formState) {
    case 'Create':
      return <CreatePoi />;
    case 'Update-General':
      return <UpdateGeneralFields />;
    case 'Update-Template':
      return <UpdateTemplateFields />;
    case 'Verify':
      return <VerifyTemplateFields />;
    default:
      return <></>;
  }
};

type Props = {
  open: boolean;
  defaultDrawerWidth?: number;
};
export const PoiEditingForms = ({ open, defaultDrawerWidth }: Props) => {
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);

  const pointOfInterest = editPointOfInterest?.pointOfInterest;

  const { rightDrawerWidth, doublePanelWidth } = useDrawerWidths();

  const drawerWidth = useMemo(() => {
    if (
      editPointOfInterest.formState === 'Update-General' ||
      editPointOfInterest.formState === 'Verify'
    )
      return defaultDrawerWidth || rightDrawerWidth;

    if (pointOfInterest?.blister && editPointOfInterest.state === 'WaitingForDetail') {
      return doublePanelWidth;
    }

    return defaultDrawerWidth || rightDrawerWidth;
  }, [
    defaultDrawerWidth,
    doublePanelWidth,
    rightDrawerWidth,
    editPointOfInterest.formState,
    editPointOfInterest.state,
    pointOfInterest?.blister,
  ]);

  return (
    <SideDrawer
      position="right"
      width={drawerWidth || rightDrawerWidth}
      isOpen={open}
      handleOpen={() => {}}
      variant="persistent"
      scrollable
    >
      <PoiFormSelector formState={editPointOfInterest.formState} />
    </SideDrawer>
  );
};
