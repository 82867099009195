import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import * as state from '@/state';
import * as taggingState from '@/components/Inspection/Tagging/state';

import { AssemblyId } from '@/types';
import { useSelectCuboid, useGetCuboidColor } from '../../../SplitCuboids/hooks';
import { convertRgbToColorHexString } from '@/utils';

export const SplitCuboidsTable = () => {
  const allAssemblies = useRecoilValue(state.allAssemblies);
  const currentCuboid = useRecoilValue(state.currentCuboid);
  const isolatedPartCuboids = useRecoilValue(taggingState.isolatedPartCuboids);

  const getCuboidColor = useGetCuboidColor();
  const selectCuboid = useSelectCuboid();

  const assemblyNameById = useMemo(() => {
    return new Map<AssemblyId, string>(allAssemblies.map(({ id, tagName }) => [id, tagName]));
  }, [allAssemblies]);

  const cuboidsComp = useMemo(() => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ columnWidth: '15%' }}>#</TableCell>
              <TableCell>Colour</TableCell>
              <TableCell>Equipment Tag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isolatedPartCuboids.map((cuboid, index) => {
              const hexColor = convertRgbToColorHexString(getCuboidColor(cuboid));
              const style =
                currentCuboid && cuboid.id === currentCuboid.id
                  ? { backgroundColor: '#bbddff' }
                  : { '&:hover': { backgroundColor: '#ddd' } };
              return (
                <TableRow
                  key={cuboid.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ...style,
                  }}
                  onClick={() => selectCuboid(cuboid)}
                >
                  <TableCell component="th" scope="row" width="15%">
                    {index}
                  </TableCell>
                  <TableCell component="th" scope="row" width="15%">
                    <div
                      style={{
                        backgroundColor: hexColor,
                        width: '16px',
                        height: '16px',
                        border: '1px solid #555',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {cuboid.assemblyId && assemblyNameById.get(cuboid.assemblyId)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [isolatedPartCuboids, currentCuboid, assemblyNameById, selectCuboid, getCuboidColor]);

  return <>{cuboidsComp}</>;
};
