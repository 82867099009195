import Typography from '@mui/material/Typography';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

type ProgressBarWithTextProps = {
  text?: string;
};

export const ProgressBarWithText = (props: LinearProgressProps & ProgressBarWithTextProps) => {
  const { value, text } = props;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <LinearProgress variant="determinate" {...props} />
      </Grid>
      <Grid item xs>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value || 0)}% ${text || ''}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
