import { useMemo } from 'react';
import { POI_IS_BLISTER_NAME } from '@/constants';
import { AllPointOfInterestTemplate } from '@/types';

export const useGetDisabledBlisterTemplateFieldIds = (allTemplates: AllPointOfInterestTemplate) => {
  return useMemo(() => {
    return allTemplates
      .filter((template) => {
        return template?.fields.some(
          (field) => (field.name ?? '').toLowerCase().replace(/\s/g, '') === POI_IS_BLISTER_NAME
        );
      })
      .map((template) => template?.id);
  }, [allTemplates]);
};
