import { AbilityContext } from './Can';

import useAbility from '@/hooks/useAbility';

const AbilityProvider = ({ children }: { children: React.ReactNode }) => {
  const { ability } = useAbility();

  if (AbilityContext) {
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
  }
  return <>AbilityContext is undefined!</>;
};

export default AbilityProvider;
