import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Autocomplete, AutocompleteChangeReason, Stack, TextField } from '@mui/material';
import { AssemblyId } from '@/types';
import * as state from '@/state';
import { NO_TAG_ASSEMBLY_DISPLAY_NAME, NO_TAG_ASSEMBLY_NAME } from '@/constants';

interface AssemblySelectorProps {
  assemblyId: AssemblyId | undefined;
  setAssemblyId: (assemblyId: AssemblyId | undefined) => void;
}

const getOptionGroup = (option: { id: string; tagName: string; isNewlyAdded?: boolean }) => {
  return option.isNewlyAdded ? 'Newly Added' : 'Predefined';
};

const getOptionLabel = (option: { id: string; tagName: string; isNewlyAdded?: boolean }) => {
  return option.tagName === NO_TAG_ASSEMBLY_NAME ? NO_TAG_ASSEMBLY_DISPLAY_NAME : option.tagName;
};

export const AssemblySelector = ({ assemblyId, setAssemblyId }: AssemblySelectorProps) => {
  const allAssemblies = useRecoilValue(state.allAssemblies);

  const findAssemblyById = useCallback(
    (byId: AssemblyId) => {
      if (!allAssemblies) return undefined;
      return allAssemblies.find(({ id }) => id === byId);
    },
    [allAssemblies]
  );

  const selectedAssembly = useMemo(() => {
    const assembly = assemblyId ? findAssemblyById(assemblyId) : undefined;
    return assembly;
  }, [findAssemblyById, assemblyId]);

  const onAssemblyChange = async (
    _event: SyntheticEvent,
    value: { id: string; tagName: string; isNewlyAdded?: boolean } | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption') {
      if (value) {
        setAssemblyId(value.id);
      } else {
        setAssemblyId(undefined);
      }
    }
  };

  return (
    <Stack direction="row" width="80%">
      <Autocomplete
        fullWidth
        id="assembly-selector"
        defaultValue={selectedAssembly}
        value={selectedAssembly}
        isOptionEqualToValue={(option, value) => value && option.id === value.id}
        options={allAssemblies || []}
        groupBy={getOptionGroup}
        getOptionLabel={getOptionLabel}
        disableListWrap
        autoHighlight
        onChange={onAssemblyChange}
        renderInput={(parameters) => (
          <TextField {...parameters} label="Equipment tag" variant="standard" fullWidth />
        )}
      />
    </Stack>
  );
};
