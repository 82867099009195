import { ChangeEvent, useCallback, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { ControlsType, TransformMode } from '@abyss/3d-viewer';
import * as state from '@/state';
import { AssemblyId, CuboidClickMode } from '@/types';
import { useIsolatedPartId, useCuboids } from '../../../SplitCuboids/hooks';
import { SplitCuboidsTable } from './SplitCuboidsTable';
import { AssemblySelector } from './AssemblySelector';

const CUBOID_CONTROL_MODES: { [modeName: string]: number[] } = {
  Resize: [ControlsType.AlignedBox],
  SymmetricalResize: [ControlsType.Transform, TransformMode.Scale],
  Move: [ControlsType.Transform, TransformMode.Translate],
  Rotate: [ControlsType.Transform, TransformMode.Rotate],
};

const getCuboidControlModeName = (
  controlsType: ControlsType,
  transformMode: TransformMode
): string => {
  if (controlsType === ControlsType.AlignedBox) {
    return 'Resize';
  }
  const entries = Object.entries(CUBOID_CONTROL_MODES);
  const index = entries.findIndex(
    ([, [type, mode]]) => type === controlsType && mode === transformMode
  );
  return entries[index][0];
};

export const SplitCuboids = () => {
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const [cuboidClickMode, setCuboidClickMode] = useRecoilState(state.cuboidClickMode);
  const [cuboidControlsType, setCuboidControlsType] = useRecoilState(state.cuboidControlsType);
  const [cuboidTransformMode, setCuboidTransformMode] = useRecoilState(state.cuboidTransformMode);
  const [isCuboidVisibilityBoxEnabled, setIsCuboidVisibilityBoxEnabled] = useRecoilState(
    state.isCuboidVisibilityBoxEnabled
  );
  const setIsCuboidEditorEnabled = useSetRecoilState(state.isCuboidEditorEnabled);

  const isolatedPartId = useIsolatedPartId();
  const { removeCuboid } = useCuboids(isolatedPartId);

  const setCurrentCuboidAssemblyId = useCallback(
    (assemblyId: AssemblyId | undefined) => {
      setCurrentCuboid((current) => {
        if (!current) return undefined;
        return { ...current, assemblyId };
      });
    },
    [setCurrentCuboid]
  );

  const handleAddSplitCuboid = useCallback(() => {
    setCuboidClickMode((mode) =>
      mode === CuboidClickMode.AddCuboid ? CuboidClickMode.Default : CuboidClickMode.AddCuboid
    );
  }, [setCuboidClickMode]);

  const handleSelectCuboid = useCallback(() => {
    setCuboidClickMode((mode) =>
      mode === CuboidClickMode.SelectCuboid ? CuboidClickMode.Default : CuboidClickMode.SelectCuboid
    );
  }, [setCuboidClickMode]);

  const handleSaveCuboid = useCallback(() => {
    setIsCuboidEditorEnabled(false);
  }, [setIsCuboidEditorEnabled]);

  const handleCancelCuboid = useCallback(() => {
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
  }, [setCurrentCuboid, setIsCuboidEditorEnabled]);

  const handleRemoveCuboid = useCallback(() => {
    if (currentCuboid) {
      removeCuboid(currentCuboid);
    }
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
  }, [currentCuboid, removeCuboid, setCurrentCuboid, setIsCuboidEditorEnabled]);

  const handleSetControlsTypeModeEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const [controlsType, transformMode] = CUBOID_CONTROL_MODES[event.target.value];
      setCuboidControlsType(controlsType);
      if (transformMode !== undefined) {
        setCuboidTransformMode(transformMode);
      }
    },
    [setCuboidControlsType, setCuboidTransformMode]
  );

  useEffect(() => {
    switch (cuboidClickMode) {
      case CuboidClickMode.AddCuboid:
        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="info">Please click on Point cloud to place a new cuboid</Alert>,
        });
        break;
      case CuboidClickMode.SelectCuboid:
        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="info">Please click on Cuboid to select</Alert>,
        });
        break;
      default:
    }
  }, [cuboidClickMode, setSnackbarMessage]);

  return (
    <>
      {isolatedPartId && (
        <>
          <FormLabel>Split Cuboids</FormLabel>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddSplitCuboid}
            disabled={!!currentCuboid}
          >
            Add split cuboid
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSelectCuboid}
            disabled={!!currentCuboid}
          >
            Select split cuboid
          </Button>
          {currentCuboid && (
            <>
              <Box sx={{ paddingLeft: '20px' }}>
                <FormLabel>Selected Cuboid</FormLabel>
                <AssemblySelector
                  assemblyId={currentCuboid.assemblyId ?? undefined}
                  setAssemblyId={setCurrentCuboidAssemblyId}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={isCuboidVisibilityBoxEnabled}
                      onChange={() => setIsCuboidVisibilityBoxEnabled((current) => !current)}
                    />
                  }
                  disabled={cuboidControlsType === ControlsType.Transform}
                  label="Hide points outside Cuboid"
                />
                <RadioGroup
                  value={getCuboidControlModeName(cuboidControlsType, cuboidTransformMode)}
                  onChange={handleSetControlsTypeModeEvent}
                >
                  <FormControlLabel value="Resize" control={<Radio />} label="Resize" />
                  <FormControlLabel
                    value="SymmetricalResize"
                    control={<Radio />}
                    label="Symmetrical Resize"
                  />
                  <FormControlLabel value="Move" control={<Radio />} label="Move" />
                </RadioGroup>
                <ButtonGroup sx={{ paddingTop: '5px', paddingBottom: '10px' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveCuboid}
                    disabled={!currentCuboid}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemoveCuboid}
                    disabled={!currentCuboid}
                  >
                    Remove
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelCuboid}
                    disabled={!currentCuboid}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Box>
            </>
          )}
        </>
      )}
      {isolatedPartId && <SplitCuboidsTable />}
    </>
  );
};
