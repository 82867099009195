import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { MouseEvent, useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useUser } from '@auth0/nextjs-auth0';
import { useGetDisabledBlisterTemplateFieldIds } from '@/hooks';
import * as state from '@/components/Analysis/state';
import * as poiState from '../state';
import {
  PointOfInterestTemplateDocument,
  AllPointOfInterestTemplate,
  WidgetData,
  Permissions,
} from '@/types';
import { Widgets } from '@/components/shared/Widgets';
import { upperSnakeCase } from '@/utils';
import { Container } from './styles';
import { hasPermission } from '@/utils/userAccess';
import { PIT, BLISTER } from '@/constants';
import { PoiIcon } from '../PointOfInterestTab/PoiIcon';

export const PoiWidget = ({
  distanceFromRight,
  template,
  handleClick,
}: {
  distanceFromRight: number;
  template: PointOfInterestTemplateDocument | undefined;
  handleClick: (template: PointOfInterestTemplateDocument | undefined, isBlister: boolean) => void;
}) => {
  const { user } = useUser();
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const allTemplates = useRecoilValue<AllPointOfInterestTemplate>(
    state.allPointOfInterestTemplates
  );
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const [enableViewpoints, setEnableViewpoints] = useRecoilState(state.enableViewpoints);

  const disableBlisterFieldIds = useGetDisabledBlisterTemplateFieldIds(allTemplates);
  const blisterTemplateId = useMemo(() => {
    return allTemplates.find(({ name }) => name === BLISTER)?.id;
  }, [allTemplates]);

  const widgetData = useMemo<WidgetData[]>(() => {
    return allTemplates
      ?.filter(
        (data) =>
          data?.permissions?.every((permission) =>
            hasPermission(permission as Permissions, user)
          ) && data.name !== PIT
      )
      .map<WidgetData>((templateInfo) => {
        const joinedName = upperSnakeCase(templateInfo.name);

        return {
          id: templateInfo.id ?? '',
          displayName: templateInfo.name ?? '',
          size: 'large',
          color: 'secondary',
          icon: <PoiIcon name={joinedName} />,
        };
      })
      .sort((a, b) => {
        // sort widgets by name (ascending) so that the 'Blister' option is at the top of the widget
        return `${a.displayName}`.localeCompare(b.displayName);
      });
  }, [allTemplates, user]);

  const templateIdToInfo = useCallback(
    (templateId: string) =>
      allTemplates.find(({ id }) => id === templateId) as PointOfInterestTemplateDocument,
    [allTemplates]
  );

  const filteredWidgetData = useMemo<WidgetData[]>(() => {
    if (!selectedSpherical) {
      return widgetData.filter(
        (data) => !data.displayName.toLowerCase().includes(BLISTER.toLowerCase())
      );
    }
    return widgetData;
  }, [selectedSpherical, widgetData]);

  const clickHandlerWrapper = useCallback(
    (event: MouseEvent) => {
      const clickedIconId = event.currentTarget.id;

      // an option was selected, undo the workflows of the current selected option (undo all options)
      cancelPOIAdd(undefined);

      // previously selected option was selected again, deselct it and end
      if (clickedIconId === template?.id) {
        handleClick(undefined, false);
        if (enableViewpoints !== undefined) setAreSphericalsVisible(enableViewpoints);
        setEnableViewpoints(undefined);
        return;
      }

      // start the workflow of the selected option
      if (blisterTemplateId === clickedIconId && !disableBlisterFieldIds.includes(clickedIconId)) {
        handleClick(templateIdToInfo(clickedIconId), true);
      } else {
        handleClick(templateIdToInfo(clickedIconId), false);
      }
    },
    [
      blisterTemplateId,
      cancelPOIAdd,
      disableBlisterFieldIds,
      enableViewpoints,
      handleClick,
      setAreSphericalsVisible,
      setEnableViewpoints,
      template?.id,
      templateIdToInfo,
    ]
  );

  return (
    <Container toolbarPosition={distanceFromRight}>
      <Stack>
        <Widgets
          onChange={clickHandlerWrapper}
          data={filteredWidgetData}
          selectedButtonId={template?.id}
        />
      </Stack>
    </Container>
  );
};
