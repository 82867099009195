import { useEffect, memo, Suspense, lazy } from 'react';
import { useSetRecoilState } from 'recoil';
import { StructureStatus, useGetStructureStatusQuery } from './data.graphql';
import * as state from '@/state';
import { Breadcrumb } from '../shared/Breadcrumb';

const TaggingLazy = lazy(() =>
  import('../Inspection/Tagging').then(({ Tagging }) => ({ default: Tagging }))
);

const LabellingLazy = lazy(() =>
  import('../Inspection/Labelling').then(({ Labelling }) => ({ default: Labelling }))
);

export const InspectionRouter = memo(({ inspectionId }: { inspectionId: string }) => {
  const structureStatus = state.useStructureStatusFromId(inspectionId);

  const setSelectedStructureId = useSetRecoilState(state.selectedStructureId);

  useEffect(() => {
    setSelectedStructureId(inspectionId);
  }, [setSelectedStructureId, inspectionId]);

  const { data, loading, error } = useGetStructureStatusQuery({
    variables: {
      structureId: inspectionId,
    },
    fetchPolicy: 'no-cache',
    skip: !!structureStatus,
  });

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>{`InspectionRouter component: ${error.message}`}</>;
  }

  const queriedStructureStatus = data?.structure?.status;

  return (
    <>
      {(structureStatus || queriedStructureStatus) === StructureStatus.Tagging && (
        <>
          <Breadcrumb inspectionId={inspectionId} />
          <Suspense>
            <TaggingLazy />
          </Suspense>
        </>
      )}
      {(structureStatus || queriedStructureStatus) === StructureStatus.Labelling && (
        <>
          <Breadcrumb inspectionId={inspectionId} />
          <Suspense>
            <LabellingLazy />
          </Suspense>
        </>
      )}
    </>
  );
});
