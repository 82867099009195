import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { PointOfInterestChecked } from '@/types';
import { generatePoiDisplayName } from '../SelectedPoiContent/utils';

type Props = {
  poiId: string;
  equipmentName: string;
  clickHandler?: () => undefined;
  handleCheckedPOI: Function;
  poi: PointOfInterestChecked;
  templateId: string;
};

const boxStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  px: 2,
  py: 0,
  backgroundColor: '#f8f9fa',
  my: 0.5,
  cursor: 'pointer',
};

export const PoiListItem = ({
  poiId,
  equipmentName,
  poi,
  handleCheckedPOI,
  templateId,
  clickHandler,
}: Props) => {
  const poiName = generatePoiDisplayName({
    name: poi.name,
    poiId: poiId,
    poiType: poi.type,
  });
  return (
    <Box sx={boxStyles}>
      <Checkbox checked={poi.checked} onClick={handleCheckedPOI(poi, templateId)} />
      <Box sx={boxStyles} onClick={clickHandler}>
        <Typography fontSize="1.4rem">{poiName}</Typography>
        <Typography fontSize="1.4rem">{equipmentName}</Typography>
      </Box>
    </Box>
  );
};
