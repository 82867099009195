import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';
import { LabellingBlendingMode, LabellingColors } from '@/types';
import { colorWithAlpha } from '@/utils';

export const useInitialiseMode = (
  colorMap: ColorMap | undefined,
  { defaultColor, highlightColor }: LabellingColors
) => {
  const currentBlendingMode = useRecoilValue(state.labellingCurrentBlendingMode);
  const labellingStructureRelationships = useRecoilValue(state.labellingStructureRelationships);
  const shouldShowBlendingModes = useRecoilValue(state.labellingShouldShowBlendingModes);

  const byAnnotation3dReferenceWithColor = useMemo(() => {
    if (!labellingStructureRelationships?.byAnnotation3dReference) return undefined;
    if (!colorMap) return undefined;
    const annotations = Object.keys(labellingStructureRelationships.byAnnotation3dReference).map(
      Number
    );

    const annotationsWithPreviousColor = annotations.map((annotation3DReference) => {
      const color = colorMap?.getColor(annotation3DReference);
      let finalColor = 0x1;
      if (shouldShowBlendingModes) {
        const blendOpacity =
          currentBlendingMode === LabellingBlendingMode.SUPERVOXEL_RGB
            ? 0x60
            : currentBlendingMode === LabellingBlendingMode.SUPERVOXEL
            ? 0x1
            : 0xff;
        finalColor = colorWithAlpha(color, blendOpacity);
      } else {
        finalColor = color;
      }

      return {
        annotation3DReference,
        finalColor,
      };
    });
    return annotationsWithPreviousColor;
  }, [
    labellingStructureRelationships?.byAnnotation3dReference,
    colorMap,
    currentBlendingMode,
    shouldShowBlendingModes,
  ]);

  useEffect(() => {
    if (!colorMap || !colorMap?.setColor) return;
    if (!byAnnotation3dReferenceWithColor) return;

    byAnnotation3dReferenceWithColor.forEach(({ annotation3DReference, finalColor }) => {
      colorMap.setColor(annotation3DReference, finalColor);
    });
  }, [colorMap, defaultColor, byAnnotation3dReferenceWithColor, highlightColor]);
};

export const useBlendingModeEffect = (
  colorMap: ColorMap | undefined,
  allColors: LabellingColors
) => {
  useInitialiseMode(colorMap, allColors);
};
