import Stack from '@mui/material/Stack';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import * as state from '@/components/Analysis/state';
import { DefectAccordian } from './DefectAccordian';

export const DefectColorLegend = () => {
  const selectedStructureDefectsLegends = useRecoilValue(state.selectedStructureDefectsLegends);
  const selectedAssemblyTemplate = useRecoilValue(state.assemblyTemplate);

  const defectsArray = useMemo(() => {
    if (selectedAssemblyTemplate?.defectsArray) {
      return selectedAssemblyTemplate.defectsArray;
    }
    return [];
  }, [selectedAssemblyTemplate?.defectsArray]);

  const filteredDefectByTemplate = useMemo(() => {
    return selectedStructureDefectsLegends?.filter((d) => defectsArray.includes(d.groupKey));
  }, [defectsArray, selectedStructureDefectsLegends]);

  const uniqueGroupNames = useMemo(
    () =>
      filteredDefectByTemplate?.reduce((accumulator, current) => {
        if (!accumulator.includes(current.groupName)) {
          accumulator.push(current.groupName);
        }
        return accumulator;
      }, [] as string[]),
    [filteredDefectByTemplate]
  );

  const groupsAndDefects = useMemo(
    () =>
      uniqueGroupNames?.map((group) => {
        const defects = selectedStructureDefectsLegends?.filter(
          (defect) => defect.groupName === group
        );
        return { groupName: group, defects: defects || [] };
      }),
    [selectedStructureDefectsLegends, uniqueGroupNames]
  );

  if (!groupsAndDefects) return <>No Defects</>;

  return (
    <Stack justifyContent="space-evenly">
      {groupsAndDefects.map((group) => (
        <DefectAccordian key={group.groupName} {...group} />
      ))}
    </Stack>
  );
};
