import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FindViewpointsToolState } from '@/types';
import * as state from '@/components/Analysis/state';
import * as moduleState from '../state';
import { CurrentImageProps, ImageType } from '@abyss/3d-viewer';

export const useFindViewpointsFocusOnPoint = () => {
  const findViewpointsToolState = useRecoilValue(moduleState.findViewpointsToolState);
  const currentVoxelPoint = useRecoilValue(moduleState.currentVoxelPoint);
  const foundViewpoints = useRecoilValue(moduleState.foundViewpoints);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);

  const previousSpherical = useRef<CurrentImageProps | undefined>(undefined);

  // Focus on clicked point of FindViewpointsTool when entering spherical view
  useEffect(() => {
    if (!selectedSpherical) {
      previousSpherical.current = undefined;
      return;
    }
    if (previousSpherical.current === selectedSpherical) {
      return;
    }

    if (
      !currentVoxelPoint ||
      findViewpointsToolState === FindViewpointsToolState.INACTIVE ||
      findViewpointsToolState === FindViewpointsToolState.WAITING_FOR_POINT
    ) {
      return;
    }

    const foundIndex = foundViewpoints.findIndex(
      ({ name }) =>
        (selectedSpherical.imageType === ImageType.Spherical ||
          selectedSpherical.imageType === ImageType.Rectilinear) &&
        name === selectedSpherical.name
    );

    if (foundIndex < 0) {
      return;
    }

    previousSpherical.current = selectedSpherical;

    setTimeout(
      () =>
        setCameraTarget({
          position: selectedSpherical.position,
          lookAt: currentVoxelPoint,
        }),
      50
    );
  }, [
    selectedSpherical,
    currentVoxelPoint,
    findViewpointsToolState,
    foundViewpoints,
    setCameraTarget,
  ]);
};
