import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Number3, SphericalImageViewerClickEvent } from '@abyss/3d-viewer';
import * as localState from '@/components/Analysis/state';
import * as poiState from './state';
import { PointOfInterestDocument } from '@/types';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { calculateDistance3d, calculatePointOnRayWithDistance } from '@/utils/geometry';

const DEFAULT_BLISTER_DISTANCE = 3;

export function useBlisterSphericalClick() {
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );
  const [blisterToAdd, setBlisterToAdd] = useRecoilState(poiState.blisterToAdd);
  const selectedSpherical = useRecoilValue(localState.selectedSpherical);
  const selectedAssemblyId = useRecoilValue(localState.selectedAssemblyId);
  const structureId = useGetStructureId();

  const blisterSphericalClickHandler = useCallback(
    ({
      textureCoordinates,
      worldCoordinates,
      nativeEvent,
      pickPointCloud,
    }: SphericalImageViewerClickEvent) => {
      if (!structureId) return;
      if (!selectedSpherical) return;
      if (!selectedAssemblyId) return;
      if (blisterToAdd && blisterToAdd.state === 'Loading' && editPointOfInterest) return;

      if (blisterToAdd && blisterToAdd.state === 'WaitingForCenter' && editPointOfInterest) {
        let pickedPoint: Number3 | undefined;
        const pickResult = pickPointCloud(nativeEvent);
        if (pickResult) {
          const point = pickResult
            .getPointAttributes(pickResult.pointIndex, ['position'])
            .get('position');
          if (point && Array.isArray(point) && point.length === 3) {
            pickedPoint = [point[0], point[1], point[2]];
          }
        }

        const pointOnSphere: Number3 = [worldCoordinates.x, worldCoordinates.y, worldCoordinates.z];

        // useBlisterData onMeasure will update centerPoint3d
        // with more precise position
        const distance = pickedPoint
          ? calculateDistance3d(selectedSpherical.position, pickedPoint)
          : DEFAULT_BLISTER_DISTANCE;

        const point = calculatePointOnRayWithDistance(
          selectedSpherical.position,
          pointOnSphere,
          distance
        );
        const [x, y, z] = selectedSpherical.position;

        const newPointOfInterest = {
          id: Object.values(worldCoordinates).toString(),
          centerPoint3d: { x: point[0], y: point[1], z: point[2] },
          cameraLocation: {
            x,
            y,
            z,
          },
          type: 'OTHER',
          name: '', // TODO Next PI. Add Naming capability
          location: { id: selectedSpherical?.id! },
          assembly: { id: selectedAssemblyId, structure: { id: structureId! } },
        };
        setEditPointOfInterest((current) => ({
          ...current,
          pointOfInterest: newPointOfInterest as PointOfInterestDocument,
        }));

        setBlisterToAdd({
          state: 'WaitingForEdge',
          centerPoint: textureCoordinates,
        });
      } else if (
        blisterToAdd &&
        blisterToAdd.centerPoint &&
        editPointOfInterest &&
        editPointOfInterest.pointOfInterest
      ) {
        setBlisterToAdd({
          ...blisterToAdd,
          state: 'Loading',
          edgePoint: textureCoordinates,
        });
        setEditPointOfInterest((current) => ({
          ...current,
          pointOfInterest: {
            ...current.pointOfInterest!,
            blister: {
              centerPoint: {
                bearing: blisterToAdd.centerPoint!.x,
                elevation: blisterToAdd.centerPoint!.y,
              },
              radiusPoint: {
                bearing: textureCoordinates.x,
                elevation: textureCoordinates.y,
              },
              borders3d: current.pointOfInterest?.blister?.borders3d ?? [],
            },
          } as PointOfInterestDocument,
          state: 'WaitingForDetail',
          formState: 'Create',
        }));
      }
    },
    [
      structureId,
      selectedSpherical,
      selectedAssemblyId,
      blisterToAdd,
      editPointOfInterest,
      setBlisterToAdd,
      setEditPointOfInterest,
    ]
  );

  return blisterSphericalClickHandler;
}
