import { useRecoilState } from 'recoil';
import * as state from '@/state';
import { InspectionExplorer } from './InspectionExplorer';
import { InspectionDataLoader } from './InspectionDataLoader';
import { Snackbar } from '../../shared/Snackbar';

export const Labelling = () => {
  const [snackbarMessage, setSnackbarMessage] = useRecoilState(state.snackbarMessage);

  const closeSnack = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage({ shouldShow: false, content: undefined });
  };

  const { shouldShow, content } = snackbarMessage;

  return (
    <>
      <InspectionDataLoader>
        <Snackbar open={shouldShow} onClose={closeSnack}>
          {content}
        </Snackbar>
        <InspectionExplorer />
      </InspectionDataLoader>
    </>
  );
};
