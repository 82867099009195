import { PointPickResult } from '@abyss/3d-viewer';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as localState from '@/components/Analysis/state';
import * as poiState from './state';
import { PointOfInterestDocument } from '@/types';
import { useGetStructureId } from '@/hooks/useGetStructureId';

export const useHandlePoiClick = () => {
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );
  const blisterToAdd = useRecoilValue(poiState.blisterToAdd);

  const selectedAssemblyId = useRecoilValue(localState.selectedAssemblyId);
  const selectedSpherical = useRecoilValue(localState.selectedSpherical);
  const structureId = useGetStructureId();

  const handlePOIClick = useCallback(
    (pickResult: PointPickResult) => {
      if (
        editPointOfInterest.state === 'WaitingForPoint' &&
        selectedAssemblyId &&
        structureId &&
        !blisterToAdd
      ) {
        const point = pickResult
          .getPointAttributes(pickResult.pointIndex, ['position'])
          .get('position') as number[];
        const [x, y, z] = selectedSpherical?.position || [point[0], point[1], point[2]];
        const newPointOfInterest = {
          centerPoint3d: { x: point[0], y: point[1], z: point[2] },
          cameraLocation: {
            x,
            y,
            z,
          },
          type: 'OTHER',
          name: '', // TODO Next PI. Add Naming capability
          location: { id: selectedSpherical?.id! },
          assembly: { id: selectedAssemblyId, structure: { id: structureId } },
        };
        setEditPointOfInterest((current) => ({
          ...current,
          state: 'WaitingForDetail',
          formState: 'Create',
          pointOfInterest: newPointOfInterest as PointOfInterestDocument,
        }));
      }
    },
    [
      editPointOfInterest.state,
      selectedAssemblyId,
      structureId,
      blisterToAdd,
      selectedSpherical?.position,
      selectedSpherical?.id,
      setEditPointOfInterest,
    ]
  );

  return handlePOIClick;
};
