import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import * as Sentry from '@sentry/nextjs';

import * as state from '@/state';
import * as selectors from '@/state/selectors';
import { SplitPartInput, useSplitAnnotationFromPartMutation } from './data.graphql';
import { LoadingAlert } from '@/components/shared/LoadingAlert';
import { AnnotationId, AssemblyInfo } from '@/types';

export const SplitPartButton = () => {
  const { user } = useUser();

  const selectedStructureId = useRecoilValue(state.selectedStructureId);
  const selectedZone = useRecoilValue(state.selectedZone);
  const [selectedAnnotationIds, setSelectedAnnotationIds] = useRecoilState(
    state.selectedAnnotationIds
  );
  const [selectedPartIds, setSelectedPartIds] = useRecoilState(state.selectedPartIds);
  const [{ previousMode: previousAbyssViewerMode }, setAbyssViewerState] = useRecoilState(
    selectors.setAbyssViewerState
  );
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const setStructureRelationships = useSetRecoilState(state.structureRelationships);

  const [splitAnnotationFromPartMutation, { loading, error }] =
    useSplitAnnotationFromPartMutation();

  const handleSplitPart = useCallback(() => {
    if (
      selectedPartIds &&
      selectedStructureId &&
      selectedAnnotationIds.length > 0 &&
      selectedPartIds.length === 1
    ) {
      let input: SplitPartInput = {
        partId: selectedPartIds[0],
        annotationIdsForNewPart: selectedAnnotationIds,
        structureId: selectedStructureId,
      };
      if (selectedZone?.id) {
        input = { ...input, zoneId: selectedZone.id };
      }

      splitAnnotationFromPartMutation({
        variables: {
          input,
        },
        onCompleted(mutationReturn) {
          if (mutationReturn.splitPart) {
            const newPartId = mutationReturn.splitPart.id;

            if (mutationReturn.splitPart.updatedBy === user?.sub) {
              setSnackbarMessage({
                shouldShow: true,
                content: <Alert severity="success">Part successfully split</Alert>,
              });
            }

            setStructureRelationships((currentRelationships) => {
              if (!currentRelationships) {
                return currentRelationships;
              }

              const byAssemblyId = currentRelationships.byAssemblyId.asMutableStateMap();
              const byPartId = currentRelationships.byPartId.asMutableStateMap();
              const byAnnotationId = currentRelationships.byAnnotationId.asMutableStateMap();

              const originalPart = byPartId.get(input.partId);
              let newPart = byPartId.get(newPartId);
              if (!newPart) {
                if (originalPart) {
                  newPart = {
                    ...originalPart,
                    annotationIds: new Set<AnnotationId>(),
                  };
                  byPartId.set(newPartId, newPart);
                } else {
                  Sentry.captureMessage(`Part split: original part not found`, {
                    user: { sub: user?.sub },
                    extra: { partSplitData: { newPartId, originalPartId: input.partId } },
                  });
                  return currentRelationships;
                }
              }

              let newAssembly: AssemblyInfo | undefined;
              if (newPart?.assemblyId) {
                newAssembly = byAssemblyId.get(newPart?.assemblyId);
                if (!newAssembly) {
                  Sentry.captureMessage(`Part split: new assembly not found`, {
                    user: { sub: user?.sub },
                    extra: {
                      newAssemblyId: newPart?.assemblyId,
                      partSplitData: { newPartId, originalPartId: input.partId },
                    },
                  });
                }
              }

              selectedAnnotationIds.forEach((annotationId) => {
                const annotation = byAnnotationId.get(annotationId);
                if (annotation) {
                  // remove from old part
                  const oldPart = byPartId.get(annotation.partId);
                  oldPart?.annotationIds.delete(annotationId);

                  if (newPart) {
                    // add annotations to new part
                    newPart.annotationIds.add(annotationId);
                    // update annotation assembly
                    byAnnotationId.set(annotationId, {
                      ...annotation,
                      partId: newPartId,
                    });
                  }
                }
              });

              return {
                ...currentRelationships,
                byPartId: byPartId.asStateMap(),
                byAnnotationId: byAnnotationId.asStateMap(),
              };
            });

            setSelectedAnnotationIds([]);
            setSelectedPartIds([newPartId]);
            setAbyssViewerState({ mode: previousAbyssViewerMode || 'Normal' });
          } else {
            setSnackbarMessage({
              shouldShow: true,
              content: (
                <Alert severity="error">
                  Failed to split the part&nbsp; Please try again later
                </Alert>
              ),
            });
          }
        },
      });
    } else {
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="warning">Please select labels to split to a new part</Alert>,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previousAbyssViewerMode,
    selectedAnnotationIds,
    selectedPartIds,
    selectedStructureId,
    setAbyssViewerState,
    setSelectedAnnotationIds,
    setSelectedPartIds,
    setSnackbarMessage,
    splitAnnotationFromPartMutation,
  ]);

  useEffect(() => {
    if (loading) {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <div>
            <LoadingAlert message="Splitting part" />
          </div>
        ),
      });
    } else if (error) {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <Alert severity="error">Failed to split the part&nbsp; Please try again later</Alert>
        ),
      });
    }
  }, [loading, error, setSnackbarMessage]);

  return (
    <Button variant="contained" color="secondary" onClick={handleSplitPart} disabled={loading}>
      Split selection into new part
    </Button>
  );
};
