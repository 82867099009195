import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';

// iterate over the keys and check for a single true
const isOneDrawerOpen = (drawerState: { [key: string]: boolean }) => {
  return Object.values(drawerState).some(Boolean);
};

export const useDrawerOpen = (side: 'right' | 'left') => {
  const drawerRight = useRecoilValue(state.drawerRight);
  const drawerLeft = useRecoilValue(state.drawerLeft);

  if (side === 'right') {
    return isOneDrawerOpen(drawerRight);
  }
  return isOneDrawerOpen(drawerLeft);
};
