/* eslint-disable react/jsx-one-expression-per-line */

import CircularProgress from '@mui/material/CircularProgress';
import { AllHistoriesQuery, usePartQuery } from '../../data.graphql';
import { formatDate, formatName } from '@/utils';

type PartSplitEntryProps = {
  history: AllHistoriesQuery['allHistories'][0];
};

export const PartSplitEntry = ({ history }: PartSplitEntryProps) => {
  const { data, loading, error } = usePartQuery({
    variables: {
      partId: history.document.originalPartId,
    },
  });

  if (!error) {
    return (
      <p>
        <b>
          Part split from{' '}
          {loading || !data ? (
            <CircularProgress size={10} thickness={7.2} />
          ) : (
            data.part?.assembly?.tagName
          )}
        </b>
        <br />
        {formatName(history.createdByName)} - {formatDate(new Date(Number(history.createdAt)))}
      </p>
    );
  }

  // eslint-disable-next-line unicorn/no-null
  return null;
};
