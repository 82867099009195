import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactElement } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import styled from 'styled-components';
import {
  drawerContainerStyles,
  drawerMainStyles,
  drawerBodyStyles,
  drawerChildrenContainerStyles,
} from './styles';
import { AnalysisNavbar } from '@/components/Analysis/shared/AnalysisNavbar';
import { Navbar } from '@/components/shared/Navbar';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
interface MainProps {
  navbarHeight: number;
}
const Main = styled.main<MainProps>`
  padding-top: ${(props) => props.navbarHeight}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

type DrawerLayoutProps = {
  children: ReactElement[] | ReactElement;
  variant?: 'analysis' | 'inspection';
};

type DrawerItem = {
  title: string;
  link: string;
  disabled?: boolean;
  icon?: ReactElement;
};

const listItems: DrawerItem[] = [
  { title: 'Zones Allocation', link: 'tasks', icon: <ViewQuiltIcon /> },
];

export const DrawerLayout = ({ variant = 'inspection', children }: DrawerLayoutProps) => {
  const router = useRouter();
  const navbarHeight = useNavbarHeight();

  const links = router.asPath.split('/');

  const activeLink = links.at(-1);

  const { inspection } = router.query;

  return (
    <Main navbarHeight={navbarHeight}>
      {variant === 'analysis' ? <AnalysisNavbar /> : <Navbar />}
      <Box sx={drawerContainerStyles}>
        <Drawer variant="permanent" sx={drawerMainStyles}>
          <Box sx={drawerBodyStyles}>
            <List>
              {listItems.map(({ title, link, icon }) => (
                <ListItem key={title} disablePadding>
                  <Link href={`/inspection/${inspection}/${link}`}>
                    <ListItemButton selected={link === activeLink}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box sx={drawerChildrenContainerStyles}>
          {Array.isArray(children) ? children.map((child) => <>{child}</>) : children}
        </Box>
      </Box>
    </Main>
  );
};
