import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';

export function useUpdateColor() {
  const selectedSuperVoxels = useRecoilValue(state.selectedSuperVoxels);

  const updateColor = useCallback(
    (
      colorMap: ColorMap,
      color: number,
      highlightColor: number,
      annotation3DReference: number[]
    ) => {
      annotation3DReference.forEach((annotation) => {
        const isSelected = selectedSuperVoxels.indexOf(annotation);
        if (isSelected > 0) {
          colorMap.setColor(annotation, highlightColor);
        } else {
          colorMap.setColor(annotation, color);
        }
      });
    },
    [selectedSuperVoxels]
  );

  return {
    updateColor,
  };
}
