import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Cancel from '@mui/icons-material/Cancel';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { Alert } from '@mui/material';
import { FileCopy, SquareFoot } from '@mui/icons-material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { Can } from '@/components/Can';
import { useFeatureFlag, useHavePermission } from '@/hooks';
import { AnalyseViewpointButton } from './AnalyseViewpointButton/AnalyseViewpointButton';
import FilterIcon from '@mui/icons-material/Filter';
import * as state from '@/components/Analysis/state';
import { snackbarMessage } from '@/state';
import { poiState } from '@/components/Analysis/modules/pointOfInterest';
import { useDrawerOpen } from '@/hooks/useDrawerOpen';
import { AnalysisDrawerRight, Permissions } from '@/types';
import {
  MeasurementToolMenu,
  useMeasurementTool,
} from '@/components/Analysis/modules/measurementTool';
import { ResponsiveButton, ResponsiveText, Toolbar } from './styles';
import { toggleValue } from '@/utils';
import { useFindViewpointsTool } from '@/components/Analysis/modules/findViewpointsTool';
import { FindViewpointsToolMenu } from '@/components/Analysis/modules/findViewpointsTool/FindViewpointsToolMenu';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';
import { useWindowSize } from '@/hooks/useWindowSize';
import { FabDropdown } from '@/components/shared/FabDropdown/FabDropdown';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import { useFocusOnSelectedAssembly } from '../../../hooks/useFocusOnSelectedAssembly';
import { CuboidToolbar } from '@/components/Analysis/modules/cuboids';
import * as cuboidState from '@/analysis-cuboids/state';

const SuccessAlert = (
  <div>
    <Alert severity="success">Url copies to clipboard.</Alert>
  </div>
);

type MenuBarProps = {
  updateCameraTarget: () => void;
};

export const MenuBar = ({ updateCameraTarget }: MenuBarProps) => {
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const { isMeasurementMenuVisible } = useMeasurementTool();
  const { isFindViewpointsToolActive, activateFindViewpointsTool } = useFindViewpointsTool();
  const [showPointCloudInSpherical, setShowPointCloudInSpherical] = useRecoilState(
    state.showPointCloudInSpherical
  );
  const setSnackbarMessage = useSetRecoilState(snackbarMessage);
  const inspectionMetadata = useRecoilValue(state.inspectionMetadata);
  const structureLocations = useRecoilValue(state.structureLocations);
  const [selectedSpherical, setSelectedSpherical] = useRecoilState(state.selectedSpherical);
  const structureLocationsMap = useRecoilValue(state.structureLocationsMap);
  const setImageLayer = useSetRecoilState(state.imageLayer);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const [enableViewpoints, setEnableViewpoints] = useRecoilState(state.enableViewpoints);
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const [drawerRight, setDrawerRight] = useRecoilState(state.drawerRight);

  const isCuboidEditorEnabled = useRecoilValue(cuboidState.isCuboidEditorEnabled);

  const isAlphaUser = useFeatureFlag('alpha-user');
  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);

  const isRightDrawerOpen = useDrawerOpen('right');
  const isLeftDrawerOpen = useDrawerOpen('left');
  const screenSize = useWindowSize();

  const { leftDrawerWidth } = useDrawerWidths();

  const exitSphericalButtonDistanceFromRight = useMemo(() => {
    return isRightDrawerOpen ? leftDrawerWidth + 70 : 70;
  }, [leftDrawerWidth, isRightDrawerOpen]);

  const buttonsDistanceFromLeft = useMemo(() => {
    return isLeftDrawerOpen ? leftDrawerWidth + 64 : 72;
  }, [leftDrawerWidth, isLeftDrawerOpen]);

  const closeImageOffset = useMemo(() => {
    const { width } = screenSize;
    if (width && width <= 1728) {
      return 32;
    }
    return 48;
  }, [screenSize]);

  const sphericalInfoOffset = useMemo(() => {
    const { width } = screenSize;

    if (width && width <= 1728) {
      return 190;
    }
    return 232;
  }, [screenSize]);

  const exitImageViewpoint = () => {
    if (enableViewpoints !== undefined) setAreSphericalsVisible(enableViewpoints);
    setEnableViewpoints(undefined);
    setCameraTarget(undefined);
    setSelectedSpherical(undefined);
    cancelPOIAdd(undefined);
  };

  const selectedSphericalDeckName = useMemo(() => {
    if (!selectedSpherical) return undefined;
    const deckName = structureLocations?.find((location) => location.id === selectedSpherical?.id)
      ?.deck.name;
    return deckName;
  }, [selectedSpherical, structureLocations]);

  const toggleHighlightAsset = () => {
    setShowPointCloudInSpherical((current) => !current);
  };

  const handleGenerateDeepLinkClicked = useCallback(() => {
    updateCameraTarget();
    navigator.clipboard.writeText(window.location.href);
    setSnackbarMessage({
      shouldShow: true,
      content: SuccessAlert,
    });
  }, [setSnackbarMessage, updateCameraTarget]);

  const handleRightToggle = useCallback(
    (property: keyof AnalysisDrawerRight) => {
      setDrawerRight(toggleValue<AnalysisDrawerRight>(drawerRight, property));
    },
    [drawerRight, setDrawerRight]
  );

  const { measurementToolHandlerForAdormants, cancelMeasuring } = useMeasurementTool();
  const enableMeasureTool = useCallback(() => {
    measurementToolHandlerForAdormants();
    handleRightToggle('measuringTool');
  }, [handleRightToggle, measurementToolHandlerForAdormants]);

  useEffect(() => {
    if (!drawerRight.measuringTool) {
      cancelMeasuring();
    }
  }, [cancelMeasuring, drawerRight]);

  const { focusOnAssembly } = useFocusOnSelectedAssembly(selectedSpherical);

  const enableFindViewpointsTool = useCallback(() => {
    activateFindViewpointsTool();
    handleRightToggle('findViewpointsTool');
  }, [handleRightToggle, activateFindViewpointsTool]);

  const hasActiveTool = isMeasurementMenuVisible || isFindViewpointsToolActive;

  const imageLayers = useMemo(() => {
    if (selectedSpherical?.id && structureLocationsMap) {
      return structureLocationsMap?.get(selectedSpherical.id)?.layers ?? [];
    }
    return [];
  }, [selectedSpherical?.id, structureLocationsMap]);

  const imageLayersOptions = useMemo(() => {
    const options = imageLayers?.map((layer) => layer?.displayName ?? '') ?? [];
    const defaultText = 'Default';
    if (!options.includes(defaultText)) {
      options.unshift(defaultText);
    }
    return options ?? [];
  }, [imageLayers]);

  const changeImage = useCallback(
    (selectedIndex: number) => {
      if (imageLayers) {
        const imageLayer = imageLayers[selectedIndex - 1]; // Cater for Default being index 0
        setImageLayer(imageLayer ?? undefined);
      }

      setSelectedImage(selectedIndex);
      setSelectedSpherical((current) => {
        if (!current) return current;
        let currentSpherical = { ...current };
        return currentSpherical;
      });
    },
    [imageLayers, setImageLayer, setSelectedSpherical]
  );

  return (
    <>
      {isMeasurementMenuVisible && (
        <div
          style={{
            position: 'absolute',
            top: 20,
            zIndex: 1,
            left: buttonsDistanceFromLeft,
          }}
        >
          <MeasurementToolMenu />
        </div>
      )}
      {isFindViewpointsToolActive && (
        <div
          style={{
            position: 'absolute',
            top: 20,
            zIndex: 1,
            left: buttonsDistanceFromLeft,
          }}
        >
          <FindViewpointsToolMenu />
        </div>
      )}
      <Toolbar offset={buttonsDistanceFromLeft}>
        <Stack gap={1} mb={1} direction="row">
          {!hasActiveTool && (
            <ResponsiveButton
              variant="contained"
              className="responsive-btn"
              onClick={handleGenerateDeepLinkClicked}
              startIcon={<FileCopy />}
            >
              Copy view
            </ResponsiveButton>
          )}
          {!hasActiveTool && (
            <Can I="read" a="PointOfInterest">
              <ResponsiveButton
                variant="contained"
                className="responsive-btn"
                onClick={enableMeasureTool}
                startIcon={<SquareFoot />}
              >
                Measure
              </ResponsiveButton>
            </Can>
          )}
          {/* Only enable cuboid icons if the user is adding a cuboid */}
          {isCuboidEditorEnabled && <CuboidToolbar />}
          {!hasActiveTool &&
            !selectedSpherical &&
            inspectionMetadata?.voxelLocationsPath && (
              <Can I="read" a="PointOfInterest">
                <ResponsiveButton
                  variant="contained"
                  className="responsive-btn"
                  onClick={enableFindViewpointsTool}
                  startIcon={<AdsClickIcon />}
                >
                  Find viewpoint
                </ResponsiveButton>
              </Can>
            )}
          {isAlphaUser && !hasActiveTool && imageLayers?.length !== 0 && !selectedSpherical && (
            <FabDropdown
              icon={<FilterIcon sx={{ mr: 1 }} />}
              title="Toggle Image"
              options={imageLayersOptions}
              onSelect={(index) => changeImage(index)}
              selectedIndex={selectedImage}
            />
          )}
        </Stack>
        <Stack gap={1} direction="row">
          {!hasActiveTool &&
            selectedSpherical &&
            inspectionMetadata?.voxelLocationsPath && (
              <Can I="read" a="PointOfInterest">
                <ResponsiveButton
                  variant="contained"
                  className="responsive-btn"
                  onClick={enableFindViewpointsTool}
                  startIcon={<AdsClickIcon />}
                >
                  Find viewpoint
                </ResponsiveButton>
              </Can>
            )}
          {selectedSpherical && (
            <ResponsiveButton
              variant="contained"
              onClick={focusOnAssembly}
              startIcon={<FilterCenterFocusIcon />}
            >
              Focus on Component
            </ResponsiveButton>
          )}
          {isAlphaUser && !hasActiveTool && imageLayers?.length !== 0 && selectedSpherical && (
            <FabDropdown
              icon={<FilterIcon sx={{ mr: 1 }} />}
              title="Toggle Image"
              options={imageLayersOptions}
              onSelect={(index) => changeImage(index)}
              selectedIndex={selectedImage}
            />
          )}
          {selectedSpherical && userCanUpdateAsset && (
            <>
              <ResponsiveButton variant="contained" onClick={toggleHighlightAsset}>
                {showPointCloudInSpherical ? 'Remove highlight' : 'Highlight asset'}
              </ResponsiveButton>
              <AnalyseViewpointButton />
            </>
          )}
        </Stack>
      </Toolbar>

      {selectedSpherical && (
        <>
          <ResponsiveText
            sx={{
              position: 'absolute',
              right: exitSphericalButtonDistanceFromRight + sphericalInfoOffset,
              color: 'white',
              top: 10,
              zIndex: 1,
            }}
          >
            {selectedSphericalDeckName} / <b>{selectedSpherical?.name}</b>
          </ResponsiveText>
          <ResponsiveButton
            variant="contained"
            className="responsive-btn"
            sx={{
              position: 'absolute',
              right: exitSphericalButtonDistanceFromRight + closeImageOffset,
              top: 10,
              zIndex: 1,
            }}
            onClick={exitImageViewpoint}
            startIcon={<Cancel />}
          >
            Close image view
          </ResponsiveButton>
        </>
      )}
    </>
  );
};
