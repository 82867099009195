import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Collapse, FormControl, FormLabel, IconButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import * as state from '@/state';
import { useEquipmentClassesForStrunctureQuery } from './data.graphql';

type EquipmentClass = {
  name: string;
  code: string;
};

type EquipmentClassGroup = {
  name: string;
  classes: EquipmentClass[];
};

const equipmentClassesContainerStyles = {
  display: 'flex',
  width: '100%',
};

const equipmentClassesGroupContainerStyles = {
  flex: '1 1 auto',
};

const equipmentClassesGroupItemStyles = {
  display: 'flex',
  flexDirection: 'column',
  ml: 3,
};

export default function HideUnhideEquipment() {
  const [expandedEquipmentGroups, setExpandedEquipmentGroups] = useState<string[]>([]);
  const [selectedEquipmentClasses, setSelectedEquipmentClasses] = useState<string[]>([]);
  const structureId = useRecoilValue(state.selectedStructureId) || '';
  const initialEquipmentClassesHasSet = useRef(false);

  const setStructureRelationships = useSetRecoilState(state.structureRelationships);

  const { data } = useEquipmentClassesForStrunctureQuery({
    variables: {
      structureId,
    },
  });

  useEffect(() => {
    let allEquipmentClassesCodes: string[] = [];
    data?.structure?.equipmentClasses.forEach((equipmentClassGroup) => {
      allEquipmentClassesCodes = [
        ...allEquipmentClassesCodes,
        ...equipmentClassGroup.classes.map((equipmentClass) => equipmentClass.code),
      ];
    });
    setSelectedEquipmentClasses(allEquipmentClassesCodes);
  }, [data]);

  useEffect(() => {
    if (initialEquipmentClassesHasSet.current) {
      setStructureRelationships((currentRelationships) => {
        if (currentRelationships) {
          const byPartId = currentRelationships.byPartId.asMutableStateMap();
          if (data?.structure?.equipmentClasses && data?.structure?.equipmentClasses.length > 0) {
            byPartId.forEach((part, partId) => {
              const isHiddenVolatile = !selectedEquipmentClasses.includes(part.class);
              byPartId.set(partId, { ...part, isHiddenVolatile });
            });

            return { ...currentRelationships, byPartId: byPartId.asStateMap() };
          }
        }
        return currentRelationships;
      });
    } else {
      initialEquipmentClassesHasSet.current = true;
    }
  }, [selectedEquipmentClasses, setStructureRelationships, data?.structure?.equipmentClasses]);

  const toggleGroupExpansion = useCallback(
    (group: string) => () => {
      const indexOfGroup = expandedEquipmentGroups.indexOf(group);
      const updatedExpandedGroups = [...expandedEquipmentGroups];
      if (indexOfGroup === -1) {
        updatedExpandedGroups.push(group);
      } else {
        updatedExpandedGroups.splice(indexOfGroup, 1);
      }
      setExpandedEquipmentGroups(updatedExpandedGroups);
    },
    [expandedEquipmentGroups]
  );

  const isGroupSelected = useCallback(
    (equipmentGroup: EquipmentClassGroup) => {
      const groupItemIds = equipmentGroup.classes.map((item: EquipmentClass) => item.code);
      return (
        groupItemIds.filter(
          (equipmentClassCode) => !selectedEquipmentClasses.includes(equipmentClassCode)
        ).length === 0
      );
    },
    [selectedEquipmentClasses]
  );

  const handleGroupClicked = useCallback(
    (group: EquipmentClassGroup) => () => {
      const equipmentClassesCodes = group.classes.map((equipmentClass) => equipmentClass.code);
      if (isGroupSelected(group)) {
        setSelectedEquipmentClasses(
          selectedEquipmentClasses.filter((x) => !equipmentClassesCodes.includes(x))
        );
      } else {
        const concatinatedList = [...selectedEquipmentClasses, ...equipmentClassesCodes];
        setSelectedEquipmentClasses([...new Set(concatinatedList)]);
      }
    },
    [isGroupSelected, selectedEquipmentClasses]
  );

  const handleItemClicked = useCallback(
    (equipmentClassCode: string) => () => {
      const updatedSelectedEuipmentClassesCodes = [...selectedEquipmentClasses];
      const indexOfClickedEquipmentClass = selectedEquipmentClasses.indexOf(equipmentClassCode);
      if (indexOfClickedEquipmentClass === -1) {
        updatedSelectedEuipmentClassesCodes.push(equipmentClassCode);
      } else {
        updatedSelectedEuipmentClassesCodes.splice(indexOfClickedEquipmentClass, 1);
      }
      setSelectedEquipmentClasses(updatedSelectedEuipmentClassesCodes);
    },
    [selectedEquipmentClasses]
  );

  const renderGroupItems = (group: EquipmentClassGroup) => {
    const { classes, name } = group;
    return (
      <Collapse in={expandedEquipmentGroups.includes(name)} timeout="auto" unmountOnExit>
        <Box sx={equipmentClassesGroupItemStyles}>
          {classes.map((equipmentClass) => (
            <FormControlLabel
              key={equipmentClass.name}
              label={equipmentClass.name}
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  checked={selectedEquipmentClasses.includes(equipmentClass.code)}
                  onChange={handleItemClicked(equipmentClass.code)}
                />
              }
            />
          ))}
        </Box>
      </Collapse>
    );
  };

  return (
    <>
      {!!data?.structure?.equipmentClasses.length && (
        <DockPanelItem>
          <FormControl fullWidth>
            <FormLabel>Hide / Unhide Equipment</FormLabel>
            {data?.structure?.equipmentClasses.map((equipmentClass) => (
              <Fragment key={equipmentClass.name}>
                <Box sx={equipmentClassesContainerStyles}>
                  <FormControlLabel
                    sx={equipmentClassesGroupContainerStyles}
                    label={equipmentClass.name}
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        checked={isGroupSelected(equipmentClass)}
                        onChange={handleGroupClicked(equipmentClass)}
                      />
                    }
                  />
                  {expandedEquipmentGroups.includes(equipmentClass.name) ? (
                    <IconButton onClick={toggleGroupExpansion(equipmentClass.name)}>
                      <ExpandLess />
                    </IconButton>
                  ) : (
                    <IconButton onClick={toggleGroupExpansion(equipmentClass.name)}>
                      <ExpandMore />
                    </IconButton>
                  )}
                </Box>
                {renderGroupItems(equipmentClass)}
              </Fragment>
            ))}
          </FormControl>
        </DockPanelItem>
      )}
    </>
  );
}
