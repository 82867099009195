import { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Slider, { SliderProps } from '@mui/material/Slider';
import { useRecoilState } from 'recoil';
import * as state from '@/state';

type PointBudgetSliderProps = {
  title: string;
} & SliderProps;

const valuetext = (value: number) => {
  return `${value}`;
};

export const PointBudgetSlider = ({ title, ...props }: PointBudgetSliderProps) => {
  const [pointBudget, setPointBudget] = useRecoilState<number>(state.pointBudget);
  const [unfinishedPointBudget, setUnfinishedPointBudget] = useState(pointBudget);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setPointBudget(unfinishedPointBudget);
    }, 500);

    return () => clearTimeout(debounce);
  }, [setPointBudget, unfinishedPointBudget]);

  const handlePointBudgetChanged = (event: Event, value: number | number[]) => {
    setUnfinishedPointBudget(value as number);
  };

  return (
    <>
      <FormLabel>{title}</FormLabel>
      <Slider
        {...props}
        aria-label="PointBudget"
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        value={pointBudget}
        onChange={handlePointBudgetChanged}
      />
    </>
  );
};
