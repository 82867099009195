import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';

export const Line = styled('hr')`
  width: 20px;
  border: 1px solid #000;
`;

export const DashedLine = styled('hr')`
  width: 20px;
  border: 1px dashed hsla(218, 13%, 49%, 0.2);
  margin: 0 2px;
`;

export const StyledBorderedAvatar = styled(Avatar)`
  border: 2px solid #000;
`;

export const EmptyAvatar = styled(Avatar)`
  background-color: #fff;
  border: 2px solid hsla(218, 13%, 49%, 0.2);
  border-style: dashed;
`;

export const listStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const containerStyles = { position: 'absolute', top: -18, right: '20%' };

export const tooltipStyles = {
  backgroundColor: '#fff',
  color: 'rgba(0, 0, 0, 0.87)',
  minWidth: 230,
  fontSize: '1.2rem',
  borderRadius: 7,
  paddingLeft: 15,
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
};
