import React, { useCallback } from 'react';
import Button from '@mui/material/Button';

type Props = {
  onResetCameraClicked?: () => void;
  getHtmlCanvas?: () => HTMLCanvasElement | undefined;
};

const buttonStyles = {
  marginLeft: 8,
};

export const MenuBar = ({ onResetCameraClicked, getHtmlCanvas }: Props) => {
  const handleResetCameraClicked = useCallback(() => {
    if (onResetCameraClicked) {
      onResetCameraClicked();
    }
  }, [onResetCameraClicked]);

  const handleTake3dSnapshot = useCallback(() => {
    const filename = 'viewer_screenshot';

    const htmlCanvas = getHtmlCanvas && getHtmlCanvas();

    if (htmlCanvas === undefined) {
      return;
    }
    const canvas2 = document.createElement('canvas');
    canvas2.width = htmlCanvas.width;
    canvas2.height = htmlCanvas.height;
    const context2 = canvas2.getContext('2d');
    // eslint-disable-next-line eqeqeq
    if (context2 == undefined) {
      return;
    }

    // fill background
    context2.fillStyle = 'black';
    context2.fillRect(0, 0, canvas2.width, canvas2.height);
    context2.drawImage(htmlCanvas, 0, 0);

    const imgData = canvas2.toDataURL();
    const a = document.createElement('a');
    a.href = imgData!;
    a.download = filename;
    a.click();
  }, [getHtmlCanvas]);

  return (
    <div style={{ position: 'absolute', margin: '8px', zIndex: 2 }}>
      <Button variant="contained" size="medium" color="primary" onClick={handleResetCameraClicked}>
        Reset Camera View
      </Button>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleTake3dSnapshot}
        style={buttonStyles}
      >
        Take 3d-View Snapshot
      </Button>
    </div>
  );
};
