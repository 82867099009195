import { useCallback } from 'react';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHavePermission } from '@/hooks';
import { Permissions, PointOfInterestDocument } from '@/types';

type DefectRowProps = {
  defectId: string;
  defectType: string;
  dcClass: string;
  pointOfInterest: PointOfInterestDocument;
  onDefectClassRowClicked: (poi: PointOfInterestDocument) => void;
  onNominateDefectClassClicked: (poi: PointOfInterestDocument) => void;
  isNominated: boolean;
};

export const DefectRow = ({
  defectId,
  defectType,
  dcClass,
  pointOfInterest,
  onDefectClassRowClicked,
  onNominateDefectClassClicked,
  isNominated,
}: DefectRowProps) => {
  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);

  const handleRowClicked = useCallback(() => {
    if (pointOfInterest) {
      onDefectClassRowClicked(pointOfInterest);
    }
  }, [onDefectClassRowClicked, pointOfInterest]);

  const handleNominateClicked = useCallback(() => {
    onNominateDefectClassClicked(pointOfInterest);
  }, [onNominateDefectClassClicked, pointOfInterest]);

  return (
    <TableRow hover onClick={handleRowClicked} key={defectId} selected={isNominated}>
      <TableCell component="th" scope="row">
        {isNominated && <Typography variant="body2">{defectId}</Typography>}
        {!isNominated && (
          <Link underline="hover" component="button" variant="body2" onClick={handleRowClicked}>
            {defectId}
          </Link>
        )}
      </TableCell>

      <TableCell>{defectType}</TableCell>
      <TableCell>{dcClass}</TableCell>

      <TableCell>
        {isNominated && 'Yes'}
        {!isNominated && !userCanUpdateAsset && 'No'}

        {!isNominated && userCanUpdateAsset && (
          <Button size="medium" variant="contained" color="primary" onClick={handleNominateClicked}>
            Nominate
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
