import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';
import { Colors } from '@/types';
import { NO_TAG_ASSEMBLY_NAME, TRANSPARENT_COLOR } from '@/constants';

export const useSetAnnotationColours = (
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  }
) => {
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const annotationIdsForSelectedPartId = useRecoilValue(state.annotationIdsForSelectedPart);
  const shouldHighlightReviewedParts = useRecoilValue(state.shouldHighlightReviewedParts);
  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const shouldHighlightAllPartsWithSameTag = useRecoilValue(
    state.shouldHighlightAllPartsWithSameTag
  );
  const areUnselectedEquipmentHidden = useRecoilValue(state.areUnselectedEquipmentHidden);
  const hideTaggedPoints = useRecoilValue(state.hideTaggedPoints);
  const unlabelledAssembly = useMemo(() => {
    return structureRelationships?.byAssemblyId?.find(
      (assembly) => assembly.tagName === NO_TAG_ASSEMBLY_NAME
    );
  }, [structureRelationships?.byAssemblyId]);

  const setAnnotationColourByHiddenOrAssembly = useCallback(
    (
      annotationId: string,
      colorMap: ColorMap | undefined,
      highlightedAssemblyId?: string | undefined
    ) => {
      const byAnnotationId = structureRelationships?.byAnnotationId;
      const byPartId = structureRelationships?.byPartId;
      if (!byAnnotationId || !byPartId) return;

      const annotation = byAnnotationId.get(annotationId);
      if (!annotation || !colorMap) return;

      const { partId, annotation3dReference } = annotation;

      const part = byPartId.get(partId);
      const isAnnotationOutOfScope = part?.isHidden;

      // TODO: Need to rename current isHidden with isOutOfScope
      const isAnnotationHidden = part?.isHiddenVolatile;
      const isAnnotationReviewed = part?.isReviewed;

      const assemblyId = part?.assemblyId;

      // Set color based on reviewed, out of scope, and assembly
      let annotationColor = allColors.noAssemblyColour;
      if (isAnnotationHidden) {
        annotationColor = TRANSPARENT_COLOR;
      } else if (isAnnotationReviewed && shouldHighlightReviewedParts) {
        annotationColor = allColors.highlightReviewedPartColour;
      } else if (isAnnotationOutOfScope) {
        annotationColor = allColors.outOfScopeColour;
      } else if (assemblyId) {
        annotationColor = colorsForAssembly[assemblyId];
      }
      // Set colors to highlight assembly if requested - make all other assemblies translucent
      if (
        shouldHighlightAllPartsWithSameTag &&
        highlightedAssemblyId &&
        assemblyId !== highlightedAssemblyId
      ) {
        // Use bit masking to change the alpha of the color to 11
        // eslint-disable-next-line no-bitwise
        annotationColor &= areUnselectedEquipmentHidden ? TRANSPARENT_COLOR : 0xffffff11;
      }

      if (
        assemblyId !== unlabelledAssembly?.[1] &&
        hideTaggedPoints &&
        abyssViewerMode !== 'IsolatePart'
      ) {
        annotationColor = TRANSPARENT_COLOR;
      }

      colorMap?.setColor(annotation3dReference, annotationColor);
    },

    [
      structureRelationships?.byAnnotationId,
      structureRelationships?.byPartId,
      allColors.noAssemblyColour,
      allColors.highlightReviewedPartColour,
      allColors.outOfScopeColour,
      shouldHighlightReviewedParts,
      shouldHighlightAllPartsWithSameTag,
      unlabelledAssembly,
      hideTaggedPoints,
      abyssViewerMode,
      colorsForAssembly,
      areUnselectedEquipmentHidden,
    ]
  );

  const setAnnotationColoursForSelectedPart = useCallback(
    (colorMap: ColorMap | undefined) => {
      const byAnnotationId = structureRelationships?.byAnnotationId;
      if (colorMap && byAnnotationId) {
        annotationIdsForSelectedPartId?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation !== undefined) {
            colorMap.setColor(annotation.annotation3dReference, allColors.selectedColour);
          }
        });
      }
    },
    [
      allColors.selectedColour,
      annotationIdsForSelectedPartId,
      structureRelationships?.byAnnotationId,
    ]
  );

  return {
    setAnnotationColourByHiddenOrAssembly,
    setAnnotationColoursForSelectedPart,
  };
};
