import { useEffect, useState } from 'react';

export const useFetchCookies = () => {
  const [cookiesFetched, setCookiesFetched] = useState(false);

  useEffect(() => {
    fetch('/api/auth/cookies', { method: 'GET', credentials: 'include' })
      .then(() => {
        setCookiesFetched(true);
      })
      .catch((error) => {
        console.error('useFetchCookies ->', error);
      });
  }, []);

  return cookiesFetched;
};
