import { atom } from 'recoil';
import { MeasurementLinesProps } from '@abyss/3d-viewer';
import { MeasurementToolState } from '@/types';

export const measurementsToAdd = atom<MeasurementLinesProps | undefined>({
  default: undefined,
  key: 'AnalysisMeasurements',
});

export const measurementToolState = atom<MeasurementToolState>({
  default: MeasurementToolState.IN_ACTIVE,
  key: 'AnalysisMeasurementToolState',
});

export const measurementLineDistance = atom<number>({
  default: 0,
  key: 'AnalysisMeasurementLineDistance',
});
