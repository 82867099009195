/* eslint-disable unicorn/no-null */
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Alert } from '@mui/material';
import { ZoneStatus, useUpdateZoneStatusMutation } from '../data.graphql';
import * as state from '@/state';
import { Zone } from '@/types';
import { shouldRedirectToTasks } from '../common';

const SuccessAlert = <Alert severity="success">Status updated successfully</Alert>;

const ErrorAlert = <Alert severity="error">Error updating status</Alert>;

export const ZoneStatusChangerOptions = () => {
  const router = useRouter();

  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const [selectedZone, setSelectedZone] = useRecoilState(state.selectedZone);

  const zoneIdFromUrl = router?.query?.zoneid;

  const zoneId = zoneIdFromUrl ? zoneIdFromUrl.toString() : selectedZone?.id;

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const inspection = router?.query?.inspection;

  const [mutate, { loading }] = useUpdateZoneStatusMutation({
    refetchQueries: ['zoneAndHistoriesFor'],
    onCompleted: (data) => {
      if (shouldRedirectToTasks(selectedZone?.status, data.updateZoneStatus.currentStatus)) {
        router.push({
          pathname: '/inspection/[inspection]/tasks',
          query: { inspection },
        });
        return;
      }

      setSelectedZone(
        (previous) =>
          ({
            ...previous,
            status: data.updateZoneStatus.currentStatus,
          } as Zone)
      );
    },
  });

  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  const updateStatus = useCallback(
    (newStatus: ZoneStatus) => {
      if (!zoneId) {
        setSnackbarMessage({
          shouldShow: true,
          content: ErrorAlert,
        });
        return;
      }

      mutate({
        variables: {
          input: {
            zoneId,
            status: newStatus,
          },
        },
      })
        .then(() => {
          setSnackbarMessage({
            shouldShow: true,
            content: SuccessAlert,
          });
          handleClose();
        })
        .catch(() => {
          setSnackbarMessage({
            shouldShow: true,
            content: ErrorAlert,
          });
        });
    },
    [mutate, setSnackbarMessage, zoneId]
  );

  const options = {
    [ZoneStatus.NotStarted]: (
      <MenuItem disabled={loading} onClick={() => updateStatus(ZoneStatus.InProgress)}>
        Assign to me
      </MenuItem>
    ),
    [ZoneStatus.InProgress]: (
      <MenuItem disabled={loading} onClick={() => updateStatus(ZoneStatus.InProgress)}>
        Assign to me
      </MenuItem>
    ),
    [ZoneStatus.InReview]: (
      <MenuItem disabled={loading} onClick={() => updateStatus(ZoneStatus.InReview)}>
        Assign to me
      </MenuItem>
    ),
    [ZoneStatus.ReviewReady]: (
      <MenuItem disabled={loading} onClick={() => updateStatus(ZoneStatus.InReview)}>
        Assign to me
      </MenuItem>
    ),
    [ZoneStatus.Completed]: (
      <>
        <MenuItem disabled={loading} onClick={() => updateStatus(ZoneStatus.InProgress)}>
          Move to In Progress
        </MenuItem>
        <MenuItem disabled={loading} onClick={() => updateStatus(ZoneStatus.ReviewReady)}>
          Move to In Review
        </MenuItem>
      </>
    ),
  };

  if (!selectedZone || !selectedZone?.status) return <></>;

  return (
    <div style={{ marginLeft: '5%' }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorElement} open={open} onClose={handleClose}>
        {options[selectedZone.status]}
      </Menu>
    </div>
  );
};
