import { Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useUser } from '@auth0/nextjs-auth0';
import { TestIds } from '../../../../../../cypress/testIds';

interface UserSettingHeaderProps {
  handleCloseUserMenu: () => void;
}

export const Header = ({ handleCloseUserMenu }: UserSettingHeaderProps) => {
  const { user } = useUser();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <Stack direction="column">
        <Typography variant="subtitle2">
          <strong>User Settings</strong>
        </Typography>
        <Typography variant="caption">{user?.email}</Typography>
      </Stack>
      <IconButton data-test-id={TestIds.LOGOUT_INPUT} onClick={handleCloseUserMenu}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
