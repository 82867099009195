import { FormLabel, Slider } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useRef } from 'react';
import * as state from '@/components/Analysis/state';

export const BlendingMode = () => {
  const [blendingOpacity, setBlendingOpacity] = useRecoilState(state.blendOpacity);
  const hasRgb = useRecoilValue(state.hasRgb);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const previousBlendingOpacityReference = useRef(blendingOpacity);

  useEffect(() => {
    if (hasRgb) {
      setBlendingOpacity(1); // show rgb colours
      previousBlendingOpacityReference.current = 1;
    }
  }, [setBlendingOpacity, hasRgb]);

  const handleToggleRgb = (_: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      return;
    }
    setBlendingOpacity(newValue);
    previousBlendingOpacityReference.current = newValue;
  };

  useEffect(() => {
    setBlendingOpacity(selectedSpherical ? 255 : previousBlendingOpacityReference.current);
  }, [selectedSpherical, setBlendingOpacity]);

  return (
    <div>
      <FormLabel>Color Blender</FormLabel>
      <Slider value={blendingOpacity} step={20} min={1} max={255} onChange={handleToggleRgb} />
    </div>
  );
};
