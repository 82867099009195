import { LinearProgress, Stack } from '@mui/material';

type Props = {
  background?: 'dark' | 'light';
  hideProgress?: boolean;
  backdrop?: boolean;
};

export const PageLoader = ({
  background = 'light',
  hideProgress = false,
  backdrop = false,
}: Props) => {
  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': { maxWidth: { xl: '67px', md: '48px' } },
        ...(backdrop
          ? {
              backgroundColor:
                background === 'dark' ? 'rgba(0, 0, 0, 0.8)' : ' rgba(255,255,255, 0.8)',
            }
          : {}),
      }}
    >
      <img
        src={
          background === 'dark'
            ? '/assets/abyss_fabric_mini_logo_white.svg'
            : '/assets/abyss_fabric_mini_logo_black.svg'
        }
        alt="Abyss Fabric"
        style={{ height: '6.4rem', ...(hideProgress ? {} : { marginBottom: '30px' }) }}
      />
      {!hideProgress && (
        <LinearProgress
          sx={{
            width: '200px',
            '& .MuiLinearProgress-colorPrimary': {
              backgroundColor: background === 'light' ? '#9E9E9E' : '#C5C5C5',
            },
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: background === 'light' ? '#000' : '#fff',
            },
          }}
        />
      )}
    </Stack>
  );
};
