import { useState } from 'react';
import { GeneralFieldsType } from '../types';

export type UseGeneralFieldsHookType = {
  state: GeneralFieldsType;
  updateValue: (
    key: keyof GeneralFieldsType,
    value: GeneralFieldsType[keyof GeneralFieldsType]
  ) => void;
  setState: React.Dispatch<React.SetStateAction<GeneralFieldsType>>;
};

export const useGeneralFields = (initialState: GeneralFieldsType): UseGeneralFieldsHookType => {
  const [state, setState] = useState<GeneralFieldsType>(initialState);

  const updateValue = (
    key: keyof GeneralFieldsType,
    value: GeneralFieldsType[keyof GeneralFieldsType]
  ) => {
    setState((previousState) => ({ ...previousState, [key]: value }));
  };

  return { state, setState, updateValue };
};
