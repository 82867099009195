export enum TestIds {
  LOGIN_INPUT = 'LOGIN_INPUT',
  LOGOUT_INPUT = 'LOGOUT_INPUT',
  PLATFORMS_CONTAINER = 'PLATFORMS_CONTAINER',
  PLATFORM_EXPLORER_INSIGHTS = 'PLATFORM_EXPLORER_INSIGHTS',
  PLATFORM_EXPLORER_TAGGING = 'PLATFORM_EXPLORER_TAGGING',
  STAGE_OVERVIEW_CONTAINER = 'STAGE_OVERVIEW_CONTAINER',
  ASSEMBLY_TABLE_CONTAINER = 'ASSEMBLY_TABLE_CONTAINER',
  LABELLING_TABLE_CONTAINER = 'LABELLING_TABLE_CONTAINER',
}

export const platformIds = ['61e7ca258f1a3cc4411029cc', '6298b4cf34349ba5acb519d4'];
export const assetIds = ['6298b4d8a89a8dcf5a4aac48'];
export const assetTags = ['2-LF-2110-A'];
