import { Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { assemblyHighlightColors } from '@/theme/colors';

export const cardStyles = {
  cursor: 'pointer',
  width: '100%',
  height: '75px',
  padding: 0.4,
  marginBottom: 0.3,
  borderRadius: 0,
  boxShadow: 0,
  backgroundColor: '#f8f9fa',
};

export const highlightStyles = {
  ...cardStyles,
  backgroundColor: assemblyHighlightColors.background,
  border: `1px solid ${assemblyHighlightColors.border}`,
};

export const assemblyNameStyles = { fontSize: '1.4rem', fontWeight: 500, flex: 5 };

export const percentageIndicatorStyles = { fontSize: '1rem', flex: 2, alignItems: 'center' };

export const textWithTitleStyles = { fontSize: '1.2rem', fontWeight: 700, marginLeft: 0.2 };

export const InlineTypography = muiStyled(Typography)(() => ({
  display: 'flex',
}));

export const cardContentStyles = {
  display: 'flex',
  padding: 1.5,
  height: '100%',
};

export const cardSectionsStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
};
