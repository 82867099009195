import { ButtonBase, Divider, Link, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import PeopleIcon from '@mui/icons-material/People';
import SpeedIcon from '@mui/icons-material/Speed';
import LogoutIcon from '@mui/icons-material/Logout';
import { Body } from './Body';
import { Header } from './Header';
import { useFeatureFlag, useHavePermission } from '@/hooks';
import { Permissions } from '@/types';
import { UnitSelection } from '../UnitSelection';
import { abyssColors } from '@/theme/colors';

export interface UserSettingProps {
  handleCloseUserMenu: () => void;
}

const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const UserSettings = ({ handleCloseUserMenu }: UserSettingProps) => {
  const canManageUsers = useHavePermission(Permissions.MANAGE_USERS);
  const isBetaUser = useFeatureFlag('beta-user');

  const userSettingsLogout = useMemo(
    () => (
      <Stack>
        <Stack p={2} spacing={2} sx={{ cursor: 'pointer' }}>
          <Divider />
          {canManageUsers && (
            <>
              <Link href="/userManagement/main" data-testid="user_mgm_header">
                <ButtonBase sx={{ justifyContent: 'left' }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <PeopleIcon />
                    <Typography textAlign="left" variant="subtitle2">
                      <strong>User management</strong>
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Link>
              <Divider />
            </>
          )}
          <Link href="#">
            <ButtonBase sx={{ justifyContent: 'left' }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <SquareFootIcon />
                <Typography textAlign="left" variant="subtitle2">
                  <strong>Unit</strong>
                </Typography>
                <UnitSelection />
              </Stack>
            </ButtonBase>
          </Link>
          {isBetaUser && (
            <>
              <Divider />
              <Link href="/system-test">
                <ButtonBase sx={{ justifyContent: 'left' }} disabled>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <SpeedIcon />
                    <Typography textAlign="left" variant="subtitle2">
                      <strong data-testid="system_requirement_header">
                        System requirement test
                      </strong>
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Link>
            </>
          )}

          <Divider />
          <Link href="/api/auth/logout">
            <ButtonBase sx={{ justifyContent: 'left' }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <LogoutIcon />
                <Typography textAlign="left" variant="subtitle2">
                  <strong>Log out</strong>
                </Typography>
              </Stack>
            </ButtonBase>
          </Link>
        </Stack>

        <Stack sx={{ backgroundColor: abyssColors.primary[50], py: 2, px: 2 }} direction="row">
          <Typography sx={{ fontSize: '1.2rem', mr: 1 }}>©</Typography>
          <Typography sx={{ fontSize: '1.2rem' }}>2024 Abyss solutions</Typography>
          {APP_VERSION && (
            <Typography sx={{ fontSize: '1.2rem', ml: 'auto' }}>{APP_VERSION}</Typography>
          )}
        </Stack>
      </Stack>
    ),
    [canManageUsers, isBetaUser]
  );

  return (
    <>
      <Stack direction="column" spacing={2} p={0}>
        <Stack sx={{ p: 2 }}>
          <Header handleCloseUserMenu={handleCloseUserMenu} />
          <Body />
        </Stack>
        {userSettingsLogout}
      </Stack>
    </>
  );
};
