import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useRouter } from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useMemo, useState } from 'react';
import { DatePickerIcon } from '@/assets/icons/DatePickerIcon';
import { LoadingButton } from '@/components/shared/LoadingButton';
import { selectedStructureId, snackbarMessage } from '@/state';
import { ButtonLabel, containedButtonStyle, textButtonStyle } from '@/theme';
import { ReportSubmitState } from '@/types';
import { formatEnumForDisplay, upperSnakeCase } from '@/utils';
import { Alert } from '../Alert';
import {
  GetAllDraftReportsQuery,
  ReportActionStatus,
  ReportStatus,
  ReportType,
  useCreateNewReportMutation,
  useGetAllDraftReportsQuery,
  useUpdateReportByPointOfInterestsMutation,
} from './data.graphql';
import {
  ButtonContainer,
  ReportDatePicker,
  ReportFormRadioGroup,
  ReportFormTextField,
  autocompleteTextFieldStyles,
  buttonStyles,
  datePickerSlotProps,
  outlinedButtonStyles,
} from './styles';

export type ReportSummary = {
  name: string;
  amount: number;
};

type Props = {
  reportSummary?: ReportSummary[];
  handleClose: () => void;
  selectedPointOfInterestIds: string[];
  reportCreated?: () => void;
};

type GetAllDraftReportsQueryResult = GetAllDraftReportsQuery['allReports'];

type SubmittingReportState = 'Loading' | 'Success' | undefined;

const fiveCharacterStringRegex = /^[\d A-Za-z]{5,}$/;

const actionStatuses = [
  ReportActionStatus.Backlog,
  ReportActionStatus.InProgress,
  ReportActionStatus.Complete,
];

const typesLabels = {
  [ReportType.FmWorkpack]: 'FM workpack',
  [ReportType.MiWorkpack]: 'MI workpack',
};

export const AddToReport = ({
  reportSummary,
  selectedPointOfInterestIds,
  handleClose,
  reportCreated,
}: Props) => {
  const router = useRouter();
  const [reportState, setReportState] = useState<ReportSubmitState>('create');
  const [reportName, setReportName] = useState<string>('');
  const [workpackNumber, setWorkpackNumber] = useState<string>('');
  const [actionStatus, setActionStatus] = useState<ReportActionStatus | undefined>(
    ReportActionStatus.Backlog
  );
  const [reportType, setReportType] = useState<ReportType | undefined>(undefined);
  const [dueDate, setDueDate] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [reportId, setReportId] = useState<string>('');
  const [submittingReportState, setSubmittingReportState] = useState<SubmittingReportState>();

  const structureId = useRecoilValue(selectedStructureId)!;

  const [createNewReportMutation] = useCreateNewReportMutation();
  const [updateReportByPointOfInterestsMutation] = useUpdateReportByPointOfInterestsMutation();

  const setSnackbarMessage = useSetRecoilState(snackbarMessage);

  const getAllDraftReportsQueryResult = useGetAllDraftReportsQuery({
    variables: {
      input: {
        structureId,
        status: ReportStatus.Draft,
      },
    },
  });

  const handleRadioChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (['create', 'edit'].includes(value)) {
        setReportState(value as ReportSubmitState);
      }
    },
    [setReportState]
  );

  const handleReportNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
      if (!event) return;
      setReportName(event.target.value);
    },
    [setReportName]
  );

  const handleWorkpackNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
      if (!event) return;
      setWorkpackNumber(event.target.value);
    },
    [setWorkpackNumber]
  );

  const handleReportDueDateChange = useCallback(
    (value: unknown) => {
      if (!value) return;
      setDueDate(value.toString());
    },
    [setDueDate]
  );

  const handleReportActionStatusChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string | null) => {
      if (!value) return;
      setActionStatus(
        Object.values(ReportActionStatus).find((status) => status === upperSnakeCase(value))
      );
    },
    [setActionStatus]
  );

  const handleReportTypeChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string | null) => {
      if (!value) return;
      setReportType(Object.values(ReportType).find((status) => status === upperSnakeCase(value)));
    },
    [setReportType]
  );

  const handleReportIdChanged = useCallback(
    (_: React.SyntheticEvent<Element, Event>, report: { label: string; id: string } | null) => {
      if (!report) return;
      setReportId(report.id);
    },
    [setReportId]
  );

  const handleCreateReport = useCallback(async () => {
    try {
      const newReport = await createNewReportMutation({
        variables: {
          input: {
            pointOfInterestIds: selectedPointOfInterestIds,
            name: reportName,
            structureId,
            actionStatus,
            ...(dueDate ? { dueDate } : {}),
            ...(workpackNumber ? { workpackNumber } : {}),
            ...(reportType ? { type: reportType } : {}),
          },
        },
      });
      setSubmittingReportState('Success');
      const newReportId = newReport?.data?.createNewReport?.id;
      const selectedReportURLQuery = newReport ? `selectedReport=${newReportId}` : '';
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="success">Report has been created successfully</Alert>,
      });
      setTimeout(() => {
        router.push(
          `/analysis/${structureId}/reports?inspection=${structureId}&${selectedReportURLQuery}`
        );
        setSnackbarMessage({
          shouldShow: false,
          content: undefined,
        });
      }, 1000);
    } catch {
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="error">{`Error creating report ${reportName}`}</Alert>,
      });
    }
  }, [
    actionStatus,
    createNewReportMutation,
    dueDate,
    reportName,
    reportType,
    router,
    selectedPointOfInterestIds,
    setSnackbarMessage,
    structureId,
    workpackNumber,
  ]);

  const handleAddPOIToReport = useCallback(async () => {
    try {
      await updateReportByPointOfInterestsMutation({
        variables: {
          input: {
            reportId,
            pointOfInterestIds: selectedPointOfInterestIds,
          },
        },
      });
      setSubmittingReportState('Success');
      setTimeout(() => {
        handleClose();
        if (reportCreated) {
          reportCreated();
        }
      }, 3000);
    } catch {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <div>
            <Alert severity="error">Error while updating Report!</Alert>
          </div>
        ),
      });
    }
  }, [
    handleClose,
    reportCreated,
    reportId,
    selectedPointOfInterestIds,
    setSnackbarMessage,
    updateReportByPointOfInterestsMutation,
  ]);

  const handleAddOrCreateReport = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
      event?.preventDefault();
      setSubmittingReportState('Loading');
      if (reportState === 'create') {
        handleCreateReport();
      } else {
        handleAddPOIToReport();
      }
    },
    [handleCreateReport, handleAddPOIToReport, reportState]
  );

  const draftReports: GetAllDraftReportsQueryResult = useMemo(() => {
    const allReports = getAllDraftReportsQueryResult.data?.allReports;
    return allReports || [];
  }, [getAllDraftReportsQueryResult.data?.allReports]);

  const mappedDraftReports = useMemo(() => {
    if (!draftReports) return [];

    return draftReports.map((report: GetAllDraftReportsQueryResult[0]) => {
      return {
        label: report?.name || '',
        id: report?.id || '',
      };
    });
  }, [draftReports]);

  const isInValidReportEntry = useMemo(() => {
    if (reportId === '' && reportState === 'edit') {
      return true;
    }
    if (!fiveCharacterStringRegex.test(reportName) && reportState === 'create') {
      return true;
    }
    return false;
  }, [reportId, reportName, reportState]);

  return (
    <Box width="100%" data-testid="report-modal-content">
      <Stack>
        {reportSummary && (
          <>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Report Summary
            </Typography>
            <Stack direction="row" justifyContent="space-between" marginBottom={2}>
              {reportSummary.map((summary) => {
                return (
                  <Stack alignItems="center" key={summary.name}>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: '400' }}
                      data-testid="report-summary-amount"
                    >
                      {summary?.amount || 0}
                    </Typography>
                    <Typography variant="caption">{summary?.name || ''}</Typography>
                  </Stack>
                );
              })}
            </Stack>
          </>
        )}

        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <ReportFormRadioGroup
            defaultValue="create"
            onChange={handleRadioChanged}
            value={reportState}
            row
          >
            <Grid container sx={{ width: '100%' }}>
              <Grid item xs={6}>
                <FormControlLabel value="create" control={<Radio />} label="Create new report" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="edit" control={<Radio />} label="Add to existing report" />
              </Grid>
            </Grid>
          </ReportFormRadioGroup>
        </FormControl>

        {reportState === 'create' ? (
          <>
            <Stack gap={5}>
              <ReportFormTextField
                label="Report name"
                variant="standard"
                fullWidth
                error={isFocused && !fiveCharacterStringRegex.test(reportName)}
                helperText={
                  isFocused && !fiveCharacterStringRegex.test(reportName)
                    ? 'Report name must be at least 5 characters long to create the report. Special characters are not allowed'
                    : ''
                }
                onChange={handleReportNameChange}
                onFocus={() => setIsFocused(true)}
              />
              <Grid container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    value={reportType ? typesLabels[reportType] : undefined}
                    options={Object.values(ReportType).map((type) => typesLabels[type])}
                    disableClearable
                    onChange={handleReportTypeChange}
                    renderInput={(parameters) => (
                      <TextField
                        {...parameters}
                        label="Report type"
                        variant="standard"
                        sx={autocompleteTextFieldStyles}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ReportFormTextField
                    label="Workpack number"
                    variant="standard"
                    fullWidth
                    size="small"
                    onChange={handleWorkpackNumberChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack alignItems="end" direction="row" height="100%">
                    <ReportDatePicker
                      format="dd MMM yyyy"
                      disablePast
                      label="Due date"
                      slotProps={datePickerSlotProps}
                      onChange={handleReportDueDateChange}
                      value={dueDate ? new Date(dueDate) : undefined}
                      slots={{
                        openPickerIcon: DatePickerIcon,
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    value={formatEnumForDisplay(actionStatus)}
                    options={actionStatuses.map((status) => formatEnumForDisplay(status))}
                    disableClearable
                    onChange={handleReportActionStatusChange}
                    renderInput={(parameters) => (
                      <TextField
                        {...parameters}
                        label="Status"
                        variant="standard"
                        sx={autocompleteTextFieldStyles}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
          </>
        ) : (
          <Autocomplete
            options={mappedDraftReports}
            fullWidth
            disableListWrap
            autoHighlight
            onChange={handleReportIdChanged}
            renderInput={(parameters) => (
              <TextField
                error={reportId === ''}
                helperText={reportId === '' ? 'Please select a report...' : ''}
                {...parameters}
                label="Report Name"
                data-testid="report-selector"
              />
            )}
          />
        )}
      </Stack>
      <ButtonContainer>
        <Button
          onClick={handleClose}
          sx={{
            ...textButtonStyle,
            ...outlinedButtonStyles,
          }}
          variant="outlined"
        >
          <ButtonLabel>Cancel</ButtonLabel>
        </Button>
        <Tooltip
          title={
            selectedPointOfInterestIds.length === 0
              ? '"Please select at least one point of interest to create a report"'
              : ''
          }
          placement="top"
          arrow
        >
          <span>
            <LoadingButton
              disabled={isInValidReportEntry || selectedPointOfInterestIds.length === 0}
              loading={submittingReportState === 'Loading'}
              success={submittingReportState === 'Success'}
              onClick={handleAddOrCreateReport}
              buttonStyles={{
                ...containedButtonStyle,
                ...buttonStyles,
              }}
            >
              <ButtonLabel>
                {reportState === 'create' ? 'Create Report' : 'Add to Report'}
              </ButtonLabel>
            </LoadingButton>
          </span>
        </Tooltip>
      </ButtonContainer>
    </Box>
  );
};
