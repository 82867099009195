import { useUser } from '@auth0/nextjs-auth0';
import { UserRoles } from '@/types';

export const useFeatureFlag = (flag: UserRoles | Array<UserRoles>) => {
  const { user } = useUser();

  if (!user || !flag) {
    return false;
  }

  if (Array.isArray(flag)) {
    const roles: Array<string> = user['http://abyss.com/user/roles'] as Array<string>;
    if (roles.some((role) => flag.includes(role as UserRoles))) {
      return true;
    }
  }

  const roles: Array<string> = user['http://abyss.com/user/roles'] as Array<string>;
  if (roles.includes(flag as UserRoles)) {
    return true;
  }
  return false;
};
