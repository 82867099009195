import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { MarkerProps, MarkerShapeType } from '@abyss/3d-viewer';
import { FindViewpointsToolState } from '@/types';
import * as moduleState from '../state';

export const useFindViewpointsMarker = () => {
  const findViewpointsToolState = useRecoilValue(moduleState.findViewpointsToolState);
  const currentVoxelPoint = useRecoilValue(moduleState.currentVoxelPoint);
  const showPointMarker = useRecoilValue(moduleState.showPointMarker);

  const marker: MarkerProps | undefined = useMemo(() => {
    if (
      !showPointMarker ||
      !currentVoxelPoint ||
      findViewpointsToolState === FindViewpointsToolState.INACTIVE ||
      findViewpointsToolState === FindViewpointsToolState.WAITING_FOR_POINT
    ) {
      return undefined;
    }

    return {
      id: 'find-viewpoints-marker',
      shapeType: MarkerShapeType.SPHERE,
      style: {
        color: '#fff',
        opacity: 1,
        size: 0.02,
      },
      position: currentVoxelPoint,
    };
  }, [findViewpointsToolState, currentVoxelPoint, showPointMarker]);

  return marker;
};
