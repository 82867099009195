/* 7d4abf300dfb4b1e3dd33b4c0993c43b9b9a98e7
 * This file is automatically generated by graphql-let. */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type AddNewAssemblyTagNameInput = {
  isNewlyAdded?: InputMaybe<Scalars['Boolean']['input']>;
  structureId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type AddPartsToAssemblyInput = {
  /** Object Id for Assembly to add Parts to */
  assemblyId: Scalars['ID']['input'];
  /** Object Ids of parts to link to assembly */
  partIds: Array<Scalars['ID']['input']>;
  /** Object Id for the structure this assembly belongs to */
  structureId: Scalars['ID']['input'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddRemoveStructuresToFromUserGroupsInput = {
  structureIds: Array<Scalars['ID']['input']>;
  userGroupIds: Array<Scalars['ID']['input']>;
};

export type AddRemoveUsersToFromUserGroupInput = {
  userGroupId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type AddUpdateCuboidInput = {
  cuboidClassId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  position: Array<Scalars['Float']['input']>;
  rotation: Array<Scalars['Float']['input']>;
  scale: Array<Scalars['Float']['input']>;
  structureId: Scalars['ID']['input'];
  zoneId: Scalars['ID']['input'];
};

export type AddUpdateSplitCuboidOnPartInput = {
  /** Object Id of part to add cuboid to */
  partId: Scalars['ID']['input'];
  /** Object Id for the cuboid to update */
  splitCuboid: SplitCuboidInput;
  /** Object Id for the structure this part belongs to */
  structureId: Scalars['ID']['input'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddUpdateSplitCuboidOnPartNotification = {
  __typename?: 'AddUpdateSplitCuboidOnPartNotification';
  /** Object Id of part to add cuboid to */
  partId: Scalars['ID']['output'];
  /** Split Cuboid to add or update */
  splitCuboid: SplitCuboid;
  /** Object Id for the structure this part belongs to */
  structureId: Scalars['ID']['output'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: Maybe<Scalars['ID']['output']>;
};

export type AlarmLevelRecommendation = {
  __typename?: 'AlarmLevelRecommendation';
  /** Any supplementary comments about the recommendation for this assembly from initial analysis by the reviewer */
  additionalComments?: Maybe<Scalars['String']['output']>;
  /** Recommended alarm level after analysis stage, valid values: see alarm levels enum */
  alarmLevel: AlarmLevels;
  /** Any supplementary comments about the recommendation for this assembly that is only relevant to the internal team */
  internalTeamComments?: Maybe<Scalars['String']['output']>;
  /**
   * User id of the user in charge of analysis/review/finalization of this assembly,
   * equivalent to user_id in Auth0 user profile and sub claim in the jwt
   */
  recommendationByUser?: Maybe<User>;
  /** User name of the user in charge of analysis/review/finalization of this assembly, */
  recommendationByUserName?: Maybe<Scalars['String']['output']>;
  /** Comment about the recommendation for this assembly from initial analysis from a standardised set of supporting comments */
  supportingComment?: Maybe<Scalars['String']['output']>;
  /** Whether the alarm level was manually updated for subjective reasons to override the alarm level that was automatically calculated based on governing value */
  wasAnalysisAlarmLevelManuallySet?: Maybe<Scalars['Boolean']['output']>;
};

export type AlarmLevelRecommendationInput = {
  additionalComments?: InputMaybe<Scalars['String']['input']>;
  alarmLevel: AlarmLevels;
  internalTeamComments?: InputMaybe<Scalars['String']['input']>;
  supportingComment?: InputMaybe<Scalars['String']['input']>;
};

export enum AlarmLevels {
  BorderlineLevel_1Or_2 = 'BORDERLINE_LEVEL_1_OR_2',
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3',
  None = 'NONE'
}

export type AllCommentsInput = {
  collectionName: Scalars['String']['input'];
  referenceId: Scalars['ID']['input'];
  structureId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AllStructuresByRegion = {
  __typename?: 'AllStructuresByRegion';
  defectArea: Array<DefectArea>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  structures: Array<StructureWithDefects>;
};

export type Analysed = {
  __typename?: 'Analysed';
  /** User who analysed this Part */
  analysedBy: Scalars['ID']['output'];
  /** Last time this Part was analysed */
  updatedAt: Scalars['String']['output'];
};

export type Annotation = {
  __typename?: 'Annotation';
  /** Integer ID of this Annotation in point cloud */
  annotationId: Scalars['Float']['output'];
  /** Unique Annotation ID */
  id: Scalars['ID']['output'];
  /** Location that this Annotation belongs to */
  location: Location;
  locationId?: Maybe<Scalars['ID']['output']>;
  /** Overlap info related to part Annotation */
  overlapInfo?: Maybe<OverlapInfo>;
  /** Part that this Annotation belongs to */
  part?: Maybe<Part>;
  partId?: Maybe<Scalars['ID']['output']>;
  /** Polygon info related to Annotation */
  polygon: Polygon;
  processingReferences: ProcessingReferences;
  /** Structure that this Annotation belongs to */
  structure: Structure;
  structureId?: Maybe<Scalars['ID']['output']>;
};

export type AreaAndType = {
  __typename?: 'AreaAndType';
  type: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type AssembliesFilterInput = {
  assemblyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selectedDeckIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selectedFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  selectedPaintRegionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selectedTemplateFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  structureId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

/** An Assembly of Parts that facilitates some operation in the Structure. */
export type Assembly = {
  __typename?: 'Assembly';
  /** Total surface area of the assembly in sq ft. */
  area: Scalars['Float']['output'];
  /** Visibility Box of this Assembly */
  box?: Maybe<Box>;
  /**
   * Maximum corrosion level across all parts belonging to this assembly,
   * this aggregate value is stored here for convenience as it is a frequently queried value when filtering assemblies,
   * valid values: HEAVY, MODERATE, LIGHT, CLEAN
   */
  corrosionLevel?: Maybe<CorrosionLevel>;
  /** Date this Assembly was created */
  createdAt: Scalars['String']['output'];
  /**
   * Deck detail of assembly. A single assembly can be a part of multiple decks.
   * decks would contain the deckId in which this assembly exist and area that assembly occupied on that deck.
   */
  decks?: Maybe<Array<Maybe<DecksDetails>>>;
  /** Files attched to assembly */
  files?: Maybe<Array<File>>;
  /**
   * The focal point of this assembly, for now this means the midpoint between all the points that make up this assembly
   * - calculated from the parts/annotations that make up this assembly and stored here for convenience.
   * This is used to help a user focus on a selected assembly as they navigate around the spatial viewer
   *
   * Current workaround is to assume an this assembly would have several viewpoints surrounding it,
   * and then calculate the midpoint of all the viewpoints locations.
   *
   * Calculation is done by averaging the x y z coordinates of all the viewpoints locations.
   * Migration script should have this process in order to migrate existing assemblies to the new format.
   * x: (viewpoint1.x + viewpoint2.x + viewpoint3.x + ...) / length
   * y: (viewpoint1.y + viewpoint2.y + viewpoint3.y + ...) / length
   * z: (viewpoint1.z + viewpoint2.z + viewpoint3.z + ...) / length
   */
  focalPoint?: Maybe<Vector3>;
  /** Unique Assembly ID */
  id: Scalars['ID']['output'];
  /** Integer ID of this Assembly in point cloud data */
  integerId?: Maybe<Scalars['Float']['output']>;
  /**
   * If the assembly is newly added by the admin than this flag would
   * be set to true.
   *
   * This  boolean can be used to differentiate between the predefined
   * assembly from newly added assembly
   */
  isNewlyAdded?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Pipe specification related to this assembly, used for calculating the effect of corrosion on this assembly and
   * Abyss' subsequent recommendation
   */
  pipeSpec?: Maybe<PipeSpec>;
  /**
   * Abyss' recommendations for this particular assembly,
   * currently just one recommendation object per assembly because "structure" currently means scan/deployment,
   * and there would only be one recommendation per scan/deployment
   */
  recommendation?: Maybe<Recommendation>;
  /**
   * All the viewpoints that can see any portion of this assembly,
   * calculated from viewpoints that can see parts belonging to this assembly, stored here for convenience
   */
  seenByViewpoints?: Maybe<Array<SeenByViewpoint>>;
  /**
   * Service that this piece of equipment provides, for oil & gas this the content of an assembly
   * e.g. fuel gas, sewage water. This helps priorities assemblies for analysis and reporting
   */
  service?: Maybe<Service>;
  /** Identifier for the Structure that this Assembly belongs to */
  structure: Structure;
  /** User readable identifier for the Assembly */
  tagName: Scalars['String']['output'];
  /** Template related to this assembly for dynamic data */
  template?: Maybe<Template>;
  /** Data values from template for this assembly */
  templateData?: Maybe<Array<TemplateData>>;
  /** Data values from template for this assembly */
  templateFieldData?: Maybe<Scalars['JSON']['output']>;
  /**
   * Type of Assembly
   * eg: Line or Vessel or etc., this terminology is used within AIT. When AIT engineers analyze assemblies for MI client insights,
   * they only analyze lines (one of the assembly types, collection of parts including pipes; flanges and valves) not vessels
   */
  type?: Maybe<Scalars['String']['output']>;
  /** Date this Assembly was last updated */
  updatedAt: Scalars['String']['output'];
};

export type AssemblyPaintRegion = {
  __typename?: 'AssemblyPaintRegion';
  /** Assembly that this Paint Block belongs to */
  assembly?: Maybe<Assembly>;
  assemblyId?: Maybe<Scalars['ID']['output']>;
  /** Date this Paint Block was created */
  createdAt: Scalars['String']['output'];
  /** Deck that this Paint Region belongs to */
  deck?: Maybe<Deck>;
  deckId?: Maybe<Scalars['ID']['output']>;
  /** Unique Paint Block ID */
  id: Scalars['ID']['output'];
  /** Paint Region that this paint block data belongs to */
  paintRegion?: Maybe<PaintRegion>;
  paintRegionId?: Maybe<Scalars['ID']['output']>;
  /** Identifier for the Structure that this Paint Block belongs to */
  structure?: Maybe<Structure>;
  structureId?: Maybe<Scalars['ID']['output']>;
  /** Template related to this paint block for dynamic data */
  template?: Maybe<Template>;
  /** Data values from template for this paint block */
  templateData?: Maybe<Array<TemplateData>>;
  templateId?: Maybe<Scalars['ID']['output']>;
  /** Date this Paint Block was last updated */
  updatedAt: Scalars['String']['output'];
};

export type AssemblySummary = {
  __typename?: 'AssemblySummary';
  data?: Maybe<Scalars['JSON']['output']>;
  displayConfig?: Maybe<Array<DisplaySettings>>;
  displayType: DisplayType;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  operation: Scalars['String']['output'];
  rowOrder: Scalars['Int']['output'];
};

export type AssemblyTagNameAdded = {
  __typename?: 'AssemblyTagNameAdded';
  id: Scalars['ID']['output'];
  isNewlyAdded: Scalars['Boolean']['output'];
  structureId: Scalars['ID']['output'];
  tagName: Scalars['String']['output'];
};

export type AssemblyTemplateDataInput = {
  tagName: Scalars['String']['input'];
  templateData: Array<TemplateDataValues>;
};

export type AssemblyTemplateField = TemplateField & {
  __typename?: 'AssemblyTemplateField';
  /** Should this field be visible on first load inside a list or table */
  defaultVisible?: Maybe<Scalars['Boolean']['output']>;
  /** Table configuration */
  displayConfig?: Maybe<DisplayConfig>;
  /** Identify if field should be shown in the query tab */
  displayInQuery?: Maybe<Scalars['Boolean']['output']>;
  /** Identify if field should be shown in the risk assessment tab */
  displayInRisk?: Maybe<Scalars['Boolean']['output']>;
  /** Roles that the user needs to have to edit template data */
  editRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Unique Template Field ID */
  id: Scalars['ID']['output'];
  /** Specifies if field allows editing or not */
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Should not be shown in the UI. Used for identifying certain fields */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Is this Template Field read-only; i.e. value cannot be updated */
  isReadOnly: Scalars['Boolean']['output'];
  /** Is this Template Field always required */
  isRequired: Scalars['Boolean']['output'];
  /** Should not be shown in the UI. Used for identifying risk assessment filters */
  isRiskAssessment?: Maybe<Scalars['Boolean']['output']>;
  /** Key to identify field in the risk fields */
  key?: Maybe<Scalars['String']['output']>;
  /** Name of this Template Field */
  name: Scalars['String']['output'];
  /** Valid options for this Template Field value */
  options: Array<Scalars['String']['output']>;
  /** Type of permission that a user have to read the template */
  permission?: Maybe<Scalars['String']['output']>;
  /** Identify fixed decimal place */
  roundToDecimal?: Maybe<Scalars['Int']['output']>;
  /** Should the template options be fetched from the server on runtime */
  serverSide: Scalars['Boolean']['output'];
  /** Type of this Template Field */
  type: TemplateFieldTypeEnum;
  /** Identity about the unit of the field */
  unit?: Maybe<UnitsEnum>;
};

export type AssemblyUpdatedEventResult = {
  __typename?: 'AssemblyUpdatedEventResult';
  id: Scalars['String']['output'];
  structureId: Scalars['String']['output'];
  templateData?: Maybe<Array<TemplateData>>;
  templateId: Scalars['String']['output'];
};

export type AssignRemoveRoleToFromUsersInput = {
  roleId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type BenchmarkDownloadInput = {
  size: Scalars['Int']['input'];
};

export type BenchmarkDownloadOutput = {
  __typename?: 'BenchmarkDownloadOutput';
  data: Scalars['String']['output'];
};

export type BenchmarkUploadInput = {
  data: Scalars['String']['input'];
};

/** Benchmark provides API to upload and download data for network benchmark. */
export type BenchmarkUploadOutput = {
  __typename?: 'BenchmarkUploadOutput';
  size: Scalars['Int']['output'];
};

export type Blister = {
  __typename?: 'Blister';
  /**
   * Borders that make up the polygon where the blister was measured from
   * - specifically for display within a spherical image viewpoint
   */
  borders: Array<Maybe<PolarPoint>>;
  /**
   * 3d Borders consisting of one or more polygons of the area where the blister was
   * measured from in 3d space. Multiple polygons may be returned, for example when
   * the blister surface is obstructed by a separate pipe in the foreground of the
   * depth image
   */
  borders3d: Array<Polygon3d>;
  /** Blister center */
  centerPoint: PolarPoint;
  /** Estimated height of the blister (in millimetres) */
  estimatedHeight: Scalars['Float']['output'];
  /**
   * Radius used for fitting, this is a larger area around the blister so that
   * there is more flat surface for us to figure out the actual surface of the
   * object, currently defaults to a ratio of 1.33x blister radius (in millimetres)
   */
  fittingRadius: Scalars['Float']['output'];
  /** Manually set height of the blister (in millimetres) */
  manualSetHeight?: Maybe<Scalars['Float']['output']>;
  /** Maximum height of the blister (in millimetres) */
  maxHeight?: Maybe<Scalars['Float']['output']>;
  /** Radius calculated based on centerPoint and radiusPoint (in millimetres) */
  radius: Scalars['Float']['output'];
  /** Radius of a blister is measured from blister center to this point */
  radiusPoint: PolarPoint;
  /**  Residual corrosion allowance */
  residualCorrosionAllowance?: Maybe<ValueAndPercentage>;
  /** Residual thickness */
  residualThickness?: Maybe<ValueAndPercentage>;
  /** SC Category of Blister */
  scCategory?: Maybe<Scalars['String']['output']>;
  /** Verified maximum blister height */
  verifiedMaxHeight?: Maybe<Scalars['String']['output']>;
  /** Verified Residual Thickness */
  verifiedResidualThickness?: Maybe<Scalars['String']['output']>;
  /** Wall loss */
  wallLoss?: Maybe<ValueAndPercentage>;
};

export type BlisterHeightMeasurement = {
  __typename?: 'BlisterHeightMeasurement';
  /** Blister height measurements */
  blister: BlisterHeightMeasurementResult;
  /** Signed URL of Location depth image used to calculate line distance */
  id: Scalars['String']['output'];
  /** Blister height measurements API status */
  status: MeasurementStatus;
};

export type BlisterHeightMeasurementResult = {
  __typename?: 'BlisterHeightMeasurementResult';
  blisterProfilePlotPoints: Array<BlisterProfilePlotPoint>;
  borders3d: Array<Polygon3d>;
  centerPoint3d: Vector3;
  /** The max difference from the estimated height in metres */
  maxHeight: Scalars['Float']['output'];
};

export type BlisterInput = {
  /**
   * Borders that make up the polygon where the blister was measured from
   * - specifically for display within a spherical image viewpoint
   */
  borders: Array<InputMaybe<PolarPointInput>>;
  /**
   * 3d Borders consisting of one or more polygons of the area where the blister was
   * measured from in 3d space. Multiple polygons may be returned, for example when
   * the blister surface is obstructed by a separate pipe in the foreground of the
   * depth image
   */
  borders3d: Array<Polygon3dInput>;
  /** Blister center */
  centerPoint: PolarPointInput;
  /** Estimated height of the blister (in millimetres) */
  estimatedHeight?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Radius used for fitting, this is a larger area around the blister so that
   * there is more flat surface for us to figure out the actual surface of the
   * object, currently defaults to a ratio of 1.33x blister radius (in millimetres)
   */
  fittingRadius: Scalars['Float']['input'];
  /** Manually set height of the blister (in millimetres) */
  manualSetHeight?: InputMaybe<Scalars['Float']['input']>;
  /** Maximum height of the blister (in millimetres) */
  maxHeight?: InputMaybe<Scalars['Float']['input']>;
  /** Radius calculated based on centerPoint and radiusPoint (in millimetres) */
  radius: Scalars['Float']['input'];
  /** Radius of a blister is measured from blister center to this point */
  radiusPoint: PolarPointInput;
  /**  Residual corrosion allowance */
  residualCorrosionAllowance?: InputMaybe<ValueAndPercentageInput>;
  /** Residual thickness */
  residualThickness?: InputMaybe<ValueAndPercentageInput>;
  /** SC Category of Blister */
  scCategory?: InputMaybe<Scalars['String']['input']>;
  /** Verified maximum blister height */
  verifiedMaxHeight?: InputMaybe<Scalars['String']['input']>;
  /** Verified Residual Thickness */
  verifiedResidualThickness?: InputMaybe<Scalars['String']['input']>;
  /** Wall loss */
  wallLoss?: InputMaybe<ValueAndPercentageInput>;
};

export type BlisterMeasurementInput = {
  centerPoint: ImagePointInput;
  edgePoint: ImagePointInput;
};

export type BlisterProfilePlotPoint = {
  __typename?: 'BlisterProfilePlotPoint';
  /** Distance from the estimated height in metres */
  height: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type Box = {
  __typename?: 'Box';
  /** Unique Box ID */
  id: Scalars['ID']['output'];
  /** Max Vector3 point of this Box */
  max: Vector3;
  /** Min Vector3 point of this Box */
  min: Vector3;
};

export type BulkCreateForAssembliesInput = {
  collectionName: Scalars['String']['input'];
  comments: Array<CommentFieldsInput>;
  excludedAssemblyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  referenceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  selectedFilters?: InputMaybe<Array<InputMaybe<RiskAssessmentSelectedFiltersInput>>>;
  structureId: Scalars['ID']['input'];
  updateAllAssemblies: Scalars['Boolean']['input'];
};

export type BulkCreateOrUpdateCommentsForAssemblies = {
  __typename?: 'BulkCreateOrUpdateCommentsForAssemblies';
  insertedCount: Scalars['Float']['output'];
  modifiedCount: Scalars['Float']['output'];
  upsertedCount: Scalars['Float']['output'];
};

export type CalculatedToActualPofInput = {
  actualValue: Scalars['String']['input'];
  calculatedValue: Scalars['Float']['input'];
};

export type CardInsights = {
  __typename?: 'CardInsights';
  color?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type CenterPoint = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

/**
 * Client of Abyss Solutions.
 *
 * Clients own the Structures whose conditions are being assessed using automated ML technologies by Abyss Solutions.
 */
export type Client = {
  __typename?: 'Client';
  /**
   * Configuration at a customer level, this will be the
   * default configuration for all structures for this customer
   */
  config?: Maybe<Config>;
  /** Date this Client was created */
  createdAt: Scalars['String']['output'];
  /** Unique Client ID */
  id: Scalars['ID']['output'];
  /** Relative file path to logo for Client */
  logoPath?: Maybe<Scalars['String']['output']>;
  /** Client company name */
  name: Scalars['String']['output'];
  /** Date this Client was last updated */
  updatedAt: Scalars['String']['output'];
};

export enum ColumnKeysEnum {
  All = 'ALL',
  Blister = 'BLISTER',
  Component = 'COMPONENT',
  Cups = 'CUPS',
  Hotbolting = 'HOTBOLTING',
  Other = 'OTHER',
  PaintDamage = 'PAINT_DAMAGE',
  Pit = 'PIT',
  Subsea = 'SUBSEA'
}

export type Comment = {
  __typename?: 'Comment';
  /** Name of MongoDB Collection where Reference Document resides */
  collectionName: Scalars['String']['output'];
  /** Date this Comment was created */
  createdAt: Scalars['String']['output'];
  /** Auth0 ID of user who created this Comment */
  createdBy: Scalars['String']['output'];
  /** Details of User who created this Comment */
  createdByDetails: CreatedByDetails;
  /** Details of User who created this comment */
  createdByUser: User;
  /** Date this Comment was deleted - for soft delete */
  deletedAt?: Maybe<Scalars['String']['output']>;
  /** Unique Comment ID */
  id: Scalars['ID']['output'];
  /** Check if this Comment has been edited */
  isEdited: Scalars['Boolean']['output'];
  /** To identtify with which field this comments is linked */
  key?: Maybe<Scalars['String']['output']>;
  /** Status of the linked zone */
  status?: Maybe<ZoneStatus>;
  /** Structure that this Comment belongs to  */
  structureId: Scalars['ID']['output'];
  /** Text content of the Comment */
  text: Scalars['String']['output'];
  /** Title content of the Comment */
  title?: Maybe<Scalars['String']['output']>;
  /** Type of comment belongs to i.e COF, POF etc */
  type?: Maybe<Scalars['String']['output']>;
  /** Date this Comment was last updated */
  updatedAt: Scalars['String']['output'];
};

export type CommentFieldsInput = {
  key: Scalars['String']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ComplexPrismVolume = {
  __typename?: 'ComplexPrismVolume';
  /** Optional exclusion volumes */
  exclusions?: Maybe<Array<PrismVolume>>;
  /** Main volume defines inclusion volume */
  inclusion: PrismVolume;
};

export type ComplexPrismVolumeInput = {
  /** Optional exclusion volumes */
  exclusions?: InputMaybe<Array<PrismVolumeInput>>;
  /** Main volume defines inclusion volume */
  inclusion: PrismVolumeInput;
};

export type Config = {
  __typename?: 'Config';
  features?: Maybe<Features>;
  /** Default measurement units, i.e. IMPERIAL or METRIC */
  measurementUnit?: Maybe<MeasurementUnitEnum>;
};

/** Auth0 Connection */
export type ConnectionAuth0 = {
  __typename?: 'ConnectionAuth0';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Corrosion = {
  __typename?: 'Corrosion';
  /** Primary category of corrosion detected */
  primaryCategory: CorrosionLevel;
};

export enum CorrosionLevel {
  Clean = 'CLEAN',
  CoatingBreakdown = 'COATING_BREAKDOWN',
  Heavy = 'HEAVY',
  Light = 'LIGHT',
  Moderate = 'MODERATE'
}

export type Count = {
  __typename?: 'Count';
  count: Scalars['Int']['output'];
};

export type Counter = {
  __typename?: 'Counter';
  /** Count of the groupBy column */
  count: Scalars['Int']['output'];
  /** Group by an arbitrary column like poi.type */
  groupBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Structure that this Counter belongs to */
  structureId: Scalars['ID']['output'];
};

export type CoverageMetrics = {
  __typename?: 'CoverageMetrics';
  /** Coverage area metric */
  area?: Maybe<Scalars['Float']['output']>;
  /** Coverage mean metric */
  mean?: Maybe<Scalars['Float']['output']>;
  /** Coverage slice metric */
  slice?: Maybe<CoverageSlice>;
};

export type CoverageSlice = {
  __typename?: 'CoverageSlice';
  count: Array<Scalars['Int']['output']>;
  mean: Array<Scalars['Float']['output']>;
  start: Scalars['Float']['output'];
  step: Scalars['Float']['output'];
};

export type CreateCommentInput = {
  collectionName: Scalars['String']['input'];
  referenceId: Scalars['ID']['input'];
  structureId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreatePointOfInterestInput = {
  /** Accessibility of this POI */
  accessibility?: InputMaybe<PoiAccessibilityEnum>;
  /** Assembly relation for this POI */
  assemblyId: Scalars['ID']['input'];
  /** User who created this Point of interest */
  assignee?: InputMaybe<Scalars['String']['input']>;
  /** Blister metadata for this POI (if type is BLISTER) */
  blister?: InputMaybe<BlisterInput>;
  /** Camera location for this POI */
  cameraLocation: Vector3Input;
  /** Center point for this POI */
  centerPoint3d: Vector3Input;
  /** Comment for this POI */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Cuboids of volume of interest */
  cuboids?: InputMaybe<Array<CuboidInput>>;
  /** Due date of rectification for this Point of interest */
  dueDate?: InputMaybe<Scalars['String']['input']>;
  /** Linked POIs to the cuboid */
  linkedPointOfInterestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Location relation for this POI */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** Metadata for this POI */
  meta?: InputMaybe<Scalars['JSON']['input']>;
  /** Name for this POI */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Part relation for this POI */
  partId?: InputMaybe<Scalars['ID']['input']>;
  /** Priority of this Point of interest */
  priority?: InputMaybe<PoiPriority>;
  /** Current status of this Point of interest */
  status?: InputMaybe<PoiStatus>;
  /** Structure relation for this POI */
  structureId: Scalars['ID']['input'];
  /** Teams that this POI is shared with */
  teamIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Data values associated with Template for this POI */
  templateData?: InputMaybe<Array<TemplateDataInput>>;
  /** Template related to this POI for dynamic data */
  templateId?: InputMaybe<Scalars['ID']['input']>;
  /** Type for this POI */
  type: Scalars['String']['input'];
  /** Unlinked POIs to the cuboid */
  unlinkedPointOfInterestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Height of the POI relative to the viewpoint it was created from */
  viewpointRelativeHeight?: InputMaybe<Scalars['Float']['input']>;
  /** Visibility for this POI */
  visibility?: InputMaybe<VisibilityEnum>;
  /** Workpack id of this poi */
  workpackId?: InputMaybe<Scalars['ID']['input']>;
  /** Workpack number of POI */
  workpackNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRegionInput = {
  name: Scalars['String']['input'];
};

export type CreateRiskAssessmentDetailsInput = {
  /** TODO: deprecated */
  age?: InputMaybe<Scalars['Int']['input']>;
  /** TODO: deprecated */
  berthingCalculation?: InputMaybe<Scalars['Int']['input']>;
  /** TODO: deprecated */
  corrosionCategory?: InputMaybe<Scalars['String']['input']>;
  /** TODO: deprecated */
  hoursAtBerth?: InputMaybe<Scalars['Int']['input']>;
  /** TODO: deprecated */
  mooringAnalysis?: InputMaybe<Scalars['Int']['input']>;
  /** TODO: deprecated */
  percentDraftCondition?: InputMaybe<Scalars['Float']['input']>;
  /** TODO: deprecated */
  percentOfHours?: InputMaybe<Scalars['Float']['input']>;
  /** TODO: deprecated */
  shipsAtBerth?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the structure that will be updated */
  structureId: Scalars['ID']['input'];
  /** Data values associated with Template for this structure */
  templateData: Array<TemplateDataInput>;
  /** Template related to this structure for dynamic data */
  templateId: Scalars['ID']['input'];
};

export type CreateSupervoxelClass = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
  structureId: Scalars['ID']['input'];
};

export type CreateUserGroupInput = {
  name: Scalars['String']['input'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateWorkpackInput = {
  name: Scalars['String']['input'];
  structureId: Scalars['ID']['input'];
};

export type CreateWorkpackItemInput = {
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  partId?: InputMaybe<Scalars['String']['input']>;
  view: WorkpackItemViewInput;
  workpackId: Scalars['ID']['input'];
};

export type CreatedByDetails = {
  __typename?: 'CreatedByDetails';
  name: Scalars['String']['output'];
  profilePicture: Scalars['String']['output'];
};

/**
 * Image Sensor data of a Structure that has been inspected.
 *
 * An inspection takes place on the Location that has a certain image sensor in some cases such as Drones
 */
export type Cuboid = {
  __typename?: 'Cuboid';
  /** Assembly that this Cuboid belongs to */
  assemblyId?: Maybe<Scalars['ID']['output']>;
  /** CuboidClass assigned to this cuboid - Structure.cudoidClass */
  cuboidClassId?: Maybe<Scalars['ID']['output']>;
  /** Unique role ID */
  id: Scalars['ID']['output'];
  /** Position of the cuboid (x,y,z) */
  position: Array<Scalars['Float']['output']>;
  /** Rotation of the cuboid (roll,pitch,yaw). Euler angles using Abyss conventions. */
  rotation: Array<Scalars['Float']['output']>;
  /** Size of the cuboid in 3 axes (x,y,z) */
  scale: Array<Scalars['Float']['output']>;
  /** Structure that this Location data belongs to */
  structureId: Scalars['ID']['output'];
  /** Zone this cuboid was created in */
  zoneId?: Maybe<Scalars['ID']['output']>;
};

export type CuboidInput = {
  /** Position of the cuboid (x,y,z) */
  position: Array<Scalars['Float']['input']>;
  /** Rotation of the cuboid (roll,pitch,yaw). Euler angles using Abyss conventions. */
  rotation: Array<Scalars['Float']['input']>;
  /** Size of the cuboid in 3 axes (x,y,z) */
  scale: Array<Scalars['Float']['input']>;
};

/** Currently logged in user. */
export type CurrentUser = {
  __typename?: 'CurrentUser';
  /** Auth0 Connection name used to authenticate logged in user */
  connection: Scalars['String']['output'];
  /** User Groups which logged in user belongs to */
  groups: Array<Scalars['String']['output']>;
  /** Auth0 ID of the logged in user */
  id: Scalars['ID']['output'];
  /** Auth0 Roles which logged in user has */
  roles: Array<UserRolesEnum>;
};

export type DamageClass = {
  name: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

/**
 * Deck is a floor level on the Structure.
 *
 * A Structure can have multiple Decks on which day-to-day operations take place.
 */
export type Deck = {
  __typename?: 'Deck';
  /** Absolute height of deck from 0m */
  cadFloorHeight?: Maybe<Scalars['Float']['output']>;
  /** Transform matrix from platform grid coordinate system to 2D pixels on deck map */
  cadTransform: Array<Scalars['Float']['output']>;
  /** Date this Deck was created */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Defect Area for the structure */
  defectArea?: Maybe<Scalars['JSON']['output']>;
  /** [min, max] height range for spatial heatmap slider */
  heightRange: Array<Scalars['Float']['output']>;
  /** Increment step for spatial heatmap slider */
  heightStep?: Maybe<Scalars['Int']['output']>;
  /** Unique Deck ID */
  id: Scalars['ID']['output'];
  /** [width, height] dimensions of deck plan image */
  imageDimensions: Array<Scalars['Int']['output']>;
  /** User readable name */
  name: Scalars['String']['output'];
  /** URL for deck plan image file */
  resourcePath: Scalars['String']['output'];
  /** URL for voxel SQLite db file */
  spatialHeatmapPath: Scalars['String']['output'];
  /** Structure that the deck belongs to */
  structure: Structure;
  /** Date this Deck was last updated */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** Sort decks by this value to get the physical stacking order of decks */
  zOffset: Scalars['Int']['output'];
};

export type DeckAreaSummary = {
  __typename?: 'DeckAreaSummary';
  deckId: Scalars['ID']['output'];
  totalArea: Scalars['Float']['output'];
};

export type DeckWithDefects = {
  __typename?: 'DeckWithDefects';
  defectArea: Array<DefectArea>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DecksDetails = {
  __typename?: 'DecksDetails';
  area?: Maybe<Scalars['Float']['output']>;
  deckId: Deck;
};

export type Defect = {
  __typename?: 'Defect';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DefectArea = {
  __typename?: 'DefectArea';
  area: Scalars['Float']['output'];
  key: Scalars['String']['output'];
};

export type DefectColorMeta = {
  __typename?: 'DefectColorMeta';
  key: Scalars['String']['output'];
  value: Array<DefectColorMetaValue>;
};

export type DefectColorMetaValue = {
  __typename?: 'DefectColorMetaValue';
  colour: Scalars['String']['output'];
  name: Scalars['String']['output'];
  octreeIndex: Scalars['Int']['output'];
};

export type DefectLegend = {
  __typename?: 'DefectLegend';
  color: Scalars['String']['output'];
  groupKey: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  legendText?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  octreeKey: OctreeKey;
  octreeValue: Scalars['Int']['output'];
};

export type DefectMetrics = {
  __typename?: 'DefectMetrics';
  /** Coverage metrics of defect */
  coverage: CoverageMetrics;
  /** Type of defect */
  defectType: Scalars['String']['output'];
};

export type DeleteCuboidInput = {
  id: Scalars['ID']['input'];
};

export type DeletePointOfInterestInput = {
  /** Unique ID for POI to delete */
  pointOfInterestId: Scalars['ID']['input'];
};

export type DeleteUserGroupInput = {
  userGroupId: Scalars['ID']['input'];
};

export type DeleteWorkpackInput = {
  workpackId: Scalars['ID']['input'];
};

export type DeleteWorkpackItemInput = {
  workpackId: Scalars['ID']['input'];
  workpackItemId: Scalars['ID']['input'];
};

export type DisplayColor = {
  __typename?: 'DisplayColor';
  /** One of the value from options */
  key: Scalars['String']['output'];
  /** Define color for that option */
  value: Scalars['String']['output'];
};

export type DisplayConfig = {
  __typename?: 'DisplayConfig';
  /** Define the color for options */
  color?: Maybe<Array<Maybe<DisplayColor>>>;
  /** Unique ID */
  id: Scalars['ID']['output'];
  /** Defines Input Adorment for the text field */
  inputAdorment?: Maybe<InputAdormentType>;
  /** Is this Template Field can be sorted when used in table */
  isSortable: Scalars['Boolean']['output'];
  /** Sorting type based on the data that this field contian i.e number or string */
  sortType: SortTypeEnum;
  /** Used to define Number of rows to be rendered in Text Area field */
  textAreaRows?: Maybe<Scalars['Float']['output']>;
  /** Type or Style of the Table Cell */
  type: TableCellStyleEnum;
};

export type DisplaySettings = {
  __typename?: 'DisplaySettings';
  color: Scalars['String']['output'];
  fieldValue: Scalars['String']['output'];
};

export enum DisplayType {
  CircularBar = 'CIRCULAR_BAR',
  Counter = 'COUNTER',
  DeckImages = 'DECK_IMAGES',
  StackedBar = 'STACKED_BAR'
}

export enum EqiupmentCardFieldTypeEnum {
  AlarmLevel = 'ALARM_LEVEL',
  DamageClass = 'DAMAGE_CLASS',
  PercentageIndicator = 'PERCENTAGE_INDICATOR',
  SimpleText = 'SIMPLE_TEXT',
  TextWithTitle = 'TEXT_WITH_TITLE'
}

export type Equipment = {
  __typename?: 'Equipment';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type EquipmentCardField = {
  __typename?: 'EquipmentCardField';
  /** Key of the template field from templateFieldData */
  fieldKey?: Maybe<Scalars['String']['output']>;
  /** Unique Equipment Card Field ID */
  id: Scalars['ID']['output'];
  /** Name of this Equipment Card Field */
  name: Scalars['String']['output'];
  /** Position of this Equipment Card Field */
  position: EquipmentCardPositionEnum;
  /** Type of this Equipment Card Field */
  type: EqiupmentCardFieldTypeEnum;
};

export enum EquipmentCardPositionEnum {
  BottomMiddle = 'BOTTOM_MIDDLE',
  BottomRight = 'BOTTOM_RIGHT',
  Subtitle = 'SUBTITLE',
  TopMiddle = 'TOP_MIDDLE',
  TopRight = 'TOP_RIGHT'
}

export type EquipmentClasses = {
  __typename?: 'EquipmentClasses';
  classes: Array<Equipment>;
  name: Scalars['String']['output'];
};

export type EquipmentDetailField = {
  __typename?: 'EquipmentDetailField';
  /** Table configuration */
  displayConfig?: Maybe<DisplayConfig>;
  /** Unique human-readable key. It should be same as field key in template */
  fieldKey: Scalars['String']['output'];
  /** Unique Equipment Field ID */
  id: Scalars['ID']['output'];
  /** Should not be shown in the UI. Used for identifying certian fields */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Is this Equipment Template Field read-only; i.e. value cannot be updated */
  isReadOnly: Scalars['Boolean']['output'];
  /** Is this Equipment Field always required */
  isRequired: Scalars['Boolean']['output'];
  /** Name of this Equipment Field */
  name: Scalars['String']['output'];
  /** Valid options for this Equipment Field value */
  options: Array<EquipmentDetailOptionField>;
  /** Should the Equipment template options be fetched from the server on runtime */
  serverSide: Scalars['Boolean']['output'];
  /** Sub-Type of this Equipment  Field */
  subType?: Maybe<EquipmentDetailSubTypeEnum>;
  /** Type of this Equipment Field */
  type: EquipmentDetailTypeEnum;
};

export type EquipmentDetailOptionField = {
  __typename?: 'EquipmentDetailOptionField';
  /** Unique human-readable key. It should be same as field key in template */
  fieldKey?: Maybe<Scalars['String']['output']>;
  /** Unique Equipment Option Field */
  id: Scalars['ID']['output'];
  /** Name associated for the Equipment option field */
  name: Scalars['String']['output'];
  /** Type associated with the Equipment option field */
  type: OptionFieldTypeEnum;
};

export enum EquipmentDetailSubTypeEnum {
  DefectConsolidatedDegreeOfRusting = 'DEFECT_CONSOLIDATED_DEGREE_OF_RUSTING',
  DefectCorrosion = 'DEFECT_CORROSION',
  DefectDegreeOfRusting = 'DEFECT_DEGREE_OF_RUSTING',
  DefectSubstrateCondition = 'DEFECT_SUBSTRATE_CONDITION'
}

export enum EquipmentDetailTypeEnum {
  DefectConsolidatedDegree = 'DEFECT_CONSOLIDATED_DEGREE',
  DefectCorrosionArea = 'DEFECT_CORROSION_AREA',
  DefectCorrosionRatio = 'DEFECT_CORROSION_RATIO',
  DefectMaxSeverity = 'DEFECT_MAX_SEVERITY',
  TemplateList = 'TEMPLATE_LIST'
}

export type FailedUserInfo = {
  __typename?: 'FailedUserInfo';
  email: Scalars['String']['output'];
  errorMessage: Scalars['String']['output'];
};

/** Abyss fabric Features */
export type Features = {
  __typename?: 'Features';
  blisterHeights?: Maybe<Scalars['Boolean']['output']>;
};

export type File = {
  __typename?: 'File';
  fileName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  name: FileNameTypes;
  resourcePath: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  type: FileTypes;
  uploadStatus?: Maybe<FileUploadStatus>;
};

export type FileAttributes = {
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  uuid: Scalars['String']['input'];
};

export type FileInput = {
  fileName: Scalars['String']['input'];
  name: FileNameTypes;
  resourcePath: Scalars['String']['input'];
  type: FileTypes;
};

export enum FileNameTypes {
  IsometricDrawing = 'ISOMETRIC_DRAWING',
  PoiAttachments = 'POI_ATTACHMENTS'
}

export enum FileTypes {
  Jpeg = 'JPEG',
  Mp4 = 'MP4',
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum FileUploadStatus {
  Failed = 'FAILED',
  Inprogress = 'INPROGRESS',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type FilterForStructures = {
  name?: InputMaybe<Scalars['String']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type FilterForUserGroups = {
  name?: InputMaybe<Scalars['String']['input']>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};

export type FilterForUsers = {
  connection?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  excludeRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTeamId?: InputMaybe<Scalars['ID']['input']>;
  excludeUserGroupId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

export type FilterForUsersByUserGroup = {
  q?: InputMaybe<Scalars['String']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type FilteredOptions = {
  __typename?: 'FilteredOptions';
  /** Filtered options for this Template */
  options: Array<Scalars['String']['output']>;
};

export type FiltersForPreRegisteredUsers = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type FiltersForUsersCount = {
  connection?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  userGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type FiltersInput = {
  id: Scalars['String']['input'];
  value: Array<Scalars['String']['input']>;
};

/** Float (number) metadata */
export type FloatMeta = {
  __typename?: 'FloatMeta';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GenerateCropFromRectilinearInput = {
  /** X Y Z Position of the clicked point */
  centerPoint?: InputMaybe<CenterPoint>;
  /** Location information from the image */
  image: Image;
  /** Output image height */
  outputHeight: Scalars['Float']['input'];
  /** Output Vertical Field Of View */
  outputVerticalFov: Scalars['Float']['input'];
  /** Output image width */
  outputWidth: Scalars['Float']['input'];
};

export type GenerateImageInput = {
  /** Rotation of camera angle */
  bearing: Scalars['Float']['input'];
  /** Elevation of camera angle */
  elevation: Scalars['Float']['input'];
  /** Image name */
  imageName: Scalars['String']['input'];
  /** Image URL to download from */
  imageUrl: Scalars['String']['input'];
  /** Id of Location */
  locationId: Scalars['String']['input'];
  /** Output image height */
  outputHeight: Scalars['Float']['input'];
  /** Output image width */
  outputWidth: Scalars['Float']['input'];
  /** Vertical field of view (degrees) */
  verticalFovDegrees: Scalars['Float']['input'];
};

export type GenerateImageResult = {
  __typename?: 'GenerateImageResult';
  /** Base64 encoded string of image data */
  imageData: Scalars['String']['output'];
};

export type GeneratedPresignedUrl = {
  __typename?: 'GeneratedPresignedURL';
  id: Scalars['String']['output'];
  resourcePath: Scalars['String']['output'];
  url: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type GoverningValueBlister = {
  __typename?: 'GoverningValueBlister';
  /** Estimated value of thickness of pipe wall that has been lost to corrosion based on blister height (in millimeters) */
  estimatedWallLoss: Scalars['Float']['output'];
  /** Calculated wall loss as a percentage of nominal pipe wall thickness (in %) */
  estimatedWallLossPercentage: Scalars['Float']['output'];
  /** Recorded height of the blister (in millimeters) */
  height: Scalars['Float']['output'];
  /** Remaining allowance for corrosion based on estimated thickness loss and pipe spec (in millimeters) */
  residualCorrosionAllowance: Scalars['Float']['output'];
  /** Calculated residual corrosion allowance as a percentage of nominal corrosion allowance (in %) */
  residualCorrosionAllowancePercentage: Scalars['Float']['output'];
  /** Calculated residual thickness based on wall loss and pipe spec (in millimeters) */
  residualThickness: Scalars['Float']['output'];
  /** Calculated residual thickness as a percentage of nominal thickness (in %) */
  residualThicknessPercentage: Scalars['Float']['output'];
};

export type GoverningValueInput = {
  estimatedWallLoss: Scalars['Float']['input'];
  estimatedWallLossPercentage: Scalars['Float']['input'];
  height: Scalars['Float']['input'];
  residualCorrosionAllowance: Scalars['Float']['input'];
  residualCorrosionAllowancePercentage: Scalars['Float']['input'];
  residualThickness: Scalars['Float']['input'];
  residualThicknessPercentage: Scalars['Float']['input'];
};

export type GoverningValueRecommendation = {
  __typename?: 'GoverningValueRecommendation';
  blister?: Maybe<PointOfInterest>;
  calculations?: Maybe<GoverningValueBlister>;
  manuallyUpdated?: Maybe<Scalars['Boolean']['output']>;
  pointOfInterest?: Maybe<PointOfInterest>;
};

export type GoverningValueRecommendationInput = {
  blisterId: Scalars['ID']['input'];
  calculations: GoverningValueInput;
};

export type GroupsByPartIdsInput = {
  partIds: Array<Scalars['String']['input']>;
  structureId: Scalars['String']['input'];
};

/**
 * History Document
 *
 * A history document is a detailed snapshot of a MongoDB document from another MongoDB collection at a particular point in time.
 */
export type History = {
  __typename?: 'History';
  /** Name of MongoDB Collection where Reference Document resides */
  collectionName: Scalars['String']['output'];
  /** Date this History was created */
  createdAt: Scalars['String']['output'];
  /** User who created this History */
  createdBy: Scalars['String']['output'];
  /** Auth0 Name and Profile Picture of the user associated to createdBy from auth0 fetched at request time. */
  createdByDetails?: Maybe<CreatedByDetails>;
  /** Name of the user associated to createdBy from auth0 fetched at request time. createdBy must be present to use this */
  createdByName: Scalars['String']['output'];
  /** Full Document capture at the time of History */
  document: Scalars['JSON']['output'];
  /** Name of Event that triggered creation of History */
  eventName: Scalars['String']['output'];
  /** Unique History ID */
  id: Scalars['ID']['output'];
  /** Structure that this History event belongs to  */
  structureId?: Maybe<Scalars['ID']['output']>;
};

export type IdmsTask = {
  __typename?: 'IdmsTask';
  /** Task Start Date */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Unique Task ID */
  id: Scalars['ID']['output'];
  /** Task Number */
  idmsTaskNumber: Scalars['String']['output'];
  /** Line Number of Task */
  lineNumber: Scalars['String']['output'];
  /** Recommendations for this task */
  recommendations: Array<TaskRecommendation>;
  /** Task Status */
  status?: Maybe<Scalars['String']['output']>;
  /** Task Status Updated At */
  statusUpdatedAt?: Maybe<Scalars['String']['output']>;
  /** Structure ID to which this Task belongs to */
  structureId: Scalars['ID']['output'];
  /** Task Due Date */
  taskDueDate?: Maybe<Scalars['String']['output']>;
};

export type IdmsTasksWithCount = {
  __typename?: 'IdmsTasksWithCount';
  count: Scalars['Int']['output'];
  tasks: Array<IdmsTask>;
};

export type IdmsUpload = {
  __typename?: 'IdmsUpload';
  /** Date this File was created */
  createdAt: Scalars['String']['output'];
  /** User who created this File */
  createdBy: User;
  /** File path where file is present */
  filePath: Scalars['String']['output'];
  /** Unique File ID */
  id: Scalars['ID']['output'];
  /** File name */
  name: Scalars['String']['output'];
  /** Structure to which this file belongs to */
  structure: Structure;
  /** Template Type of File */
  templateType: IdmsUploadsTypeEnum;
  /** Date this File was last updated */
  updatedAt: Scalars['String']['output'];
};

export type IdmsUploadMutationInput = {
  /** File to be uploaded in base64 format */
  file: Scalars['String']['input'];
  name: Scalars['String']['input'];
  structureId: Scalars['ID']['input'];
  templateType: IdmsUploadsTypeEnum;
};

export enum IdmsUploadsTypeEnum {
  AfInspectionPriority = 'AF_INSPECTION_PRIORITY',
  ApmLineMetadata = 'APM_LINE_METADATA',
  ApmRecommendation = 'APM_RECOMMENDATION',
  ApmRecommendationStatus = 'APM_RECOMMENDATION_STATUS',
  ApmTaskNumber = 'APM_TASK_NUMBER',
  ApmTaskStatus = 'APM_TASK_STATUS',
  ApmWorkOrder = 'APM_WORK_ORDER',
  WorkOrderStatus = 'WORK_ORDER_STATUS'
}

export type Image = {
  /** Image Sensor */
  imageSensor: ImageSensorInput;
  /** Pose of the camera */
  pose: Pose;
  /** path to the Location image */
  url: Scalars['String']['input'];
};

export type ImagePointInput = {
  /** Position along the x axis of the image with a value between 0 and 1 */
  x: Scalars['Float']['input'];
  /** Position along the y axis of the image with a value between 0 and 1 */
  y: Scalars['Float']['input'];
};

/**
 * Image Sensor data of a Structure that has been inspected.
 *
 * An inspection takes place on the Location that has a certain image sensor in some cases such as Drones
 */
export type ImageSensor = {
  __typename?: 'ImageSensor';
  /** Calibration coordinates X */
  cx: Scalars['Float']['output'];
  /** Calibration coordinates Y */
  cy: Scalars['Float']['output'];
  /** Sensor Image Focal Length */
  focalLength: Scalars['Float']['output'];
  /** Sensor Image Height */
  height: Scalars['Int']['output'];
  /** Unique role ID */
  id: Scalars['ID']['output'];
  /** Sensor Name */
  name: Scalars['String']['output'];
  /** Structure that this Location data belongs to */
  structure: Structure;
  /** Sensor Image Width */
  width: Scalars['Int']['output'];
};

export type ImageSensorInput = {
  /** Calibration coordinates X */
  cx: Scalars['Float']['input'];
  /** Calibration coordinates Y */
  cy: Scalars['Float']['input'];
  /** Sensor Image Focal Length */
  focalLength: Scalars['Float']['input'];
  /** Sensor Image Height */
  height: Scalars['Int']['input'];
  /** Sensor Image Width */
  width: Scalars['Int']['input'];
};

export enum InputAdormentIconTypeEnum {
  MeasurementTool = 'MEASUREMENT_TOOL',
  Reload = 'RELOAD'
}

export enum InputAdormentPositionEnum {
  End = 'END',
  Start = 'START'
}

export type InputAdormentType = {
  __typename?: 'InputAdormentType';
  /** If the Icon Path is present, we will fetch icons from the path. */
  iconPath?: Maybe<Scalars['String']['output']>;
  /** If the Icon Path is undefined, fallback to use static map on frontend. */
  iconType?: Maybe<InputAdormentIconTypeEnum>;
  /** Position of the Input Adorment */
  position?: Maybe<InputAdormentPositionEnum>;
};

export type InputGroupByWorkpackId = {
  pointOfInterestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  workpackId?: InputMaybe<Scalars['ID']['input']>;
};

export type InputUpdateFileForPointOfInterest = {
  /** File id which status needs to be updated */
  fileId: Scalars['ID']['input'];
  /** POI for which to attach files */
  pointOfInterestId: Scalars['ID']['input'];
  /** Upload status */
  uploadStatus: FileUploadStatus;
};

/** Integer (number) metadata */
export type IntMeta = {
  __typename?: 'IntMeta';
  key: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type InviteUsersInput = {
  clientId: Scalars['String']['input'];
  connection: Scalars['String']['input'];
  emails: Array<Scalars['String']['input']>;
  groups: Array<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
};

export type LabelAnnotation = {
  __typename?: 'LabelAnnotation';
  /** Unique Label Annotation ID */
  id: Scalars['ID']['output'];
  /** LabelClass this label-annotation belongs (structures.labelClass) */
  labelClassId: Scalars['ID']['output'];
  /** Octree id the label-annotation belongs to */
  octreeId: Scalars['Int']['output'];
  /** Structure that this Label Annotation belongs to */
  structureId: Scalars['ID']['output'];
};

export type LabelClass = {
  __typename?: 'LabelClass';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LineMeasurement = {
  __typename?: 'LineMeasurement';
  /** Signed URL of Location depth image used to calculate line distance */
  id: Scalars['String']['output'];
  /** Location document ID */
  image_id: Scalars['String']['output'];
  /** Line measurements */
  line: LineMeasurementResult;
  /** Measurements API status */
  status: MeasurementStatus;
};

export type LineMeasurementResult = {
  __typename?: 'LineMeasurementResult';
  /** Distance between line endpoints */
  distance: Array<Array<Scalars['Float']['output']>>;
  points2D: Array<Array<Scalars['Float']['output']>>;
  points3D: Array<Array<Scalars['Float']['output']>>;
};

/**
 * Location data of a Structure that has been inspected.
 *
 * An inspection takes place on the Location that involves data capture of a Structure in the form of spherical (panoramic) images or LIDAR scans.
 * This Location data of the Structure is then used to assess the conditions of Parts that compose the Structure and identify any defects on these Parts, such as corrosion.
 */
export type Location = {
  __typename?: 'Location';
  /** User who last analysed this Location */
  analysedBy?: Maybe<Scalars['ID']['output']>;
  /** Default resource path used as base path for layers */
  baseResourcePath?: Maybe<Scalars['String']['output']>;
  /** Date this Location was created */
  createdAt: Scalars['String']['output'];
  /** Deck that this Location data belongs to */
  deck: Deck;
  /** Position of Location on Deck plan */
  deckPlan?: Maybe<LocationDeckPlan>;
  /** URL for the Location depth map */
  depthResource?: Maybe<Scalars['String']['output']>;
  /** Relative path to the location depth map */
  depthResourcePath?: Maybe<Scalars['String']['output']>;
  /** Distance from asset */
  distance?: Maybe<Scalars['Int']['output']>;
  /** Unique Location ID */
  id: Scalars['ID']['output'];
  /** Image Sensor that was use for this location */
  imageSensor?: Maybe<ImageSensor>;
  /** Image layers that can be toggled */
  layers?: Maybe<Array<Maybe<LocationLayer>>>;
  /** User readable identifier for the Location */
  name: Scalars['String']['output'];
  /** Paint Region that this Location data belongs to */
  paintRegion?: Maybe<PaintRegion>;
  /** Camera pose of Location in grid coordinate system */
  pose?: Maybe<LocationPose>;
  /** URL for the Location scan data */
  resource?: Maybe<Scalars['String']['output']>;
  /** Relative path to the location scan data */
  resourcePath?: Maybe<Scalars['String']['output']>;
  /** Scan key used to identify the image and deck eg. LD/LDW0250 */
  scanKey?: Maybe<Scalars['String']['output']>;
  /** Size of the images used to build the resource path in layers */
  size?: Maybe<Scalars['String']['output']>;
  /** Structure that this Location data belongs to */
  structure: Structure;
  /** Date this Location was last updated */
  updatedAt: Scalars['String']['output'];
  /** User who last updated this Location */
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type LocationDeckPlan = {
  __typename?: 'LocationDeckPlan';
  /** [x,y] coordinates of Location on Deck plan */
  coordinates: Array<Scalars['Float']['output']>;
  /** Id of Location on Deck plan */
  id: Scalars['ID']['output'];
};

export type LocationLayer = {
  __typename?: 'LocationLayer';
  /** Name To Be Displayed */
  displayName: Scalars['String']['output'];
  /** File Extension eg. jpg, png, svg */
  fileExt?: Maybe<Scalars['String']['output']>;
  /** Type used in the url eg. rgb or bv (blister-vision) */
  type: Scalars['String']['output'];
};

export type LocationPose = {
  __typename?: 'LocationPose';
  /** Pitch angle (in radians) */
  pitch: Scalars['Float']['output'];
  /** Roll angle (in radians) */
  roll: Scalars['Float']['output'];
  /** X-axis */
  x: Scalars['Float']['output'];
  /** Y-axis */
  y: Scalars['Float']['output'];
  /** Yaw angle (in radians) */
  yaw: Scalars['Float']['output'];
  /** Yaw offset angle (in radians). Aligns spherical image with scan point cloud (depends on camera type) */
  yawOffset: Scalars['Float']['output'];
  /** Z-axis */
  z: Scalars['Float']['output'];
};

export type LocationsForAssemblyInput = {
  deckId: Scalars['ID']['input'];
  selectedTemplateFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  structureId: Scalars['ID']['input'];
};

/** Login Types */
export enum LoginTypeEnum {
  Email = 'EMAIL',
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT'
}

export type MarkLocationAnalysedInput = {
  isAnalysed: Scalars['Boolean']['input'];
  locationId: Scalars['ID']['input'];
};

export type MarkViewPointAsAnalysedFromCurrentAssemblyInput = {
  assemblyId: Scalars['ID']['input'];
  viewpointId: Scalars['ID']['input'];
};

export type MeasurementStatus = {
  __typename?: 'MeasurementStatus';
  /** Location depth image cache status */
  cacheStatus: Scalars['String']['output'];
  /** Blister measurement fitting radius */
  fittingRadius?: Maybe<Scalars['Float']['output']>;
  /** Blister measurement target radius */
  targetRadius?: Maybe<Scalars['Float']['output']>;
  /** Blister measurement window size */
  windowSize: Array<Scalars['Int']['output']>;
};

export enum MeasurementUnitEnum {
  /** US Imperial units of measurement */
  Imperial = 'IMPERIAL',
  /** Metric units of measurement */
  Metric = 'METRIC'
}

/** Meta is used for storing data with dynamic keys */
export type Meta = DefectColorMeta | FloatMeta | IntMeta | StringArrayMeta | StringMeta;

export type ModeInput = {
  heightRange: Array<Scalars['Int']['input']>;
  includeValues?: InputMaybe<Array<Scalars['Int']['input']>>;
  modes: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Group POI into a single workpack group */
  GroupPointOfInterestsByWorkpackId?: Maybe<Array<Maybe<PointOfInterest>>>;
  /** Remove POI from Workpack */
  RemoveWorkpackIdFromPointOfInterests?: Maybe<Array<Maybe<PointOfInterest>>>;
  addNewAssemblyTagName?: Maybe<Assembly>;
  addPartsToAssembly: Array<Part>;
  /** Adds or Updates the Split Cuboid on the Part */
  addUpdateCuboid?: Maybe<Cuboid>;
  /** Adds or Updates the Split Cuboid on the Part */
  addUpdateSplitCuboidOnPart: AddUpdateSplitCuboidOnPartNotification;
  addUserGroupsToStructure?: Maybe<StructuresAndGroups>;
  addUsersToTeam: Team;
  addUsersToUserGroup?: Maybe<UserGroup>;
  assignRoleToUsers: Role;
  benchmarkDownload?: Maybe<BenchmarkDownloadOutput>;
  benchmarkUpload?: Maybe<BenchmarkUploadOutput>;
  blockOrUnblockUser: User;
  bulkCreateCommentsForAssemblies?: Maybe<BulkCreateOrUpdateCommentsForAssemblies>;
  createAnnotation?: Maybe<Annotation>;
  createComment: Comment;
  createNewReport: Report;
  /** Create a new POI */
  createPointOfInterest?: Maybe<PointOfInterest>;
  createPreRegisteredUsers: Array<PreRegisteredUser>;
  createRegion?: Maybe<Region>;
  createRiskAssessmentDetails?: Maybe<Structure>;
  createSupervoxelClass?: Maybe<SupervoxelClass>;
  createTeam: Team;
  /** Create a new Template */
  createTemplate?: Maybe<Template>;
  createUserGroup?: Maybe<UserGroup>;
  createWorkpack?: Maybe<Workpack>;
  createWorkpackItem?: Maybe<WorkpackItem>;
  deleteAssemblyFromReport: Report;
  deleteComment: Comment;
  /** Removes the Split Cuboid on the Part */
  deleteCuboid?: Maybe<Scalars['ID']['output']>;
  deleteDraftReport: Report;
  /** Delete an existing POI */
  deletePointOfInterest?: Maybe<PointOfInterest>;
  deletePointOfInterestFromReport: Report;
  deletePreRegisteredUser?: Maybe<PreRegisteredUser>;
  deleteReport: Scalars['Boolean']['output'];
  deleteTeam: Scalars['ID']['output'];
  /** Delete an existing Template */
  deleteTemplate?: Maybe<Template>;
  deleteUserGroup?: Maybe<UserGroup>;
  deleteWorkpack?: Maybe<Workpack>;
  deleteWorkpackItem?: Maybe<WorkpackItem>;
  idmsFileUpload?: Maybe<IdmsUpload>;
  inviteUsers: UserInvite;
  markLocationAnalysed?: Maybe<Location>;
  markViewPointAsAnalysedFromCurrentAssembly?: Maybe<Assembly>;
  nominateGoverningPointOfInterest?: Maybe<Scalars['String']['output']>;
  /** Remediate POI */
  remediatePointOfInterests: Array<PointOfInterest>;
  /** Remove attachments from a POI */
  removeAttachmentsFromPoi?: Maybe<PointOfInterest>;
  removeRoleFromUsers: Role;
  /** Removes the Split Cuboid on the Part */
  removeSplitCuboidOnPart: RemoveSplitCuboidOnPartNotification;
  removeUserGroupsFromStructure?: Maybe<StructuresAndGroups>;
  removeUsersFromTeam: Team;
  removeUsersFromUserGroup?: Maybe<UserGroup>;
  resetPassword: Scalars['String']['output'];
  /** Save screenshot of a POI */
  savePointOfInterestScreenshot?: Maybe<PointOfInterest>;
  setAlarmLevel?: Maybe<AlarmLevelRecommendation>;
  setHiddenForParts: Array<Scalars['String']['output']>;
  setReviewedForParts: Array<Scalars['String']['output']>;
  splitPart: Part;
  updateAssembliesProbabilityOfFailureRiskFactors?: Maybe<Array<Maybe<Assembly>>>;
  updateAssembly?: Maybe<Assembly>;
  /** Create or Update Blister POI Recommendation for Assembly (unique by Assembly ID / Blister POI name pair) */
  updateAssemblyBlisters: PointOfInterest;
  updateGoverningValue?: Maybe<Assembly>;
  updatePaintRegionStatus: PaintRegion;
  /** Update an existing POI */
  updatePointOfInterest?: Maybe<PointOfInterest>;
  /** Update file upload status of specific file */
  updatePointOfInterestFile: PointOfInterest;
  /** Update existing POIs visibility */
  updatePointOfInterestVisibility: Array<PointOfInterest>;
  updateReport: Report;
  updateReportByPointOfInterests: Report;
  updateReportPointOfInterestImages: Report;
  updateReportStatus: Report;
  updateRiskAssessmentTemplateFieldDataForAssemblies?: Maybe<Array<Maybe<Assembly>>>;
  updateSingleTemplateData?: Maybe<Assembly>;
  updateSupervoxels: Array<SuperVoxels>;
  /** Update a Template */
  updateTemplate?: Maybe<Template>;
  /** UpdateTemplateData mutation is used by ES-Sheet in v1 to directly populate features in v2 */
  updateTemplateData?: Maybe<UpdatedTemplateDataResult>;
  updateUserColumnPreferences?: Maybe<User>;
  updateUserGroup?: Maybe<UserGroup>;
  /** Create or update auth0 user profile info in mongo */
  updateUserInfo?: Maybe<User>;
  updateUserPreferences?: Maybe<User>;
  updateWorkpack?: Maybe<Workpack>;
  updateWorkpackItem?: Maybe<WorkpackItem>;
  updateZoneStatus: ZoneUpdate;
  /** Upload attachments to a POI */
  uploadAttachmentsToPoi: Array<GeneratedPresignedUrl>;
  /** Verify a POI */
  verifyPointOfInterest?: Maybe<PointOfInterest>;
};


export type MutationGroupPointOfInterestsByWorkpackIdArgs = {
  input?: InputMaybe<InputGroupByWorkpackId>;
};


export type MutationRemoveWorkpackIdFromPointOfInterestsArgs = {
  input?: InputMaybe<InputGroupByWorkpackId>;
};


export type MutationAddNewAssemblyTagNameArgs = {
  input?: InputMaybe<AddNewAssemblyTagNameInput>;
};


export type MutationAddPartsToAssemblyArgs = {
  input?: InputMaybe<AddPartsToAssemblyInput>;
};


export type MutationAddUpdateCuboidArgs = {
  input?: InputMaybe<AddUpdateCuboidInput>;
};


export type MutationAddUpdateSplitCuboidOnPartArgs = {
  input?: InputMaybe<AddUpdateSplitCuboidOnPartInput>;
};


export type MutationAddUserGroupsToStructureArgs = {
  input?: InputMaybe<AddRemoveStructuresToFromUserGroupsInput>;
};


export type MutationAddUsersToTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationAddUsersToUserGroupArgs = {
  input?: InputMaybe<AddRemoveUsersToFromUserGroupInput>;
};


export type MutationAssignRoleToUsersArgs = {
  input?: InputMaybe<AssignRemoveRoleToFromUsersInput>;
};


export type MutationBenchmarkDownloadArgs = {
  input?: InputMaybe<BenchmarkDownloadInput>;
};


export type MutationBenchmarkUploadArgs = {
  input?: InputMaybe<BenchmarkUploadInput>;
};


export type MutationBlockOrUnblockUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationBulkCreateCommentsForAssembliesArgs = {
  input?: InputMaybe<BulkCreateForAssembliesInput>;
};


export type MutationCreateAnnotationArgs = {
  imageId: Scalars['String']['input'];
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateNewReportArgs = {
  input?: InputMaybe<CreateNewReportInput>;
};


export type MutationCreatePointOfInterestArgs = {
  input?: InputMaybe<CreatePointOfInterestInput>;
};


export type MutationCreatePreRegisteredUsersArgs = {
  input?: InputMaybe<PreRegisteredUserInput>;
};


export type MutationCreateRegionArgs = {
  input?: InputMaybe<CreateRegionInput>;
};


export type MutationCreateRiskAssessmentDetailsArgs = {
  input?: InputMaybe<CreateRiskAssessmentDetailsInput>;
};


export type MutationCreateSupervoxelClassArgs = {
  input?: InputMaybe<CreateSupervoxelClass>;
};


export type MutationCreateTeamArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateTemplateArgs = {
  input?: InputMaybe<TemplateInput>;
};


export type MutationCreateUserGroupArgs = {
  input?: InputMaybe<CreateUserGroupInput>;
};


export type MutationCreateWorkpackArgs = {
  input?: InputMaybe<CreateWorkpackInput>;
};


export type MutationCreateWorkpackItemArgs = {
  input?: InputMaybe<CreateWorkpackItemInput>;
};


export type MutationDeleteAssemblyFromReportArgs = {
  input?: InputMaybe<DeleteAssemblyFromReportInput>;
};


export type MutationDeleteCommentArgs = {
  commentId?: InputMaybe<Scalars['ID']['input']>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteCuboidArgs = {
  input?: InputMaybe<DeleteCuboidInput>;
};


export type MutationDeleteDraftReportArgs = {
  input?: InputMaybe<DeleteDraftReportInput>;
};


export type MutationDeletePointOfInterestArgs = {
  input?: InputMaybe<DeletePointOfInterestInput>;
};


export type MutationDeletePointOfInterestFromReportArgs = {
  input?: InputMaybe<DeletePointOfInterestFromReportInput>;
};


export type MutationDeletePreRegisteredUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteReportArgs = {
  reportId: Scalars['ID']['input'];
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type MutationDeleteTemplateArgs = {
  templateId: Scalars['ID']['input'];
};


export type MutationDeleteUserGroupArgs = {
  input?: InputMaybe<DeleteUserGroupInput>;
};


export type MutationDeleteWorkpackArgs = {
  input?: InputMaybe<DeleteWorkpackInput>;
};


export type MutationDeleteWorkpackItemArgs = {
  input?: InputMaybe<DeleteWorkpackItemInput>;
};


export type MutationIdmsFileUploadArgs = {
  input: IdmsUploadMutationInput;
};


export type MutationInviteUsersArgs = {
  input?: InputMaybe<InviteUsersInput>;
};


export type MutationMarkLocationAnalysedArgs = {
  input?: InputMaybe<MarkLocationAnalysedInput>;
};


export type MutationMarkViewPointAsAnalysedFromCurrentAssemblyArgs = {
  input?: InputMaybe<MarkViewPointAsAnalysedFromCurrentAssemblyInput>;
};


export type MutationNominateGoverningPointOfInterestArgs = {
  input?: InputMaybe<NominateGoverningPointOfInterestInput>;
};


export type MutationRemediatePointOfInterestsArgs = {
  input?: InputMaybe<RemediatePointOfInterestInput>;
};


export type MutationRemoveAttachmentsFromPoiArgs = {
  input?: InputMaybe<RemoveAttachmentsInput>;
};


export type MutationRemoveRoleFromUsersArgs = {
  input?: InputMaybe<AssignRemoveRoleToFromUsersInput>;
};


export type MutationRemoveSplitCuboidOnPartArgs = {
  input?: InputMaybe<RemoveSplitCuboidOnPartInput>;
};


export type MutationRemoveUserGroupsFromStructureArgs = {
  input?: InputMaybe<AddRemoveStructuresToFromUserGroupsInput>;
};


export type MutationRemoveUsersFromTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationRemoveUsersFromUserGroupArgs = {
  input?: InputMaybe<AddRemoveUsersToFromUserGroupInput>;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSavePointOfInterestScreenshotArgs = {
  input?: InputMaybe<SavePointOfInterestScreenshotInput>;
};


export type MutationSetAlarmLevelArgs = {
  input?: InputMaybe<SetAlarmLevelInput>;
};


export type MutationSetHiddenForPartsArgs = {
  input?: InputMaybe<UpdatePartsHiddenFlagInput>;
};


export type MutationSetReviewedForPartsArgs = {
  input?: InputMaybe<UpdatePartsReviewedFlagInput>;
};


export type MutationSplitPartArgs = {
  input?: InputMaybe<SplitPartInput>;
};


export type MutationUpdateAssembliesProbabilityOfFailureRiskFactorsArgs = {
  input?: InputMaybe<UpdateTemplateFieldDataPofInput>;
};


export type MutationUpdateAssemblyArgs = {
  input?: InputMaybe<UpdateAssemblyInput>;
};


export type MutationUpdateAssemblyBlistersArgs = {
  input?: InputMaybe<CreatePointOfInterestInput>;
};


export type MutationUpdateGoverningValueArgs = {
  input?: InputMaybe<UpdateGoverningValueInput>;
};


export type MutationUpdatePaintRegionStatusArgs = {
  input?: InputMaybe<UpdatePaintRegionStatusInput>;
};


export type MutationUpdatePointOfInterestArgs = {
  input?: InputMaybe<UpdatePointOfInterestInput>;
};


export type MutationUpdatePointOfInterestFileArgs = {
  input?: InputMaybe<InputUpdateFileForPointOfInterest>;
};


export type MutationUpdatePointOfInterestVisibilityArgs = {
  filters?: InputMaybe<PointOfInterestInput>;
  input?: InputMaybe<UpdatePointOfInterestVisibilityInput>;
};


export type MutationUpdateReportArgs = {
  input?: InputMaybe<UpdateReportInput>;
};


export type MutationUpdateReportByPointOfInterestsArgs = {
  input?: InputMaybe<UpdateReportByPointOfInterestsInput>;
};


export type MutationUpdateReportPointOfInterestImagesArgs = {
  input?: InputMaybe<UpdateReportPointOfInterestImagesInput>;
};


export type MutationUpdateReportStatusArgs = {
  input?: InputMaybe<UpdateReportStatusInput>;
};


export type MutationUpdateRiskAssessmentTemplateFieldDataForAssembliesArgs = {
  input: UpdateRiskAssessmentTemplateFieldDataForAssembliesInput;
};


export type MutationUpdateSingleTemplateDataArgs = {
  input?: InputMaybe<UpdateSingleTemplateDataInput>;
};


export type MutationUpdateSupervoxelsArgs = {
  input?: InputMaybe<UpdateSupervoxel>;
};


export type MutationUpdateTemplateArgs = {
  input?: InputMaybe<TemplateInput>;
};


export type MutationUpdateTemplateDataArgs = {
  input?: InputMaybe<UpdateTemplateDataInput>;
};


export type MutationUpdateUserColumnPreferencesArgs = {
  input?: InputMaybe<UpdateUserColumnPreferencesInput>;
};


export type MutationUpdateUserGroupArgs = {
  input?: InputMaybe<UpdateUserGroupInput>;
};


export type MutationUpdateUserInfoArgs = {
  input?: InputMaybe<UpdateUserInfoInput>;
};


export type MutationUpdateUserPreferencesArgs = {
  input?: InputMaybe<UpdateUserPreferencesInput>;
};


export type MutationUpdateWorkpackArgs = {
  input?: InputMaybe<UpdateWorkpackInput>;
};


export type MutationUpdateWorkpackItemArgs = {
  input?: InputMaybe<UpdateWorkpackItemInput>;
};


export type MutationUpdateZoneStatusArgs = {
  input?: InputMaybe<UpdateZoneStatusInput>;
};


export type MutationUploadAttachmentsToPoiArgs = {
  input?: InputMaybe<UploadAttachmentsInput>;
};


export type MutationVerifyPointOfInterestArgs = {
  input?: InputMaybe<VerifyPointOfInterestInput>;
};

export type NominateGoverningPointOfInterestInput = {
  pointOfInterestId: Scalars['ID']['input'];
};

export type NominationCalculations = {
  __typename?: 'NominationCalculations';
  alarmLevel: Scalars['String']['output'];
  governingValue?: Maybe<GoverningValueBlister>;
};

export type NominationCalculationsInput = {
  assemblyId: Scalars['ID']['input'];
  blisterId: Scalars['ID']['input'];
};

export enum OctreeKey {
  Corrosion = 'CORROSION',
  Defect = 'DEFECT',
  Degree = 'DEGREE',
  DegreeOfRusting = 'DEGREE_OF_RUSTING'
}

export enum OptionFieldTypeEnum {
  CircularProgressBar = 'CIRCULAR_PROGRESS_BAR',
  SimpleText = 'SIMPLE_TEXT'
}

export type Overlap = {
  __typename?: 'Overlap';
  annotationId: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  /** Number of voxels that overlap with the reference annotation */
  voxelCount: Scalars['Int']['output'];
};

export type OverlapInfo = {
  __typename?: 'OverlapInfo';
  overlaps: Array<Overlap>;
};

export type PageInfo = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['Int']['input']>;
};

export type PaintBlockFiltersInput = {
  deckIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  paintRegionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selectedTemplateFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  structureId: Scalars['ID']['input'];
};

export type PaintBlocksCorrodedArea = {
  __typename?: 'PaintBlocksCorrodedArea';
  assemblyId?: Maybe<Scalars['ID']['output']>;
  deckId?: Maybe<Scalars['ID']['output']>;
  paintRegionId?: Maybe<Scalars['ID']['output']>;
  pointOfInterestFindings?: Maybe<Array<Maybe<PointOfInterestFindings>>>;
  structureId: Scalars['ID']['output'];
  totalArea?: Maybe<Array<Maybe<AreaAndType>>>;
};

/**
 * A Paint Region is a region of space on a Deck of the Structure.
 *
 * Each deck is made up of different Paint Regions that can be actioned upon by human workers.
 */
export type PaintRegion = {
  __typename?: 'PaintRegion';
  /** Date this Paint Region was created */
  createdAt: Scalars['String']['output'];
  /** Deck that this Paint Region belongs to */
  deck: Deck;
  defectMetrics: Array<DefectMetrics>;
  /** Unique Paint Region ID */
  id: Scalars['ID']['output'];
  /** User readable identifier for the Paint Region */
  name: Scalars['String']['output'];
  polygons: Array<PaintRegionPolygons>;
  /** Status of the Paint Region */
  status?: Maybe<PaintRegionStatus>;
  /** Date this Paint Region was last updated */
  updatedAt: Scalars['String']['output'];
  /** 3d-volume of paint region */
  volume?: Maybe<ComplexPrismVolume>;
};

export type PaintRegionPolygons = {
  __typename?: 'PaintRegionPolygons';
  area: Scalars['Float']['output'];
  points: Array<Array<Scalars['Float']['output']>>;
  /** Type of this PaintRegion polygon; i.e. plan */
  type?: Maybe<Scalars['String']['output']>;
};

export enum PaintRegionStatus {
  InspectionPending = 'INSPECTION_PENDING',
  InProgress = 'IN_PROGRESS',
  NoWorkRequired = 'NO_WORK_REQUIRED',
  Remediated = 'REMEDIATED',
  Scheduled = 'SCHEDULED'
}

export type PaintRegionVolumeInput = {
  /** Integer id of paint region */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** 3d-volume of paint region */
  volume?: InputMaybe<ComplexPrismVolumeInput>;
};

export type PaintRegionVolumesInput = {
  /** All paint region volumes for the structure */
  regions?: InputMaybe<Array<InputMaybe<PaintRegionVolumeInput>>>;
};

/** An individual Part of the Structure. */
export type Part = {
  __typename?: 'Part';
  /** Assembly that this Part belongs to */
  assembly?: Maybe<Assembly>;
  assemblyId?: Maybe<Scalars['ID']['output']>;
  /** Class (grouping) of this Part */
  class: Scalars['String']['output'];
  /** Corrosion detected on the entire part */
  corrosion?: Maybe<Corrosion>;
  /** Date this Part was created */
  createdAt: Scalars['String']['output'];
  /** Parts this part grouped with for graph assisted tagging */
  groupedPartIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** Unique Part ID */
  id: Scalars['ID']['output'];
  /** Part is hidden if it is not relevant to this structure */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** User marks if a Part is reviewed */
  isReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** Unstructured metadata about this Part */
  meta: Array<Meta>;
  /** Metrics on this Part */
  metrics: PartMetrics;
  /** All the viewpoints that can see any portion of this part */
  seenByViewpoints?: Maybe<Array<SeenByViewpoint>>;
  /** Split Cuboids define split points from this Part in data processing */
  splitCuboids?: Maybe<Array<SplitCuboid>>;
  /** Date this Part was last updated */
  updatedAt: Scalars['String']['output'];
  /** User who last updated this Part */
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type PartGroup = {
  __typename?: 'PartGroup';
  /** Part Ids part of this group */
  groupedParts: Array<Scalars['String']['output']>;
  /** Unique Zone ID */
  id: Scalars['ID']['output'];
  /** Structure of this Zone */
  structure: Structure;
};

export type PartHiddenFlagUpdatedNotification = {
  __typename?: 'PartHiddenFlagUpdatedNotification';
  assemblyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isReviewed?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['String']['output'];
  zoneId?: Maybe<Scalars['String']['output']>;
};

export type PartLegend = {
  __typename?: 'PartLegend';
  color: Scalars['String']['output'];
  legendText?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type PartMetrics = {
  __typename?: 'PartMetrics';
  /** Corrosion category (class) of this Part: 0 - Clean | 1 - AC_L | 2 - AC_M | 3 - AC_H */
  corrosionCategory: Scalars['Int']['output'];
  /** Metrics on defects on this Part */
  defectMetrics: Array<DefectMetrics>;
  /** Predicted corrosion category (class) of this Part: 0 - Clean | 1 - AC_L | 2 - AC_M | 3 - AC_H */
  predictedCorrosionCategory: Scalars['Int']['output'];
  /** Remediation count of the defect on this Part: 0 - Not remediated | >=1 - Remediated */
  remediated: Scalars['Int']['output'];
  /** Observed metric surface area of defect on this Part (if any) */
  surfaceAreaObserved?: Maybe<Scalars['Float']['output']>;
  /** Total metric surface area of defect on this Part (if any) */
  surfaceAreaTotal?: Maybe<Scalars['Float']['output']>;
};

export type PartReviewFlagUpdatedNotification = {
  __typename?: 'PartReviewFlagUpdatedNotification';
  assemblyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReviewed?: Maybe<Scalars['Boolean']['output']>;
  structureId: Scalars['ID']['output'];
  userId: Scalars['String']['output'];
  zoneId?: Maybe<Scalars['String']['output']>;
};

export type PartSplitNotification = {
  __typename?: 'PartSplitNotification';
  assemblyName?: Maybe<Scalars['String']['output']>;
  newPartId: Scalars['ID']['output'];
  originalPartId: Scalars['ID']['output'];
  structureId?: Maybe<Scalars['ID']['output']>;
  updatedAnnotationIds: Array<Scalars['ID']['output']>;
  updatedAnnotations: Array<Annotation>;
  userId: Scalars['String']['output'];
  zoneId?: Maybe<Scalars['String']['output']>;
};

export type PartsAddedToAssemblyNotification = {
  __typename?: 'PartsAddedToAssemblyNotification';
  assemblyId?: Maybe<Scalars['ID']['output']>;
  assemblyName?: Maybe<Scalars['String']['output']>;
  isReviewed?: Maybe<Scalars['Boolean']['output']>;
  partId: Scalars['ID']['output'];
  structureId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['String']['output'];
  zoneId?: Maybe<Scalars['String']['output']>;
};

export type PipeSpec = {
  __typename?: 'PipeSpec';
  /** The material used in the construction of this pipe */
  material: Scalars['String']['output'];
  /** Amount of corrosion allowed for the pipe (in mm) */
  nominalCorrosionAllowance: Scalars['Float']['output'];
  /** Nominal thickness of the pipe wall (in mm) */
  nominalThickness: Scalars['Float']['output'];
  /** Pipe schedule is how the wall thickness of a pipe is described */
  schedule: Scalars['String']['output'];
  /** Standard pipe size - Nominal pipe size (NPS) for non-metric or Diametre nominal (DN) for metric) */
  size: Scalars['String']['output'];
  /** Pipe specification code */
  spec: Scalars['String']['output'];
};

export type Platform = {
  __typename?: 'Platform';
  /** Unique platform ID */
  id: Scalars['ID']['output'];
  /** Name of this platform */
  name: Scalars['String']['output'];
  /** Card insight detail for a platform */
  platformCardInsights?: Maybe<Array<PlatformCardInsights>>;
  /** Region to which this platform belongs */
  region?: Maybe<Region>;
};

export type PlatformCardInsights = {
  __typename?: 'PlatformCardInsights';
  color?: Maybe<Scalars['String']['output']>;
  group: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum PoiAccessibilityEnum {
  NoScaffoldingRequired = 'NO_SCAFFOLDING_REQUIRED',
  ScaffoldingRequired = 'SCAFFOLDING_REQUIRED'
}

export type PoiCuboid = {
  __typename?: 'PoiCuboid';
  /** Unique ID for this cuboid */
  id: Scalars['ID']['output'];
  /** Position of the cuboid (x,y,z) */
  position: Array<Scalars['Float']['output']>;
  /** Rotation of the cuboid (roll,pitch,yaw). Euler angles using Abyss conventions. */
  rotation: Array<Scalars['Float']['output']>;
  /** Size of the cuboid in 3 axes (x,y,z) */
  scale: Array<Scalars['Float']['output']>;
};

export enum PoiPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum PoiStatus {
  ChaseItemAdded = 'CHASE_ITEM_ADDED',
  Completed = 'COMPLETED',
  Inprogress = 'INPROGRESS',
  Inreview = 'INREVIEW',
  Remediated = 'REMEDIATED'
}

/**
 * A point of interest (POI) marked in 3d space.
 * POI can be marked from a Location and for a specific Part optionally.
 */
export type PointOfInterest = {
  __typename?: 'PointOfInterest';
  /** Accessibility of this POI */
  accessibility?: Maybe<PoiAccessibilityEnum>;
  /** Assembly that this POI belongs to */
  assembly: Assembly;
  /** User whom this Point of interest is assigned to */
  assignee?: Maybe<Scalars['String']['output']>;
  /** Additional Blister metadata for this POI (if type is BLISTER) */
  blister?: Maybe<Blister>;
  /** Camera point in 3d space looking at this POI */
  cameraLocation: Vector3;
  /**
   * Approximate center of where the POI was measured from in 3d space.
   * This is used to navigate to and display POIs.
   */
  centerPoint3d: Vector3;
  /**
   * A comment left by the creator of the POI. This comment is different
   * from the comments in the comment tab, in the sense that this one
   * appears in the POI table, is sortable and works just like any other field
   */
  comment?: Maybe<Scalars['String']['output']>;
  /** Date this Point of interest was created */
  createdAt: Scalars['String']['output'];
  /** User who created this Point of interest */
  createdByUser?: Maybe<User>;
  /** Cuboids of volume of interest */
  cuboids?: Maybe<Array<PoiCuboid>>;
  /** Height of this POI from nearest deck */
  deckRelativeHeight?: Maybe<Scalars['Float']['output']>;
  /** Due date of rectification for this Point of interest */
  dueDate?: Maybe<Scalars['String']['output']>;
  /** Image or PDF attached to POI */
  files?: Maybe<Array<File>>;
  /** Unique POI ID */
  id: Scalars['ID']['output'];
  /** Whether this POI has been verified by an inspector */
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  /** Linked POIs to the cuboid */
  linkedPointOfInterestIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Location that this POI belongs to (if POI marked from a Location) */
  location?: Maybe<Location>;
  /** Location ID that this POI belongs to (if POI marked from a Location) */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** Unstructured metadata about this POI */
  meta: Array<Meta>;
  /** Name of this POI */
  name?: Maybe<Scalars['String']['output']>;
  /** Paint Region that this POI belongs to */
  paintRegion?: Maybe<PaintRegion>;
  /** Part that this POI belongs to (if Part selected from UI before POI marked) */
  part?: Maybe<Part>;
  /** A user friendly ID in the format of {POI Type}-{Number} */
  poiId?: Maybe<Scalars['String']['output']>;
  /** Priority of this Point of interest */
  priority?: Maybe<PoiPriority>;
  /** Remediation Note with POI */
  remediationNote?: Maybe<Scalars['String']['output']>;
  /** Paths to saved screenshots for this POI */
  screenshotPaths?: Maybe<ScreenshotPaths>;
  /** Date this Point of interest was assigned */
  status?: Maybe<PoiStatus>;
  /** Tab from where poi status is updated */
  statusRemediatedBy?: Maybe<StatusRemediatedBy>;
  /** Teams that this POI is shared with */
  teams: Array<Team>;
  /** Template related to this POI for dynamic data */
  template?: Maybe<PointOfInterestTemplate>;
  /** Data values associated with Template for this POI */
  templateData?: Maybe<Array<TemplateData>>;
  /** Template ID related to this POI */
  templateId?: Maybe<Scalars['ID']['output']>;
  /** Type of this POI */
  type: Scalars['String']['output'];
  /** Unlinked POIs to the cuboid */
  unlinkedPointOfInterestIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Height of the POI relative to the viewpoint it was created from */
  viewpointRelativeHeight?: Maybe<Scalars['Float']['output']>;
  /** Visibility settings for this POI (private, public, custom) */
  visibility?: Maybe<VisibilityEnum>;
  /** Workpack id of this poi */
  workpack?: Maybe<Workpack>;
  /** Workpack number of POI */
  workpackNumber?: Maybe<Scalars['String']['output']>;
};

export type PointOfInterestFindings = {
  __typename?: 'PointOfInterestFindings';
  count: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type PointOfInterestImages = {
  __typename?: 'PointOfInterestImages';
  pointOfInterestId: Scalars['ID']['output'];
  selectedImages?: Maybe<Array<Maybe<File>>>;
};

export type PointOfInterestInput = {
  accessibility?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assemblyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isVerified?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  paintRegionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  partId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  selectedPoiFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  selectedTemplateFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  workpackId?: InputMaybe<Scalars['ID']['input']>;
};

export type PointOfInterestTemplate = {
  __typename?: 'PointOfInterestTemplate';
  /** Color used for graph legends */
  accentColor?: Maybe<Scalars['String']['output']>;
  /** User who created this Template */
  createdBy: Scalars['ID']['output'];
  /** Field definitions for this Template */
  fields: Array<PointOfInterestTemplateField>;
  /** Icon for POI */
  icon?: Maybe<TemplateIcon>;
  /** Unique Template ID */
  id: Scalars['ID']['output'];
  /** Name of this Template */
  name: Scalars['String']['output'];
  /** Permission the user should have to access this template. e.g generic engineer don't have read:defects permission so he cannot access those template if permission = read:defects */
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PointOfInterestTemplateField = TemplateField & {
  __typename?: 'PointOfInterestTemplateField';
  /** Should this field be visible on first load inside a list or table */
  defaultVisible?: Maybe<Scalars['Boolean']['output']>;
  /** Table configuration */
  displayConfig?: Maybe<DisplayConfig>;
  /** Unique Template Field ID */
  id: Scalars['ID']['output'];
  /** Should not be shown in the UI. Used for identifying certain fields */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Is this Template Field read-only; i.e. value cannot be updated */
  isReadOnly: Scalars['Boolean']['output'];
  /** Is this Template Field always required */
  isRequired: Scalars['Boolean']['output'];
  /** Key to identify field in template data */
  key?: Maybe<Scalars['String']['output']>;
  /** Name of this Template Field */
  name: Scalars['String']['output'];
  /** Valid options for this Template Field value */
  options: Array<Scalars['String']['output']>;
  /** Type of permission that a user have to read the template */
  permission?: Maybe<Scalars['String']['output']>;
  /** Should the template options be fetched from the server on runtime */
  serverSide: Scalars['Boolean']['output'];
  /** Type of this Template Field */
  type: TemplateFieldTypeEnum;
  /** Identity about the unit of the field */
  unit?: Maybe<UnitsEnum>;
};

export type PointOfInterestsGroupedByWorkpack = {
  __typename?: 'PointOfInterestsGroupedByWorkpack';
  _id?: Maybe<Scalars['ID']['output']>;
  assembliesCount?: Maybe<Scalars['Float']['output']>;
  pointOfInterestsCount?: Maybe<Scalars['Float']['output']>;
  verifiedPointOfInterestsCount?: Maybe<Scalars['Float']['output']>;
  workpack?: Maybe<Workpack>;
};

export type PolarCoordinates = {
  __typename?: 'PolarCoordinates';
  /** Rotation of camera angle */
  bearing: Scalars['Float']['output'];
  /** Elevation of camera angle */
  elevation: Scalars['Float']['output'];
  /** Distance to camera */
  range: Scalars['Float']['output'];
};

export type PolarPoint = {
  __typename?: 'PolarPoint';
  bearing: Scalars['Float']['output'];
  elevation: Scalars['Float']['output'];
};

export type PolarPointInput = {
  bearing: Scalars['Float']['input'];
  elevation: Scalars['Float']['input'];
};

export type PolarPointMeasurementInput = {
  /** Rotation of camera angle */
  bearing: Scalars['Float']['input'];
  /** Elevation of camera angle */
  elevation: Scalars['Float']['input'];
};

export type Polygon = {
  __typename?: 'Polygon';
  area: Scalars['Float']['output'];
  centroid: Array<Scalars['Float']['output']>;
  points: Array<Array<Scalars['Float']['output']>>;
};

export type Polygon3d = {
  __typename?: 'Polygon3d';
  points: Array<Vector3>;
};

export type Polygon3dInput = {
  points: Array<Vector3Input>;
};

export type Pose = {
  pitch: Scalars['Float']['input'];
  roll: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  yaw: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

/** PreRegisteredUser is a user but not yet loggedin. */
export type PreRegisteredUser = {
  __typename?: 'PreRegisteredUser';
  /** Email of this PreRegisteredUser */
  email: Scalars['String']['output'];
  /** User Group Ids of this PreRegisteredUser */
  groups: Array<UserGroup>;
  /** Unique PreRegisteredUser ID */
  id: Scalars['ID']['output'];
  /** Roles of user */
  roleIds: Array<Maybe<Role>>;
  /** When a user logins it's object id will be stored here */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type PreRegisteredUserInput = {
  emails: Array<Scalars['String']['input']>;
  groups: Array<Scalars['String']['input']>;
  roleIds: Array<Scalars['String']['input']>;
};

export type PrismVolume = {
  __typename?: 'PrismVolume';
  /** Closed 2d polygon (points) */
  xy_polygon: Array<Array<Scalars['Float']['output']>>;
  /** Prism low and high level in z-dimention */
  z_range: Array<Scalars['Float']['output']>;
};

export type PrismVolumeInput = {
  /** Closed 2d polygon (points) */
  xy_polygon: Array<Array<Scalars['Float']['input']>>;
  /** Prism low and high level in z-dimention */
  z_range: Array<Scalars['Float']['input']>;
};

export type ProcessingReferences = {
  __typename?: 'ProcessingReferences';
  localAnnotationId: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  Annotations: Array<Annotation>;
  GetPointOfInterestGroupedByWorkpack: Array<PointOfInterestsGroupedByWorkpack>;
  GetPointOfInterestGroupedByWorkpackCount?: Maybe<Count>;
  UserGroupsByStructureId?: Maybe<Array<Maybe<UserGroup>>>;
  allAnnotations: Array<Annotation>;
  allAssemblies: Array<Assembly>;
  allAssemblyPaintRegions: Array<AssemblyPaintRegion>;
  allClients: Array<Client>;
  allComments?: Maybe<Array<Comment>>;
  allCuboids: Array<Maybe<Cuboid>>;
  allDecks: Array<Deck>;
  allDefects: Array<Defect>;
  allHistories: Array<History>;
  allImageSensors: Array<ImageSensor>;
  allLabelAnnotations: Array<Maybe<LabelAnnotation>>;
  allLocations: Array<Location>;
  allLocationsForAssembly?: Maybe<Array<Location>>;
  allPaintRegions: Array<PaintRegion>;
  allParts: Array<Part>;
  allPlatforms: Array<Platform>;
  /** Get all POITemplate */
  allPointOfInterestTemplate: Array<PointOfInterestTemplate>;
  /** Find all points of interest with optional filter arguments */
  allPointOfInterests: Array<PointOfInterest>;
  allRegions?: Maybe<Array<Region>>;
  allReports: Array<Maybe<Report>>;
  allRoles: Array<Maybe<Role>>;
  allStructures: Array<Structure>;
  allStructuresByRegion: Array<AllStructuresByRegion>;
  allTeams: Array<Team>;
  allTemplates: Array<Template>;
  allUserGroups: Array<UserGroup>;
  allUsers: Array<User>;
  allWorkpackItems: Array<WorkpackItem>;
  allWorkpacks: Array<Workpack>;
  annotation?: Maybe<Annotation>;
  assembly?: Maybe<Assembly>;
  client?: Maybe<Client>;
  corrodedAreaAndFindingsForAssemblyPaintRegions: Array<PaintBlocksCorrodedArea>;
  cuboidsByClassId: Array<Maybe<Cuboid>>;
  cuboidsByZoneId: Array<Maybe<Cuboid>>;
  deck?: Maybe<Deck>;
  decksWithDefects: Array<DeckWithDefects>;
  /** Measure max height of a blister on the Location */
  fetchBlisterHeightMeasurement?: Maybe<BlisterHeightMeasurement>;
  fetchCropFromRectilinear?: Maybe<GenerateImageResult>;
  fetchGenerateImage?: Maybe<GenerateImageResult>;
  fetchGetPaintRegionOfPoint?: Maybe<Scalars['Int']['output']>;
  /** Measure distance between two line points on the Location */
  fetchLineMeasurement?: Maybe<LineMeasurement>;
  fetchSpatialHeatmap?: Maybe<SpatialHeatmap>;
  filteredOptions: Array<FilteredOptions>;
  getAssembliesByRiskAssessmentFilters: Array<Assembly>;
  getAssembliesByTemplateFieldDataFilters: Array<Assembly>;
  getAssembliesByTemplateFieldsFilters: Array<Assembly>;
  getAssembliesCount?: Maybe<Count>;
  getAssembliesDecksSummary: Array<Maybe<DeckAreaSummary>>;
  getAssembliesRiskAssessmentSummary: Array<Maybe<RiskAssessmentSummary>>;
  getAssembliesSummary: Array<AssemblySummary>;
  getAuth0Connections: Array<ConnectionAuth0>;
  getNominationCalculations?: Maybe<NominationCalculations>;
  getRiskAssembliesCount?: Maybe<Count>;
  getUserAbilities?: Maybe<Scalars['String']['output']>;
  getUserColumnPreferences?: Maybe<UserColumnPreferences>;
  getUserPreferences?: Maybe<UserPreferences>;
  groupsByPartIds: Array<PartGroup>;
  idmsTasks: IdmsTasksWithCount;
  idmsUploads: Array<IdmsUpload>;
  imageSensor?: Maybe<ImageSensor>;
  latestIdmsUploadsByType: Array<IdmsUpload>;
  location?: Maybe<Location>;
  me?: Maybe<CurrentUser>;
  myUserGroups: Array<UserGroup>;
  paintRegion?: Maybe<PaintRegion>;
  part?: Maybe<Part>;
  platform?: Maybe<Platform>;
  /** Find one point of interest */
  pointOfInterest?: Maybe<PointOfInterest>;
  pointOfInterests: Array<PointOfInterest>;
  pointOfInterestsCount?: Maybe<Count>;
  preRegisteredUsers: Array<PreRegisteredUser>;
  preRegisteredUsersCount: Count;
  report: Report;
  scCategoryOptions: Array<Scalars['String']['output']>;
  setting?: Maybe<Setting>;
  structure?: Maybe<Structure>;
  structuresByUserGroup: Array<Maybe<Structure>>;
  structuresCount?: Maybe<Count>;
  supervoxelsClassesForStructure: Array<SupervoxelClass>;
  supervoxelsForStructure: Array<Maybe<SuperVoxels>>;
  team: Team;
  /** Find one Template */
  template?: Maybe<Template>;
  user?: Maybe<User>;
  userDetails: UserProfile;
  userGroup?: Maybe<UserGroup>;
  userGroupsCount?: Maybe<Count>;
  usersByRoleId: Array<Maybe<User>>;
  usersByTeam: Array<User>;
  usersByUserGroup: Array<Maybe<User>>;
  usersCount?: Maybe<Count>;
  validateIdmsTemplate?: Maybe<Scalars['String']['output']>;
  workpack?: Maybe<Workpack>;
  workpackItem?: Maybe<WorkpackItem>;
  zonesAndHistoriesFor: Array<ZoneAndHistory>;
  zonesForStructure: Array<Zone>;
  zonesForStructureCSV: Scalars['String']['output'];
};


export type QueryAnnotationsArgs = {
  imageId: Scalars['String']['input'];
};


export type QueryGetPointOfInterestGroupedByWorkpackArgs = {
  input?: InputMaybe<PointOfInterestInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryGetPointOfInterestGroupedByWorkpackCountArgs = {
  input?: InputMaybe<PointOfInterestInput>;
};


export type QueryUserGroupsByStructureIdArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllAnnotationsArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>;
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAssembliesArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAssemblyPaintRegionsArgs = {
  input?: InputMaybe<PaintBlockFiltersInput>;
};


export type QueryAllCommentsArgs = {
  input?: InputMaybe<AllCommentsInput>;
};


export type QueryAllCuboidsArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllDecksArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllDefectsArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllHistoriesArgs = {
  collectionName?: InputMaybe<Scalars['String']['input']>;
  eventNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllImageSensorsArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllLabelAnnotationsArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllLocationsArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllLocationsForAssemblyArgs = {
  input?: InputMaybe<LocationsForAssemblyInput>;
};


export type QueryAllPaintRegionsArgs = {
  deckIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllPartsArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPointOfInterestTemplateArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllPointOfInterestsArgs = {
  assemblyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  partId?: InputMaybe<Scalars['ID']['input']>;
  selectedTemplateFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  workpackId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllReportsArgs = {
  input?: InputMaybe<ReportsInput>;
};


export type QueryAllStructuresArgs = {
  input?: InputMaybe<StructureFilters>;
};


export type QueryAllTemplatesArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<TemplateTypeEnum>;
};


export type QueryAllUserGroupsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllUsersArgs = {
  input?: InputMaybe<FilterForUsers>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryAllWorkpackItemsArgs = {
  workpackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllWorkpacksArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAnnotationArgs = {
  annotationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAssemblyArgs = {
  assemblyId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryClientArgs = {
  clientId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCorrodedAreaAndFindingsForAssemblyPaintRegionsArgs = {
  input: PaintBlockFiltersInput;
};


export type QueryCuboidsByClassIdArgs = {
  cuboidClassId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCuboidsByZoneIdArgs = {
  zoneId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDeckArgs = {
  deckId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDecksWithDefectsArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFetchBlisterHeightMeasurementArgs = {
  blisterMeasurementPoints?: InputMaybe<BlisterMeasurementInput>;
  locationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchCropFromRectilinearArgs = {
  input?: InputMaybe<GenerateCropFromRectilinearInput>;
};


export type QueryFetchGenerateImageArgs = {
  input?: InputMaybe<GenerateImageInput>;
};


export type QueryFetchGetPaintRegionOfPointArgs = {
  paintRegions?: InputMaybe<PaintRegionVolumesInput>;
  point?: InputMaybe<Array<Scalars['Float']['input']>>;
};


export type QueryFetchLineMeasurementArgs = {
  input?: InputMaybe<Array<InputMaybe<PolarPointMeasurementInput>>>;
  locationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchSpatialHeatmapArgs = {
  heatmapParameters?: InputMaybe<SpatialHeatmapInput>;
};


export type QueryFilteredOptionsArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  fieldKey: Scalars['String']['input'];
  q?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
};


export type QueryGetAssembliesByRiskAssessmentFiltersArgs = {
  input?: InputMaybe<RiskAssessmentFiltersInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryGetAssembliesByTemplateFieldDataFiltersArgs = {
  input?: InputMaybe<AssembliesFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryGetAssembliesByTemplateFieldsFiltersArgs = {
  input?: InputMaybe<AssembliesFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryGetAssembliesCountArgs = {
  input?: InputMaybe<AssembliesFilterInput>;
};


export type QueryGetAssembliesDecksSummaryArgs = {
  input?: InputMaybe<AssembliesFilterInput>;
};


export type QueryGetAssembliesRiskAssessmentSummaryArgs = {
  input?: InputMaybe<RiskAssessmentSummaryInput>;
};


export type QueryGetAssembliesSummaryArgs = {
  input?: InputMaybe<AssembliesFilterInput>;
};


export type QueryGetAuth0ConnectionsArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetNominationCalculationsArgs = {
  input?: InputMaybe<NominationCalculationsInput>;
};


export type QueryGetRiskAssembliesCountArgs = {
  input?: InputMaybe<RiskAssessmentFiltersInput>;
};


export type QueryGetUserColumnPreferencesArgs = {
  input?: InputMaybe<UserColumnPreferencesInput>;
};


export type QueryGroupsByPartIdsArgs = {
  input?: InputMaybe<GroupsByPartIdsInput>;
};


export type QueryIdmsTasksArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  structureId: Scalars['ID']['input'];
};


export type QueryIdmsUploadsArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryImageSensorArgs = {
  imageSensorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLatestIdmsUploadsByTypeArgs = {
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLocationArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPaintRegionArgs = {
  paintRegionId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPartArgs = {
  partId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlatformArgs = {
  platformId: Scalars['ID']['input'];
};


export type QueryPointOfInterestArgs = {
  pointOfInterestId: Scalars['ID']['input'];
};


export type QueryPointOfInterestsArgs = {
  input?: InputMaybe<PointOfInterestInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryPointOfInterestsCountArgs = {
  input?: InputMaybe<PointOfInterestInput>;
};


export type QueryPreRegisteredUsersArgs = {
  input?: InputMaybe<FiltersForPreRegisteredUsers>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryPreRegisteredUsersCountArgs = {
  input?: InputMaybe<FiltersForPreRegisteredUsers>;
};


export type QueryReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type QueryScCategoryOptionsArgs = {
  structureId: Scalars['ID']['input'];
};


export type QuerySettingArgs = {
  revision?: InputMaybe<Scalars['Float']['input']>;
  type: SettingTypesEnum;
};


export type QueryStructureArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStructuresByUserGroupArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStructuresCountArgs = {
  input?: InputMaybe<FilterForStructures>;
};


export type QuerySupervoxelsClassesForStructureArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySupervoxelsForStructureArgs = {
  supervoxelsForStructureInput?: InputMaybe<SupervoxelsForStructureInput>;
};


export type QueryTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryTemplateArgs = {
  templateId: Scalars['ID']['input'];
  type?: InputMaybe<TemplateTypeEnum>;
};


export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserDetailsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserGroupArgs = {
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserGroupsCountArgs = {
  input?: InputMaybe<FilterForUserGroups>;
};


export type QueryUsersByRoleIdArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersByTeamArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  teamId: Scalars['ID']['input'];
};


export type QueryUsersByUserGroupArgs = {
  input?: InputMaybe<FilterForUsersByUserGroup>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryUsersCountArgs = {
  input?: InputMaybe<FiltersForUsersCount>;
};


export type QueryValidateIdmsTemplateArgs = {
  input: ValidateIdmsTemplateQueryInput;
};


export type QueryWorkpackArgs = {
  workpackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWorkpackItemArgs = {
  workpackItemId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryZonesAndHistoriesForArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryZonesForStructureArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryZonesForStructureCsvArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySummaryItem = {
  __typename?: 'QuerySummaryItem';
  /** Name of the collection where the item is stored */
  collection: Scalars['String']['output'];
  /** Array of display settings for the item */
  displayConfig?: Maybe<Array<DisplaySettings>>;
  /** Enum type representing how the item should be displayed */
  displayType: DisplayType;
  /** Unique identifier for each item */
  id: Scalars['ID']['output'];
  /** Optional field to store the key associated with the item */
  key?: Maybe<Scalars['String']['output']>;
  /** Match conditions, allowing complex filtering logic */
  matchCondition?: Maybe<Scalars['JSON']['output']>;
  /** Name of the query summary item */
  name: Scalars['String']['output'];
  /** Operation type for the item */
  operation: Scalars['String']['output'];
  /** Order of the item in the row */
  rowOrder: Scalars['Int']['output'];
};

export type Recommendation = {
  __typename?: 'Recommendation';
  /** Recommendation made during initial analysis of the assembly */
  analysisRecommendation?: Maybe<AlarmLevelRecommendation>;
  analysisStatus: RecommendationStatus;
  blisters: Array<PointOfInterest>;
  /** Final recommendation */
  finalRecommendation?: Maybe<AlarmLevelRecommendation>;
  /**
   * Worst blister identified that would affect Abyss' recommendation for this assembly
   * (judgment of 'worst' may be subjective
   * e.g based on shape and position of blister and not just its height and corresponding wall loss)
   */
  governingValue?: Maybe<GoverningValueRecommendation>;
  /** Whether or not a final review is required after peer review, typically when analysis recommendation and review recommendation are conflicting */
  isFinalisationRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Recommendation made during peer review of the recommendation from initial analysis */
  reviewRecommendation?: Maybe<AlarmLevelRecommendation>;
};

export type RecommendationDetailField = {
  __typename?: 'RecommendationDetailField';
  /** Unique human-readable key of the RecomendationDetalsField */
  fieldKey: Scalars['String']['output'];
  /** Unique Id of the RecomendationDetalsField */
  id: Scalars['ID']['output'];
};

export type RecommendationField = {
  __typename?: 'RecommendationField';
  /** Details field describe the dynamic field to render in the UI */
  details: Array<Maybe<RecommendationDetailField>>;
  /** Header field describe the text to display on the header of the UI */
  header: Scalars['String']['output'];
};

export enum RecommendationStatus {
  /** Assembly analysis has been completed by Abyss and recommendation recorded */
  Complete = 'COMPLETE',
  /** Recommendation not yet made by Abyss */
  Incomplete = 'INCOMPLETE'
}

export type Region = {
  __typename?: 'Region';
  /** Unique region ID */
  id: Scalars['ID']['output'];
  /** Name of this region */
  name: Scalars['String']['output'];
};

export type RemediatePointOfInterestInput = {
  pointOfInterestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  statusRemediatedBy?: InputMaybe<StatusRemediatedBy>;
};

export type RemoveAttachmentsInput = {
  fileIds: Array<Scalars['ID']['input']>;
  pointOfInterestId: Scalars['ID']['input'];
};

export type RemoveSplitCuboidOnPartInput = {
  /** Object Id of part to remove split cuboid from */
  partId: Scalars['ID']['input'];
  /** Object Id of cuboid to remove */
  splitCuboidId: Scalars['ID']['input'];
  /** Object Id for the structure this part belongs to */
  structureId: Scalars['ID']['input'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type RemoveSplitCuboidOnPartNotification = {
  __typename?: 'RemoveSplitCuboidOnPartNotification';
  /** Object Id of part to add cuboid to */
  partId: Scalars['ID']['output'];
  /** Object Id for the cuboid to remove */
  splitCuboidId: Scalars['ID']['output'];
  /** Object Id for the structure this part belongs to */
  structureId: Scalars['ID']['output'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: Maybe<Scalars['ID']['output']>;
};

export type Report = {
  __typename?: 'Report';
  /** Current status of the action on the report i.e Backlog, In progress or Complete */
  actionStatus?: Maybe<ReportActionStatus>;
  /** Date this Report was created */
  createdAt: Scalars['String']['output'];
  /** User Who created the report */
  createdByUser: User;
  /** Deadline Date for action on the report */
  dueDate?: Maybe<Scalars['String']['output']>;
  /** Unique Report ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Name of the report decided by the user */
  name: Scalars['String']['output'];
  /** Point of Interests Document IDs this Reports refers to */
  pointOfInterests: Array<PointOfInterest>;
  /** Selected images for point of interests */
  selectedPointOfInterestImages?: Maybe<Array<Maybe<PointOfInterestImages>>>;
  /** Current status of the report i.e Draft, Published or Closed */
  status: ReportStatus;
  structure: Structure;
  /** Type of report i.e FM Workpack or MI Workpack */
  type?: Maybe<ReportType>;
  /** Workpack number of Report */
  workpackNumber?: Maybe<Scalars['String']['output']>;
};

export enum ReportActionStatus {
  Backlog = 'BACKLOG',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS'
}

export enum ReportStatus {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum ReportType {
  FmWorkpack = 'FM_WORKPACK',
  MiWorkpack = 'MI_WORKPACK'
}

export type ResetPasswordInput = {
  clientId: Scalars['String']['input'];
  connection: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type RiskAssessment = {
  __typename?: 'RiskAssessment';
  /** TODO: Deprecated (RiskAssessment */
  age?: Maybe<Scalars['Int']['output']>;
  berthingCalculation?: Maybe<Scalars['Int']['output']>;
  corrosionCategory?: Maybe<Scalars['String']['output']>;
  hoursAtBerth?: Maybe<Scalars['Int']['output']>;
  mooringAnalysis?: Maybe<Scalars['Int']['output']>;
  percentDraftCondition?: Maybe<Scalars['Float']['output']>;
  percentOfHours?: Maybe<Scalars['Float']['output']>;
  shipsAtBerth?: Maybe<Scalars['Int']['output']>;
};

export type RiskAssessmentFiltersInput = {
  assemblyId?: InputMaybe<Scalars['ID']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  selectedFilters?: InputMaybe<Array<InputMaybe<RiskAssessmentSelectedFiltersInput>>>;
  structureId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type RiskAssessmentSelectedFiltersInput = {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type RiskAssessmentSummary = {
  __typename?: 'RiskAssessmentSummary';
  key?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<RiskType>>>;
};

export type RiskAssessmentSummaryInput = {
  assemblyId?: InputMaybe<Scalars['ID']['input']>;
  structureId: Scalars['ID']['input'];
  templateFieldKeys: Array<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
};

export type RiskFields = {
  __typename?: 'RiskFields';
  /** Key to identify template field */
  fieldKey: Scalars['String']['output'];
  /** Unique Risk Field ID */
  id: Scalars['ID']['output'];
  /** Identify whether the field needs to be displayed */
  isHiddenColumn: Scalars['Boolean']['output'];
  /** Identify where the field needs to be shown */
  type: Array<Maybe<RiskFieldsTypeEnum>>;
};

export type RiskFieldsInput = {
  /** Key to identify template field */
  fieldKey: Scalars['String']['input'];
  /** Identify whether the field needs to be displayed */
  isHiddenColumn: Scalars['Boolean']['input'];
  /** Identify where the field needs to be shown */
  type: Array<InputMaybe<RiskFieldsTypeEnum>>;
};

export enum RiskFieldsTypeEnum {
  Cof = 'COF',
  Pof = 'POF',
  Review = 'REVIEW',
  TransitionPeriod = 'TRANSITION_PERIOD'
}

export type RiskType = {
  __typename?: 'RiskType';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Role = {
  __typename?: 'Role';
  /** Auth0 id of role */
  auth0Id: Scalars['ID']['output'];
  /** Description of role */
  description?: Maybe<Scalars['String']['output']>;
  /** Unique role ID */
  id: Scalars['ID']['output'];
  /** Name of this role */
  name: Scalars['String']['output'];
  /** Permissions of role */
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SavePointOfInterestScreenshotInput = {
  /** Base64-encoded string of screenshot image */
  image: Scalars['String']['input'];
  /** POI for which to save screenshot */
  pointOfInterestId: Scalars['ID']['input'];
  /** Type of screenshot, i.e. VIEWPOINT */
  type: ScreenshotTypeEnum;
};

export type ScreenshotPaths = {
  __typename?: 'ScreenshotPaths';
  viewer?: Maybe<Scalars['String']['output']>;
  viewpoint?: Maybe<Scalars['String']['output']>;
};

export enum ScreenshotTypeEnum {
  Viewer = 'VIEWER',
  Viewpoint = 'VIEWPOINT'
}

export type SeenByViewpoint = {
  __typename?: 'SeenByViewpoint';
  /** Metadata about if a viewpoint has been analysed, with respect to the relavant assembly */
  analysed?: Maybe<Analysed>;
  /** Corrosion detected on the portion of the part or assembly that the viewpoint can see */
  corrosion: Corrosion;
  /**
   * Mean distance from the viewpoint's location to the portion of the part or assembly it can see -
   * ie how far away was the camera/scanner from the part (in metres)
   */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Viewpoint details including exact location and scan data */
  location: Location;
};

export type Service = {
  __typename?: 'Service';
  /** Code or acronym for the service an assembly provides e.g PG for Process Gas, can be empty when unknown */
  code?: Maybe<Scalars['String']['output']>;
  /**
   * Name of the group this service belongs to
   * - services are currently grouped to help ease prioritisation of assemblies
   * e.g. Hydrocarbon Lines include services Process Gas, Fuel Gas, etc
   */
  group: Scalars['String']['output'];
};

export type SetAlarmLevelInput = {
  alarmLevelRecommendation: AlarmLevelRecommendationInput;
  assemblyId: Scalars['ID']['input'];
};

export type Setting = {
  __typename?: 'Setting';
  /** Unique Setting ID */
  id: Scalars['ID']['output'];
  meta: Array<Meta>;
  /** Name of this Setting */
  name: Scalars['String']['output'];
  /** Revision of this Setting, i.e. 0 */
  revision: Scalars['Int']['output'];
  /** Type of this Setting, i.e. COLOUR_MAP_FOR_3D_VIEWER_PARTS */
  type: SettingTypesEnum;
};

export enum SettingTypesEnum {
  ColourMapForAbyssViewerDefects = 'COLOUR_MAP_FOR_ABYSS_VIEWER_DEFECTS',
  ColourMapForAbyssViewerParts = 'COLOUR_MAP_FOR_ABYSS_VIEWER_PARTS',
  ColourMapForDeckHeatmapApiModes = 'COLOUR_MAP_FOR_DECK_HEATMAP_API_MODES'
}

export enum SortTypeEnum {
  Number = 'NUMBER',
  String = 'STRING'
}

export type SpatialHeatmap = {
  __typename?: 'SpatialHeatmap';
  svg: Scalars['String']['output'];
};

export type SpatialHeatmapInput = {
  deckId: Scalars['ID']['input'];
  modes: ModeInput;
  selectedTemplateFilters?: InputMaybe<Array<InputMaybe<FiltersInput>>>;
  structureId: Scalars['ID']['input'];
};

export type SplitCuboid = {
  __typename?: 'SplitCuboid';
  /** Assembly that this cuboid volume should belong to */
  assembly?: Maybe<Assembly>;
  assemblyId?: Maybe<Scalars['ID']['output']>;
  /** Cuboid colour (RGB) */
  color: Scalars['Int']['output'];
  /** Unique ID */
  id: Scalars['ID']['output'];
  /** Position of the cuboid (x,y,z) */
  position: Array<Scalars['Float']['output']>;
  /** Rotation of the cuboid (roll,pitch,yaw). Euler angles using Abyss conventions. */
  rotation: Array<Scalars['Float']['output']>;
  /** Size of the cuboid in 3 axes (x,y,z) */
  scale: Array<Scalars['Float']['output']>;
};

export type SplitCuboidInput = {
  /** Assembly that this cuboid volume should belong to */
  assemblyId?: InputMaybe<Scalars['ID']['input']>;
  /** Cuboid colour (RGB) */
  color: Scalars['Int']['input'];
  /** Unique Cuboid ID */
  id: Scalars['ID']['input'];
  /** Position of the cuboid (x,y,z) */
  position: Array<Scalars['Float']['input']>;
  /** Rotation of the cuboid (roll,pitch,yaw). Euler angles using Abyss conventions. */
  rotation: Array<Scalars['Float']['input']>;
  /** Size of the cuboid in 3 axes (x,y,z) */
  scale: Array<Scalars['Float']['input']>;
};

export type SplitPartInput = {
  /** Object Ids of all the annotations to split from given part into a new part */
  annotationIdsForNewPart: Array<Scalars['ID']['input']>;
  /** Object Id of the part to split */
  partId: Scalars['ID']['input'];
  /** Object Id for the structure this part belongs to */
  structureId: Scalars['ID']['input'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export enum StatusRemediatedBy {
  PaintRegion = 'PAINT_REGION'
}

/** String array metadata */
export type StringArrayMeta = {
  __typename?: 'StringArrayMeta';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

/** String metadata */
export type StringMeta = {
  __typename?: 'StringMeta';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/**
 * A Structure whose condition is being inspected.
 *
 * A Structure exists on a Location where data is captured, processed, then analysed to assess the overall condition of the Structure.
 */
export type Structure = {
  __typename?: 'Structure';
  /** Assembly types existing for each structure, valid values may vary from platform to platform */
  assemblyTypes: Array<Maybe<Scalars['String']['output']>>;
  /** URL for base image for this Structure */
  baseImage?: Maybe<Scalars['String']['output']>;
  /**
   * List of all base paths in CDN where static assets for this Structure have been deployed.
   * Used to determine access control for CDN assets.
   */
  basePaths: Array<Scalars['String']['output']>;
  /** Card insight detail for a structure */
  cardInsights?: Maybe<Array<Maybe<CardInsights>>>;
  /**
   * Coating Condition Category Mapping
   * In some platforms we use different terminology for the same thing, this is a mapping to help us translate between the two platforms
   */
  coatingCategoryMapping?: Maybe<Scalars['JSON']['output']>;
  /** URL for cover image for this Structure */
  coverImage?: Maybe<Scalars['String']['output']>;
  /** Path for cover image for this Structure */
  coverImagePath: Scalars['String']['output'];
  /** Date this Structure was created */
  createdAt: Scalars['String']['output'];
  /** Classes assigned to Cuboids for 3D Asset Labelling */
  cuboidClasses?: Maybe<Array<Maybe<LabelClass>>>;
  /** Defect Area for the structure */
  defectArea?: Maybe<Scalars['JSON']['output']>;
  /** List of legends and colors for the structure defects */
  defectLegends: Array<DefectLegend>;
  /** Deployment level of this Structure, i.e. COMPONENT LEVEL, FACILITY LEVEL */
  deploymentLevel?: Maybe<StructureDeploymentLevel>;
  /** Description of this Structure */
  description: Scalars['String']['output'];
  /** Equipment Classes defined on a particular structure */
  equipmentClasses: Array<EquipmentClasses>;
  features?: Maybe<Scalars['JSON']['output']>;
  /** Octree data for this platform contains RGB colors */
  hasRGB?: Maybe<Scalars['Boolean']['output']>;
  /**
   * If this structure can perform a risk assesment. If this structure has the data needed to
   * perform a risk assesment, then this field will be set to 'true'
   */
  hasRiskAssessment?: Maybe<Scalars['Boolean']['output']>;
  /** Structure Has find viewpoint by point data and feature enabled */
  hasVoxelLocations?: Maybe<Scalars['Boolean']['output']>;
  /** Unique Structure ID */
  id: Scalars['ID']['output'];
  /** It will check if the structure is an active deployment or not */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Input Classes for 3D Asset Labelling */
  labelClasses?: Maybe<Array<Maybe<LabelClass>>>;
  /** Large voxel size parameter of voxel locations data (deployed file per large voxel in s3 bucket) */
  largeVoxelSize?: Maybe<Scalars['Float']['output']>;
  /** Name of this Structure */
  name: Scalars['String']['output'];
  /** Path to directory contatining JSON file per assembly with the list of octree nodes for the assembly */
  nodesPerAssemblyPath?: Maybe<Scalars['String']['output']>;
  /** Path to JSON file with octree files hierarchy */
  octreeResourcesPath: Scalars['String']['output'];
  /** List of and colors for each part */
  partLegends?: Maybe<Array<PartLegend>>;
  /** Platform this Structure belongs to */
  platform?: Maybe<Platform>;
  /** Region this Structure belongs to */
  region?: Maybe<Region>;
  /** TODO: deprecated */
  riskAssessment?: Maybe<RiskAssessment>;
  /**
   * Subtract Condition Category Mapping
   * In some platforms we use different terminology for the same thing, this is a mapping to help us translate between the two platforms
   */
  scCategoryMapping?: Maybe<Scalars['JSON']['output']>;
  /**
   * List of service groups that assemblies in this rootAsset belong to,
   * stored here so we can easily get a list of service groups to filter
   * assemblies by
   */
  serviceGroups: Array<Maybe<Scalars['String']['output']>>;
  /** The current processing stage of the structure */
  status: StructureStatus;
  /** Path to JSON file with structure relationships */
  structureRelationshipPath?: Maybe<Scalars['String']['output']>;
  /** Template related to this structure for dynamic data */
  template?: Maybe<Template>;
  /** Data values associated with Template for this structure */
  templateData?: Maybe<Array<TemplateData>>;
  /** Type of this Structure, i.e. PLATFORM */
  type: StructureTypes;
  /** Date this Structure was last updated */
  updatedAt: Scalars['String']['output'];
  /** User who last updated this Structure */
  updatedBy?: Maybe<Scalars['ID']['output']>;
  /** User groups with access to this Structure */
  userGroups: Array<UserGroup>;
  /** Path to voxel locations directory containing large voxel json files */
  voxelLocationsPath?: Maybe<Scalars['String']['output']>;
  /** Voxel size parameter of voxel locations data (voxel size for locations lookup) */
  voxelSize?: Maybe<Scalars['Float']['output']>;
  /** Value that will calculate wall loss for different deployments */
  wallLossFactor?: Maybe<Scalars['Float']['output']>;
};

export enum StructureDeploymentLevel {
  ComponentLevel = 'COMPONENT_LEVEL',
  FacilityLevel = 'FACILITY_LEVEL'
}

export type StructureFilters = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  excludeByUserGroupId?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformId?: InputMaybe<Scalars['ID']['input']>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
};

export enum StructureStatus {
  /** All assests of a structure have been tagged and are ready for MI analysis */
  Analysis = 'ANALYSIS',
  /** For 3D asset labelling using Cuboids */
  AssetLabelling_3D = 'ASSET_LABELLING_3D',
  /** For labelling of a structure */
  Labelling = 'LABELLING',
  /** Structure tag data has been captured and processed and are ready for quality checks */
  Tagging = 'TAGGING'
}

export enum StructureTypes {
  ChainLink = 'CHAIN_LINK',
  Jacket = 'JACKET',
  Platform = 'PLATFORM'
}

export type StructureWithDefects = {
  __typename?: 'StructureWithDefects';
  defectArea: Array<DefectArea>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: StructureStatus;
};

export type StructuresAndGroups = {
  __typename?: 'StructuresAndGroups';
  structures: Array<Structure>;
  userGroups: Array<UserGroup>;
};

export type Subscription = {
  __typename?: 'Subscription';
  annotationUpdated?: Maybe<Annotation>;
  assemblyTagNameAdded?: Maybe<AssemblyTagNameAdded>;
  assemblyUpdated?: Maybe<AssemblyUpdatedEventResult>;
  commentAdded: Comment;
  commentDeleted: Comment;
  partSplit: PartSplitNotification;
  partsAddedToAssembly: Array<PartsAddedToAssemblyNotification>;
  partsHiddenFlagUpdated: Array<PartHiddenFlagUpdatedNotification>;
  partsReviewedFlagUpdated: Array<PartReviewFlagUpdatedNotification>;
  /** Event that is fired after POI is created */
  pointOfInterestCreated?: Maybe<PointOfInterest>;
  /** Event that is fired after POI is deleted */
  pointOfInterestDeleted?: Maybe<PointOfInterest>;
  /** Event that is fired after POI screenshot is saved */
  pointOfInterestScreenshotSaved?: Maybe<PointOfInterest>;
  /** Event that is fired after POI is updated */
  pointOfInterestUpdated?: Maybe<PointOfInterest>;
  /** Event that is fired after POI Visibility is updated */
  pointOfInterestVisibilityUpdated: Array<PointOfInterest>;
  reportPointOfInterestVerified: VerifiedDetails;
  roleAssignedToUsers?: Maybe<Role>;
  roleRemovedFromUsers?: Maybe<Role>;
  /** Removing notification for a Split Cuboid on the Part */
  splitCuboidOnPartRemoved: Array<RemoveSplitCuboidOnPartNotification>;
  /** Adding/Updating notification for a Split Cuboid on the Part */
  splitCuboidOnPartUpdated: Array<AddUpdateSplitCuboidOnPartNotification>;
  supervoxelsUpdated: Array<SuperVoxels>;
  /** Event that is fired after Template is created */
  templateCreated?: Maybe<Template>;
  /** Event that is fired after Template is deleted */
  templateDeleted?: Maybe<Template>;
  /** Event that is fired after Template is updated */
  templateUpdated?: Maybe<Template>;
  userAddedToUserGroup?: Maybe<UserGroup>;
  userGroupCreated?: Maybe<UserGroup>;
  userGroupDeleted?: Maybe<UserGroup>;
  userGroupRemovedFromStructure?: Maybe<Structure>;
  userGroupUpdated?: Maybe<UserGroup>;
  userGroupsAddedToStructure?: Maybe<StructuresAndGroups>;
  userGroupsRemovedFromStructure?: Maybe<StructuresAndGroups>;
  userRemovedFromUserGroup?: Maybe<UserGroup>;
  workpackCreated?: Maybe<Workpack>;
  workpackDeleted?: Maybe<Workpack>;
  workpackItemCreated?: Maybe<WorkpackItem>;
  workpackItemDeleted?: Maybe<WorkpackItem>;
  workpackItemUpdated?: Maybe<WorkpackItem>;
  workpackUpdated?: Maybe<Workpack>;
};


export type SubscriptionAssemblyTagNameAddedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionAssemblyUpdatedArgs = {
  assemblyId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};


export type SubscriptionCommentAddedArgs = {
  referenceId?: InputMaybe<Scalars['ID']['input']>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionCommentDeletedArgs = {
  referenceId?: InputMaybe<Scalars['ID']['input']>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionPartSplitArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionPartsAddedToAssemblyArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionReportPointOfInterestVerifiedArgs = {
  reportId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionSplitCuboidOnPartRemovedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionSplitCuboidOnPartUpdatedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionSupervoxelsUpdatedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionTemplateCreatedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionTemplateDeletedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionTemplateUpdatedArgs = {
  structureId?: InputMaybe<Scalars['String']['input']>;
};

export type SuperVoxels = {
  __typename?: 'SuperVoxels';
  /** Unique SuperVoxel ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Structure of this super voxel */
  structureId: Scalars['ID']['output'];
  /** 3D reference of super voxel */
  supervoxel3DReference: Scalars['Int']['output'];
  /** Super voxel class of the this super voxel */
  supervoxelClassId?: Maybe<Scalars['ID']['output']>;
  /** Zone of this super voxel */
  zoneId: Scalars['ID']['output'];
};

export type SupervoxelClass = {
  __typename?: 'SupervoxelClass';
  /** Color of the supervoxel class */
  color: Scalars['String']['output'];
  /** SuperVoxel ID */
  id: Scalars['ID']['output'];
  /** Name of supervoxel class */
  name: Scalars['String']['output'];
  /** Structure of this supervoxel class */
  structureId: Scalars['ID']['output'];
};

export enum TableCellStyleEnum {
  Bullet = 'BULLET',
  Chip = 'CHIP',
  Link = 'LINK',
  Text = 'TEXT'
}

export type TaskRecommendation = {
  __typename?: 'TaskRecommendation';
  createdAt?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lineNumber: Scalars['String']['output'];
  recommendationNumber: Scalars['String']['output'];
  recommendationType: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  statusUpdatedAt?: Maybe<Scalars['String']['output']>;
  workOrders: Array<WorkOrder>;
};

/**
 * Team is a groups of users that can have particular access.
 *
 * A Structure can have multiple Teams.
 */
export type Team = {
  __typename?: 'Team';
  /** Date this Team was created */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** User who created this User Team */
  createdByUser: User;
  /** Unique Team ID */
  id: Scalars['ID']['output'];
  /** Team name */
  name: Scalars['String']['output'];
  /** Date this Team was last updated */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** User who last updated this User Team */
  updatedByUser: User;
  /** Users belongs to the Team */
  users: Array<User>;
};

export type Template = {
  __typename?: 'Template';
  /** User who created this Template */
  createdBy: Scalars['ID']['output'];
  /** Defects Array allow different defects to be visible based on the Assembly Type i.e Pipe, Structure etc */
  defectsArray: Array<Scalars['String']['output']>;
  /** Equipment Card definitions for this Template */
  equipmentCard: Array<EquipmentCardField>;
  /** Equipment Detail Field definitions for this Template */
  equipmentDetails: Array<EquipmentDetailField>;
  /** Field definitions for this Template */
  fields: Array<AssemblyTemplateField>;
  /** Add icon detail here */
  icon?: Maybe<TemplateIcon>;
  /** Unique Template ID */
  id: Scalars['ID']['output'];
  /** Name of this Template */
  name: Scalars['String']['output'];
  /** Permission the user should have to access this template. e.g generic engineer don't have read:defects permission so he cannot access those template if permission = read:defects */
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Fields for Query Summary */
  querySummary?: Maybe<Array<QuerySummaryItem>>;
  /** Recommendation Field Definition used to render alarm levels or damage class UI */
  recommendation?: Maybe<RecommendationField>;
  /** Risk assessment fields definition */
  riskFields?: Maybe<Array<RiskFields>>;
  /** Type of this Template */
  type: TemplateTypeEnum;
};

export type TemplateData = {
  __typename?: 'TemplateData';
  /** Unique Template Field ID */
  id: Scalars['ID']['output'];
  /** Data Value associated with the Template Field */
  templateFieldId?: Maybe<Scalars['ID']['output']>;
  /** Data Value associated with the Template Field */
  value?: Maybe<Scalars['String']['output']>;
  /** Verified value associated with the Template Field */
  verifiedValue?: Maybe<Scalars['String']['output']>;
};

export type TemplateDataInput = {
  /** Unique Template Field ID */
  id: Scalars['ID']['input'];
  /** Data Value associated with the Template Field */
  value: Scalars['String']['input'];
};

export type TemplateDataValues = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TemplateField = {
  /** Should this field be visible on first load inside a list or table */
  defaultVisible?: Maybe<Scalars['Boolean']['output']>;
  /** Table configuration */
  displayConfig?: Maybe<DisplayConfig>;
  /** Unique Template Field ID */
  id: Scalars['ID']['output'];
  /** Should not be shown in the UI. Used for identifying certain fields */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Is this Template Field read-only; i.e. value cannot be updated */
  isReadOnly: Scalars['Boolean']['output'];
  /** Is this Template Field always required */
  isRequired: Scalars['Boolean']['output'];
  /** Key to identify field in template data */
  key?: Maybe<Scalars['String']['output']>;
  /** Name of this Template Field */
  name: Scalars['String']['output'];
  /** Valid options for this Template Field value */
  options: Array<Scalars['String']['output']>;
  /** Type of permission that a user have to read the template */
  permission?: Maybe<Scalars['String']['output']>;
  /** Should the template options be fetched from the server on runtime */
  serverSide: Scalars['Boolean']['output'];
  /** Type of this Template Field */
  type: TemplateFieldTypeEnum;
  /** Identity about the unit of the field */
  unit?: Maybe<UnitsEnum>;
};

export type TemplateFieldInput = {
  /** Identify if field should be shown in the query tab */
  displayInQuery?: InputMaybe<Scalars['Boolean']['input']>;
  /** Identify if field should be shown in the risk assessment tab */
  displayInRisk?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should not be shown in the UI. Used for identifying certain fields */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is this Template Field read-only; i.e. cannot be updated */
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is this Template Field always required */
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should not be shown in the UI. Used for identifying risk assessment filters */
  isRiskAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Key to identify field in the risk fields */
  key?: InputMaybe<Scalars['String']['input']>;
  /** Name of this Template Field */
  name: Scalars['String']['input'];
  /** Valid options for this Template Field value */
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Type of this Template Field */
  type: TemplateFieldTypeEnum;
  /** Identity about the unit of the field */
  unit?: InputMaybe<UnitsEnum>;
};

export enum TemplateFieldTypeEnum {
  AutoAssemblyPressureService = 'AUTO_ASSEMBLY_PRESSURE_SERVICE',
  /** Auto Populated Fields that is rendered in Paint Damage */
  AutoAssemblyService = 'AUTO_ASSEMBLY_SERVICE',
  Input = 'INPUT',
  MeasurementPaintArea = 'MEASUREMENT_PAINT_AREA',
  MeasurementPaintDiameter = 'MEASUREMENT_PAINT_DIAMETER',
  /** Measurement Enums is used to render calculated fields for paint-damage */
  MeasurementPaintLength = 'MEASUREMENT_PAINT_LENGTH',
  MultiSelect = 'MULTI_SELECT',
  Numeric = 'NUMERIC',
  RadioButton = 'RADIO_BUTTON',
  SingleSelect = 'SINGLE_SELECT',
  Textarea = 'TEXTAREA'
}

export type TemplateIcon = {
  __typename?: 'TemplateIcon';
  color?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type TemplateInput = {
  /** Field definitions for this Template */
  fields: Array<TemplateFieldInput>;
  /** Name of this Template */
  name: Scalars['String']['input'];
  /** Risk Fields definition for each risk assessment field */
  riskFields?: InputMaybe<Array<InputMaybe<RiskFieldsInput>>>;
  /** Structure relation for this Template */
  structureId: Scalars['ID']['input'];
  /** Type of this Template */
  type: TemplateTypeEnum;
};

export enum TemplateTypeEnum {
  Assembly = 'ASSEMBLY',
  PaintBlocks = 'PAINT_BLOCKS',
  PointOfInterest = 'POINT_OF_INTEREST',
  Structure = 'STRUCTURE'
}

/** Unit Types used in preferences */
export enum UnitSystemEnum {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC'
}

export enum UnitsEnum {
  Meter = 'METER',
  Millimeter = 'MILLIMETER',
  SquareMeter = 'SQUARE_METER'
}

export type UpdateAssemblyInput = {
  assemblyId: Scalars['ID']['input'];
  /** Update Bounding Box of a Assembly */
  box?: InputMaybe<Scalars['JSON']['input']>;
  /** Update Focal Point of the assembly. */
  focalPoint?: InputMaybe<Vector3Input>;
  structureId: Scalars['ID']['input'];
  /** Update data values associated with Template for this Assembly */
  templateFieldData?: InputMaybe<Scalars['JSON']['input']>;
  /** Update Template related to this Assembly for dynamic data */
  templateId?: InputMaybe<Scalars['ID']['input']>;
  /** Type of Assembly */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoverningValueInput = {
  alarmLevel?: InputMaybe<AlarmLevels>;
  analysisStatus?: InputMaybe<RecommendationStatus>;
  assemblyId: Scalars['ID']['input'];
  governingValue: GoverningValueRecommendationInput;
};

export type UpdatePaintRegionStatusInput = {
  paintRegionId: Scalars['ID']['input'];
  status: PaintRegionStatus;
};

export type UpdatePartsHiddenFlagInput = {
  /** String part mongo ids from Part documents */
  ids: Array<Scalars['String']['input']>;
  /** Boolean flag for is out of scope */
  isHidden: Scalars['Boolean']['input'];
  /** Object Id for the structure the user is tagging in */
  structureId: Scalars['ID']['input'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdatePartsReviewedFlagInput = {
  /** String part mongo ids from Part documents */
  ids: Array<Scalars['String']['input']>;
  /** Boolean flag for a part is reviewed or not */
  isReviewed: Scalars['Boolean']['input'];
  /** Object Id for the structure the user is tagging in */
  structureId: Scalars['ID']['input'];
  /** Object Id for the zone the user is tagging in to filter the subscriptions */
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdatePointOfInterestInput = {
  /** Accessibility of this POI */
  accessibility?: InputMaybe<PoiAccessibilityEnum>;
  /** Update Assembly relation for this POI */
  assemblyId?: InputMaybe<Scalars['ID']['input']>;
  /** User who created this Point of interest */
  assignee?: InputMaybe<Scalars['String']['input']>;
  /** Update blister metadata for this POI (if type is BLISTER) */
  blister?: InputMaybe<BlisterInput>;
  /** Update camera location for this POI */
  cameraLocation?: InputMaybe<Vector3Input>;
  /** Update center point for this POI */
  centerPoint3d?: InputMaybe<Vector3Input>;
  /** Comment for this POI */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Cuboids of volume of interest */
  cuboids?: InputMaybe<Array<CuboidInput>>;
  /** Due date of rectification for this Point of interest */
  dueDate?: InputMaybe<Scalars['String']['input']>;
  /** Linked POIs to the cuboid */
  linkedPointOfInterestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Update Location relation for this POI */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** Update metadata for this POI */
  meta?: InputMaybe<Scalars['JSON']['input']>;
  /** Update name for this POI */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Update Part relation for this POI */
  partId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique ID for POI to update */
  pointOfInterestId: Scalars['ID']['input'];
  /** Priority of this Point of interest */
  priority?: InputMaybe<PoiPriority>;
  /** Current status of this Point of interest */
  status?: InputMaybe<PoiStatus>;
  /** Update Structure relation for this POI */
  structureId?: InputMaybe<Scalars['ID']['input']>;
  /** Teams that this POI is shared with */
  teamIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Update data values associated with Template for this POI */
  templateData?: InputMaybe<Array<TemplateDataInput>>;
  /** Update Template related to this POI for dynamic data */
  templateId?: InputMaybe<Scalars['ID']['input']>;
  /** Update type for this POI */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Unlinked POIs to the cuboid */
  unlinkedPointOfInterestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Height of the POI relative to the viewpoint it was created from */
  viewpointRelativeHeight?: InputMaybe<Scalars['Float']['input']>;
  /** Visibility for this POI */
  visibility?: InputMaybe<VisibilityEnum>;
  /** Workpack id of this poi */
  workpackId?: InputMaybe<Scalars['ID']['input']>;
  /** Workpack number of POI */
  workpackNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePointOfInterestVisibilityInput = {
  /** Bulk poi update */
  isBulkUpdate: Scalars['Boolean']['input'];
  /** Unique IDs for POIs to update */
  pointOfInterestIds: Array<Scalars['ID']['input']>;
  /** Teams that this POI is shared with */
  teamIds: Array<Scalars['ID']['input']>;
  /** Visibility for this POI */
  visibility: VisibilityEnum;
};

export type UpdateRiskAssessmentTemplateFieldDataForAssembliesInput = {
  assemblyIds: Array<Scalars['ID']['input']>;
  excludedAssemblyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  selectedFilters?: InputMaybe<Array<InputMaybe<RiskAssessmentSelectedFiltersInput>>>;
  selectedInputs: Array<RiskAssessmentSelectedFiltersInput>;
  structureId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
  updateAllAssemblies: Scalars['Boolean']['input'];
};

export type UpdateSingleTemplateDataInput = {
  assemblyId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  newValue: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type UpdateTeamInput = {
  teamId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type UpdateTemplateDataInput = {
  assemblyTemplateData: Array<AssemblyTemplateDataInput>;
  structureId: Scalars['ID']['input'];
  templateId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type UpdateTemplateFieldDataPofInput = {
  assemblyIds: Array<Scalars['ID']['input']>;
  calculatedToActualPof: Array<CalculatedToActualPofInput>;
  damageClassToBasePof: Array<DamageClass>;
  excludedAssemblyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  inputValues?: InputMaybe<Array<InputMaybe<RiskAssessmentSelectedFiltersInput>>>;
  selectedFilters?: InputMaybe<Array<InputMaybe<RiskAssessmentSelectedFiltersInput>>>;
  selectedInputs: Array<RiskAssessmentSelectedFiltersInput>;
  structureId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
  updateAllAssemblies: Scalars['Boolean']['input'];
};

export type UpdateUserColumnPreferencesInput = {
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: ColumnKeysEnum;
  structureId: Scalars['ID']['input'];
};

export type UpdateUserGroupInput = {
  name: Scalars['String']['input'];
  userGroupId: Scalars['ID']['input'];
};

export type UpdateUserInfoInput = {
  connection: Scalars['String']['input'];
  email: Scalars['String']['input'];
  loginType?: InputMaybe<LoginTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  picture: Scalars['String']['input'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  status: UserStatusEnum;
  userId: Scalars['ID']['input'];
};

export type UpdateUserPreferencesInput = {
  unit: UnitSystemEnum;
};

export type UpdateWorkpackInput = {
  name: Scalars['String']['input'];
  structureId: Scalars['ID']['input'];
  workpackId: Scalars['ID']['input'];
};

export type UpdateWorkpackItemInput = {
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  workpackId: Scalars['ID']['input'];
  workpackItemId: Scalars['ID']['input'];
};

export type UpdateZoneStatusInput = {
  status: ZoneStatus;
  zoneId: Scalars['ID']['input'];
};

export type UpdatedTemplateDataResult = {
  __typename?: 'UpdatedTemplateDataResult';
  isUpdated: Scalars['Boolean']['output'];
};

export type UploadAttachmentsInput = {
  /** Attachments list */
  files: Array<FileAttributes>;
  /** POI for which to attach files */
  pointOfInterestId: Scalars['ID']['input'];
  /** Remediation note */
  remediationNote?: InputMaybe<Scalars['String']['input']>;
};

/** User profile info */
export type User = {
  __typename?: 'User';
  /** Auth0 connection name for this user */
  connection?: Maybe<Scalars['String']['output']>;
  /** Unique email (per Auth0 connection) for this User */
  email: Scalars['String']['output'];
  /** Unique User ID */
  id: Scalars['ID']['output'];
  /** Last time the user logged in */
  lastLogin?: Maybe<Scalars['String']['output']>;
  /** Total number of counts the user logged in to the fabric */
  loginCount?: Maybe<Scalars['Int']['output']>;
  /** Last time the user logged in via which channel i.e. email, google, microsoft */
  loginType?: Maybe<LoginTypeEnum>;
  /** Full name of this user */
  name?: Maybe<Scalars['String']['output']>;
  /** Permissions the user synced from auth0 */
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  /** URL to profile picture for this User */
  picture?: Maybe<Scalars['String']['output']>;
  /** Preferences of the user */
  preferences?: Maybe<UserPreferences>;
  /** Roles of user */
  roleIds: Array<Maybe<Role>>;
  /** User Status if invite is sent or not and if user logged in or not */
  status?: Maybe<UserStatusEnum>;
};

export type UserColumnPreferences = {
  __typename?: 'UserColumnPreferences';
  columns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserColumnPreferencesInput = {
  key: ColumnKeysEnum;
  structureId: Scalars['ID']['input'];
};

/**
 * User Group is a grouping of Users.
 *
 * Users who belong to a User Group have access to all Structures that belong to the User Group.
 */
export type UserGroup = {
  __typename?: 'UserGroup';
  /** Date this User Group was created */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** User who created this User Group */
  createdBy?: Maybe<Scalars['ID']['output']>;
  /** Unique UserGroup ID */
  id: Scalars['ID']['output'];
  /** Name of this User Group */
  name: Scalars['String']['output'];
  /** Date this User Group was last updated */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** User who last updated this User Group */
  updatedBy?: Maybe<Scalars['ID']['output']>;
  /** Unique IDs of Users that belong to this User Group */
  userIds: Array<Scalars['ID']['output']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  createdUsers: Array<Maybe<User>>;
  failedUsers?: Maybe<Array<Maybe<FailedUserInfo>>>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  structure?: Maybe<Scalars['JSON']['output']>;
  unit?: Maybe<UnitSystemEnum>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  structures: Array<Structure>;
  user: User;
  userGroups: Array<UserGroup>;
};

/** Roles assigned to user by Auth0 authentication provider */
export enum UserRolesEnum {
  Admin = 'ADMIN',
  AitEngineer = 'AIT_ENGINEER',
  AssetTaggingUser = 'ASSET_TAGGING_USER',
  WorkpackManager = 'WORKPACK_MANAGER'
}

/** User Status assigned to user after creating */
export enum UserStatusEnum {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type ValidateIdmsTemplateQueryInput = {
  csvString: Scalars['String']['input'];
  template: IdmsUploadsTypeEnum;
};

export type ValueAndPercentage = {
  __typename?: 'ValueAndPercentage';
  percentage: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type ValueAndPercentageInput = {
  percentage: Scalars['Float']['input'];
  value: Scalars['Float']['input'];
};

export type Vector3 = {
  __typename?: 'Vector3';
  /** Unique Point ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** X axis of this Point */
  x: Scalars['Float']['output'];
  /** Y axis of this Point */
  y: Scalars['Float']['output'];
  /** Z axis of this Point */
  z: Scalars['Float']['output'];
};

export type Vector3Input = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

export type VerifiedDetails = {
  __typename?: 'VerifiedDetails';
  pointOfInterest: PointOfInterest;
  reportId: Scalars['ID']['output'];
};

export type VerifiedTemplateDataInput = {
  /** Unique Template Field ID */
  id: Scalars['ID']['input'];
  /** Verified Data Value associated with the Template Field */
  verifiedValue: Scalars['String']['input'];
};

export type VerifyPointOfInterestInput = {
  /** Unique ID for POI to verify */
  pointOfInterestId: Scalars['ID']['input'];
  /** Used to verify or unverify a POI */
  verified: Scalars['Boolean']['input'];
  /** Array of verified data for this POI */
  verifiedData?: InputMaybe<Array<VerifiedTemplateDataInput>>;
  verifiedMaxHeight?: InputMaybe<Scalars['String']['input']>;
  verifiedResidualThickness?: InputMaybe<Scalars['String']['input']>;
};

export enum VisibilityEnum {
  Custom = 'CUSTOM',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type WorkOrder = {
  __typename?: 'WorkOrder';
  id: Scalars['ID']['output'];
  lineNumber: Scalars['String']['output'];
  poiName: Scalars['String']['output'];
  recommendationNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusUpdatedAt?: Maybe<Scalars['String']['output']>;
  workOrderNumber: Scalars['String']['output'];
};

export type Workpack = {
  __typename?: 'Workpack';
  createdBy: Scalars['ID']['output'];
  /** Unique Workpack ID */
  id: Scalars['ID']['output'];
  /** Name of this Workpack */
  name: Scalars['String']['output'];
  /** Structure this Workpack belongs to */
  structure: Structure;
};

export type WorkpackItem = {
  __typename?: 'WorkpackItem';
  /** Date this Workpack Item was created */
  createdAt: Scalars['String']['output'];
  /** User who created this Workpack Item */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** Unique Workpack Item ID */
  id: Scalars['ID']['output'];
  /** Name of this Workpack Item */
  name: Scalars['String']['output'];
  /** Parts of interest in this Workpack Item */
  parts: Array<WorkpackItemPart>;
  /** Date this Workpack Item was last updated */
  updatedAt: Scalars['String']['output'];
  /** User who last updated this Workpack Item */
  updatedBy?: Maybe<Scalars['ID']['output']>;
  /** Workpack this Workpack Item belongs to */
  workpack: Workpack;
};

export type WorkpackItemPart = {
  __typename?: 'WorkpackItemPart';
  /** Metadata associated with this related Part */
  meta: Array<Meta>;
  /** Part this Workpack Item relates to */
  part: Part;
  /** Views of this related Part from Location data */
  views: Array<WorkpackItemView>;
};

export type WorkpackItemView = {
  __typename?: 'WorkpackItemView';
  /** Coordinates in Location Data for this Workpack Item */
  coordinates: PolarCoordinates;
  /** Location data which this Workpack Item belongs to */
  location: Location;
  /** Metadata associated with this view */
  meta: Array<Meta>;
};

export type WorkpackItemViewInput = {
  bearing: Scalars['Float']['input'];
  elevation: Scalars['Float']['input'];
  locationId: Scalars['ID']['input'];
  range: Scalars['Float']['input'];
};

export type Zone = {
  __typename?: 'Zone';
  /** Visibility Box of this Zone */
  box: Box;
  /** Unique Zone ID */
  id: Scalars['ID']['output'];
  /** Name of this Zone */
  name: Scalars['String']['output'];
  /** Status of the this zone */
  status: ZoneStatus;
  /** Structure of this Zone */
  structure: Structure;
  /** Used as meta data for the Fabric V2 in workflow management so AIT can make informed decision on who to assign this zone based upon the number of parts present in a zone */
  totalParts?: Maybe<Scalars['Int']['output']>;
  /** Used to determine the density of the zone i.e low / medium / high depending upon total points present in the zone */
  totalPoints?: Maybe<Scalars['Int']['output']>;
  /** Used as meta data for the Fabric V2 in workflow management so AIT can make informed decision on who to assign this zone based upon the number of sphericals present in a zone */
  totalSphericals?: Maybe<Scalars['Int']['output']>;
};

export type ZoneAndHistory = {
  __typename?: 'ZoneAndHistory';
  _id: Scalars['ID']['output'];
  box: Box;
  histories: Array<Maybe<History>>;
  name: Scalars['String']['output'];
  status?: Maybe<ZoneStatus>;
  structureId: Scalars['ID']['output'];
  totalParts?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalSphericals?: Maybe<Scalars['Int']['output']>;
};

export enum ZoneStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  NotStarted = 'NOT_STARTED',
  ReviewReady = 'REVIEW_READY'
}

export type ZoneUpdate = {
  __typename?: 'ZoneUpdate';
  createdBy: Scalars['ID']['output'];
  currentStatus: ZoneStatus;
  prevStatus: ZoneStatus;
  referenceId: Scalars['ID']['output'];
};

export type CreateNewReportInput = {
  actionStatus?: InputMaybe<ReportActionStatus>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pointOfInterestIds: Array<InputMaybe<Scalars['ID']['input']>>;
  structureId: Scalars['String']['input'];
  type?: InputMaybe<ReportType>;
  workpackNumber?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAssemblyFromReportInput = {
  assemblyId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type DeleteDraftReportInput = {
  reportId: Scalars['ID']['input'];
};

export type DeletePointOfInterestFromReportInput = {
  pointOfInterestId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type ReportInput = {
  reportId: Scalars['ID']['input'];
};

export type ReportsInput = {
  dueDate?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReportStatus>;
  structureId: Scalars['ID']['input'];
};

export type SupervoxelsForStructureInput = {
  structureId: Scalars['String']['input'];
  supervoxelClassId?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportByPointOfInterestsInput = {
  pointOfInterestIds: Array<InputMaybe<Scalars['ID']['input']>>;
  reportId: Scalars['ID']['input'];
};

export type UpdateReportInput = {
  actionStatus?: InputMaybe<ReportActionStatus>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  status?: InputMaybe<ReportStatus>;
  structureId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateReportPointOfInterestImagesInput = {
  pointOfInterestId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  selectedImages: Array<InputMaybe<FileInput>>;
};

export type UpdateReportStatusInput = {
  reportId: Scalars['ID']['input'];
  status: ReportStatus;
};

export type UpdateSupervoxel = {
  structureId: Scalars['ID']['input'];
  supervoxel3DReference: Array<Scalars['Int']['input']>;
  supervoxelClassId: Scalars['ID']['input'];
};

export type AllCommentsQueryVariables = Exact<{
  input?: InputMaybe<AllCommentsInput>;
}>;


export type AllCommentsQuery = { __typename?: 'Query', allComments?: Array<{ __typename?: 'Comment', id: string, text: string, isEdited: boolean, status?: ZoneStatus | null, updatedAt: string, structureId: string, createdBy: string, createdAt: string, collectionName: string, createdByDetails: { __typename?: 'CreatedByDetails', name: string, profilePicture: string } }> | null };

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', text: string, structureId: string, createdByDetails: { __typename?: 'CreatedByDetails', name: string, profilePicture: string } } };

export type DeleteCommentMutationVariables = Exact<{
  structureId?: InputMaybe<Scalars['ID']['input']>;
  commentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'Comment', id: string } };

export type CommentAddedSubscriptionVariables = Exact<{
  structureId?: InputMaybe<Scalars['ID']['input']>;
  referenceId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CommentAddedSubscription = { __typename?: 'Subscription', commentAdded: { __typename?: 'Comment', id: string, text: string, isEdited: boolean, status?: ZoneStatus | null, updatedAt: string, structureId: string, createdBy: string, createdAt: string, collectionName: string, createdByDetails: { __typename?: 'CreatedByDetails', name: string, profilePicture: string } } };

export type CommentDeletedSubscriptionVariables = Exact<{
  structureId?: InputMaybe<Scalars['ID']['input']>;
  referenceId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CommentDeletedSubscription = { __typename?: 'Subscription', commentDeleted: { __typename?: 'Comment', id: string } };


export const AllCommentsDocument = gql`
    query AllComments($input: AllCommentsInput) {
  allComments(input: $input) {
    id
    text
    isEdited
    status
    updatedAt
    structureId
    createdByDetails {
      name
      profilePicture
    }
    createdBy
    createdAt
    collectionName
  }
}
    `;

/**
 * __useAllCommentsQuery__
 *
 * To run a query within a React component, call `useAllCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllCommentsQuery(baseOptions?: Apollo.QueryHookOptions<AllCommentsQuery, AllCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCommentsQuery, AllCommentsQueryVariables>(AllCommentsDocument, options);
      }
export function useAllCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCommentsQuery, AllCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCommentsQuery, AllCommentsQueryVariables>(AllCommentsDocument, options);
        }
export function useAllCommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCommentsQuery, AllCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCommentsQuery, AllCommentsQueryVariables>(AllCommentsDocument, options);
        }
export type AllCommentsQueryHookResult = ReturnType<typeof useAllCommentsQuery>;
export type AllCommentsLazyQueryHookResult = ReturnType<typeof useAllCommentsLazyQuery>;
export type AllCommentsSuspenseQueryHookResult = ReturnType<typeof useAllCommentsSuspenseQuery>;
export type AllCommentsQueryResult = Apollo.QueryResult<AllCommentsQuery, AllCommentsQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    text
    structureId
    createdByDetails {
      name
      profilePicture
    }
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($structureId: ID, $commentId: ID) {
  deleteComment(structureId: $structureId, commentId: $commentId) {
    id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      structureId: // value for 'structureId'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const CommentAddedDocument = gql`
    subscription CommentAdded($structureId: ID, $referenceId: ID) {
  commentAdded(structureId: $structureId, referenceId: $referenceId) {
    id
    text
    isEdited
    status
    updatedAt
    structureId
    createdByDetails {
      name
      profilePicture
    }
    createdBy
    createdAt
    collectionName
  }
}
    `;

/**
 * __useCommentAddedSubscription__
 *
 * To run a query within a React component, call `useCommentAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentAddedSubscription({
 *   variables: {
 *      structureId: // value for 'structureId'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useCommentAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CommentAddedSubscription, CommentAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CommentAddedSubscription, CommentAddedSubscriptionVariables>(CommentAddedDocument, options);
      }
export type CommentAddedSubscriptionHookResult = ReturnType<typeof useCommentAddedSubscription>;
export type CommentAddedSubscriptionResult = Apollo.SubscriptionResult<CommentAddedSubscription>;
export const CommentDeletedDocument = gql`
    subscription CommentDeleted($structureId: ID, $referenceId: ID) {
  commentDeleted(structureId: $structureId, referenceId: $referenceId) {
    id
  }
}
    `;

/**
 * __useCommentDeletedSubscription__
 *
 * To run a query within a React component, call `useCommentDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentDeletedSubscription({
 *   variables: {
 *      structureId: // value for 'structureId'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useCommentDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CommentDeletedSubscription, CommentDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CommentDeletedSubscription, CommentDeletedSubscriptionVariables>(CommentDeletedDocument, options);
      }
export type CommentDeletedSubscriptionHookResult = ReturnType<typeof useCommentDeletedSubscription>;
export type CommentDeletedSubscriptionResult = Apollo.SubscriptionResult<CommentDeletedSubscription>;