import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import React, { ChangeEventHandler } from 'react';
import { formControlLabelStyles } from './styles';

type ToggleItemVisibilityProps = {
  label: string;
  isVisible: boolean;
  toggleVisibility: ChangeEventHandler;
  disabled?: boolean;
  tooltipTitle?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};

export const ToggleItemVisibility = ({
  label,
  isVisible,
  toggleVisibility,
  disabled,
  tooltipTitle,
  tooltipPlacement = 'right',
}: ToggleItemVisibilityProps) => {
  return (
    <FormControlLabel
      disabled={disabled}
      control={
        tooltipTitle ? (
          <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
            <Switch checked={isVisible} onChange={toggleVisibility} size="small" />
          </Tooltip>
        ) : (
          <Switch checked={isVisible} onChange={toggleVisibility} size="small" />
        )
      }
      label={label}
      labelPlacement="start"
      sx={formControlLabelStyles}
    />
  );
};
