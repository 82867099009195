import { useState } from 'react';
import { getLocalStorageValue, setLocalStorageValue } from '@/utils/sessionLocalStorage';

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T | ((oldValue: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState(() => getLocalStorageValue(key, initialValue));

  const setValue = (value: T | ((oldValue: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    setLocalStorageValue(key, valueToStore);
  };
  return [storedValue, setValue];
}
