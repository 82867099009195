import { useCallback } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { useSetRecoilState } from 'recoil';
import { CircularProgress } from '@mui/material';
import { useNominateGoverningPointOfInterestMutation } from './data.graphql';
import Button from '@/components/shared/Button';
import { GetAssembliesByTemplateFieldsFiltersDocument } from '@/components/Analysis/shared/FilterSideBar/data.graphql';
import * as state from '@/components/Analysis/state';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { GetOneAssemblyDocument } from '../data.graphql';

type Props = {
  blisterId: string;
  closeDialog: () => void;
  shouldOpenUpdateAlarmDialog?: boolean;
} & ButtonProps;

export const AcceptNominationButton = ({
  blisterId,
  closeDialog,
  shouldOpenUpdateAlarmDialog = false,
  ...props
}: Props) => {
  const setIsAlarmLevelDialogOpen = useSetRecoilState(state.isAlarmLevelDialogOpen);

  const { showSnackBar } = useSnackBarMessage({
    variant: 'filled',
  });
  const [manuallyNominateBlister, { loading }] = useNominateGoverningPointOfInterestMutation({
    variables: {
      input: {
        pointOfInterestId: blisterId,
      },
    },
    refetchQueries: [GetAssembliesByTemplateFieldsFiltersDocument, GetOneAssemblyDocument],
  });

  const onButtonClick = useCallback(() => {
    manuallyNominateBlister()
      .then((response) => {
        const newGoverningBlisterId = response?.data?.nominateGoverningPointOfInterest;
        if (newGoverningBlisterId) {
          showSnackBar('Blister nominated successfully', 'success');
          if (shouldOpenUpdateAlarmDialog) {
            setIsAlarmLevelDialogOpen(true);
          }
          closeDialog();
        }
      })
      .catch(() => showSnackBar('Failed to nominate blister', 'error'));
  }, [
    closeDialog,
    manuallyNominateBlister,
    shouldOpenUpdateAlarmDialog,
    showSnackBar,
    setIsAlarmLevelDialogOpen,
  ]);

  return (
    <Button {...props} disabled={loading} onClick={onButtonClick}>
      {loading ? <CircularProgress /> : 'Accept'}
    </Button>
  );
};
