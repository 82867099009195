import { CircularProgress, Link } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import * as state from '@/state';
import { useKey } from '@/hooks';
import { useGetGroupsByPartIdsLazyQuery, GetGroupsByPartIdsQuery } from './data.graphql';

type Groups = GetGroupsByPartIdsQuery['groupsByPartIds'];

const getSelectedPartIdsByGroups = (groups: Groups = [], selectedPartIds: string[] = []) => {
  const groupedParts = groups.map((group) => group?.groupedParts || []);
  const partIdsByGroups = groupedParts.flat();
  return [...new Set([...partIdsByGroups, ...selectedPartIds])];
};

export const GroupOfParts = () => {
  const [selectedPartIds, setSelectedPartIds] = useRecoilState(state.selectedPartIds);
  const structureId = useRecoilValue(state.selectedStructureId) || '';

  const [getGroupsByPartIds, { loading: loadingParts }] = useGetGroupsByPartIdsLazyQuery();

  const handleSelectGroupOfParts = useCallback(() => {
    async function fetchData() {
      try {
        const { data } = await getGroupsByPartIds({
          variables: {
            input: {
              structureId,
              partIds: selectedPartIds,
            },
          },
        });
        const partIdsByGroupedPartIds = getSelectedPartIdsByGroups(
          data?.groupsByPartIds,
          selectedPartIds
        );
        setSelectedPartIds(partIdsByGroupedPartIds);
      } catch (error) {
        console.log('error fetching group of parts', error);
      }
    }
    fetchData();
  }, [getGroupsByPartIds, selectedPartIds, setSelectedPartIds, structureId]);

  useKey('g', handleSelectGroupOfParts, 'alt');

  return (
    <>
      <DockPanelItem>
        <Link component="button" disabled={loadingParts} onClick={handleSelectGroupOfParts}>
          {!loadingParts && <>Select group of parts</>}
          {loadingParts && (
            <>
              <CircularProgress sx={{ marginLeft: 2 }} size={15} />
            </>
          )}
        </Link>
      </DockPanelItem>
    </>
  );
};
