import { Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';
import { ExpandMore, NumberFound } from './styles';

type AssetDetailsGridProps = {
  customComponent?: JSX.Element;
  numberFound: string | number | undefined;
  title: string;
  expanded: boolean;
};
export const AssetDetailsGrid = ({
  customComponent,
  numberFound,
  title,
  expanded,
}: AssetDetailsGridProps) => {
  const { smallItemWidth, titleItemWidth } = useMemo(() => {
    const smallItem = customComponent ? 2 : 3;
    const titleItem = customComponent ? 6 : 7;
    return { smallItemWidth: smallItem, titleItemWidth: titleItem };
  }, [customComponent]);

  return (
    <Grid container spacing={2}>
      {customComponent && (
        <Grid item xs={smallItemWidth}>
          {customComponent}
        </Grid>
      )}
      <Grid item xs={smallItemWidth}>
        <NumberFound>{numberFound || ''}</NumberFound>
      </Grid>
      <Grid item xs={titleItemWidth}>
        {title}
      </Grid>

      <Grid item xs={2}>
        <ExpandMore expand={expanded}>
          <ExpandMoreIcon />
        </ExpandMore>
      </Grid>
    </Grid>
  );
};
