import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Dock } from '@/components/shared/Dock';
import { Inspection3dViewer } from './Inspection3dViewer';
import { Navigation } from './Panels/Navigation';
import { SelectedVoxel } from './Panels/SelectedVoxel';
import * as state from '@/state';
import { Visibility } from './Panels/Visibility';

export const InspectionExplorer = () => {
  const recoilInspectionMetadata = useRecoilValue(state.inspectionMetadata);
  const token = useRecoilValue(state.auth0TokenState);
  const selectedSuperVoxels = useRecoilValue(state.selectedSuperVoxels);
  const topSidebarTabs = useMemo(() => {
    return [
      { content: <Navigation />, id: 'zone_selector', title: 'Navigation' },
      { content: <Visibility />, id: 'visibility', title: 'Visibility' },
    ];
  }, []);

  const bottomSidebarTabs = useMemo(() => {
    return selectedSuperVoxels.length > 0
      ? [{ content: <SelectedVoxel />, id: 'voxel_selector', title: 'Selected Voxel' }]
      : [];
  }, [selectedSuperVoxels]);

  return (
    <>
      {recoilInspectionMetadata && token && (
        <Dock topSidebarTabs={topSidebarTabs} bottomSidebarTabs={bottomSidebarTabs}>
          <Inspection3dViewer />
        </Dock>
      )}
    </>
  );
};
