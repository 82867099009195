import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  BoxProps,
  Cuboid,
  CuboidEditor,
  CuboidProps,
  TransformTarget,
  VisibilityBoxMode,
} from '@abyss/3d-viewer';
import { CuboidClickMode, AnalysisCuboid } from '@/types';
import * as state from '@/components/Analysis/modules/cuboids/state';
import {
  makeCuboidPropsFromAnalysisCuboid,
  useSelectCuboid,
  useGetAnalysisCuboidColor,
} from '@/analysis-cuboids/hooks';
import * as globalState from '@/components/Analysis/state';

export const SceneCuboids = () => {
  const [cuboids, setCuboids] = useRecoilState(state.cuboids);
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const cuboidControlsType = useRecoilValue(state.cuboidControlsType);
  const cuboidTransformMode = useRecoilValue(state.cuboidTransformMode);
  const cuboidClickMode = useRecoilValue(state.cuboidClickMode);
  const isCuboidEditorEnabled = useRecoilValue(state.isCuboidEditorEnabled);
  const setCurrentCuboidVisibilityBox = useSetRecoilState(state.currentCuboidVisibilityBox);

  const selectedAssemblyId = useRecoilValue(globalState.selectedAssemblyId);

  const [transformTarget, setTransformTarget] = useState<TransformTarget>();

  const selectCuboid = useSelectCuboid();
  const getCuboidColor = useGetAnalysisCuboidColor();

  const onCuboidClick = useCallback(
    (cuboid: AnalysisCuboid) => {
      if (cuboidClickMode !== CuboidClickMode.SelectCuboid) return;
      selectCuboid(cuboid);
    },
    [cuboidClickMode, selectCuboid]
  );

  const onBoxUpdate = useCallback(
    (newBox: BoxProps | undefined) => {
      if (newBox) {
        setCurrentCuboidVisibilityBox((current) => ({
          ...current,
          ...newBox,
          mode: VisibilityBoxMode.Enabled,
        }));
      }
    },
    [setCurrentCuboidVisibilityBox]
  );

  const makeCuboidProps = useCallback(
    (cuboid: AnalysisCuboid) => {
      const color = getCuboidColor();
      return makeCuboidPropsFromAnalysisCuboid(cuboid, color);
    },
    [getCuboidColor]
  );

  const cuboidsComp = useMemo(
    () =>
      // Check to ensure we only render cuboids when an assembly is selected
      selectedAssemblyId
        ? cuboids?.map((cuboid) => {
            if (currentCuboid && cuboid.id === currentCuboid.id) return undefined;
            return (
              <Cuboid
                {...makeCuboidProps(cuboid)}
                key={cuboid.id}
                onClick={() => onCuboidClick(cuboid)}
              />
            );
          })
        : [],
    [cuboids, currentCuboid, onCuboidClick, makeCuboidProps, selectedAssemblyId]
  );

  const currentCuboidProps = useMemo<CuboidProps | undefined>(() => {
    if (!currentCuboid) return undefined;
    return makeCuboidProps(currentCuboid);
  }, [currentCuboid, makeCuboidProps]);

  const setCurrentCuboidFromViewer: Dispatch<SetStateAction<CuboidProps | undefined>> = useCallback(
    (valueOrFunction: SetStateAction<CuboidProps | undefined>) => {
      setCurrentCuboid((current: AnalysisCuboid | undefined) => {
        if (!current) return undefined;
        const cuboid =
          valueOrFunction instanceof Function
            ? valueOrFunction(makeCuboidProps(current))
            : valueOrFunction;
        if (!cuboid) return undefined;
        const { position, scale, rotation } = cuboid;
        return { ...current, position, scale, rotation };
      });
    },
    [setCurrentCuboid, makeCuboidProps]
  );

  const addEditingCuboidToAllCuboids = (cub: CuboidProps) => {
    if (!currentCuboid) return;

    const { position, rotation, scale } = cub;
    const newCuboid: AnalysisCuboid = {
      ...currentCuboid,
      position,
      rotation,
      scale,
    };

    setCuboids((current) => {
      const newCuboids = current.asMutableStateMap();
      newCuboids?.set(newCuboid.id, newCuboid);
      return newCuboids.asStateMap();
    });
  };

  return (
    <>
      {cuboidsComp}
      {currentCuboidProps && (
        <CuboidEditor
          isEnabled={isCuboidEditorEnabled}
          cuboid={currentCuboidProps}
          setCuboid={setCurrentCuboidFromViewer}
          controlsType={cuboidControlsType}
          transformMode={cuboidTransformMode}
          transformTarget={transformTarget}
          setTransformTarget={setTransformTarget}
          onExit={addEditingCuboidToAllCuboids}
          onBoxUpdate={onBoxUpdate}
        />
      )}
    </>
  );
};
