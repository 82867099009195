import { useUser } from '@auth0/nextjs-auth0';
import { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box, Popover } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import Button from '@/components/shared/Button';
import { TestIds } from '../../../../cypress/testIds';
import { UserAvatar } from '../UserAvatar';
import { SystemPopover } from '../../SystemRequirement/SystemPopover';
import { UserSettings } from './UserSettings';
import * as state from '@/state/atoms';

const placeHolderTitle = 'Abyss User';

export const LogInOrOut = () => {
  const { user } = useUser();
  const auth0Token = useRecoilValue(state.auth0TokenState);
  const [anchorElementUser, setAnchorElementUser] = useState<undefined | HTMLElement>(undefined);
  const [anchorElementSystem, setAnchorElementSystem] = useState<undefined | HTMLElement>(
    undefined
  );
  const [isSystemPopoverOpened, setIsSystemPopoverOpened] = useRecoilState(
    state.isSystemPopoverOpened
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElementUser(undefined);
  };

  const handleCloseSystemMenu = () => {
    setAnchorElementSystem(undefined);
    setIsSystemPopoverOpened(false);
  };

  useEffect(() => {
    if (user?.sub && user?.email) {
      Sentry.setUser({
        email: user.email,
        id: user.sub,
      });
    }
  }, [user]);

  const userLoggedIn = useMemo(() => {
    return (
      <Stack direction="row" sx={{ cursor: 'pointer' }} spacing={2} alignItems="center">
        <Stack direction="column">
          <Typography sx={{ lineHeight: 'normal' }} variant="subtitle2">
            {user?.name || 'Fabric User'}
          </Typography>
          <Typography sx={{ lineHeight: 'normal' }} variant="caption">
            {placeHolderTitle}
          </Typography>
        </Stack>
        <UserAvatar
          name={user?.name}
          picture={user?.picture}
          sx={{ height: '32px', width: '32px' }}
        />
      </Stack>
    );
  }, [user?.name, user?.picture]);

  if (!user || !auth0Token) {
    return (
      <>
        <Button
          color="secondary"
          size="large"
          variant="text"
          data-test-id={TestIds.LOGIN_INPUT}
          href="/api/auth/login"
        >
          Log in
        </Button>
      </>
    );
  }

  return (
    <>
      <Box onClick={handleOpenUserMenu}>{userLoggedIn}</Box>
      <Popover
        sx={{ mt: '45px', padding: '1%' }}
        anchorEl={anchorElementUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElementUser)}
        onClose={handleCloseUserMenu}
        PaperProps={{
          sx: {
            borderRadius: '3%',
            left: 'unset !important',
            right: '24px',
          },
        }}
      >
        <UserSettings handleCloseUserMenu={handleCloseUserMenu} />
      </Popover>
      <SystemPopover
        anchorElementSystem={anchorElementSystem}
        open={isSystemPopoverOpened}
        handleCloseSystemMenu={handleCloseSystemMenu}
      />
    </>
  );
};
