import styled from 'styled-components';

export const DockPanel = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
  height: 100%;
  overflow-y: auto;
`;
