import { Alert, Button, CircularProgress, Tooltip } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import * as state from '@/state';
import { CSVProcessor } from '@/utils/csv';
import { useAllPartsLazyQuery } from './data.graphql';

const buttonStyles = {
  marginLeft: 8,
};

export const PartsLabelReportButton = () => {
  const allAssemblies = useRecoilValue(state.allAssemblies);
  const selectedStructureId = useRecoilValue(state.selectedStructureId);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);

  const router = useRouter();

  const { inspection: inspectionId } = router.query;

  const structureId =
    inspectionId && typeof inspectionId === 'string' ? inspectionId : selectedStructureId || '';

  const [getAllPartsData, { loading }] = useAllPartsLazyQuery({
    variables: {
      structureId,
    },
  });

  const handleClick = useCallback(() => {
    getAllPartsData()
      .then((response) => {
        const data = response?.data?.allParts;

        if (!data) return;

        const structureName = response?.data?.structure?.name;

        const allTagsColumn = {
          label: 'All Assemblies',
          data: allAssemblies.map((assembly) => assembly.tagName)
            .filter(Boolean)
            .sort((a, b) => a.localeCompare(b)),
        };

        const partTagsColumn = {
          label: 'Parts',
          data: [...new Set(data.map((part) => part?.assembly?.tagName || ''))]
            .filter(Boolean)
            .sort((a, b) => a.localeCompare(b)),
        };

        const csv = new CSVProcessor({
          columns: [allTagsColumn, partTagsColumn],
        });

        // Format -> 25-Jun-2021
        const date = format(new Date(), 'dd-MMM-yyyy');

        const fileName = `${structureName || 'Structure'}-Parts-Label-Report-${date}.csv`;

        csv.download(fileName);
      })
      .catch((error) => {
        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="error">{error.message}</Alert>,
        });
      });
  }, [allAssemblies, getAllPartsData, setSnackbarMessage]);

  return (
    <Tooltip
      title="
    Export CSV report of all labels assigned to parts in the current structure.
  "
    >
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleClick}
        style={buttonStyles}
        disabled={loading}
      >
        {loading ? (
          <>
            Downloading
            <CircularProgress
              size={20}
              sx={{
                ml: 5,
              }}
              color="secondary"
            />
          </>
        ) : (
          'Export CSV'
        )}
      </Button>
    </Tooltip>
  );
};
