import { snackbarMessage, structureFeatures } from '@/components/Analysis/state';
import { StructureName } from '@/components/shared/Breadcrumb';
import { Button } from '@/components/shared/Button/Button';
import { LogInOrOut } from '@/components/shared/LogInOrOut/LogInOrOut';
import { Logo } from '@/components/shared/Navbar/styles';
import { REPORT_A_BUG, USAGE_GUIDE } from '@/constants';
import { BugReport, ChromeReaderMode } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Menu, MenuList, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Feature } from '@/types';
import { InfoMenuItem, Navbar, iconStyles } from '@/components/shared/Navbar/Navbar';
import { NavbarMenu } from './NavbarMenu';
import { NavbarLinks, structureInfoBoxStyles } from './styles';

const AnalysisPages = () => {
  const router = useRouter();
  const { inspection } = router.query;
  const structureInfo = useRecoilValue(structureFeatures);
  const setSnackbarMessage = useSetRecoilState(snackbarMessage);

  const tabs: Feature[] = useMemo(() => {
    if (!structureInfo?.features?.navigation) return [];
    return structureInfo?.features?.navigation.filter((feature: Feature) => feature.enabled);
  }, [structureInfo?.features?.navigation]);

  const handlePageClick = (route: string) => {
    setSnackbarMessage({
      shouldShow: false,
      content: undefined,
    });
    router.push({ pathname: `/analysis/${inspection}${route}` });
  };

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
      {structureInfo &&
        Object.keys(structureInfo?.features).includes('navigation') &&
        tabs.map((tab: Feature) => (
          <>
            {tab?.subNavigation ? (
              <NavbarMenu
                label={tab?.label}
                items={tab?.subNavigation ?? []}
                handleItemClick={handlePageClick}
              />
            ) : (
              <Button
                key={tab?.flag}
                onClick={() => handlePageClick(tab?.url ?? '')}
                sx={NavbarLinks}
                disabled={tab?.disabled}
                style={{
                  borderColor: router.route.includes(tab?.url ?? '') ? '#fff' : 'transparent',
                }}
              >
                {tab?.label}
              </Button>
            )}
          </>
        ))}
    </Stack>
  );
};

const leftNav = (inspectionId: string) => {
  return (
    <Stack direction="row" spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
      <Link href="/" display="contents">
        <Logo src="/assets/fabric_logo_white.svg" alt="Abyss Fabric" />
      </Link>
      <Box sx={structureInfoBoxStyles}>
        <StructureName inspectionId={inspectionId} />
      </Box>
    </Stack>
  );
};

const menuItems = [
  {
    url: USAGE_GUIDE,
    icon: <ChromeReaderMode sx={iconStyles} />,
    label: 'Usage guide',
    show: true,
  },
  {
    url: REPORT_A_BUG,
    icon: <BugReport sx={iconStyles} />,
    label: 'Report a bug',
    show: true,
  },
];

const RightNav = () => {
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>(undefined);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(undefined);
  };

  return (
    <Stack direction="row" spacing={4} justifyContent="flex-end" alignItems="center">
      <Button onClick={handleClick} sx={{ color: 'white', minWidth: 0 }}>
        <HelpOutlineIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList sx={{ p: 0, width: 220 }}>
          {menuItems.map((item) => (
            <InfoMenuItem key={item.label} handleClose={handleClose} {...item} />
          ))}
        </MenuList>
      </Menu>
      <LogInOrOut />
    </Stack>
  );
};

export const AnalysisNavbar = () => {
  const router = useRouter();
  const { inspection } = router.query;

  if (!inspection) {
    router.push('/');
    return <></>;
  }

  return (
    <Navbar left={leftNav(inspection as string)} center={<AnalysisPages />} right={RightNav()} />
  );
};
