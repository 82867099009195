/**
  Function takes an array of Objects and groups them based on the property provided
  Provided property needs to exist on the Object type in the array.
  Grouping is done to a single property and expects the property to be unique to the array
  eg:
  input ([{age:1,name:"john"},{age:2,name:"jane"}],'age');
  output {1:{age:1,name:"john"},2:{age:2,name:"jane"}};
 */

import { useMemo } from 'react';

type RollupByResult<T> = Record<string, T>;

export function useRollupOnData<T>(array: T[] | undefined, property: keyof T): RollupByResult<T> {
  const data = useMemo(() => {
    if (array) {
      return array.reduce((result, value) => {
        const key = String(value[property]);
        return { ...result, [key]: value };
      }, {} as RollupByResult<T>);
    }
    return {};
  }, [array, property]);

  return data;
}
