/* eslint-disable react/jsx-one-expression-per-line */

import { AllHistoriesQuery } from '../../data.graphql';
import { formatDate, formatName } from '@/utils';

type PartHiddenEntryProps = {
  history: AllHistoriesQuery['allHistories'][0];
};

export const PartHiddenEntry = ({ history }: PartHiddenEntryProps) => {
  return (
    <p>
      <b>
        {`Part ${
          history.document.isHidden ? 'set as out of scope by' : 'cancelled out of scope state by'
        }`}
      </b>
      <br />
      {formatName(history.createdByName)} - {formatDate(new Date(Number(history.createdAt)))}
    </p>
  );
};
