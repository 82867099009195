import styled from 'styled-components';

interface TextProps {
  isHighlighted?: boolean;
}

export const Text = styled('p')<TextProps>(({ isHighlighted = false }) => ({
  marginTop: '2px',
  marginBottom: '2px',
  ...(isHighlighted && {
    color: 'rgba(2, 136, 209, 1)',
    '&:hover': {
      cursor: 'pointer', // Change cursor to pointer on hover
    },
  }),
}));
