import { useCallback } from 'react';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Viewpoint } from '@/types';
import { TableCellContainer } from './style';
import { titleCaseSentence } from '@/utils';

type Props = {
  viewpoint: Viewpoint;
  isSelected: boolean;
  isAnalysed: boolean;
  canReadDefects: boolean;
  onViewpointSelected: (viewpointId: string) => void;
};
export const ViewpointRow = ({
  viewpoint,
  isSelected,
  isAnalysed,
  canReadDefects,
  onViewpointSelected,
}: Props) => {
  const handleViewpointSelected = useCallback(() => {
    onViewpointSelected(viewpoint.location.id);
  }, [onViewpointSelected, viewpoint.location.id]);

  return (
    <TableRow
      hover
      onClick={handleViewpointSelected}
      key={viewpoint.location.id}
      selected={isSelected}
    >
      <TableCell component="th" scope="row" sx={{p: 1.5}}>
        <TableCellContainer>
          {isSelected ? (
            <Typography variant="body2">{viewpoint.location.name}</Typography>
          ) : (
            <Link
              underline="hover"
              component="button"
              variant="body2"
              onClick={handleViewpointSelected}
            >
              {viewpoint.location.name}
            </Link>
          )}
          {isAnalysed && <CheckCircleIcon sx={{ pl: 1 }} color="success" />}
        </TableCellContainer>
      </TableCell>
      {canReadDefects && (
        <TableCell sx={{p: 1.5}}>{titleCaseSentence(viewpoint.corrosion?.primaryCategory)}</TableCell>
      )}
      <TableCell sx={{p: 1.5}}>{viewpoint.distanceDisplayText}</TableCell>
    </TableRow>
  );
};
