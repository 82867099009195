import { CheckCircleOutline } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { ReactNode } from 'react';
import { severityColor } from '@/theme/colors';
import { SxProps } from '@mui/material';

type Props = {
  severity: 'error' | 'info' | 'success' | 'warning';
  children: ReactNode | undefined;
  sxProps?: SxProps;
  action?: ReactNode;
};

export const Alert = ({ severity, children, sxProps = {}, action }: Props) => {
  return (
    <MuiAlert
      iconMapping={{
        success: <CheckCircleOutline fontSize="inherit" sx={{ color: severityColor.success }} />,
      }}
      severity={severity}
      sx={sxProps}
      {...(action ? { action } : {})}
    >
      <>{children}</>
    </MuiAlert>
  );
};
