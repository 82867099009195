import axios from 'axios';
import { Attachment } from '../types';

export const uploadFileToUrl = async (
  file: Attachment,
  url: string,
  onProgress?: (progress: number) => void
) => {
  try {
    const response = await axios.put(url, file.file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (onProgress) onProgress(percentCompleted);
        }
      },
    });
    return response.status === 200;
  } catch (error) {
    console.log(error);
    console.error('File upload failed', error);
    return false;
  }
};

/**
 * Extracts the MIME type from a base64 string.
 * @param base64 The base64 encoded string.
 * @returns The MIME type.
 */
export const getContentTypeFromBase64 = (base64: string): string => {
  const match = base64.match(/^data:(.*?);base64,/);
  if (match && match.length > 1) {
    return match[1];
  }
  throw new Error('Invalid base64 string');
};

/**
 * Converts a base64 string to a Blob.
 * @param base64 The base64 encoded string.
 * @param contentType The MIME type of the file.
 * @returns A Blob representing the file.
 */
export const base64ToBlob = (base64: string, contentType: string) => {
  const base64Data = base64.replace(/^data:(.*?);base64,/, '');
  const binaryData = atob(base64Data); // Decode base64 string to binary data
  const byteNumbers: number[] = Array.from({ length: binaryData.length });
  for (let index = 0; index < binaryData.length; index++) {
    byteNumbers[index] = binaryData.codePointAt(index) || 0;
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

/**
 * Uploads a base64 string as a file to the specified URL.
 * @param base64 The base64 encoded string.
 * @param url The URL to upload the file to.
 * @param onProgress Callback function to handle progress updates.
 * @returns A promise that resolves to a boolean indicating success or failure.
 */
export const uploadBase64ToUrl = async (
  base64: string,
  url: string,
  onProgress?: (progress: number) => void
): Promise<boolean> => {
  try {
    const contentType = getContentTypeFromBase64(base64);
    const fileData = base64ToBlob(base64, contentType);
    const response = await axios.put(url, fileData, {
      headers: {
        'Content-Type': contentType,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total && onProgress) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress(percentCompleted);
        }
      },
    });

    return response.status === 200;
  } catch (error) {
    console.error('File upload failed', error);
    return false;
  }
};

/**
 * Calculates the size of a base64 string in bytes and returns an integer.
 * @param base64String The base64 encoded string.
 * @returns The size in bytes as an integer.
 */
export const getSizeFromBase64 = (base64String: string): number => {
  let stringLength = base64String.length;
  if (base64String.endsWith('==')) stringLength -= 2;
  else if (base64String.endsWith('=')) stringLength -= 1;
  return Math.ceil((stringLength * 3) / 4);
};
