import React, { useEffect, useMemo, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { METERS_TO_FEET_FACTOR } from '@abyss/3d-viewer';
import {
  DisplayConfig,
  Maybe,
} from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { paintDiameterSharedState } from './state';
import { UnitSystemEnum } from '../Breadcrumb/data.graphql';
import {
  SQFEET_TO_SQMETERS_FACTOR,
  convertInchestoFeetDisplay,
  convertInchestoMetresDisplay,
} from '@/utils/unitSystem';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';

export type Props = {
  title?: string;
  displayConfig?: Maybe<DisplayConfig>;
  onFieldValueChange?: (value: string) => void;
};

export const ProposedPaintArea = ({ title, displayConfig, onFieldValueChange }: Props) => {
  const rows = displayConfig?.textAreaRows || 4;
  const paintDiameter = useRecoilValue(paintDiameterSharedState);
  const { measurementDistance } = useMeasurementTool();
  const unitSystem = useRecoilValue(unitSystemState);
  const [paintArea, setPaintArea] = useState<string | undefined>('');

  const unitScale = useMemo(() => {
    return unitSystem === UnitSystemEnum.Imperial ? SQFEET_TO_SQMETERS_FACTOR : 1;
  }, [unitSystem]);

  // set paint area to null in case length is changed, calucation of area will happen
  useEffect(() => {
    if (measurementDistance) setPaintArea(undefined);
  }, [measurementDistance]);

  const calculatedPaintArea = useMemo(() => {
    if (paintArea !== undefined) return paintArea;

    const paintDiameterNumber =
      unitSystem === UnitSystemEnum.Imperial
        ? convertInchestoFeetDisplay(Number(paintDiameter))
        : convertInchestoMetresDisplay(Number(paintDiameter));

    if (!paintDiameterNumber) return 0;

    const measurementDistanceNumber = Number(measurementDistance);

    const paintDistanceNumber =
      unitSystem === UnitSystemEnum.Imperial
        ? measurementDistanceNumber * METERS_TO_FEET_FACTOR
        : measurementDistanceNumber;

    const result = Math.PI * paintDiameterNumber * paintDistanceNumber;

    if (Number.isNaN(result)) {
      return 0;
    }

    return result.toFixed(2);
  }, [measurementDistance, paintDiameter, unitSystem, paintArea]);

  useEffect(() => {
    if (onFieldValueChange !== undefined && calculatedPaintArea) {
      onFieldValueChange(String(Number(calculatedPaintArea) / unitScale));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedPaintArea, unitScale]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          '& .MuiTextField-root': { width: '100%' },
        }}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={`TextArea-${title}`}
            label={`${title} (${unitSystem === UnitSystemEnum.Imperial ? 'ft²' : 'm²'})`}
            multiline={false}
            rows={rows}
            value={calculatedPaintArea}
            onChange={(event) => setPaintArea(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Box>
    </>
  );
};
