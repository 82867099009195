import { useCallback, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as state from '@/state';
import * as selectors from '@/state/selectors';

import { IsolatedPartVisibilityRangeType } from '@/types';
import { useFeatureFlag } from '@/hooks';
import { IsolatedPartVisibilityRange } from './VisibilityRange';
import { SplitPartButton } from './SplitPart';
import { SelectedLabels } from './SelectedLabels';
import { DockPanel } from '@/components/shared/DockPanel';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import { SplitCuboids } from './SplitCuboids';

export const IsolatedPart = () => {
  const [{ previousMode: previousAbyssViewerMode }, setAbyssViewerState] = useRecoilState(
    selectors.setAbyssViewerState
  );
  const [isolatedPartVisibilityRange, setIsolatedPartVisibilityRange] = useRecoilState(
    state.isolatedPartVisibilityRange
  );
  const setSelectedAnnotationIds = useSetRecoilState(state.selectedAnnotationIds);
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);

  useEffect(() => setIsolatedPartVisibilityRange('All'), [setIsolatedPartVisibilityRange]);

  const handleIsolatedPartVisibilityRangeChanged = (
    visibilityOption: IsolatedPartVisibilityRangeType
  ) => {
    setIsolatedPartVisibilityRange(visibilityOption);
  };

  const exitIsolatePartMode = useCallback(() => {
    setSelectedSpherical(undefined);
    setAbyssViewerState({ mode: previousAbyssViewerMode || 'Normal' });
    setSelectedAnnotationIds([]);
    setSphericalPointCloudMode('All');
  }, [
    previousAbyssViewerMode,
    setAbyssViewerState,
    setSelectedAnnotationIds,
    setSelectedSpherical,
    setSphericalPointCloudMode,
  ]);

  const canViewSplitCuboids = useFeatureFlag('alpha-user');

  return (
    <DockPanel>
      <DockPanelItem>
        <IsolatedPartVisibilityRange
          visibilityRange={isolatedPartVisibilityRange}
          onChanged={handleIsolatedPartVisibilityRangeChanged}
        />
      </DockPanelItem>
      <DockPanelItem>
        <SelectedLabels />
      </DockPanelItem>
      <DockPanelItem>
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
          <SplitPartButton />
          {canViewSplitCuboids && <SplitCuboids />}
          <Button color="primary" onClick={exitIsolatePartMode}>
            Exit part isolation
          </Button>
        </Stack>
      </DockPanelItem>
    </DockPanel>
  );
};
