import { Box } from '@mui/material';
import styled from 'styled-components';

export const FiltersCount = styled(Box)(() => ({
  padding: 0.5,
  fontSize: '1.4rem',
  color: 'primary',
  fontWeight: 'bold',
  background: '#e9eaec',
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
