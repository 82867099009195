import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type StyledTooltipProps = {
  styles?: Object;
} & TooltipProps;

export const StyledTooltip = styled(
  ({ className, ...props }: StyledTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  { shouldForwardProp: (property) => property !== 'styles' }
)(({ styles }) => ({
  [`& .${tooltipClasses.tooltip}`]: styles,
}));
