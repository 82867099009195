import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Stack } from '@mui/material';
import ColorLens from '@mui/icons-material/ColorLens';
import * as state from '@/components/Analysis/state';
import { SideDrawer } from '@/components/shared/SideDrawer';
import { ToggleItemVisibility } from './ToggleItemVisibility';
import { PointSizeSlider } from '@/components/shared/PointSizeSlider';
import { StyledCollapse } from '../AssetDetails/styles';
import { DefectColorLegend } from '../DefectColorLegend';
import { DrawersProps, Permissions } from '@/types';
import { BlendingMode } from './BlendingMode';
import { useFeatureFlag, useHavePermission } from '@/hooks';
import { EdlStrengthSlider } from '@/components/shared/EdlStrengthSlider';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';

export const Visibility = ({ allClosed, isOpen, handleOpen }: DrawersProps) => {
  const [areSphericalsVisible, setAreSphericalsVisible] = useRecoilState(
    state.areSphericalsVisible
  );
  const [showPointCloudInSpherical, setShowPointCloudInSpherical] = useRecoilState(
    state.showPointCloudInSpherical
  );
  const [showPointsOutsideViewpoint, setShowPointsOutsideViewpoint] = useRecoilState(
    state.showPointsOutsideViewpoint
  );
  const [areDecksVisible, setAreDecksVisible] = useRecoilState(state.areDecksVisible);
  const [arePoisVisible, setArePoisVisible] = useRecoilState(state.arePoisVisible);
  const currentSpherical = useRecoilValue(state.selectedSpherical);
  const setEnableViewpoints = useSetRecoilState(state.enableViewpoints);
  const hasRgb = useRecoilValue(state.hasRgb);

  const { leftDrawerWidth } = useDrawerWidths();

  const isAlphaUser = useFeatureFlag('alpha-user');

  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);

  const toggleSphericalVisibility = useCallback(() => {
    setAreSphericalsVisible(!areSphericalsVisible);
    setEnableViewpoints(undefined);
  }, [areSphericalsVisible, setAreSphericalsVisible, setEnableViewpoints]);

  const togglePointCloudVisibility = () => {
    setShowPointCloudInSpherical((current) => !current);
  };

  const togglePointsOutsideViewpoint = () => {
    setShowPointsOutsideViewpoint((current) => !current);
  };

  const toggleDecksVisibility = useCallback(() => {
    setAreDecksVisible((currentAreDecksVisible) => {
      return !currentAreDecksVisible;
    });
  }, [setAreDecksVisible]);

  const togglePoiVisibility = useCallback(() => {
    setArePoisVisible((current) => !current);
  }, [setArePoisVisible]);

  return (
    <div data-testid="visibility">
      <SideDrawer
        allDrawersClosed={allClosed}
        isOpen={isOpen}
        handleOpen={handleOpen}
        icon={<ColorLens />}
        position="left"
        width={leftDrawerWidth}
        label="Visibility settings"
        iconStyles={{ top: 10 }}
        scrollable
        labelStyles={{ marginLeft: 0 }}
      >
        <Stack direction="column" spacing={2}>
          {canReadDefects && (
            <StyledCollapse in>
              <DefectColorLegend />
            </StyledCollapse>
          )}
          <PointSizeSlider title="Point size" step={0.5} min={0} max={10} />
          {isAlphaUser && <EdlStrengthSlider title="Enhance Points" />}
          {currentSpherical && (
            <>
              <ToggleItemVisibility
                label="Point Cloud"
                isVisible={showPointCloudInSpherical}
                toggleVisibility={togglePointCloudVisibility}
                tooltipTitle='"Press Alt + A"'
              />
              <ToggleItemVisibility
                label="Points outside viewpoint"
                isVisible={showPointsOutsideViewpoint}
                toggleVisibility={togglePointsOutsideViewpoint}
                disabled={!showPointCloudInSpherical}
              />
            </>
          )}
          <ToggleItemVisibility
            label="Viewpoints"
            isVisible={areSphericalsVisible}
            toggleVisibility={toggleSphericalVisibility}
            tooltipTitle='"Press Alt + V"'
          />
          {!currentSpherical && (
            <ToggleItemVisibility
              label="Deck Grids"
              isVisible={areDecksVisible}
              toggleVisibility={toggleDecksVisibility}
            />
          )}
          <ToggleItemVisibility
            label="Points of Interest"
            isVisible={arePoisVisible}
            toggleVisibility={togglePoiVisibility}
            tooltipTitle='"Press Alt + P"'
          />
          {hasRgb && <BlendingMode />}
        </Stack>
      </SideDrawer>
    </div>
  );
};
