import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { selectedStructureId as structureIdState } from '@/state';

export const useGetStructureId = () => {
  const structureId = useRecoilValue(structureIdState);
  const router = useRouter();
  const { inspection } = router?.query || {};

  const selectedStructureId = useMemo(() => {
    if (structureId) return structureId;
    if (inspection) {
      if (Array.isArray(inspection)) {
        return inspection[0];
      }
      return inspection;
    }
    return '';
  }, [inspection, structureId]);

  return selectedStructureId;
};
