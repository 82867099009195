import { useRecoilState } from 'recoil';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEventHandler } from 'react';
import * as state from '@/state';

const ShowDeSelectAfterSetCheckbox = (isChecked: boolean, toggleSelection: ChangeEventHandler) => (
  <Checkbox checked={isChecked} onChange={toggleSelection} />
);

export const DeSelectAfterSet = () => {
  const [shouldDeselectAfterSetsupervoxelClass, setShouldDeselectAfterSetsupervoxelClass] =
    useRecoilState(state.shouldDeselectAfterSetsupervoxelClass);

  const toggleDeselectAfterSet = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldDeselectAfterSetsupervoxelClass(event.target.checked);
  };

  return (
    <>
      <FormControlLabel
        control={ShowDeSelectAfterSetCheckbox(
          shouldDeselectAfterSetsupervoxelClass,
          toggleDeselectAfterSet
        )}
        label="Deselect After Set"
      />
    </>
  );
};
