export type PendoInitializationOptions = {
  visitor: {
    id: string;
  };
  account: {
    id: string;
  };
};

export const initializePendo = (options: PendoInitializationOptions) => {
  // Global Pendo object is not accesible on window while running headless tests
  // so we only need to initialize pendo in Production and Development
  if (process.env.NODE_ENV !== 'test') {
    window?.pendo?.initialize(options);
  }
};
