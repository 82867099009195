import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  message?: string;
};

/**
 * Alert info message while waiting for a response to come back
 */
export const LoadingAlert = ({ message }: Props) => {
  return (
    <Alert severity="info">
      {message && (
        <>
          {message}
          &nbsp;&nbsp;
        </>
      )}
      <CircularProgress size={14} thickness={6} />
    </Alert>
  );
};
