import { Box, Divider, Grid, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { lazy, Suspense, useCallback, useState, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useGeneralFields, useTriggerPoiEditing } from '../../hooks';
import { GeneralFieldsForm } from '../GeneralFieldsForm';
import { TemplateFieldsForm } from '../TemplateFieldsForm';
import { useTemplateFields } from '../../hooks/useTemplateFields';
import { SelectedManualTemplateTypes } from '@/types';
import {
  useCreatePointOfInterestMutation,
  PoiStatus,
  VisibilityEnum,
  PoiAccessibilityEnum,
} from './data.graphql';
import { convertTemplateStateToTemplateData } from '../../utils';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { PoiFormHeading, PoiFormSubHeading } from '../styles';
import { BlisterFormFields } from '../BlisterFormFields';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import { useBlisterData } from '../../hooks/useBlisterData';
import { useGetAllTeamsQuery } from '../../data.graphql';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { StyledForm } from '../UpdateGeneralFields/styles';
import { SaveCancelFormButtons } from '../SaveCancelFormButtons';
import { saveCancelButtonCommonStyles } from '../../../styles';
import {
  viewerCurrentSpherical as viewerCurrentSphericalAtom,
  allPointOfInterestTemplates,
} from '@/components/Analysis/state';
import { GetAssemblyPointOfInterestsDocument } from '@/components/Analysis/Viewer/Panels/AssetDetails/data.graphql';
import { upperSnakeCase } from '@/utils';
import { useViewpointPoiHeight } from '@/components/Analysis/Viewer/hooks/useViewpointPoiHeight';
import { GetOneAssemblyDocument } from '../../../../aitInsights/Blisters/data.graphql';
import { useSavePoiScreenshot } from '@/components/Analysis/modules/pointOfInterest/createOrEdit/useSavePoiScreenshot';
import { paintDiameterSharedState } from '@/components/shared/CalculatedFields/state';
import { BLISTER, BLISTER_TYPE, PIT, PIT_TYPE } from '@/constants';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import { humanizeString } from '@/utils/string';
import * as cuboidsState from '../../../../../cuboids/state';
import { useAnalysisCuboidActions } from '@/components/Analysis/modules/cuboids/state/actions';

const fieldsContainer = {
  '& > *': { my: '4%' },
};

const BlisterMeasurementLazy = lazy(() =>
  import('../../../../createOrEdit').then(({ BlisterMeasurement }) => ({
    default: BlisterMeasurement,
  }))
);

export const CreatePoi = () => {
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const allPoiTemplates = useRecoilValue(allPointOfInterestTemplates);
  const setPaintDiameter = useSetRecoilState(paintDiameterSharedState);
  const heightToPointOfInterest = useViewpointPoiHeight();
  const { handleSavePoiScreenshot } = useSavePoiScreenshot();
  const setAllPOI = useSetRecoilState(poiState.structurePois);
  const setManualBlisterHeightExists = useSetRecoilState(poiState.manualBlisterHeightExists);
  const cuboids = useRecoilValue(cuboidsState.cuboids);
  const currentCuboid = useRecoilValue(cuboidsState.currentCuboid);

  const navbarHeight = useNavbarHeight();
  const { exitEditing, removeCurrentCuboidFromCuboids } = useAnalysisCuboidActions();

  const pointOfInterest = editPointOfInterest?.pointOfInterest;
  const template = editPointOfInterest?.template;

  const { cancelEditing } = useTriggerPoiEditing();

  const onClose = useCallback(() => {
    setPaintDiameter('0');
    cancelEditing();
  }, [cancelEditing, setPaintDiameter]);

  const poiType = template?.name.toUpperCase()?.replaceAll(' ', '_');

  const { data: teamsData, loading: teamsLoading } = useGetAllTeamsQuery();

  const teamOptions = useMemo(() => {
    return teamsData?.allTeams?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [teamsData?.allTeams]);
  const setSelectedPointOfInterestId = useSetRecoilState(poiState.selectedPointOfInterestId);
  const [loading, setLoading] = useState(false);

  const [isManualTemplate, setIsManualTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectedManualTemplateTypes>(BLISTER_TYPE);

  const {
    localBlisterData,
    blisterFieldsData,
    pointSelectMessage,
    onMeasure,
    onSelectPoint,
    clearBlisterData,
    handleManualHeightChange,
    handleMeasureIconClick,
    handleChangeScCategory,
  } = useBlisterData(selectedTemplate);

  const currentSpherical = useRecoilValue(viewerCurrentSphericalAtom);

  const generalFieldsData = useGeneralFields({
    assignee: 'None',
    dueDate: new Date(),
    status: PoiStatus.Inreview,
    visibility: VisibilityEnum.Public,
    name: poiType ? humanizeString(poiType.toString(), '_') : '',
    teamIds: [],
    workpackNumber: 'N/A',
    comment: '',
    accessibility: undefined,
  });

  const templateFieldsData = useTemplateFields({
    selectedPoiInfo: pointOfInterest,
    templateFields:
      selectedTemplate === PIT_TYPE
        ? allPoiTemplates.find((poiTemplate) => poiTemplate.name.toUpperCase() === selectedTemplate)
            ?.fields ?? []
        : template?.fields ?? [],
    poiType: selectedTemplate,
  });

  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });

  const [handlePoiCreate] = useCreatePointOfInterestMutation({
    refetchQueries: [GetAssemblyPointOfInterestsDocument, GetOneAssemblyDocument],
  });

  const { inputAdormantHandlerMap, cancelMeasuring } = useMeasurementTool();

  const isBlister = poiType === BLISTER_TYPE;

  useEffect(() => {
    if (localBlisterData?.maxHeight) {
      if (isManualTemplate) {
        setSelectedTemplate(localBlisterData.maxHeight < 0 ? BLISTER_TYPE : PIT_TYPE);
      } else {
        setSelectedTemplate(localBlisterData.maxHeight < 0 ? PIT_TYPE : BLISTER_TYPE);
      }
    }
  }, [isManualTemplate, localBlisterData?.maxHeight]);

  const templateId = useMemo(() => {
    return localBlisterData?.maxHeight
      ? allPoiTemplates.find((poiTemplate) => poiTemplate.name.toUpperCase() === selectedTemplate)
          ?.id
      : template?.id;
  }, [allPoiTemplates, localBlisterData?.maxHeight, selectedTemplate, template?.id]);

  const checkboxLabel = useMemo(() => {
    return localBlisterData?.maxHeight! < 0 ? BLISTER : PIT;
  }, [localBlisterData?.maxHeight]);

  const isPoiTypeOfVoi = poiType === 'PAINT_RECOMMENDATION';

  const handleManualTemplateChange = useCallback(
    (manualTemplateValue: boolean) => {
      // If the user decides to mark the Blister as PIT
      // update the name of the POI to reflect the change
      generalFieldsData.setState((current) => ({
        ...current,
        // manualTemplateValue being true means the user has checked the checkbox
        // meaning they want to mark the Blister as PIT
        name: manualTemplateValue ? PIT : BLISTER,
      }));
      setIsManualTemplate(manualTemplateValue);
    },
    [generalFieldsData]
  );

  const createPointOfInterest = useCallback(async () => {
    const templateData = convertTemplateStateToTemplateData(templateFieldsData.state);
    const generalFields = generalFieldsData.state;

    const structureId = pointOfInterest?.assembly?.structure?.id;
    const assemblyId = pointOfInterest?.assembly?.id;
    const type = template?.name;
    const centerPoint3d = pointOfInterest?.centerPoint3d;
    const cameraLocation = pointOfInterest?.cameraLocation;

    const locationId = currentSpherical?.id;

    if (isBlister && !editPointOfInterest.hasBlisterMeasurement) {
      showSnackBar('Please Generate Measurement before save.', 'error');
      return;
    }

    if (
      !structureId ||
      !assemblyId ||
      !type ||
      !centerPoint3d ||
      !templateData ||
      !generalFields ||
      !cameraLocation
    ) {
      showSnackBar(
        'Error creating Point Of Interest. Please contact support if the issue persists.',
        'error'
      );
      return;
    }

    const accessibilityEnum = Object.values(PoiAccessibilityEnum).find(
      (accessibilityValue) =>
        accessibilityValue === upperSnakeCase(generalFieldsData.state.accessibility ?? '')
    );

    setLoading(true);
    try {
      const response = await handlePoiCreate({
        variables: {
          input: {
            ...generalFields,
            name: generalFields?.name ?? '',
            centerPoint3d,
            cameraLocation,
            structureId,
            assemblyId,
            type: localBlisterData?.maxHeight
              ? selectedTemplate.toString()
              : poiType?.toString() ?? '',
            templateData,
            templateId: templateId,
            locationId,
            blister: localBlisterData,
            dueDate: generalFields?.dueDate?.toISOString(),
            workpackNumber: generalFields.workpackNumber,
            ...(accessibilityEnum ? { accessibility: accessibilityEnum } : {}),
            ...(heightToPointOfInterest
              ? { viewpointRelativeHeight: heightToPointOfInterest }
              : {}),
            // Only pass cuboids if the POI is a VOI
            // Otherwise backend will throw an error
            cuboids:
              isPoiTypeOfVoi && currentCuboid
                ? [
                    {
                      position: currentCuboid.position,
                      scale: currentCuboid.scale,
                      rotation: currentCuboid.rotation,
                    },
                  ]
                : undefined,
          },
        },
      });
      cancelMeasuring();

      const newPoi = response.data?.createPointOfInterest;
      if (newPoi) setAllPOI((previous) => [...previous, newPoi]);
      setSelectedPointOfInterestId(newPoi?.id);
      setPaintDiameter('0');
      setManualBlisterHeightExists(false);
      showSnackBar('Point of interest created successfully', 'success');
      onClose();
      await handleSavePoiScreenshot({ poiId: newPoi?.id });
    } catch {
      showSnackBar('Could not create point of interest. Please try again', 'error');
    } finally {
      setLoading(false);
    }
  }, [
    cancelMeasuring,
    currentSpherical?.id,
    editPointOfInterest.hasBlisterMeasurement,
    generalFieldsData.state,
    handlePoiCreate,
    handleSavePoiScreenshot,
    heightToPointOfInterest,
    isBlister,
    localBlisterData,
    onClose,
    poiType,
    pointOfInterest?.assembly?.id,
    pointOfInterest?.assembly?.structure?.id,
    pointOfInterest?.cameraLocation,
    pointOfInterest?.centerPoint3d,
    selectedTemplate,
    setAllPOI,
    setPaintDiameter,
    setSelectedPointOfInterestId,
    showSnackBar,
    template?.name,
    templateFieldsData.state,
    templateId,
    currentCuboid,
    isPoiTypeOfVoi,
    setManualBlisterHeightExists,
  ]);

  /**
                            * Special case for Volume of Interest *

        Since the Cuboid editor is limited in it's API. We need to manually check if a cuboid was added.
        For that we can do a check for, if the current cuboid is present in the cuboids array.
        If it is, then we can assume that the user has added a cuboid.
        After that we fire the createPointOfInterest call and create the POI with the current cuboid in
        it's cuboid array i.e cuboids: [currentCuboid]
        For now, we're only able to add one cuboid at a time. Multiple cuboid support will be added later.

        Sorry for adding another useEffect, but this is a special case and we can't do much about it.
  **/
  useEffect(() => {
    if (!currentCuboid || !cuboids) return;

    if (cuboids.has(currentCuboid.id)) {
      createPointOfInterest();
      removeCurrentCuboidFromCuboids();
    }
  }, [createPointOfInterest, cuboids, currentCuboid, removeCurrentCuboidFromCuboids]);

  return (
    <Grid container sx={{ height: '100%' }}>
      {localBlisterData && (
        <Grid item xs={7}>
          <Suspense>
            <BlisterMeasurementLazy
              poiToAdd={pointOfInterest}
              onMeasure={onMeasure}
              pointSelectMessage={pointSelectMessage}
              onSelectPoint={onSelectPoint}
              clearBlisterData={clearBlisterData}
              measureOnLoad={editPointOfInterest.formState === 'Create'}
            />
          </Suspense>
        </Grid>
      )}
      <Grid item xs={localBlisterData ? 5 : 12}>
        <StyledForm
          onSubmit={async (event) => {
            event.preventDefault();
            // Temporary check for VOI type of POIs
            // TODO: Replace this check with a check for template type
            if (isPoiTypeOfVoi) {
              // Telling the Cuboid editor that we're done adding the cuboid
              // Exiting the editing mode triggers the onExit callback
              // The onExit callback adds the current cuboid to the cuboids array
              // then the useEffect above triggers the createPointOfInterest function
              // Not ideal, but that's how the Cuboids editor API works
              exitEditing();
              return;
            }
            await createPointOfInterest();
          }}
          style={{ borderLeft: '1px solid #ddd' }}
        >
          <Stack
            sx={{ height: `calc(100vh / var(--zoom) - ${navbarHeight}px)`, overflowY: 'auto' }}
          >
            <Box flex={1} mb="8rem">
              <Stack direction="column" sx={{ px: 2 }}>
                <PoiFormHeading variant="h5">Summary</PoiFormHeading>

                <Box sx={fieldsContainer}>
                  <PoiFormSubHeading variant="h5">POI specifications</PoiFormSubHeading>
                  {isBlister && (
                    <BlisterFormFields
                      blisterData={blisterFieldsData}
                      handleManualHeightChange={handleManualHeightChange}
                      handleMeasureIconClick={handleMeasureIconClick}
                      handleChangeScCategory={handleChangeScCategory}
                      handleManualTemplate={handleManualTemplateChange}
                      isManualTemplate={isManualTemplate}
                      label={checkboxLabel}
                    />
                  )}
                  <TemplateFieldsForm
                    template={template}
                    dataHook={templateFieldsData}
                    formState={editPointOfInterest?.formState}
                    inputAdormantHandlerMap={inputAdormantHandlerMap}
                  />
                </Box>
              </Stack>

              <Divider sx={{ mt: 4, color: '#B8CADD' }} />

              <Stack direction="column" sx={{ px: 2 }}>
                <Box sx={fieldsContainer}>
                  <PoiFormSubHeading variant="h5" mt={2}>
                    General Information
                  </PoiFormSubHeading>
                  <GeneralFieldsForm
                    teamOptions={teamOptions}
                    teamsLoading={teamsLoading}
                    dataHook={generalFieldsData}
                  />
                </Box>
              </Stack>
            </Box>

            <SaveCancelFormButtons
              cancelButtonLabel="Cancel"
              buttonLabel="Add POI"
              loadingLabel="Creating..."
              onCancel={() => {
                cancelMeasuring();
                onClose();
                setManualBlisterHeightExists(false);
              }}
              loading={loading}
              buttonIcon={<AddCircleIcon />}
              sx={saveCancelButtonCommonStyles.sx}
              saveButtonStyles={saveCancelButtonCommonStyles.saveButtonStyles}
              cancelButtonStyles={saveCancelButtonCommonStyles.cancelButtonStyles}
            />
          </Stack>
        </StyledForm>
      </Grid>
    </Grid>
  );
};
