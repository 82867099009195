import { Number3, SphericalImageProps } from '@abyss/3d-viewer';
import { ReactElement, ReactNode, SetStateAction } from 'react';
import { SxProps } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import { ExtractSubjectType, MongoQuery, Subject, SubjectRawRule } from '@casl/ability';
import { ZoneStatus } from '@/components/ZonesMangement/data.graphql';
import {
  GetAssemblyPointOfInterestsQuery,
  PointOfInterest,
  CorrosionLevel,
} from '@/components/Analysis/Viewer/Panels/AssetDetails/data.graphql';
import {
  GetAllPointOfInterestsQuery,
  Template,
  PointOfInterestTemplate,
  DefectLegend,
  GetAllPointOfInterestTemplatesQuery,
  AllPointOfInterestsForDataLoaderQuery,
} from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { SplitCuboid } from '@/components/Inspection/Tagging/InspectionDataLoader/data.graphql';
import { AllAssemblyTemplatesQuery } from '@/components/Analysis/shared/FilterSideBar/data.graphql';
import { PointOfInterestsQuery } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/data.graphql';
import { StateMap } from '@/utils/stateMap';
import { GetAllStructuresListQuery } from '@/components/shared/Breadcrumb/data.graphql';
import { GetAssembliesByTemplateFieldsFiltersQuery } from '@/components/Analysis/Viewer/Drawers/AitInsights/data.graphql';
import { GetAssembliesByTemplateFieldsFiltersQuery as AssembliesDataQuery } from '@/components/Analysis/shared/EquipmentsTab/data.graphql';
import { PointOfInterest as PointOfInterestWithCuboidsType } from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';

import {
  Vector3,
  User,
  GetAllStructuresQuery,
  StructureStatus,
} from '@/components/Platforms/data.graphql';
import { GetAnalysisStructureLocationsQuery } from '@insights-queries';

export { TemplateFieldTypeEnum } from '@/components/InspectionRouter/data.graphql';
export type PartSummary = {
  id: string;
  assemblyId?: string | null;
  assemblyTagName?: string;
  partClassName: string;
  isHidden: boolean | undefined | null;
  isReviewed?: boolean | null;
};
export type PointOfInterestChecked = PointOfInterest & { checked?: boolean };
export type AssemblyPointOfInterestQuery = GetAssemblyPointOfInterestsQuery['allPointOfInterests'];
export type PointOfInterestQuery = GetAllPointOfInterestsQuery['allPointOfInterests'];
export type DataLoaderPointOfInterestQueryObject =
  AllPointOfInterestsForDataLoaderQuery['pointOfInterests'];
export type PointOfInterestsQueryType = PointOfInterestsQuery['pointOfInterests'];
export type PlatformsType = GetAllStructuresQuery['allStructures'];

export type PointOfInterestDocument = PointOfInterest;
export type PointOfInterestTemplateDocument = PointOfInterestTemplate;

export type AllAssembliesByTemplateFilter =
  GetAssembliesByTemplateFieldsFiltersQuery['getAssembliesByTemplateFieldsFilters'];
export type AllPointOfInterestTemplate =
  GetAllPointOfInterestTemplatesQuery['allPointOfInterestTemplate'];
export type TemplateDocument = Template;
export type AssemblyTemplatesQuery = AllAssemblyTemplatesQuery['allTemplates'];
export type AssemblyTemplateQuery = AllAssemblyTemplatesQuery['allTemplates'][0];
export type EquipmentCardType = AssemblyTemplateQuery['equipmentCard'];
export type AllStructures = GetAllStructuresListQuery['allStructures'];
export type PartId = string;
export type AnnotationId = string;
export type AssemblyId = string;
export type FocalPoint = Vector3;
export type PartInfo = {
  annotationIds: Set<AnnotationId>;
  class: string;
  assemblyId?: string | null;
  isHidden?: boolean | undefined | null;
  // Key for hiding unhide labeling classes, will be updated
  // to isHidden once isHidden will be renamed to isOutOfScope
  isHiddenVolatile?: boolean | undefined | null;
  isReviewed?: boolean | null;
};

export type AnnotationInfo = {
  // Need this to map an annotation id to what 3d viewer is aware of,
  // e.g. when we need to change an annotation's color
  annotation3dReference: number;
  partId: string;
  locationId?: string | null;
  localAnnotationId?: number;
  id?: string;
};

export type AssemblyInfo = {
  id?: string;
  tagName: string;
  isNewlyAdded?: boolean | undefined;
  __typename?: string;
};

export type Abilities = SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[];

export type StructureRelationships = {
  // Annotation 3d reference is what the 3d viewer knows about
  // we need this map to translate from 3d viewer annotation to
  // the database annotation id
  // e.g. when handling a click event on the 3d viewer point cloud
  byAnnotation3dReference: StateMap<number, AnnotationId>;
  // Annotation id maps to the database annotation id
  byAnnotationId: StateMap<AnnotationId, AnnotationInfo>;
  byPartId: StateMap<PartId, PartInfo>;
  byAssemblyId: StateMap<AssemblyId, AssemblyInfo>;
};

export type AnalysisStructureRelationships = {
  annotationIdToAssemblyId: {
    [annotationId: string]: string;
  };
  annotationIdToAnnotation3d: {
    [annotationId: string]: number;
  };
  annotation3dToAnnotationId: {
    [annotation3d: number]: string;
  };
  assemblyIdToAnnotation3dArray: {
    [assemblyId: string]: number[];
  };
  assemblyIdAndLocationIdToAnnotation3dArray: {
    [assemblyId: string]: {
      [locationId: string]: number[];
    };
  };
  assemblyIdToAssembly3d: {
    [assemblyId: string]: number;
  };
  partsByAnnotationId: {
    [partId: string]: number[];
  };
  parts: {
    [partId: string]: { annotations: number[]; class: string };
  };
};

export type SnackbarMessage = {
  shouldShow: boolean;
  content?: React.ReactElement;
};

export type AbyssViewerMode = 'Normal' | 'Spherical' | 'IsolatePart' | 'VoxelSelector';

export type AbyssViewerState = {
  mode: AbyssViewerMode;
  imageViewpoint?: SphericalImageProps;
  previousMode?: AbyssViewerMode;
  previousImageViewpoint?: SphericalImageProps;
};

export type SphericalPointCloudState = 'Loaded' | 'Loading' | 'Error';

export type SphericalPointCloudMode = 'All' | 'Related' | 'None';

export type NormalVisibilityRange = 'All' | 'ZoneOnly' | 'Custom';

export type Colors = {
  colors: number[];
  selectedColour: number;
  noAssemblyColour: number;
  outOfScopeColour: number;
  highlightReviewedPartColour: number;
  segmentsFillColour: number;
  segmentsLineColour: number;
  foundAssemblyColour: number;
  hoveredAssemblyColour: number;
};

export type IsolatedPartVisibilityRangeType = 'All' | 'Selected' | 'Unselected';

export type Zone = {
  id: string;
  name: string;
  status?: ZoneStatus;
  box: {
    min: Point3d;
    max: Point3d;
  };
};

export type Point3d = {
  x: number;
  y: number;
  z: number;
};

export type Point2d = {
  x: number;
  y: number;
};

export type BlisterProfilePlotPoint = {
  x: number;
  y: number;
  z: number;
  height: number;
};

export type PanelsMode =
  | 'Default'
  | 'IsolatePart'
  | 'MeasureBlister'
  | 'AssetDetail'
  | 'SidePanelOff'
  | 'Comments';

export type Viewpoint = {
  location: {
    name: string;
    id: string;
  };
  corrosion?: { primaryCategory?: CorrosionLevel };
  distance?: number | null;
  distanceDisplayText?: string | null;
};

export enum HeatmapValueEnum {
  CORROSION = 'substrateConditionMax',
  COATINGCONDITION = 'coatingConditionMax',
  TRACE = 'trace',
  DEFECTS = 'defects',
}
export type HeatmapValue = 'substrateConditionMax' | 'coatingConditionMax' | 'trace' | 'defects';

export type Border3d = {
  points: Point3d[];
};

export type AddBlisterState =
  | 'Inactive'
  | 'WaitingForCenter'
  | 'WaitingForEdge'
  | 'Loading'
  | 'Reviewing'
  | 'UpdatingBlister'
  | undefined;

export type BlisterToAdd =
  | {
      state?: AddBlisterState;
      centerPoint?: Point2d;
      edgePoint?: Point2d;
    }
  | undefined;

export type PointOfInterestState = 'Inactive' | 'WaitingForPoint' | 'WaitingForDetail';
export type PoiFormState = 'Create' | 'Verify' | 'Update-General' | 'Update-Template';

export type EditPointOfInterest = {
  state: PointOfInterestState;
  template?: PointOfInterestTemplateDocument;
  pointOfInterest?: PointOfInterest | undefined;
  formState?: PoiFormState;
  hasBlisterMeasurement?: boolean;
};

/**
 *  A single object structure that reside in byAnnotation3dReference
 *  eg: {
 *          12: {
 *              supervoxelId: '1sdasd123sadedfdfe0',
 *              supervoxelClassId: '1sdasd123sadedfdfe1',
 *              color: '#AEFT123'
 *              highlightColor: '#AEFT123',
 *              isSelected: false
 *            }
 *      }
 */
export type ByAnnotation3dReferenceEntity = {
  supervoxelId: string;
  supervoxelClassId: string;
  color: string;
  highlightColor: number;
  isSelected: boolean;
};

/**
 *  A single object structure  that reside in bySupervoxelClassId
 *  eg: {
 *          '1sdasd123sadedfdfe1': {
 *              id: '1sdasd123sadedfdfe1',
 *              zoneId: '1sdasd123sadedfdfe2'
 *              color: '#AEFT123',
 *              name: 'flange',
 *              annotationIds: [1,3,4,5,7,8,9,10],
 *              highlightColor: '#AEFT123',
 *              isSelected: false
 *            }
 *      }
 */
export type BySupervoxelClassIdEntity = {
  id: string;
  zoneId: string;
  color: string;
  name: string;
  annotationIds: number[];
  highlightColor: number;
  isSelected: boolean;
};

export type LabellingStructureRelationships = {
  // Annotation 3d reference is what the 3d viewer knows about
  // we need this map to translate from 3d viewer annotation to
  // the database annotation id
  // e.g. when handling a click event on the 3d viewer point cloud
  byAnnotation3dReference: {
    [annotation3dReference: number]: ByAnnotation3dReferenceEntity;
  };

  // grouping by class is benefical when performing hide/unhide
  // labelling classes and evaluation which voxel has particular class
  bySupervoxelClassId: {
    [supervoxelClassId: string]: BySupervoxelClassIdEntity;
  };
};

// default type color for highligh, de-selected etc.
// eg: While performing Multi-Select the box has yellow color.
//     While performing De-Select the box has white color etc.
export type LabellingColors = {
  highlightColor: number;
  deselectColor: number;
  defaultColor: number;
  selectedColour?: number;
};

// descibes the different coloring mode in labelling
export enum LabellingColorMode {
  SuperVoxel,
  SuperVoxelClass,
  Scan,
}

export enum LabellingBlendingMode {
  SUPERVOXEL = 'superVoxel',
  SUPERVOXEL_RGB = 'superVoxel_RGB',
  COLOUR = 'color',
}

export enum LabellingAnnotationMode {
  SUPERVOXEL = 'superVoxel',
  ASSET_TYPE = 'assetType',
}

export enum SelectedTab {
  COMPONENT,
  POINTS_OF_INTEREST,
  PAINT_BLOCK,
  DECKS,
}

export type ToggleableDefectLegend = {
  isEnabled: boolean;
} & DefectLegend;

export type WidgetData = {
  id: string;
  displayName: string;
  icon: ReactElement<'svg'>;
  size: IconButtonProps['size'];
  color: IconButtonProps['color'];
};

export type AnalysisDrawerLeft = {
  visibility: boolean;
  query: boolean;
};
export type AnalysisDrawerRight = {
  equipment: boolean;
  aitInsight: boolean;
  measuringTool: boolean;
  findViewpointsTool: boolean;
};

export type DrawersProps = {
  isOpen: boolean;
  handleOpen: () => void;
  allClosed: boolean;
};

export type GroupByResult<T> = { [key: string]: T[] };

export type PointOfInterestGroupBy = PointOfInterestChecked & {
  templateId?: string;
};

export type HandleCreateReportParameters = {
  reportName: string;
  pointOfInterestIds: Array<string>;
};

export type HandleAddPOIToReportParameters = {
  reportId: string;
  pointOfInterestIds: Array<string>;
};

export type GenericModalProps = {
  open: boolean;
  handleClose: () => void;
  children?: ReactNode;
  title?: string;
  description?: string;
  sx?: SxProps | undefined;
  crossIconStyle?: SxProps | undefined;
  headerStyleProp?: SxProps | undefined;
  backgroundPaperStyle?: SxProps | undefined;
};

export type GenericAccordionProps = {
  summary: string;
  summaryStyles?: SxProps | undefined;
  children: React.ReactNode;
  numericIndicator?: number;
  openByDefault?: boolean;
};

export type PoiAccordionProps = {
  summary: string;
  numericIndicator?: number;
  openByDefault?: boolean;
  groupedPOI: PointOfInterestGroupBy[];
  setSelectedPointOfInterests: React.Dispatch<
    SetStateAction<
      Array<{
        id: string;
        name: string;
      }>
    >
  >;
  selectedPointOfInterests:
    | Array<{
        id: string;
        name: string;
      }>
    | [];
};
export type WindowSize = {
  width: number | undefined;
  height: number | undefined;
};

export type FilterState = {
  query: {
    status?: string | null;
    serviceGroup?: string | null;
    assetType?: string | null;
    assetIdentifier?: string | null;
    corrosionLevel: CorrosionLevel[];
  };
  isQueryExpanded: boolean;
  isResultsExpanded: boolean;
};

export type Columns = {
  isSortable: boolean;
  sortType?: 'NUMBER' | 'STRING';
  visibility: boolean;
  key: string;
  columnName: string;
  isTemplateField?: boolean;
  displayConfig?: { color?: Color[]; type?: string };
  templateFieldKey?: string;
  displayInQuery?: boolean | null | undefined;
  displayInRisk?: boolean | null | undefined;
  roundToDecimal?: number | null | undefined;
  unit?: string | null | undefined;
};

export type PlatformColumn = {
  key: string;
  columnName: string;
  visibility: boolean;
  width: string;
};

type RegionData = {
  id: string;
  name: string;
  structures: StructureData[];
  [key: string]: number | string | null | undefined | StructureData[];
};

type StructureData = {
  id: string;
  name: string;
  status: StructureStatus;
  decks: DeckData[];
  [key: string]: number | string | null | undefined | DeckData[];
};

type DeckData = {
  id: string;
  name: string;
  [key: string]: number | string | null | undefined;
};

export type PlatformProps = {
  columns: PlatformColumn[];
  handlePlatformChange: (id: string, status: StructureStatus) => void;
  handleFetchStructureDecks: (id: string) => void;
  searchPlatform: string;
  structureDecksLoading: boolean;
};

export type ListViewTableProps = {
  regions: RegionData[];
  loading: boolean;
} & PlatformProps;

export type RegionDataProps = {
  region: RegionData;
} & PlatformProps;

export type StructureDataProps = {
  structure: StructureData;
  setExpandedRows: React.Dispatch<React.SetStateAction<{}>>;
  expandedRows: { [key: string]: boolean };
} & PlatformProps;

export type Color = {
  key: string;
  value: string;
} | null;

export type SortType = 'parts' | 'points' | 'sphericals';
export type SortDirection = 'asc' | 'desc';
export type SortableField = `${SortType}-${SortDirection}` | undefined;

export enum TabLabel {
  MEMBER_TAB = 0,
  PLATFORM_TAB = 1,
}

export type InviteUsersState = {
  emails: string[];
  roles: string[];
  groups: string[];
};

export type TemplateWithPOIs = {
  id: string;
  name: string;
  pointOfInterests: {
    location: {
      cx: number;
      cy: number;
    };
  }[];
};

export enum MeasurementToolState {
  IN_ACTIVE = 'Inactive',
  WAITING_FOR_POINT = 'WaitingForPoint',
  DONE = 'Done',
}

export enum Permissions {
  EXPORT_ASSET = 'export:asset',
  UPDATE_ASSET = 'update:asset',
  READ_DEFECTS = 'read:defects',
  MANAGE_PAINT_REGIONS = 'manage:paint_regions',
  MANAGE_USERS = 'manage:users',
  MANAGE_BETA_FEATURES = 'manage:beta_features',
  CREATE_CUPS_POI = 'create:cups_poi',
  CREATE_HOTBOLTING_POI = 'create:hotbolting_poi',
  CREATE_BLISTER_POI = 'create:blister_poi',
  CREATE_PAINT_DAMAGE_POI = 'create:paint_damage_poi',
  UPDATE_CUPS_POI = 'update:cups_poi',
  UPDATE_HOTBOLTING_POI = 'update:hotbolting_poi',
  UPDATE_BLISTER_POI = 'update:blister_poi',
  UPDATE_PAINT_DAMAGE_POI = 'update:paint_damage_poi',
  READ_STRUCTURE_LIST_VIEW = 'read:structure_list_view',
  CREATE_UPLOAD_MEDIA = 'create:upload_media',
}

export type ReportSubmitState = 'create' | 'edit';
export enum UserManagementTabs {
  AllUsers,
  GROUPS,
  ROLES,
  PLATFROMS,
  TEAMS,
}
export type GroupedChartData = {
  pointOfInterestFindings: { [key: string]: number };
  name: string;
};

export type GroupedDataForCharts = {
  groupedByDeck: {
    [deckName: string]: GroupedChartData & {
      [key: string]: number | string | { [key: string]: number };
    };
  };
  groupedByPaintBlock: {
    [paintBlockName: string]: GroupedChartData & {
      [key: string]: number | string | { [key: string]: number };
    };
  };
};

export type UsersTableHeadCell = {
  disablePadding: boolean;
  id: keyof User;
  label: string;
  numeric: boolean;
};

export type Order = 'asc' | 'desc';

export type AutoCompleteOption = {
  label: string;
  value: string;
};

export type UserRoles =
  | 'admin'
  | 'beta-user'
  | 'asset-tagging-user'
  | 'ait-engineer'
  | 'dev'
  | 'generic-engineer'
  | 'client'
  | 'api-inspector'
  | 'alpha-user'
  | 'es-user';

export type InputAdormantHandlerMap = {
  MEASUREMENT_TOOL: () => void;
  RELOAD: () => void;
  '': () => void;
};

export enum TextFormat {
  NONE = 'NONE',
  UPPER_CASE = 'UPPER_CASE',
  LOWER_CASE = 'LOWER_CASE',
  TITLE_CASE = 'TITLE_CASE',
}

export enum CuboidClickMode {
  Default,
  AddCuboid,
  SelectCuboid,
}

export type CuboidData = Omit<SplitCuboid, 'assembly' | '__typename'>;

export type LabellingCuboid = {
  id: string;
  structureId: string;
  zoneId: string;
  cuboidClassId: string;
  position: Number3;
  rotation: Number3;
  scale: Number3;
};

export type AnalysisCuboid = {
  id: string;
  position: Number3;
  rotation: Number3;
  scale: Number3;
};

export type CuboidTransformAction = 'Resize' | 'SymmetricalResize' | 'Move' | 'Rotate';

export type CuboidClass = {
  id: string;
  name: string;
  color: number;
  isVisible?: boolean;
};

export enum CardInsightTypeEnum {
  LINEAR_PROGRESS = 'linearProgress',
  CIRCULAR_PROGRESS = 'circularProgress',
  SIMPLE_TEXT = 'simpleText',
  UNIT_AREA = 'unitArea',
}

export interface LegendColors {
  [key: string]: string;
}

export enum ConditionType {
  COATING = 'COATING',
  SUBSTRATE = 'SUBSTRATE',
}

export type LocationsData = {
  [key: string]: GetAnalysisStructureLocationsQuery['allLocations'];
};

export type AssembliesData = {
  [key: string]: AssembliesDataQuery['getAssembliesByTemplateFieldsFilters'];
};

export type ScreenshotImageObject = {
  id: string;
  viewpointBase64?: string | undefined;
  viewerBase64?: string | undefined;
};

export type ReportImage = {
  id: string;
  base64?: string;
  imageDimensions: { width: number; height: number };
};

export enum FindViewpointsToolState {
  INACTIVE = 'Inactive',
  WAITING_FOR_POINT = 'WaitingForPoint',
  WAITING_FOR_QUERY = 'WaitingForQuery',
  DONE = 'Done',
}

export type SelectedManualTemplateTypes = 'BLISTER' | 'PIT';

export type SeenByViewpointsWithLocationAndAnalysis = {
  __typename?: 'SeenByViewpoint' | undefined;
  analysed?:
    | {
        __typename?: 'Analysed' | undefined;
        analysedBy: string;
      }
    | null
    | undefined;
  location: {
    __typename?: 'Location' | undefined;
    id: string;
  };
};

export enum SocialConnections {
  GOOGLE_OAUTH2 = 'google-oauth2',
  BP_AZURE_AD = 'BP-Azure-AD',
}

export type TemplateTypeWithAllTab =
  | { name: string; fields: AllPointOfInterestTemplate[0]['fields'] }
  | AllPointOfInterestTemplate[0];

export enum RemediatedStatus {
  IN_PROGRESS = 'In Progress',
  SCHEDULED = 'Scheduled',
  INSPECTION_PENDING = 'Inspection pending',
  NO_WORK_REQUIRED = 'No work required',
  REMEDIATED = 'Remediated',
}

export type PointOfInterestWithCuboids = PointOfInterestWithCuboidsType;

export type FeatureBase = {
  flag: string;
  label?: string;
  disabled?: boolean;
  url?: string;
  permissions?: string[];
  roles?: string[];
  enabled?: boolean;
};

export type Feature = FeatureBase & {
  subNavigation?: FeatureBase[];
};

export type StrucureFeatures = {
  [key: string]: Feature | [string];
};
