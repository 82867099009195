import { useEffect, useRef } from 'react';

type CoupleKey = 'alt' | 'ctrl';

export const useKey = (targetKey: string, callback: () => void, coupleKey?: CoupleKey) => {
  const callbackReference = useRef(callback);

  useEffect(() => {
    callbackReference.current = callback;
  });

  useEffect(() => {
    const handle = (event: KeyboardEvent) => {
      if (coupleKey && event.key === targetKey) {
        if (coupleKey === 'alt' && event.altKey) {
          callbackReference.current();
        }
        if (coupleKey === 'ctrl' && event.ctrlKey) {
          callbackReference.current();
        }
      }
      if (!coupleKey && event.key === targetKey) {
        callbackReference.current();
      }
    };
    document.addEventListener('keydown', handle);
    return () => {
      document.removeEventListener('keydown', handle);
    };
  }, [targetKey, coupleKey]);
};
