import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  BandwidthBenchmarkResult,
  DownloadBenchmark,
  LatencyBenchmark,
  LatencyBenchmarkResult,
  UploadBenchmark,
} from './BackendNetworkBenchmark';

type ResultStatus = 'Excellent' | 'Good' | 'Weak' | 'Poor';

type ResultEntry = {
  name: string;
  units: string;
  measuredValue: number;
  required: number;
  recommended: number;
  numberErrors: number;
  status: ResultStatus;
  comment: string;
};

type ResultProps = {
  entries: ResultEntry[];
};

const Results = ({ entries }: ResultProps) => {
  const colors: { [K in ResultStatus]: string } = {
    Excellent: 'green',
    Good: 'green',
    Weak: 'orange',
    Poor: 'red',
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="benchmark results">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Units</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Measured</TableCell>
            <TableCell align="right">Required</TableCell>
            <TableCell align="right">Recommended</TableCell>
            <TableCell align="right">Errors</TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map(
            ({
              name,
              units,
              measuredValue,
              required,
              recommended,
              status,
              numberErrors,
              comment,
            }) => (
              <TableRow key={name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {name}
                </TableCell>
                <TableCell>{units}</TableCell>
                <TableCell align="right">
                  {status === 'Poor' ? (
                    <CrisisAlertIcon sx={{ color: colors[status] }} />
                  ) : (
                    <CheckCircleOutlineIcon sx={{ color: colors[status] }} />
                  )}
                </TableCell>
                <TableCell align="right">{measuredValue}</TableCell>
                <TableCell align="right">{required}</TableCell>
                <TableCell align="right">{recommended}</TableCell>
                <TableCell align="right">{numberErrors}</TableCell>
                <TableCell align="left">{comment}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const Benchmark = () => {
  const [results, setResults] = useState<ResultEntry[]>([]);

  const [todo, setTodo] = useState([
    {
      name: 'Backend Latency',
      component: LatencyBenchmark,
      weight: 20,
      units: 'milliseconds',
      recommended: 300,
      required: 1000,
      getMeasuredValue: (result: LatencyBenchmarkResult) => result.minLatency,
    },
    {
      name: 'Backend Upload',
      component: UploadBenchmark,
      weight: 50,
      units: 'Mbit/sec',
      recommended: 8,
      required: 1,
      getMeasuredValue: (result: BandwidthBenchmarkResult) => result.speedMbitPerSec,
    },
    {
      name: 'Backend Download',
      component: DownloadBenchmark,
      weight: 50,
      units: 'Mbit/sec',
      recommended: 50,
      required: 5,
      getMeasuredValue: (result: BandwidthBenchmarkResult) => result.speedMbitPerSec,
    },
  ]);

  const [finishedPercent, setFinishedPercent] = useState(0);
  const [currentPercent, setCurrentPercent] = useState(0);

  const totalWeight = useRef(todo.reduce((total, { weight }) => total + weight, 0));

  const onFinishBenchmark = useCallback(
    (entry: ResultEntry) => {
      setResults((previousResults) => {
        return [...previousResults, entry];
      });
      setTodo((previousTodo) => {
        const [first, ...rest] = previousTodo;
        const { weight } = first;
        setFinishedPercent((p) => {
          return rest.length > 0 ? p + (weight / totalWeight.current) * 100 : 100;
        });
        return rest;
      });
    },
    [setResults, setTodo, setFinishedPercent, totalWeight]
  );

  const onProgress = useCallback(
    (percent: number) => {
      const [first] = todo;
      const { weight } = first;
      setCurrentPercent((percent * weight) / totalWeight.current + finishedPercent);
    },
    [todo, finishedPercent, setCurrentPercent]
  );

  const currentBenchmarkComp = useMemo(() => {
    if (todo.length === 0) return <></>;
    const {
      name,
      component: ComponentType,
      units,
      recommended,
      required,
      getMeasuredValue,
    } = todo[0];

    const onComplete = (result: LatencyBenchmarkResult | BandwidthBenchmarkResult) => {
      const { numberErrors } = result;
      const measuredValue = getMeasuredValue(
        // getMeasuredValue already associated with the compatible result/component when entries created
        result as LatencyBenchmarkResult & BandwidthBenchmarkResult
      );
      const sign = Math.sign(recommended - required);
      const signedValue = sign * measuredValue;
      const status = (
        sign > 0 ? measuredValue >= 3 * recommended : measuredValue <= 0.333 * recommended
      )
        ? 'Excellent'
        : signedValue >= sign * recommended
        ? 'Good'
        : signedValue >= sign * required
        ? 'Weak'
        : 'Poor';
      const entry: ResultEntry = {
        name,
        units,
        measuredValue,
        required,
        recommended,
        numberErrors,
        status,
        comment: status,
      };
      onFinishBenchmark(entry);
    };

    return <ComponentType onProgress={onProgress} onComplete={onComplete} />;
  }, [todo, onProgress]);

  return (
    <>
      <Box sx={{ width: '38%', padding: '5%', textAlign: 'center' }}>
        <Box>{todo.length > 0 ? `Running: ${todo[0].name}` : 'All Done'}</Box>
        <Box sx={{ paddingTop: '5%', paddingBottom: '5%' }}>
          <LinearProgress variant="determinate" value={currentPercent} />
        </Box>
      </Box>
      <div style={{ width: '62%' }}>{results.length > 0 && <Results entries={results} />}</div>
      {currentBenchmarkComp}
    </>
  );
};
