import styled from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  height: 100%;
  margin: 0;
`;

export const structureNameStyles = {
  background: 'transparent',
  textTransform: 'none',
  flexGrow: 1,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1.2rem',
  pl: 2,
  pt: 1,
  pb: 1,
};
