import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';

import { DockPanel } from '@/components/shared/DockPanel';
import {
  CardContainer,
  Container,
  StyledCollapse,
  TableContainer,
  TableTitleBoxStyle,
  TableTitleContainer,
} from './styles';

import {
  useGetAssemblyTemplateFieldDataQuery,
  useGetAssemblyPipeSpecQuery,
  useGetAssemblyViewpointsQuery,
  useGetAssemblyPointOfInterestsQuery,
  GetAssemblyPointOfInterestsQuery,
} from './data.graphql';
import { RecommendationCard } from './RecommendationCard';
import { PipeSpecCard } from './PipeSpecCard';
import { ProgressBarWithText } from '@/components/shared/ProgressBarWithText';
import * as state from '@/components/Analysis/state';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import {
  Blisters,
  PointOfInterestTable,
  Defects,
} from '@/components/Analysis/modules/pointOfInterest';
import { AssetDetailsGrid } from './AssetDetailsGrid';
import {
  AssemblyPointOfInterestQuery,
  AssemblyTemplateQuery,
  PointOfInterestDocument,
} from '@/types';
import { BackToAssemblyTableButton } from '../AssemblyTable/Asset/BackToAssemblyTableButton';
import { Viewpoints } from '../../AssemblyTabs/Viewpoints';
import {
  isSphericalAnalysedRelatedToAssembly,
  useAssemblySeenByViewpoints,
} from '../../hooks/useAssemblySeenByViewpoints';
import { PIT_TYPE } from '@/constants';

const RecommendationDialogLazy = lazy(() =>
  import('@/components/Analysis/Viewer/Panels/AssemblyTable/Asset/RecommendationDialog').then(
    ({ RecommendationDialog }) => ({
      default: RecommendationDialog,
    })
  )
);

const PipeSpecDialogLazy = lazy(() =>
  import('@/components/Analysis/Viewer/Panels/AssemblyTable/Asset/PipeSpecDialog').then(
    ({ PipeSpecDialog }) => ({
      default: PipeSpecDialog,
    })
  )
);
interface Props {
  assemblyId: string;
  template: AssemblyTemplateQuery;
}

const isPoiABlister = (poi: GetAssemblyPointOfInterestsQuery['allPointOfInterests'][0]) => {
  return !!poi?.blister || poi.type === PIT_TYPE
};

const isPoiDefectClass = (poi: GetAssemblyPointOfInterestsQuery['allPointOfInterests'][0]) => {
  return poi.type === 'DEFECT_CLASS';
};


export const AssetDetails = ({ assemblyId, template }: Props) => {
  const [isAlarmLevelDialogOpen, setIsAlarmLevelDialogOpen] = useRecoilState(
    state.isAlarmLevelDialogOpen
  );
  const [isPipeSpecOpen, setIsPipeSpecOpen] = useState(false);

  const [blisterPolygonVisible, setAreBlisterPolygonsVisible] = useRecoilState(
    state.blisterPolygonVisible
  );
  const [blisterExpanded, setBlisterExpanded] = useState(false);
  const [poiExpanded, setPoiExpanded] = useState(false);
  const [defectsExpanded, setDefectsExpanded] = useState(false);

  const [areDefectClassPoisVisible, setAreDefectClassPoisVisible] = useState(true);

  const [viewPointsExpanded, setViewPointsExpanded] = useState(false);

  const allPOIs = useRecoilValue(poiState.structurePois);
  const assemblySeenByViewpoint = useAssemblySeenByViewpoints(assemblyId);

  const {
    data: viewpointQueryResponse,
    loading: isLoadingViewpoints,
    error: viewpointsError,
  } = useGetAssemblyViewpointsQuery({
    variables: {
      assemblyId,
    },
  });

  const viewpoints = viewpointQueryResponse?.assembly?.seenByViewpoints;

  const analysedViewpointPercentage: number = useMemo(() => {
    if (!Array.isArray(viewpoints) || (Array.isArray(viewpoints) && viewpoints.length === 0)) {
      return 0;
    }
    const numerator = viewpoints.filter((viewpoint) =>
      isSphericalAnalysedRelatedToAssembly({
        locationId: viewpoint.location.id,
        seenByViewpoints: assemblySeenByViewpoint,
      })
    ).length;
    const denominator = viewpoints.length;
    return (numerator / denominator) * 100;
  }, [assemblySeenByViewpoint, viewpoints]);

  const assemblyViewpointsAmount = useMemo(() => {
    return viewpoints?.length;
  }, [viewpoints]);

  const { data: pointOfInterestsQueryResult, loading: isLoadingPOI } =
    useGetAssemblyPointOfInterestsQuery({
      variables: {
        assemblyIds: [assemblyId],
      },
      fetchPolicy: 'no-cache',
    });

  const { data: getAssemblyFieldTemplateData, refetch } = useGetAssemblyTemplateFieldDataQuery({
    variables: {
      assemblyId,
    },
    fetchPolicy: 'no-cache',
  });

  const templateFieldData = useMemo(() => {
    return getAssemblyFieldTemplateData?.assembly?.templateFieldData;
  }, [getAssemblyFieldTemplateData?.assembly?.templateFieldData]);

  const [blisterPois, setBlisterPois] = useState<AssemblyPointOfInterestQuery>([]);
  const [defectClassPois, setDefectClassPois] = useState<AssemblyPointOfInterestQuery>([]);
  const [pointOfInterests, setPointOfInterests] = useState<AssemblyPointOfInterestQuery>([]);

  useEffect(() => {
    const pointOfInterestsData = pointOfInterestsQueryResult?.allPointOfInterests || [];
    const blisters = pointOfInterestsData.filter(isPoiABlister);
    setBlisterPois(blisters);
    const filteredPoi = pointOfInterestsData.filter(
      (poi) => !isPoiABlister(poi) && !isPoiDefectClass(poi)
    );
    setPointOfInterests(filteredPoi);
    setDefectClassPois(pointOfInterestsData.filter(isPoiDefectClass));
  }, [pointOfInterestsQueryResult]);

  // refetch the templateFieldData if number of pois changes
  useEffect(() => {
    refetch();
  }, [allPOIs, refetch]);

  const {
    data: pipeSpecByAssemblyId,
    loading: isLoadingPipeSpec,
    error: pipeSpecByAssemblyError,
  } = useGetAssemblyPipeSpecQuery({
    variables: {
      assemblyId,
    },
  });

  const openRecommendationDialog = useCallback(() => {
    setIsAlarmLevelDialogOpen(true);
  }, [setIsAlarmLevelDialogOpen]);

  const closeRecommendationDialog = useCallback(() => {
    setIsAlarmLevelDialogOpen(false);
  }, [setIsAlarmLevelDialogOpen]);

  const openPipeSpec = useCallback(() => {
    setIsPipeSpecOpen(true);
  }, []);

  const closePipeSpec = useCallback(() => {
    setIsPipeSpecOpen(false);
  }, []);

  const toggleBlisterExpanded = useCallback(() => {
    setBlisterExpanded(!blisterExpanded);
  }, [blisterExpanded]);

  const toggleDefectsExpanded = useCallback(() => {
    setDefectsExpanded(!defectsExpanded);
  }, [defectsExpanded]);

  const togglePoiExpanded = useCallback(() => {
    setPoiExpanded(!poiExpanded);
  }, [poiExpanded]);

  const toggleViewPointsExpanded = useCallback(() => {
    setViewPointsExpanded(!viewPointsExpanded);
  }, [viewPointsExpanded]);

  const handleBlisterCheckboxToggle = useCallback(() => {
    setAreBlisterPolygonsVisible((current) => !current);
  }, [setAreBlisterPolygonsVisible]);

  const handleDefectClassCheckboxToggle = useCallback(() => {
    setAreDefectClassPoisVisible((current) => !current);
  }, [setAreDefectClassPoisVisible]);

  const blistersCheckbox = useMemo(
    () => (
      <Checkbox
        data-testid="toggle-blister"
        checked={blisterPolygonVisible}
        onChange={handleBlisterCheckboxToggle}
        onClick={(event) => event.stopPropagation()}
      />
    ),
    [blisterPolygonVisible, handleBlisterCheckboxToggle]
  );

  const defectClassCheckbox = useMemo(
    () => (
      <Checkbox
        data-testid="toggle-defect-class"
        checked={areDefectClassPoisVisible}
        onChange={handleDefectClassCheckboxToggle}
        onClick={(event) => event.stopPropagation()}
      />
    ),
    [areDefectClassPoisVisible, handleDefectClassCheckboxToggle]
  );

  if (pipeSpecByAssemblyError || viewpointsError) {
    return (
      <DockPanel>
        <Container>
          <div>Error</div>
        </Container>
      </DockPanel>
    );
  }

  return (
    <DockPanel>
      <BackToAssemblyTableButton />
      <Container>
        {templateFieldData && template?.recommendation && (
          <Box sx={{ width: '100%' }}>
            <RecommendationCard
              template={template}
              templateFieldData={templateFieldData}
              handleAlarmLevelClicked={openRecommendationDialog}
            />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <PipeSpecCard
            data={pipeSpecByAssemblyId}
            isLoading={isLoadingPipeSpec}
            handlePipeSpecClicked={openPipeSpec}
          />
        </Box>
      </Container>
      <TableContainer>
        {isLoadingViewpoints && isLoadingPOI ? (
          <CardContainer>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </CardContainer>
        ) : (
          <>
            <TableTitleContainer>
              <Box sx={TableTitleBoxStyle} onClick={toggleBlisterExpanded}>
                <AssetDetailsGrid
                  customComponent={blistersCheckbox}
                  numberFound={blisterPois.length}
                  title="Blisters nominated"
                  expanded={blisterExpanded}
                />
              </Box>
              <StyledCollapse in={blisterExpanded}>
                <Blisters blisterPoi={blisterPois as PointOfInterestDocument[]} />
              </StyledCollapse>
            </TableTitleContainer>

            <TableTitleContainer>
              <Box sx={TableTitleBoxStyle} onClick={toggleDefectsExpanded}>
                <AssetDetailsGrid
                  customComponent={defectClassCheckbox}
                  numberFound={defectClassPois.length}
                  title="Defect nominated"
                  expanded={defectsExpanded}
                />
              </Box>
              <StyledCollapse in={defectsExpanded}>
                <Defects defectClassPois={defectClassPois as PointOfInterestDocument[]} />
              </StyledCollapse>
            </TableTitleContainer>

            <TableTitleContainer>
              <Box sx={TableTitleBoxStyle} onClick={togglePoiExpanded}>
                <AssetDetailsGrid
                  numberFound={pointOfInterests.length}
                  title=" Points of interest nominated"
                  expanded={poiExpanded}
                />
              </Box>
              <StyledCollapse in={poiExpanded}>
                <PointOfInterestTable
                  pointOfInterests={pointOfInterests as PointOfInterestDocument[]}
                />
              </StyledCollapse>
            </TableTitleContainer>

            <TableTitleContainer>
              <Box sx={TableTitleBoxStyle} onClick={toggleViewPointsExpanded}>
                <AssetDetailsGrid
                  numberFound={assemblyViewpointsAmount}
                  title="Viewpoints associated"
                  expanded={viewPointsExpanded}
                />
              </Box>
              <StyledCollapse in={viewPointsExpanded}>
                <ProgressBarWithText
                  color="secondary"
                  value={analysedViewpointPercentage}
                  text="of the Viewpoints analyzed"
                />
                <Viewpoints />
              </StyledCollapse>
            </TableTitleContainer>
          </>
        )}
      </TableContainer>

      {isAlarmLevelDialogOpen && templateFieldData && template?.recommendation && (
        <Suspense>
          <RecommendationDialogLazy
            isOpen={isAlarmLevelDialogOpen}
            assemblyId={assemblyId}
            template={template}
            templateFieldData={templateFieldData}
            onClose={closeRecommendationDialog}
          />
        </Suspense>
      )}

      <PipeSpecDialogLazy
        data={pipeSpecByAssemblyId}
        isOpen={isPipeSpecOpen}
        onClose={closePipeSpec}
      />
    </DockPanel>
  );
};
