import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import * as state from '@/components/Analysis/state';
import { CreateReportModal } from './CreateReportModal';
import { primary } from '@/theme/colors';
import { PointOfInterestGroupBy, GroupByResult } from '@/types';
import { modalStyles } from '../styles';

export const AddToReportButton = ({
  groupedPois,
}: {
  groupedPois: GroupByResult<PointOfInterestGroupBy>;
}) => {
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const allTemplates = useRecoilValue(state.allPointOfInterestTemplates);

  const handleOpen = () => setCreateReportModalOpen(true);
  const handleClose = () => setCreateReportModalOpen(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        bgcolor: 'white',
      }}
    >
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          maxHeight: '44px',
          width: '100%',
          backgroundColor: primary.darkBlue,
          borderRadius: '2px',
          textTransform: 'none',
          fontSize: '1.5rem',
          '@media (max-width: 1728px)': {
            height: '32px',
          },
        }}
      >
        <CreateNewFolderIcon sx={{ mr: 2 }} />
        Add to Report
      </Button>
      <CreateReportModal
        title="Select relevant points of interest and create your report"
        sx={modalStyles}
        open={createReportModalOpen}
        pointOfInterestTemplates={allTemplates}
        groupedPointOfInterests={groupedPois}
        handleClose={handleClose}
      />
    </Box>
  );
};
