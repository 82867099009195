import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { EquipmentDetailsTypography } from '../styles';
import { calculatePercentage, toFixedTwoDecimalPoints } from '@/utils';
import * as state from '@/components/Analysis/state';
import { localiseAreaMeasurement } from '@/utils/unitSystem';
import { EquipmentDetailSubTypeEnum, UnitSystemEnum } from '../data.graphql';

type Props = {
  title: string;
  valueInt: string | number;
  area: number | string;
  subType?: string;
};

interface CorrosionColors {
  [key: string]: string;
}

const boxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  my: 0.2,
};

const corrosionLevelColor: CorrosionColors = {
  Clean: '#D4D4D4',
  Light: '#EAB308',
  Moderate: '#FF5D60',
  Heavy: '#AA0003',
};

export const EquipmentCondition = ({ title, valueInt, area, subType }: Props) => {
  const value = toFixedTwoDecimalPoints(valueInt.toString()).toString();
  const assemblyRatio: string = useMemo(() => {
    const precentageOrNan = calculatePercentage(Number(area), Number.parseFloat(value));
    const percentage = Number.isNaN(precentageOrNan) ? '0.00' : `${precentageOrNan}%`;
    return percentage;
  }, [area, value]);

  const unitSystem = useRecoilValue(state.unitSystem);
  const unit = unitSystem === UnitSystemEnum.Imperial ? '(ft²)' : '(m²)';

  return (
    <Box sx={boxStyles}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {subType === EquipmentDetailSubTypeEnum.DefectCorrosion && (
          <ArrowUpwardRoundedIcon
            style={{ fontSize: '1.6rem', marginRight: '2px', color: corrosionLevelColor[title] }}
          />
        )}
        <EquipmentDetailsTypography>{title}</EquipmentDetailsTypography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EquipmentDetailsTypography sx={{ marginRight: 1 }}>
          {Number.isNaN(value)
            ? `${localiseAreaMeasurement(unitSystem, Number(value))} ${unit}`
            : `${localiseAreaMeasurement(unitSystem, Number(valueInt))} ${unit}`}
        </EquipmentDetailsTypography>
        -
        <EquipmentDetailsTypography sx={{ marginLeft: 1, marginRight: 2 }}>
          {assemblyRatio}
        </EquipmentDetailsTypography>
      </Box>
    </Box>
  );
};
