import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@mui/material/styles';
import { UserProvider } from '@auth0/nextjs-auth0';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { GlobalStyle, muiTheme } from '@/theme';
import { AuthGuard, ConfiguredApolloClient } from '@/components';
import AbilityProvider from '@/components/Ability';
import { getScale } from '@/utils';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  const navbarHeight = useNavbarHeight();
  return (
    <UserProvider>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RecoilRoot>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <title>Abyss Fabric Web</title>
              <link rel="icon" href="/favicon.ico" />
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
              />
              {!!process.env.NEXT_PUBLIC_PENDO_PUBLIC_KEY && (
                <script
                  src={`https://cdn.pendo.io/agent/static/${process.env.NEXT_PUBLIC_PENDO_PUBLIC_KEY}/pendo.js`}
                />
              )}
            </Head>
            <GlobalStyle scale={getScale()} navbarHeight={navbarHeight} />
            <ConfiguredApolloClient>
              <AuthGuard>
                <AbilityProvider>
                  <Component {...pageProps} />
                </AbilityProvider>
              </AuthGuard>
            </ConfiguredApolloClient>
          </RecoilRoot>
        </LocalizationProvider>
      </ThemeProvider>
    </UserProvider>
  );
};

export default dynamic(() => Promise.resolve(App), { ssr: false });
