import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { CSSProperties, useMemo } from 'react';
import { useRouter } from 'next/router';

import { useRecoilValue } from 'recoil';
import { useGetStructureNameQuery } from './data.graphql';
import { Container } from './styles';
import { titleCaseSentence } from '@/utils';
import { NextLinkComposed } from '../NextLinkComposed';
import * as state from '@/state';
import { ZoneStatusChanger } from '../../ZonesMangement/ZoneStatusChanger';
import { useFeatureFlag } from '@/hooks';

type Props = {
  inspectionId?: string;
  assetName?: string;
};

export const Breadcrumb = ({ inspectionId, assetName }: Props) => {
  const selectedStructureId = useRecoilValue(state.selectedStructureId);
  const recoilStructureName = useRecoilValue(state.structureNameByStructureId(inspectionId));
  const selectedZone = useRecoilValue(state.selectedZone);

  // gets every path name after inspection/from the router and formats it for the breadcrumb.
  // eg. /inspection/[inspection]/stage-overview/analysis => ['[inspection]', 'Stage Overview', 'Analysis']
  const router = useRouter();
  const isAtInspectionPage = router.pathname === '/inspection/[inspection]';
  const isBetaUser = useFeatureFlag('beta-user');
  const paths = router.pathname.split('/'); // the split adds an '' to the beginning of the array (paths[0] === '')
  paths.splice(0, 2);
  const trailingPaths = paths.map((path) => {
    return titleCaseSentence(path.replace('-', ' '));
  });
  const lastTrailingPath = trailingPaths.pop();

  const structureId = inspectionId || selectedStructureId || '';
  const { data } = useGetStructureNameQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
    skip: !!recoilStructureName || !structureId,
  });

  const queryStructureName = useMemo(() => {
    if (data?.structure?.name) {
      return data.structure.name;
    }
    return '';
  }, [data?.structure?.name]);

  const lastPath = useMemo(() => {
    if (lastTrailingPath === 'Asset') {
      return assetName;
    }
    if (lastTrailingPath === '[Inspection]') {
      return queryStructureName || recoilStructureName;
    }
    return lastTrailingPath;
  }, [assetName, queryStructureName, lastTrailingPath, recoilStructureName]);

  const containerStyles: CSSProperties = {
    display: 'flex',
    paddingLeft: '2rem',
    paddingTop: '1rem',
  };

  const additionalIconsContainerStyles: CSSProperties = {
    marginLeft: 'auto',
    marginRight: '4%',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <Container style={containerStyles}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={NextLinkComposed} to="/">
          <Typography variant="body1">Platforms</Typography>
        </Link>
        {trailingPaths &&
          trailingPaths.map((trailPath, index) => {
            let path = `/inspection/`;
            // TODO: need to investigate why +1 not working here
            // eslint-disable-next-line no-plusplus
            for (let indexCount = 0; indexCount <= index; indexCount++) {
              const currentPath = paths[indexCount];
              path += `${currentPath}/`;
            }
            return (
              <Link
                key={trailPath}
                component={NextLinkComposed}
                underline="hover"
                color="inherit"
                to={{
                  pathname: path,
                  query: { inspection: inspectionId },
                }}
              >
                <Typography variant="body1">
                  {trailPath === '[inspection]'
                    ? queryStructureName || recoilStructureName
                    : trailPath}
                </Typography>
              </Link>
            );
          })}
        {/* Only add link for beta users */}
        {isBetaUser
          ? lastPath && (
              <Link href={`/inspection/${inspectionId}/tasks`} underline="hover" color="inherit">
                <Typography color="text.primary" variant="body1">
                  {lastPath}
                </Typography>
              </Link>
            )
          : lastPath && (
              <Typography color="text.primary" variant="body1">
                {lastPath}
              </Typography>
            )}
        {selectedZone && isAtInspectionPage && (
          <Typography color="text.primary" variant="body1">
            {selectedZone?.name}
          </Typography>
        )}
      </Breadcrumbs>
      <div style={additionalIconsContainerStyles}>
        {isAtInspectionPage && isBetaUser && <ZoneStatusChanger status={selectedZone?.status} />}
      </div>
    </Container>
  );
};
