import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { ViewpointsAccordianStyles } from './styles';
import { Viewpoints } from '@/components/Analysis/Viewer/Panels';

export const ViewpointAccordion = ({ expanded }: { expanded?: boolean }) => {
  const [viewpointTotal, setViewpointTotal] = useState<number | undefined>();
  const [isExpanded, setIsExpanded] = useState(!!expanded);
  const handleSetViewpointTotal = useCallback(
    (total: number) => {
      setViewpointTotal(total);
    },
    [setViewpointTotal]
  );

  const onAccordionChange = useCallback((event: React.SyntheticEvent, _expanded: boolean) => {
    setIsExpanded(_expanded);
  }, []);
  return (
    <Box sx={{ p: 2, mt: 1 }}>
      <Accordion
        data-testid="viewpoint-accordion"
        onChange={onAccordionChange}
        elevation={0}
        disableGutters
        sx={{
          border: 'none',
          width: '100%',
          p: 0,
        }}
      >
        <AccordionSummary expandIcon={<ArrowDropDown />} sx={{ px: 1 }}>
          <Box sx={ViewpointsAccordianStyles}>
            <Typography fontSize="1.4rem">Viewpoints associated</Typography>
            <Typography fontSize="1.6rem" fontWeight={600}>
              {viewpointTotal}
              {viewpointTotal === undefined && isExpanded && (
                <>
                  <CircularProgress size={20} />
                </>
              )}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isExpanded && (
            <Box sx={{ overflowY: 'auto', maxHeight: '200px', width: '100%' }}>
              <Viewpoints
                setViewpointTotal={handleSetViewpointTotal}
                viewpointTotal={viewpointTotal}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
