import { Box, Skeleton } from '@mui/material';

export const EquipmentTabSkeleton = () => {
  const viewHeight = window.innerHeight - 320;

  // calculate how many rows of equipment cards can fit in the viewport
  const numberOfRows = Math.floor(viewHeight / 90);

  const CardSkeletons = () => (
    <>
      {Array.from({ length: numberOfRows }, (_, index) => index + 1).map((index) => (
        <Skeleton key={index} variant="rectangular" width="100%" height={90} sx={{ mb: '3%' }} />
      ))}
    </>
  );

  return (
    <Box
      data-testid="equipment-tab-skeleton"
      sx={{
        p: '3%',
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: '3%' }} />
      <CardSkeletons />
    </Box>
  );
};
