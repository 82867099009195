import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { GenericModal } from '@/components/shared/GenericModal';
import { AddToReport } from '@/components/shared/ReportsRevamped/AddToReport';
import { PoiAccordion } from './PoiAccordion';
import { rootStyles, scrolContainerStyles } from './styles';
import {
  GenericModalProps,
  GroupByResult,
  PointOfInterestGroupBy,
  AllPointOfInterestTemplate,
} from '@/types';

type CreateReportModalProps = GenericModalProps & {
  pointOfInterestTemplates: AllPointOfInterestTemplate;
  groupedPointOfInterests: GroupByResult<PointOfInterestGroupBy>;
};

export const CreateReportModal = ({
  sx,
  open,
  title,
  pointOfInterestTemplates,
  groupedPointOfInterests,
  handleClose,
}: CreateReportModalProps) => {
  const [selectedPointOfInterests, setSelectedPointOfInterests] = useState<
    Array<{
      id: string;
      name: string;
    }>
  >([]);

  const [unfinishedSelectedPointOfInterests, setUnfinishedSelectedPointOfInterests] = useState<
    Array<{
      id: string;
      name: string;
    }>
  >([]);

  useEffect(() => {
    const uniquePointOfInterests = unfinishedSelectedPointOfInterests.filter(
      (poi, index, self) => index === self.findIndex((element) => element.id === poi.id)
    );
    setSelectedPointOfInterests(uniquePointOfInterests);
  }, [unfinishedSelectedPointOfInterests]);

  const reportSummary = useMemo(() => {
    const reportSummaryState: {
      [key: string]: {
        name: string;
        amount: number;
      };
    } = {};

    // initialization of the report summary
    pointOfInterestTemplates?.forEach((template) => {
      if (template) {
        const key: string = template.name;
        const amount = groupedPointOfInterests[template.id]?.length || 0;
        reportSummaryState[key] = {
          name: key,
          amount,
        };
      }
    });

    // Todo: Need a refactor grouping of point of interests
    // Suggestion: updated the var name amount and name something like poiCountInAssembly and currentAssemblyName
    Object.keys(groupedPointOfInterests).forEach((groupedPoiKey) => {
      let amount = 0;
      groupedPointOfInterests[groupedPoiKey].forEach((poi) => {
        if (poi.templateId) {
          amount += selectedPointOfInterests.filter((selectedPoi) => {
            return selectedPoi.id === poi.id;
          }).length;

          const name =
            pointOfInterestTemplates.find((template) => template?.id === poi.templateId)?.name ||
            '';

          if (name === '') return;
          reportSummaryState[name] = {
            name,
            amount,
          };
        }
      });
    });

    return Object.keys(reportSummaryState).map((key) => {
      return reportSummaryState[key];
    });
  }, [selectedPointOfInterests, pointOfInterestTemplates, groupedPointOfInterests]);

  const selectedPointOfInterestIds = useMemo(() => {
    return selectedPointOfInterests.map((poi) => poi.id);
  }, [selectedPointOfInterests]);

  return (
    <GenericModal
      open={open}
      handleClose={handleClose}
      title={title}
      sx={sx}
      headerStyleProp={{ mb: 3 }}
    >
      <Box sx={rootStyles}>
        <Box width="100%" sx={scrolContainerStyles}>
          <Typography variant="h6">List of all points of interest</Typography>
          {pointOfInterestTemplates.map((template) => {
            return (
              <>
                {template && (
                  <PoiAccordion
                    summary={template.name}
                    key={template.id}
                    numericIndicator={groupedPointOfInterests[template.id]?.length || 0}
                    groupedPOI={groupedPointOfInterests[template.id]}
                    setSelectedPointOfInterests={setUnfinishedSelectedPointOfInterests}
                    selectedPointOfInterests={selectedPointOfInterests}
                  />
                )}
              </>
            );
          })}
        </Box>
        <Divider orientation="vertical" color="#ddd" sx={{ height: 'auto' }} />
        <AddToReport
          handleClose={handleClose}
          reportSummary={reportSummary}
          selectedPointOfInterestIds={selectedPointOfInterestIds}
        />
      </Box>
    </GenericModal>
  );
};
