import { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Slider, { SliderProps } from '@mui/material/Slider';
import { useRecoilState } from 'recoil';
import * as state from '@/state';

type PointSizeSliderProps = {
  title: string;
} & SliderProps;

const valuetext = (value: number) => {
  return `${value}`;
};

export const PointSizeSlider = ({ title, ...props }: PointSizeSliderProps) => {
  const [pointSize, setPointSize] = useRecoilState<number>(state.pointSize);
  const [unfinishedPointSize, setUnfinishedPointSize] = useState(pointSize);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setPointSize(unfinishedPointSize);
    }, 500);

    return () => clearTimeout(debounce);
  }, [setPointSize, unfinishedPointSize]);

  const handlePointSizeChanged = (event: Event, value: number | number[]) => {
    setUnfinishedPointSize(value as number);
  };

  return (
    <div>
      <FormLabel>{title}</FormLabel>
      <Slider
        {...props}
        aria-label="PointSize"
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        value={pointSize}
        onChange={handlePointSizeChanged}
        size="small"
      />
    </div>
  );
};
