import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { VisibilityBoxMode, VisibilityBoxProps } from '@abyss/3d-viewer';
import * as state from '@/components/Analysis/state';
import { DEFAULT_VISIBILITY_BOX } from '@/constants';
import { GetAssembliesByTemplateFieldsFiltersQuery } from '../../shared/EquipmentsTab/data.graphql';

type Assemblies =
  | GetAssembliesByTemplateFieldsFiltersQuery['getAssembliesByTemplateFieldsFilters']
  | undefined;

export const useAssemblyVisibilityBox = (assemblies: Assemblies) => {
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const setCurrentVisibilityBox = useSetRecoilState(state.currentVisibilityBox);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);

  useEffect(() => {
    if (!selectedSpherical) {
      const assemblyBox =
        selectedAssemblyId && assemblies
          ? assemblies.find((item) => item.id === selectedAssemblyId)?.box
          : undefined;

      if (assemblyBox) {
        const vboxFromAssembly: VisibilityBoxProps = {
          min: [assemblyBox.min.x, assemblyBox.min.y, assemblyBox.min.z],
          max: [assemblyBox.max.x, assemblyBox.max.y, assemblyBox.max.z],
          mode: VisibilityBoxMode.Enabled,
          step: DEFAULT_VISIBILITY_BOX.step,
        };
        setCurrentVisibilityBox(vboxFromAssembly);
        return;
      }
    }
    setCurrentVisibilityBox(DEFAULT_VISIBILITY_BOX);
  }, [assemblies, selectedAssemblyId, setCurrentVisibilityBox, selectedSpherical]);
};
