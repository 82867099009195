import React, { Fragment, useState, useRef, useEffect, useMemo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
  Alert,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Box,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useSetRecoilState } from 'recoil';
import { format, formatDistanceToNow } from 'date-fns';
import { Delete, MoreVert } from '@mui/icons-material';
import { useUser } from '@auth0/nextjs-auth0';
import * as state from '@/state';
import {
  Comment,
  useAllCommentsQuery,
  useCommentAddedSubscription,
  useCreateCommentMutation,
  useCommentDeletedSubscription,
  useDeleteCommentMutation,
} from './data.graphql';
import {
  CommentsListContainer,
  commentsListStyle,
  commentTextStyles,
  NoCommentsText,
  CommentIcon,
} from './styles';
import { WidgetIconButton } from '../Widgets/styles';
import { CommentsLoader } from './CommentsLoader';
import { useGetStructureId } from '@/hooks/useGetStructureId';

type CommentTitleProps = {
  comment: Comment;
};

type CommentsProps = {
  collectionName: string;
  referenceId: string;
};

const CommentTitle = ({ comment }: CommentTitleProps) => {
  const updatedAtDate = new Date(Number.parseInt(comment.updatedAt, 10));
  return (
    <Stack direction="row" sx={{ mt: '6px' }}>
      <Typography
        variant="subtitle2"
        color="text.primary"
        sx={{ fontSize: '1.7rem', marginLeft: '-20px', justifyContent: 'space-between' }}
      >
        <span>{comment.createdByDetails?.name}</span>
        <Typography variant="caption" color="text.primary" sx={{ mt: '4px' }}>
          <span style={{ margin: '0 2px' }}>.</span>
          <Tooltip title={format(updatedAtDate, 'LLL do, yyyy hh:mm b')}>
            <span>{formatDistanceToNow(updatedAtDate)}</span>
          </Tooltip>
          {comment.status && (
            <>
              <span style={{ margin: '0 2px' }}>.</span>
              <span>in review</span>
            </>
          )}
        </Typography>
      </Typography>
    </Stack>
  );
};

export const Comments = ({ collectionName, referenceId }: CommentsProps) => {
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const [newCommentValue, setNewCommentValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const commentListStart = useRef<HTMLDivElement>(null);
  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [comments, setComments] = useState<Comment[]>([]);
  const deletingId = useRef<string | undefined>();
  const [commentMenuAnchor, setCommentMenuAnchor] = React.useState<null | HTMLElement>();
  const structureId = useGetStructureId();
  const { user } = useUser();

  const { data, refetch, loading } = useAllCommentsQuery({
    variables: {
      input: { structureId, referenceId, collectionName },
    },
  });

  const scrollToBottom = () => {
    if (commentListStart.current) {
      commentListStart.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (data?.allComments) {
      setComments((data.allComments || []) as Comment[]);
      scrollToBottom();
    }
  }, [data]);

  useEffect(() => {
    refetch({
      input: { structureId, referenceId, collectionName },
    });
  }, [refetch, referenceId, structureId]);

  const { data: addedComment } = useCommentAddedSubscription({
    variables: {
      structureId,
      referenceId,
    },
  });

  const { data: deletedComment } = useCommentDeletedSubscription({
    variables: {
      structureId,
      referenceId,
    },
  });

  useEffect(() => {
    if (addedComment?.commentAdded) {
      setComments(
        (previousComments) => [addedComment.commentAdded, ...previousComments] as Comment[]
      );
    }
  }, [addedComment]);

  useEffect(() => {
    if (deletedComment?.commentDeleted) {
      setComments((previousComments) => {
        const deletedCommentIndex = previousComments.findIndex(
          (comment) => comment.id === deletedComment?.commentDeleted?.id
        );
        if (deletedCommentIndex !== -1) {
          previousComments.splice(deletedCommentIndex, 1);
        }
        return [...previousComments];
      });
    }
  }, [deletedComment]);

  const postComment = async () => {
    setSubmitting(true);
    try {
      await createComment({
        variables: {
          input: {
            structureId,
            collectionName,
            referenceId,
            text: newCommentValue,
          },
        },
      });
      setNewCommentValue('');
      scrollToBottom();
      setSubmitting(false);
    } catch {
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="error">Unable to add comment</Alert>,
      });
      setSubmitting(false);
    }
  };

  const handleNewCommentValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCommentValue(event.target.value);
  };

  const handleCommentMenuOpen = (commentId: string) => (event: React.MouseEvent<HTMLElement>) => {
    deletingId.current = commentId;
    setCommentMenuAnchor(event.currentTarget);
  };

  const handleCommentMenuClose = () => {
    deletingId.current = undefined;
    setCommentMenuAnchor(undefined);
  };

  const handleDeleteComment = async () => {
    deleteComment({
      variables: {
        commentId: deletingId.current,
        structureId,
      },
    });
    handleCommentMenuClose();
  };

  const CommentListItems = useMemo(() => {
    return comments.map((comment) => (
      <Fragment key={comment.id}>
        <ListItem
          alignItems="flex-start"
          style={{ paddingBottom: 0, marginBottom: 10 }}
          data-testid="comment-items"
        >
          <ListItemAvatar>
            <Avatar
              sx={{ height: 30, width: 30 }}
              alt={comment.createdByDetails?.name}
              src={comment.createdByDetails?.profilePicture}
            />
          </ListItemAvatar>
          <ListItemText primary={<CommentTitle comment={comment} />} />
          <IconButton size="small" sx={{ mt: 0.5 }} onClick={handleCommentMenuOpen(comment.id)}>
            <MoreVert fontSize="small" />
          </IconButton>
        </ListItem>
        <Typography variant="body2" color="text.primary" sx={commentTextStyles}>
          {comment.text}
        </Typography>
        <Divider />
      </Fragment>
    ));
  }, [comments]);

  const CommentsList = useMemo(() => {
    if (loading) {
      return <CommentsLoader />;
    }
    if (comments.length === 0) {
      return (
        <CommentsListContainer>
          <Box>
            <CommentIcon />
            <NoCommentsText variant="body1" fontWeight={400}>
              Currently, no comments have been posted.
            </NoCommentsText>
          </Box>
        </CommentsListContainer>
      );
    }
    return (
      <List sx={commentsListStyle}>
        <div ref={commentListStart} />
        {CommentListItems}
      </List>
    );
  }, [loading, comments.length, CommentListItems]);

  return (
    <Stack
      direction="column"
      sx={{ p: 1, height: 1 }}
      alignItems="center"
      test-id="comments-container"
    >
      {CommentsList}
      <Menu
        anchorEl={commentMenuAnchor}
        open={Boolean(commentMenuAnchor)}
        onClose={handleCommentMenuClose}
      >
        <MenuItem onClick={handleDeleteComment}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography noWrap>Delete</Typography>
        </MenuItem>
      </Menu>
      <Stack direction="row" width="100%">
        <Avatar
          sx={{ height: 30, width: 30, mt: 0.5, mr: 1 }}
          alt={user?.name || 'User'}
          src={user?.picture || ''}
        />
        <TextField
          fullWidth
          value={newCommentValue}
          onChange={handleNewCommentValueChange}
          placeholder="Add a comment..."
          multiline
          maxRows={8}
          size="small"
          InputProps={{
            sx: { fontSize: '1.2rem', borderRadius: 3 },
          }}
        />
        <WidgetIconButton
          sx={{ ml: 0.5, pt: 0.5 }}
          onClick={postComment}
          color="success"
          disabled={newCommentValue.length === 0 || submitting}
          disableRipple
        >
          {submitting ? <CircularProgress size={20} /> : <SendIcon />}
        </WidgetIconButton>
      </Stack>
    </Stack>
  );
};
