import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VisibilityBoxMode } from '@abyss/3d-viewer';
import * as state from '@/state';

export const useNormalVisibilityRangeEffect = () => {
  const normalVisibilityRange = useRecoilValue(state.normalVisibilityRange);
  const selectedZone = useRecoilValue(state.selectedZone);
  const [currentVisibilityBox, setCurrentVisibilityBox] = useRecoilState(
    state.currentVisibilityBox
  );

  useEffect(() => {
    switch (normalVisibilityRange) {
      case 'ZoneOnly':
        if (selectedZone) {
          setCurrentVisibilityBox({
            min: [selectedZone.box.min.x, selectedZone.box.min.y, selectedZone.box.min.z],
            max: [selectedZone.box.max.x, selectedZone.box.max.y, selectedZone.box.max.z],
            mode: VisibilityBoxMode.Enabled,
            step: [0.25, 0.25, 0.1],
          });
        } else {
          setCurrentVisibilityBox({
            ...currentVisibilityBox,
            mode: VisibilityBoxMode.Enabled,
          });
        }
        break;

      case 'All':
        setCurrentVisibilityBox({
          ...currentVisibilityBox,
          mode: VisibilityBoxMode.Disabled,
        });
        break;

      case 'Custom':
        setCurrentVisibilityBox({
          ...currentVisibilityBox,
          mode: VisibilityBoxMode.Adjusting,
        });

        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone, normalVisibilityRange]);
};
