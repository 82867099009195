import { Stack } from '@mui/material';
import { useUser } from '@auth0/nextjs-auth0';
import { UserAvatar } from '@/components/shared/UserAvatar';

export const Body = () => {
  const { user } = useUser();
  return (
    <Stack direction="row" justifyContent="center">
      <UserAvatar name={user?.name} picture={user?.picture} sx={{ width: 100, height: 100 }} />
    </Stack>
  );
};
