import { ReactElement } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Container, IconContainer } from './styles';
import { primary } from '@/theme/colors';

type Props = {
  onClick?: () => void;
  title?: string | null;
  subtitle?: string | null;
  icon?: ReactElement;
};

/**
 * Small card to display a summary of aspects of an asset
 */
export const SummaryCard = ({ title, subtitle, icon, onClick }: Props) => {
  const card = (
    <Paper
      elevation={1}
      sx={{
        color: (theme) => theme.palette.primary.contrastText,
        backgroundColor: primary.darkBlue,
        width: '100%',
        marginBottom: 2,
      }}
    >
      <Container>
        <div>
          <Typography variant="body1" fontWeight={700} lineHeight="18px" textTransform="uppercase">
            {title}
          </Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </div>
        {icon && <IconContainer>{icon}</IconContainer>}
      </Container>
    </Paper>
  );

  return onClick ? (
    <ButtonBase sx={{ width: '100%' }} onClick={onClick}>
      {card}
    </ButtonBase>
  ) : (
    <>{card}</>
  );
};
