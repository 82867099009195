import React, { useCallback, useMemo, KeyboardEvent, useEffect } from 'react';
import {
  AbyssViewerNoContext as AbyssViewerComponent,
  PointCloudMaterialProps,
  PointPickResult,
  SphericalImageProps,
  SegmentProps,
  useAbyssViewerContext,
} from '@abyss/3d-viewer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuBar } from './MenuBar';

import * as state from '@/state';
import { useViewerPerformanceAlert } from '@/hooks/useViewerPerformanceAlert';

type Props = {
  octreePointCloudsJsonPath: {
    path: string;
    name?: string | string[];
    contents: {
      superVoxel?: boolean;
      color?: boolean;
    }[];
  }[];
  onPointSelect?: (pickResult: PointPickResult) => void;
  onPointCloudSelection?: (pointPickResults: PointPickResult[]) => void;
  pointCloudMaterials: Map<string, PointCloudMaterialProps>;
  sphericalImages: SphericalImageProps[];
  selectionColors: { shiftKeyColor: number; altKeyColor: number };
  segments: SegmentProps[];
} & JSX.IntrinsicAttributes;

export const AbyssViewer = ({
  octreePointCloudsJsonPath,
  onPointSelect,
  onPointCloudSelection,
  pointCloudMaterials,
  sphericalImages,
  selectionColors,
  segments,
}: Props) => {
  const { callbacks } = useAbyssViewerContext();

  const [currentVisibilityBox, setCurrentVisibilityBox] = useRecoilState(
    state.currentVisibilityBox
  );
  const [cameraTarget, setCameraTarget] = useRecoilState(state.cameraTarget);
  const edlStrength = useRecoilValue(state.edlStrength);
  const areSphericalsVisible = useRecoilValue(state.areSphericalsVisible);
  const [viewerCurrentSpherical, setViewerCurrentSpherical] = useRecoilState(
    state.viewerCurrentSpherical
  );
  const [viewerNextCurrentSpherical, setViewerNextCurrentSpherical] = useRecoilState(
    state.viewerNextCurrentSpherical
  );
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const [{ mode: currentAbyssViewerMode }, setAbyssViewerState] = useRecoilState(
    state.setAbyssViewerState
  );
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);

  // Whenever user clicks on a spherical this function is called
  // Depending upon the abyssViewerMode it set or reset the mode.
  useEffect(() => {
    if (!selectedSpherical && currentAbyssViewerMode !== 'VoxelSelector') {
      // Exiting image viewpoint
      setAbyssViewerState({ mode: 'Normal' });
    }
  }, [selectedSpherical, currentAbyssViewerMode, setAbyssViewerState]);

  // Initialize point clouds passed as a props and its respective material thus spatial viewer
  // can visibly show points in fabric-v2
  const pointClouds = useMemo(
    () =>
      octreePointCloudsJsonPath?.map(({ path, name }) => ({
        name: `Line ${name}`,
        id: `Octree Point Cloud - ${path}`,
        url: path,
        material: 'superVoxel',
        isVisible: true,
      })),
    [octreePointCloudsJsonPath]
  );

  const centerOnLoad = useCallback(() => {
    if (!cameraTarget) {
      if (callbacks?.resetCameraPositionToDefault) {
        callbacks.resetCameraPositionToDefault();
      }
      if (callbacks?.getDefaultCameraPosition) {
        setCameraTarget(callbacks.getDefaultCameraPosition());
      }
    }
  }, [cameraTarget, callbacks, setCameraTarget]);

  /**
   * The keypress event is depreceated, so we are using keydown event. The Keypress is sent to an element when the browser registers keyboard input.
   * This is similar to the keydown event, except that modifier and non-printing keys such as Shift, Esc, and delete trigger keydown events but not keypress events.
   * The keyup event is sent to an element when the user releases a key on the keyboard.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
   */
  const toggleVisiblilityRangeSpherical = useCallback(
    (event: KeyboardEvent) => {
      if (inSphericalView && event.key === 'l') {
        setSphericalPointCloudMode((lastValue) => {
          if (lastValue === 'None') {
            return 'All';
          }
          return 'None';
        });
      }
    },
    [inSphericalView, setSphericalPointCloudMode]
  );

  const sphericalImageSets = useMemo(() => {
    return [
      {
        id: 'sphericalImageSet',
        name: 'sphericalImageSet',
        sphericalImages,
        isVisible: areSphericalsVisible,
      },
    ];
  }, [sphericalImages, areSphericalsVisible]);

  const abyssViewerComponentProps = useMemo(
    () => ({
      cameraTarget,
      navigationControls: {
        lookAround: { hspeed: 2, vspeed: 2, zoomStep: 0.95 },
        orbit: { rotationSpeed: 0.5 },
      },
      visibilityBox: currentVisibilityBox,
      setVisibilityBox: setCurrentVisibilityBox,
      pointCloudMaterials,
      pointClouds,
      edlStrength,
      enableEDL: !!edlStrength,
      viewCube: {
        cubeSize: 60,
        fontSize: 60,
        colors: {
          foreground: '#000',
          background: '#CCC',
          hoverForeground: '#000',
          hoverBackground: '#44AAFF',
        },
        compass: {
          ringColor: '#888',
          letterStyle: { fontSize: 16, color: '#888', strokeColor: '#111', strokeWidth: 0.1 },
        },
      },
      selectionColors,
      showPointCloudsInSphericalView: true,
      sphericalImageSets,
      fileLoaderOptions: { withCredentials: true },
      enableLoaderRetry: true,
      onInitialPointCloudLoad: centerOnLoad,
      onPointCloudSelection,
      onPointCloudClick: onPointSelect,
      currentImage: viewerCurrentSpherical,
      setCurrentImage: setViewerCurrentSpherical,
      nextCurrentImage: viewerNextCurrentSpherical,
      setNextCurrentImage: setViewerNextCurrentSpherical,
      segments,
    }),
    [
      cameraTarget,
      centerOnLoad,
      currentVisibilityBox,
      onPointCloudSelection,
      onPointSelect,
      pointCloudMaterials,
      pointClouds,
      edlStrength,
      viewerCurrentSpherical,
      setViewerCurrentSpherical,
      viewerNextCurrentSpherical,
      setViewerNextCurrentSpherical,
      selectionColors,
      setCurrentVisibilityBox,
      sphericalImageSets,
      segments,
    ]
  );

  const canvasProps = useMemo(
    () => ({
      style: {
        background: '#263238',
        height: '100%',
      },
      gl: { antialias: true, preserveDrawingBuffer: true },
    }),
    []
  );

  const handleResetCameraClicked = useCallback(() => {
    if (callbacks?.resetCameraPositionToDefault) {
      callbacks?.resetCameraPositionToDefault();
    }
  }, [callbacks]);

  const getHtmlCanvas = useCallback(() => {
    return callbacks?.getHTMLCanvas && callbacks.getHTMLCanvas();
  }, [callbacks]);

  const { performanceAlert, onMajorPerformanceCaveat } = useViewerPerformanceAlert();

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      tabIndex={1}
      onKeyDown={toggleVisiblilityRangeSpherical}
    >
      <MenuBar onResetCameraClicked={handleResetCameraClicked} getHtmlCanvas={getHtmlCanvas} />
      {performanceAlert}
      <AbyssViewerComponent
        canvasProps={canvasProps}
        abyssViewerComponentProps={abyssViewerComponentProps}
        onMajorPerformanceCaveat={onMajorPerformanceCaveat}
      />
    </div>
  );
};
