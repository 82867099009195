import { useCallback, useEffect, useRef, useState } from 'react';

export const useSharedTimeout = (timeoutMsec: number = 1000) => {
  const pendingCalls = useRef<(() => void)[]>([]);

  const triggerDelayedCall = useCallback(
    (call: () => void) => {
      pendingCalls.current.push(call);
      if (pendingCalls.current.length === 1) {
        setTimeout(() => {
          pendingCalls.current.forEach((event) => {
            event();
          });
          pendingCalls.current = [];
        }, timeoutMsec);
      }
    },
    [pendingCalls, timeoutMsec]
  );

  return triggerDelayedCall;
};

export function useBatchedSubscription<T>(
  subscription: T,
  triggerDelayedCall: (call: () => void) => void
): { consumeEvents: () => T[] } {
  const events = useRef<T[]>([]);
  const [batch, setBatch] = useState<{ consumeEvents: () => T[] }>({
    consumeEvents: () => [],
  });
  useEffect(() => {
    if (subscription) {
      events.current.push(subscription);
      if (events.current.length === 1) {
        triggerDelayedCall(() => {
          setBatch({
            consumeEvents: () => {
              const result = events.current;
              if (events.current.length > 0) {
                events.current = [];
              }
              return result;
            },
          });
        });
      }
    }
  }, [subscription, triggerDelayedCall]);

  return batch;
}
