import { Button, IconButton, IconButtonProps, Stack, Tooltip } from '@mui/material';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAnalysisCuboidActions } from '../state/actions';
import { useMemo } from 'react';

export const CuboidToolbar = () => {
  const { toggleTransformMode, cancelAddingCuboid } = useAnalysisCuboidActions();

  const commonIconButtonProps: IconButtonProps = {
    size: 'large',
    color: 'secondary',
  };

  const iconButtonData = useMemo(
    () => [
      {
        icon: HighlightAltIcon,
        label: 'Resize',
        onClick: () => toggleTransformMode('Resize'),
      },
      {
        icon: Rotate90DegreesCwIcon,
        label: 'Rotate',
        onClick: () => toggleTransformMode('Rotate'),
      },
      {
        icon: ControlCameraIcon,
        label: 'Move',
        onClick: () => toggleTransformMode('Move'),
      },
      {
        icon: UndoIcon,
        label: 'Undo',
        hidden: true,
      },
      {
        icon: RedoIcon,
        label: 'Redo',
        hidden: true,
      },
      {
        label: 'Cancel',
        icon: CancelIcon,
        onClick: () => cancelAddingCuboid(),
      },
    ],
    [toggleTransformMode, cancelAddingCuboid]
  );

  return (
    <Stack direction="row">
      <Button variant="outlined" color="secondary" size={'small'} sx={{ display: 'none' }}>
        Add another cuboid
      </Button>

      {iconButtonData
        .filter((item) => !item?.hidden)
        .map((data) => (
          <Tooltip key={data.label} title={data.label}>
            <IconButton {...commonIconButtonProps} onClick={data.onClick}>
              <data.icon />
            </IconButton>
          </Tooltip>
        ))}
    </Stack>
  );
};
