import { useCallback, MouseEvent, useMemo } from 'react';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useRecoilValue } from 'recoil';

import * as state from '@/components/Analysis/state';

import { localiseBlisterHeight } from '@/utils/unitSystem';

import { useHavePermission } from '@/hooks';
import { Permissions, PointOfInterestDocument } from '@/types';
import { generatePoiDisplayName } from '../../../PointOfInterestTab/SelectedPoiContent/utils';

type Props = {
  poiId: string;
  pointOfInterest: PointOfInterestDocument;
  onBlisterRowClicked: (poi: PointOfInterestDocument) => void;
  onNominateBlisterClicked: (poi: PointOfInterestDocument) => void;
  isNominated: boolean;
};

export const BlisterRow = ({
  poiId,
  pointOfInterest,
  onBlisterRowClicked,
  onNominateBlisterClicked,
  isNominated,
}: Props) => {
  const unitSystem = useRecoilValue(state.unitSystem);

  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);

  const handleRowClicked = useCallback(() => {
    if (pointOfInterest) {
      onBlisterRowClicked(pointOfInterest);
    }
  }, [onBlisterRowClicked, pointOfInterest]);

  const handleNominateClicked = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (pointOfInterest?.blister) {
        onNominateBlisterClicked(pointOfInterest);
      }
    },
    [onNominateBlisterClicked, pointOfInterest]
  );

  const blisterHeight = useMemo(() => {
    const heightInMM = pointOfInterest?.blister?.maxHeight;
    return localiseBlisterHeight(unitSystem, heightInMM);
  }, [pointOfInterest?.blister?.maxHeight, unitSystem]);

  const wallLoss = useMemo(() => {
    const wallLossInMM = pointOfInterest?.blister?.wallLoss?.value;
    return localiseBlisterHeight(unitSystem, wallLossInMM);
  }, [pointOfInterest?.blister?.wallLoss?.value, unitSystem]);

  const poiDisplayName = useMemo(
    () =>
      generatePoiDisplayName({
        name: pointOfInterest?.name,
        poiId,
        poiType: pointOfInterest?.type,
      }),
    [poiId, pointOfInterest?.name, pointOfInterest?.type]
  );

  return (
    <TableRow hover onClick={handleRowClicked} key={poiId} selected={isNominated}>
      <TableCell component="th" scope="row">
        {isNominated && <Typography variant="body2">{poiDisplayName}</Typography>}
        {!isNominated && (
          <Link underline="hover" component="button" variant="body2" onClick={handleRowClicked}>
            {poiDisplayName}
          </Link>
        )}
      </TableCell>

      <TableCell>{blisterHeight}</TableCell>

      <TableCell>{wallLoss}</TableCell>

      <TableCell>
        {isNominated && 'Yes'}
        {!isNominated && !userCanUpdateAsset && 'No'}

        {!isNominated && userCanUpdateAsset && (
          <Button variant="contained" size="medium" color="inherit" onClick={handleNominateClicked}>
            Nominate
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
