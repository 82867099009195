import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import {
  useSavePointOfInterestScreenshotMutation,
  ScreenshotTypeEnum,
  FileAttributes,
  useUpdatePointOfInterestFileMutation,
  FileUploadStatus,
} from './data.graphql';
import { useUploadAttachmentsToPoiMutation } from '../PointOfInterestTab/SelectedPoiContent/data.graphql';
import { Alert } from '@mui/material';
import { GetAllPointOfInterestsDocument } from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import {
  getSizeFromBase64,
  uploadBase64ToUrl,
} from '../PointOfInterestTab/SelectedPoiContent/Tabs/UploadFileToUrl';
import { PointOfInterestDocument } from '../PointOfInterestTab/data.graphql';

type SaveScreenshotProps = {
  location?: 'screenshots' | 'attachments';
  poiId: string | undefined;
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdating?: boolean;
};

export const useSavePoiScreenshot = () => {
  const selectedSpherical = useRecoilState(state.selectedSpherical);
  const callbacks = useRecoilValue(state.globalCallbacks);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);

  const [uploadAttachmentsToPoi] = useUploadAttachmentsToPoiMutation();
  const [updatePoiFile] = useUpdatePointOfInterestFileMutation();

  const [savePointOfInterestScreenshot] = useSavePointOfInterestScreenshotMutation();

  const getViewpointScreenshot = useCallback(() => {
    let screenshot: string = '';
    if (callbacks?.takeScreenShot) {
      screenshot = callbacks.takeScreenShot()?.toString() ?? '';
    }
    return screenshot;
  }, [callbacks]);

  const handleSavePoiScreenshot = useCallback(
    async ({ location = 'screenshots', poiId, isUpdating, setIsUpdating }: SaveScreenshotProps) => {
      if (isUpdating) {
        return setSnackbarMessage({
          shouldShow: true,
          content: (
            <Alert severity="warning">
              Unable to take Screenshot at the moment. Other files are uploading. Please try once
              uploading is done
            </Alert>
          ),
        });
      } else {
        if (setIsUpdating) setIsUpdating(true);
        const viewpointScreenshot = getViewpointScreenshot();
        if (!viewpointScreenshot || !poiId) {
          return '';
        }
        if (location === 'screenshots') {
          const type = selectedSpherical ? ScreenshotTypeEnum.Viewpoint : ScreenshotTypeEnum.Viewer;
          return savePointOfInterestScreenshot({
            variables: {
              input: {
                pointOfInterestId: poiId,
                type,
                image: viewpointScreenshot,
              },
            },
          });
        } else if (location === 'attachments') {
          const screenshotFile: FileAttributes = {
            uuid: `file-${Date.now()}`,
            name: `viewer-screenshot-${Date.now()}.png`,
            mimeType: 'image/png',
            size: getSizeFromBase64(viewpointScreenshot),
          };
          const { data } = await uploadAttachmentsToPoi({
            variables: {
              input: {
                pointOfInterestId: poiId,
                files: [screenshotFile],
              },
            },
            refetchQueries: [GetAllPointOfInterestsDocument],
            onError: (error) => {
              setSnackbarMessage({
                shouldShow: true,
                content: <Alert severity="error">{error.message}</Alert>,
              });
            },
          });
          if (data?.uploadAttachmentsToPoi && data?.uploadAttachmentsToPoi?.length > 0) {
            const file = data.uploadAttachmentsToPoi[0];
            const uploadedFile = await uploadBase64ToUrl(viewpointScreenshot, file.url);
            if (uploadedFile)
              updatePoiFile({
                variables: {
                  input: {
                    pointOfInterestId: poiId,
                    fileId: file.id,
                    uploadStatus: FileUploadStatus.Success,
                  },
                },
                refetchQueries: [PointOfInterestDocument],
                onCompleted: () => {
                  setSnackbarMessage({
                    shouldShow: true,
                    content: (
                      <Alert severity="success">Screenshot has been successfully uploaded</Alert>
                    ),
                  });
                },
                onError: (error) => {
                  if (setIsUpdating) setIsUpdating(false);
                  setSnackbarMessage({
                    shouldShow: true,
                    content: <Alert severity="error">{error.message}</Alert>,
                  });
                },
              });
            if (setIsUpdating) setIsUpdating(false);
          }
        }
      }
    },
    [
      getViewpointScreenshot,
      savePointOfInterestScreenshot,
      selectedSpherical,
      setSnackbarMessage,
      uploadAttachmentsToPoi,
      updatePoiFile,
    ]
  );

  return { handleSavePoiScreenshot };
};
