import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const EmptyBlisters = muiStyled(Box)(() => ({
  [`&.MuiBox-root`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
