import { Box, Stack, Typography } from '@mui/material';
import { lazy, Suspense, useCallback, useState, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTemplateFields } from '../../hooks/useTemplateFields';
import * as poiState from '../../../../state';
import { allPointOfInterestTemplates } from '@/components/Analysis/state';
import { TemplateFieldsForm } from '../TemplateFieldsForm';
import { SaveCancelFormButtons } from '../SaveCancelFormButtons';
import { useUpdatePoiTemplateFieldsMutation } from './data.graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { useBlisterData } from '../../hooks/useBlisterData';
import { BlisterFormFields } from '../BlisterFormFields';
import { useUpdatePointOfInterestMutation } from '../../data.graphql';
import { useTriggerPoiEditing } from '../../hooks';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { StyledForm } from '../UpdateGeneralFields/styles';
import { saveCancelButtonCommonStyles } from '../../../styles';
import { BLISTER, BLISTER_TYPE, PIT, PIT_TYPE } from '@/constants';
import { AllPointOfInterestsForDataLoaderDocument } from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { SelectedManualTemplateTypes } from '@/types';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import { PointOfInterestDocument } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/data.graphql';

const BlisterMeasurementLazy = lazy(() =>
  import('../../../../createOrEdit').then(({ BlisterMeasurement }) => ({
    default: BlisterMeasurement,
  }))
);

export const UpdateTemplateFields = () => {
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const setManualBlisterHeightExists = useSetRecoilState(poiState.manualBlisterHeightExists);
  const allPoiTemplates = useRecoilValue(allPointOfInterestTemplates);
  const [loading, setLoading] = useState(false);
  const poi = editPointOfInterest?.pointOfInterest;
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedManualTemplateTypes>(
    poi?.type === BLISTER_TYPE || poi?.type === PIT_TYPE ? poi?.type : BLISTER_TYPE
  );

  const [isManualTemplate, setIsManualTemplate] = useState(false);

  const {
    localBlisterData,
    blisterFieldsData,
    pointSelectMessage,
    updatePoiBlister,
    onMeasure,
    onSelectPoint,
    clearBlisterData,
    handleManualHeightChange,
    handleMeasureIconClick,
    handleChangeScCategory,
  } = useBlisterData(selectedTemplate);

  const { showSnackBar } = useSnackBarMessage({});

  const { rightDrawerWidth, doublePanelWidth } = useDrawerWidths();
  const navbarHeight = useNavbarHeight();

  const [mutate] = useUpdatePoiTemplateFieldsMutation();
  const [updatePointOfInterestMutation] = useUpdatePointOfInterestMutation();

  const { cancelEditing } = useTriggerPoiEditing();
  const { inputAdormantHandlerMap, cancelMeasuring } = useMeasurementTool();

  const onCancel = useCallback(() => {
    cancelEditing();
    cancelMeasuring();
    setManualBlisterHeightExists(false);
  }, [cancelEditing, cancelMeasuring, setManualBlisterHeightExists]);

  const dataHook = useTemplateFields({
    selectedPoiInfo: poi,
    templateFields: (editPointOfInterest?.template?.fields || []).filter(
      (field) => field.name !== 'Comments'
    ),
  });

  useEffect(() => {
    if (isManualTemplate) {
      setSelectedTemplate(poi?.type === BLISTER_TYPE ? PIT_TYPE : BLISTER_TYPE);
    }
  }, [isManualTemplate, poi?.type]);

  useEffect(() => {
    if (localBlisterData?.maxHeight) {
      updatePoiBlister(localBlisterData?.maxHeight, false);
    }
  }, [localBlisterData?.maxHeight, selectedTemplate, updatePoiBlister]);

  const templateId = useMemo(() => {
    return localBlisterData?.maxHeight
      ? allPoiTemplates.find((poiTemplate) => poiTemplate.name.toUpperCase() === selectedTemplate)
          ?.id
      : editPointOfInterest?.template?.id;
  }, [
    allPoiTemplates,
    editPointOfInterest?.template?.id,
    localBlisterData?.maxHeight,
    selectedTemplate,
  ]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    let poiInput;
    let updatePoi;
    updatePoi = mutate;
    poiInput = {
      input: {
        pointOfInterestId: poi?.id ?? '',
        templateId: templateId,
        templateData: Object.entries(dataHook.state).map(([key, value]) => ({
          id: key,
          value,
        })),
      },
    };

    if (poi?.id && localBlisterData) {
      // Removing __typename from blister
      const blister = JSON.parse(JSON.stringify(localBlisterData), (key, value) => {
        return key === '__typename' ? undefined : value;
      });
      updatePoi = updatePointOfInterestMutation;
      poiInput = {
        input: { pointOfInterestId: poi.id, blister, templateId, type: selectedTemplate },
      };
    }

    updatePoi({
      variables: poiInput,
      refetchQueries: [AllPointOfInterestsForDataLoaderDocument, PointOfInterestDocument],
    })
      .then(() => {
        showSnackBar('Point of interest updated', 'success');
        cancelEditing();
        setManualBlisterHeightExists(false);
      })
      .catch(() => {
        showSnackBar('Could not update point of interest', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    templateId,
    mutate,
    poi?.id,
    dataHook.state,
    localBlisterData,
    updatePointOfInterestMutation,
    selectedTemplate,
    showSnackBar,
    cancelEditing,
    setManualBlisterHeightExists,
  ]);

  const checkboxLabel = useMemo(() => {
    return poi?.type === BLISTER_TYPE ? PIT : BLISTER;
  }, [poi?.type]);

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      {localBlisterData && (
        <Stack width={doublePanelWidth - rightDrawerWidth}>
          <Suspense>
            <BlisterMeasurementLazy
              poiToAdd={poi}
              onMeasure={onMeasure}
              clearBlisterData={clearBlisterData}
              onSelectPoint={onSelectPoint}
              pointSelectMessage={pointSelectMessage}
            />
          </Suspense>
        </Stack>
      )}
      <Stack width={rightDrawerWidth}>
        <StyledForm
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
          style={{ borderLeft: '1px solid #ddd' }}
        >
          <Stack
            sx={{ height: `calc(100vh / var(--zoom) - ${navbarHeight}px)`, overflowY: 'auto' }}
          >
            <Box
              sx={{
                '& > *': { mb: 1 },
                p: 2,
                mb: 2,
                flex: 1,
              }}
            >
              <Typography variant="h6">Edit Details</Typography>
              {localBlisterData && (
                <BlisterFormFields
                  blisterData={blisterFieldsData}
                  handleManualHeightChange={handleManualHeightChange}
                  handleMeasureIconClick={handleMeasureIconClick}
                  handleChangeScCategory={handleChangeScCategory}
                  handleManualTemplate={setIsManualTemplate}
                  isManualTemplate={isManualTemplate}
                  label={checkboxLabel}
                />
              )}
              <TemplateFieldsForm
                dataHook={dataHook}
                template={editPointOfInterest.template}
                formState={editPointOfInterest?.formState}
                inputAdormantHandlerMap={inputAdormantHandlerMap}
              />
            </Box>

            <SaveCancelFormButtons
              cancelButtonLabel="Cancel"
              buttonLabel="Save"
              loadingLabel="Updating..."
              onCancel={onCancel}
              loading={loading}
              sx={saveCancelButtonCommonStyles.sx}
              saveButtonStyles={saveCancelButtonCommonStyles.saveButtonStyles}
              cancelButtonStyles={saveCancelButtonCommonStyles.cancelButtonStyles}
            />
          </Stack>
        </StyledForm>
      </Stack>
    </Stack>
  );
};
