import { Stack, SxProps } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { CustomTextField } from '@/components/shared/CustomField';
import { formatTemplateFieldValue } from '@/utils/unitSystem';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import { TemplateFieldTypeEnum } from '@/types';

type Props = {
  fields: Array<{
    label: string;
    value: string;
    type?: TemplateFieldTypeEnum;
    onClick?: () => void;
  }>;
  sx?: SxProps;
};

const linkFields = new Set(['Image Name']);
const truncatableFields = new Set(['Comment', 'Accessibility']);

export const FieldViewer = ({ fields, sx }: Props) => {
  const unitSystem = useRecoilValue(unitSystemState);

  const formatFieldValue = useCallback(
    (type: TemplateFieldTypeEnum | undefined, value: string) => {
      return formatTemplateFieldValue(type, value, unitSystem);
    },
    [unitSystem]
  );

  return (
    <Stack sx={sx} spacing={1}>
      {(fields || []).map(({ label, value, type, onClick }) => {
        const truncate = truncatableFields.has(label) ? { cutoff: 'dynamic' as const } : undefined;

        return (
          <CustomTextField
            key={label}
            mode="ReadOnly"
            id={label}
            title={label}
            value={formatFieldValue(type, value)}
            onClick={onClick}
            underlined={linkFields.has(label) && value !== 'N/A'}
            truncate={truncate}
          />
        );
      })}
    </Stack>
  );
};
