import { useRecoilValue } from 'recoil';
import { Comments } from '@/components/shared/Comments';
import * as state from '@/state';

export const ZoneComments = () => {
  const selectedZone = useRecoilValue(state.selectedZone);
  const collectionName = 'zones';

  return (
    <>
      {selectedZone?.id && (
        <Comments collectionName={collectionName} referenceId={selectedZone.id} />
      )}
    </>
  );
};
