import React, { ReactNode } from 'react';
import { Drawer, PaperProps, Stack, SxProps, Theme, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { StyledIconButton } from './styles';
import { primary, abyssColors } from '@/theme/colors';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

type PositionProps = 'left' | 'right';
type Variant = 'persistent' | 'temporary';
type SideDrawerProps = {
  icon?: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  handleOpen: Function;
  position: PositionProps;
  width: number;
  label?: string;
  iconStyles?: SxProps;
  drawerColor?: string;
  showCloseButton?: boolean;
  allDrawersClosed?: boolean;
  sx?: SxProps<Theme>;
  variant?: Variant;
  scrollable?: boolean;
  sxPaper?: SxProps;
  buttonText?: string;
  labelStyles?: SxProps;
  padding?: string;
};

type EvaluateIconPositionProps = Partial<SideDrawerProps>;

const evaluateIconPosition = ({ position, allDrawersClosed, width }: EvaluateIconPositionProps) => {
  return position === 'left'
    ? { left: !allDrawersClosed && width ? width + 15 : 15 }
    : { right: !allDrawersClosed && width ? width + 15 : 15 };
};

export const SideDrawer = ({
  icon,
  position,
  width,
  isOpen,
  handleOpen,
  label,
  children,
  iconStyles,
  drawerColor,
  showCloseButton,
  allDrawersClosed,
  sx,
  variant,
  scrollable,
  sxPaper,
  buttonText,
  labelStyles = {},
}: SideDrawerProps) => {
  const iconPosition = evaluateIconPosition({ position, allDrawersClosed, isOpen, width });
  const navbarHeight = useNavbarHeight();
  const paperPropsStyles: PaperProps = {
    sx: {
      width,
      height: `calc(100vh / var(--zoom) - ${navbarHeight}px)`,
      position: 'absolute',
      p: position === 'left' ? 2 : 0,
      backgroundColor: drawerColor || '#fff',
      overflowY: scrollable ? 'auto' : 'hidden',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
      ...sxPaper,
    },
  };

  return (
    <>
      <Drawer
        hideBackdrop
        elevation={0}
        PaperProps={paperPropsStyles}
        anchor={position}
        open={isOpen}
        variant={variant || 'persistent'}
        ModalProps={{
          disableEnforceFocus: true,
        }}
        sx={sx}
      >
        {(label || showCloseButton) && (
          <Stack
            sx={{ padding: '2%' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {label && (
              <Typography
                sx={{ fontSize: '1.6rem', fontWeight: 700, marginLeft: 1, ...labelStyles }}
              >
                {label}
              </Typography>
            )}
            {showCloseButton && (
              <CancelIcon
                onClick={() => {
                  handleOpen();
                }}
              />
            )}
          </Stack>
        )}
        {children}
      </Drawer>

      {icon && (
        <StyledIconButton
          onClick={() => {
            handleOpen();
          }}
          sx={{
            ...iconPosition,
            ...iconStyles,
            backgroundColor: isOpen ? primary.blue : abyssColors.neutral[500],
          }}
          disableRipple
        >
          {icon}
        </StyledIconButton>
      )}
      {buttonText && (
        <Typography
          sx={{
            ...iconPosition,
            ...iconStyles,
            backgroundColor: isOpen ? primary.blue : abyssColors.neutral[500],
          }}
          variant="body2"
        >
          {buttonText}
        </Typography>
      )}
    </>
  );
};
