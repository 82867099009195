import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as state from '@/state';

export const useIsolatedPartId = () => {
  const selectedPartIds = useRecoilValue(state.selectedPartIds);
  const viewerState = useRecoilValue(state.abyssViewerState);

  const isolatedPartId = useMemo(() => {
    if (viewerState.mode !== 'IsolatePart') return undefined;
    if (selectedPartIds.length !== 1) return undefined;
    return selectedPartIds[0];
  }, [viewerState.mode, selectedPartIds]);

  return isolatedPartId;
};
