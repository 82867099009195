import styled, { css } from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface PictureInPictureContainerProps {
  openState: boolean;
  editState: boolean;
  horizontalOffset: number;
}

export const PictureInPictureContainer = styled.div<PictureInPictureContainerProps>`
  position: absolute;
  z-index: 0;
  bottom: 16px;

  ${({ editState }) =>
    editState
      ? css`
          bottom: 48px;
          @media only screen and (max-width: 1728px) {
            bottom: 42px;
          }
        `
      : css`
          bottom: 16px;
        `};

  ${({ openState, horizontalOffset }) =>
    openState
      ? css`
          left: ${horizontalOffset + 16}px;
        `
      : css`
          left: 16px;
        `}
`;

export const ParentContainer = muiStyled(Box)<{ navbarHeight: number }>(({ navbarHeight }) => ({
  paddingTop: navbarHeight,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(100vh / var(--zoom))',
  background: '#232b30',
  overflow: 'hidden',
}));

export const ViewerLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #232b30;
`;

export const ViewerLoadingSpinnerContainer = styled.div<{ navbarHeight: number }>`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh / var(--zoom) + ${(props) => props.navbarHeight}px);
`;
