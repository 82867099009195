import { SxProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { ReactNode } from 'react';

type Props = {
  open: boolean;
  persistent?: boolean;
  onClose: () => void;
  children: ReactNode | undefined;
  autoHideDuration?: number | undefined | null;
  sxProps?: SxProps | undefined;
};

export const Snackbar = ({
  open,
  onClose,
  children,
  autoHideDuration = 5000,
  sxProps = {},
  persistent = false,
}: Props) => {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ top: '84px !important', whiteSpace: 'pre-wrap', ...sxProps }}
      open={open}
      {...(persistent ? {} : { autoHideDuration })}
      onClose={onClose}
    >
      <div>{children}</div>
    </MuiSnackbar>
  );
};
