import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Number3 } from '@abyss/3d-viewer';
import { FindViewpointsToolState } from '@/types';
import * as moduleState from '../state';

export const useFindViewpointsTool = () => {
  const [findViewpointsToolState, setFindViewpointsToolState] = useRecoilState(
    moduleState.findViewpointsToolState
  );
  const setCurrentVoxelPoint = useSetRecoilState(moduleState.currentVoxelPoint);
  const setCurrentVoxel = useSetRecoilState(moduleState.currentVoxel);
  const setFoundViewpoints = useSetRecoilState(moduleState.foundViewpoints);
  const setShowPointMarker = useSetRecoilState(moduleState.showPointMarker);

  const isFindViewpointsToolActive = findViewpointsToolState !== FindViewpointsToolState.INACTIVE;

  const activateFindViewpointsTool = useCallback(() => {
    setFindViewpointsToolState(FindViewpointsToolState.WAITING_FOR_POINT);
  }, [setFindViewpointsToolState]);

  const deactivateFindViewpointsTool = useCallback(() => {
    setCurrentVoxelPoint(undefined);
    setCurrentVoxel(undefined);
    setFoundViewpoints([]);
    setFindViewpointsToolState(FindViewpointsToolState.INACTIVE);
  }, [setFindViewpointsToolState, setFoundViewpoints, setCurrentVoxelPoint, setCurrentVoxel]);

  const clearFindViewpointsTool = useCallback(() => {
    setCurrentVoxelPoint(undefined);
    setCurrentVoxel(undefined);
    setFoundViewpoints([]);
    setFindViewpointsToolState(FindViewpointsToolState.WAITING_FOR_POINT);
  }, [setFindViewpointsToolState, setFoundViewpoints, setCurrentVoxelPoint, setCurrentVoxel]);

  const onPointSelect = useCallback(
    (position: number | number[] | undefined) => {
      if (
        findViewpointsToolState === FindViewpointsToolState.INACTIVE ||
        position === undefined ||
        !Array.isArray(position) ||
        position.length !== 3
      ) {
        return;
      }
      const point: Number3 = position as Number3;

      setCurrentVoxelPoint(point);
      setShowPointMarker(true);
    },
    [findViewpointsToolState, setCurrentVoxelPoint, setShowPointMarker]
  );

  return {
    isFindViewpointsToolActive,
    activateFindViewpointsTool,
    deactivateFindViewpointsTool,
    clearFindViewpointsTool,
    onPointSelect,
  };
};
