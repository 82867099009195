import { Button, Stack } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  selectedFilteredDeckIds,
  selectedFilteredPaintRegionIds,
  applyFilters,
  selectedTemplateFiltersState,
} from '@/components/Analysis/state';

type Props = {
  onClear?: () => void;
};

export const FilterActions = ({ onClear }: Props) => {
  const setSelectedTemplateFilters = useSetRecoilState(selectedTemplateFiltersState);
  const setSelectedDeckIds = useSetRecoilState(selectedFilteredDeckIds);
  const setSelectedPaintRegionIds = useSetRecoilState(selectedFilteredPaintRegionIds);
  const setApplyFilters = useSetRecoilState(applyFilters);

  const clearFilters = useCallback(() => {
    setSelectedTemplateFilters([]);
    setSelectedDeckIds([]);
    setSelectedPaintRegionIds([]);
    setApplyFilters(true);
    if (onClear) {
      onClear();
    }
  }, [
    onClear,
    setApplyFilters,
    setSelectedDeckIds,
    setSelectedPaintRegionIds,
    setSelectedTemplateFilters,
  ]);

  return (
    <Stack data-testid="filter-actions" direction="row" spacing={2}>
      {false && (
        <Button sx={{ fontSize: '1rem' }} startIcon={<SaveIcon />}>
          Save Query
        </Button>
      )}
      <Button
        data-testid="clear-query-button"
        sx={{ fontSize: '1.2rem', textTransform: 'none' }}
        startIcon={<CancelIcon />}
        onClick={() => clearFilters()}
      >
        Clear Query
      </Button>
    </Stack>
  );
};
