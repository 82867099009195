/**
  Function takes an array of Objects and groups them based on the property provided
  Provided property needs to exist on the Object type in the array.
  Grouping is done to a single property and expects the property to be unique to the array
  eg:
  input ([{ id: "1", value: ["Flange", "Pipe"] }, { id: "2", value: ["Heavy"] }]);
  output = ({ "1": ["Flange", "Pipe"] }, { "2", ["Heavy"] });
 */

import { useMemo } from 'react';

type FilterObject = {
  id: string;
  value: string[];
};

type CheckedResult = {
  [key: string]: string[];
};

export function useGroupFilterValue(selectedFilters: FilterObject[]): CheckedResult {
  const checked = useMemo(() => {
    return selectedFilters.reduce((result: CheckedResult, { id, value }: FilterObject) => {
      const data = { ...result };
      const key = id;
      if (!result[key]) {
        data[key] = [];
      }
      data[key] = value;
      return data;
    }, {});
  }, [selectedFilters]);

  return checked;
}
