import NotificationImportant from '@mui/icons-material/NotificationImportant';
import { Box, CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { EquipmentDetailsTypography, HorizontalBar, BoxStyles, EquipmentRowStyles } from './styles';
import { titleCase } from '@/utils';
import { AlarmLevelMapper, AlarmLevels } from '../common';
import { GetAssemblyDetailsQuery } from './data.graphql';
import { EquipmentDetailRenderer } from './EquipmentDetailRenderer';
import { useHavePermission } from '@/hooks';
import { Permissions } from '@/types';
import * as state from '@/components/Analysis/state';
import { localiseAreaMeasurement } from '@/utils/unitSystem';
import { UnitSystemEnum } from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { usePointOfInterestsCountLazyQuery } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/data.graphql';
import { abyssColors } from '@/theme/colors';

type Props = {
  assembly?: GetAssemblyDetailsQuery['assembly'];
  isLoadingAssemblyDetails?: boolean;
};

export const EquipmentDetails = ({ assembly, isLoadingAssemblyDetails }: Props) => {
  const assemblyId = useRecoilValue(state.selectedAssemblyId);
  const structureId = useGetStructureId();
  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);

  const templateFieldData: {
    [key: string]: string;
  } = assembly?.templateFieldData;
  const area = assembly?.area;

  const alarmLevel = useMemo(() => {
    return assembly?.templateFieldData?.recommendation_alarm_level as AlarmLevels;
  }, [assembly?.templateFieldData]);

  const unitSystem = useRecoilValue(state.unitSystem);

  const areaValue = useMemo(() => {
    const units = {
      [UnitSystemEnum.Imperial]: 'ft',
      [UnitSystemEnum.Metric]: 'm',
    };
    const unit = units[unitSystem] ?? '';

    return `${localiseAreaMeasurement(unitSystem, area)}${unit}`;
  }, [area, unitSystem]);

  const alarmLevelMapper = AlarmLevelMapper[alarmLevel ?? AlarmLevels.CLEAN];
  const [totalPoiCount, setTotalPoiCount] = useState(0);
  const [totalDefectPoisCount, setTotalDefectPoisCount] = useState(0);

  const [getPOICount, { loading: poiCountLoading }] = usePointOfInterestsCountLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (assemblyId) {
      const fetchPoiCounts = async () => {
        const { data: totalPoiCountData } = await getPOICount({
          variables: {
            input: {
              structureId,
              assemblyIds: [assemblyId],
            },
          },
        });

        const { data: defectTypePoiCountData } = await getPOICount({
          variables: {
            input: {
              structureId,
              assemblyIds: [assemblyId],
              type: 'DEFECT_CLASS',
            },
          },
        });

        setTotalPoiCount(totalPoiCountData?.pointOfInterestsCount?.count ?? 0);
        setTotalDefectPoisCount(defectTypePoiCountData?.pointOfInterestsCount?.count ?? 0);
      };

      fetchPoiCounts();
    }
  }, [assemblyId, getPOICount, structureId]);

  return (
    <Box sx={{ p: 3, pt: 1 }}>
      {isLoadingAssemblyDetails && (
        <Stack spacing={2} sx={{ py: 1 }} data-testid="pois-loader">
          {Array.from({ length: 6 }, (_, index) => (
            <Skeleton key={index} variant="rectangular" height={26} />
          ))}
        </Stack>
      )}

      {!isLoadingAssemblyDetails && (
        <>
          <Box>
            <Typography fontSize="1.8rem">{assembly?.tagName}</Typography>
            {alarmLevel && canReadDefects && (
              <Typography sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
                <NotificationImportant
                  fontSize="small"
                  color={alarmLevelMapper?.color || 'action'}
                  sx={{ marginRight: 1 }}
                />

                <EquipmentDetailsTypography>
                  {titleCase(alarmLevel.replace(/_/g, ' ').toString()) || 'None'}
                </EquipmentDetailsTypography>
              </Typography>
            )}
          </Box>
          <Box sx={EquipmentRowStyles}>
            <Typography fontSize="small" fontWeight={600}>
              Area
            </Typography>
            <Typography fontSize="small">
              {areaValue}
              <sup>2</sup>
            </Typography>
          </Box>

          {templateFieldData?.class && (
            <Box sx={EquipmentRowStyles}>
              <Typography fontSize="small" fontWeight={600}>
                Class
              </Typography>
              <Typography fontSize="small">{templateFieldData.class}</Typography>
            </Box>
          )}

          {templateFieldData?.size && (
            <Box sx={EquipmentRowStyles}>
              <Typography fontSize="small" fontWeight={600}>
                Size
              </Typography>
              <Typography fontSize="small">{templateFieldData.size}</Typography>
            </Box>
          )}

          {templateFieldData?.service && (
            <Box sx={EquipmentRowStyles}>
              <Typography fontSize="small" fontWeight={600}>
                Service Class
              </Typography>
              <Typography fontSize="small">{templateFieldData.service}</Typography>
            </Box>
          )}

          <HorizontalBar />

          <Box>
            <Box sx={BoxStyles}>
              {templateFieldData && canReadDefects ? (
                <EquipmentDetailRenderer data={templateFieldData} area={area ?? 0} />
              ) : (
                'No additional information'
              )}
            </Box>
          </Box>

          <Box sx={EquipmentRowStyles}>
            <EquipmentDetailsTypography fontWeight={600}>
              POIs of Component
            </EquipmentDetailsTypography>
            {poiCountLoading ? (
              <CircularProgress
                sx={{
                  color: abyssColors.primary[500],
                }}
                size="1.5rem"
              />
            ) : (
              <EquipmentDetailsTypography>{totalPoiCount}</EquipmentDetailsTypography>
            )}
          </Box>
          {!!totalDefectPoisCount  && (
            <Box sx={EquipmentRowStyles}>
              <EquipmentDetailsTypography fontWeight={600}>
                Governing defect POIs
              </EquipmentDetailsTypography>
              <EquipmentDetailsTypography>{totalDefectPoisCount}</EquipmentDetailsTypography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
