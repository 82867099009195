import { useMemo } from 'react';
import { useWindowSize } from './useWindowSize';

export const useNavbarHeight = () => {
  const screenSize = useWindowSize();
  const height = useMemo(() => {
    const { width } = screenSize;
    if (width && width <= 1728) {
      return 48;
    }
    return 56;
  }, [screenSize]);
  return height;
};
