/**
  Function takes an array of Objects and groups them based on the property provided
  Provided property needs to exist on the Object type in the array.
  Grouping is done to a single property and expects the property to be unique to the array
  eg:
  input ([{age:1,name:"john"},{age:2,name:"jane"}],'age');
  output {1:{age:1,name:"john"},2:{age:2,name:"jane"}};
 */

import { useMemo } from 'react';
import { GroupByResult } from '@/types';

export function useGroupBy<T>(array: T[] | undefined, property: keyof T): GroupByResult<T> {
  const groupedData = useMemo(() => {
    if (array) {
      return array.reduce((result: GroupByResult<T>, value: T) => {
        const data = { ...result };
        const key = String(value[property]);
        if (!result[key]) {
          data[key] = [];
        }
        data[key].push(value);
        return data;
      }, {} as GroupByResult<T>);
    }
    return {};
  }, [array, property]);

  return groupedData;
}
