import { Button, CircularProgress, Stack, SxProps } from '@mui/material';

type Props = {
  buttonIcon?: React.ReactNode;
  buttonLabel: string;
  cancelButtonLabel: string;
  loading?: boolean;
  loadingLabel?: string;
  onCancel: () => void;
  sx?: SxProps;
  saveButtonStyles?: SxProps;
  cancelButtonStyles?: SxProps;
};

export const SaveCancelFormButtons = ({
  buttonIcon,
  buttonLabel,
  cancelButtonLabel,
  loading,
  loadingLabel,
  onCancel,
  sx,
  cancelButtonStyles,
  saveButtonStyles,
}: Props) => {
  return (
    <Stack direction="row" sx={sx}>
      <Button sx={{ borderRadius: '4px', ...cancelButtonStyles }} onClick={onCancel}>
        {cancelButtonLabel}
      </Button>
      <Button
        disabled={loading}
        type="submit"
        variant="contained"
        sx={{ ...saveButtonStyles }}
        startIcon={loading ? <></> : buttonIcon}
      >
        {loading ? (
          <>
            {loadingLabel} <CircularProgress color="secondary" size={20} />
          </>
        ) : (
          buttonLabel
        )}
      </Button>
    </Stack>
  );
};
