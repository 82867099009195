import { useRecoilCallback } from 'recoil';
import * as state from '@/state';
import { AnnotationId } from '@/types';

export const useToggleAnnotationSelection = () => {
  const toggleAnnotationSelection = useRecoilCallback(
    ({ snapshot, set }) =>
      async (annotationId: string, isMultiSelectKeyDown: boolean, isDeselectDown: boolean) => {
        const selectedAnnotationIds = await snapshot.getPromise(state.selectedAnnotationIds);

        let newValue: AnnotationId[] = [];

        if (isMultiSelectKeyDown) {
          newValue = [...selectedAnnotationIds, annotationId];
        } else if (isDeselectDown) {
          newValue = selectedAnnotationIds.filter((aid) => aid !== annotationId);
        } else if (
          // deselecting single annotation id
          selectedAnnotationIds.length === 1 &&
          selectedAnnotationIds[0] === annotationId
        ) {
          newValue = [];
        } else {
          // selecting single annotation id
          newValue = [annotationId];
        }
        set(state.selectedAnnotationIds, newValue);
      },
    []
  );

  const toggleMultiAnnotationSelection = useRecoilCallback(
    ({ snapshot, set }) =>
      async (annotationIds: string[], isMultiSelectKeyDown: boolean) => {
        const currentSelectedIds = await snapshot.getPromise(state.selectedAnnotationIds);
        let newValue: AnnotationId[];
        if (isMultiSelectKeyDown) {
          newValue = [...new Set([...currentSelectedIds, ...annotationIds])];
        } else {
          // deselect key held down (most likely)
          // deselect the list of annotation ids (that were passed to this handler)
          const newSelectedIds: string[] = [];
          currentSelectedIds.forEach((currentSelectedId) => {
            // dont include the ids that were selected
            if (!annotationIds.includes(currentSelectedId)) {
              newSelectedIds.push(currentSelectedId);
            }
          });
          newValue = newSelectedIds;
        }
        set(state.selectedAnnotationIds, newValue);
      },
    []
  );

  return {
    toggleAnnotationSelection,
    toggleMultiAnnotationSelection,
  };
};
