import { useRecoilState, useRecoilValue } from 'recoil';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import * as state from '@/state';
import { LabellingBlendingMode } from '@/types';

const ShowBlendingModeCheckbox = (isChecked: boolean, toggleSelection: ChangeEventHandler) => (
  <Checkbox checked={isChecked} onChange={toggleSelection} />
);

export const BlendingModes = () => {
  const blendingModes = useRecoilValue(state.blendingModes);

  const [labellingCurrentBlendingMode, setLabellingCurrentBlendingMode] = useRecoilState(
    state.labellingCurrentBlendingMode
  );

  const [labellingShouldShowBlendingModes, setLabellingShouldShowBlendingModes] = useRecoilState(
    state.labellingShouldShowBlendingModes
  );

  const toggleShouldShowBlendingModes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabellingShouldShowBlendingModes(event.target.checked);
  };

  const memoizedBlendingModes = useMemo(() => {
    return (
      <>
        {blendingModes.map((blendMode) => {
          return (
            <FormControlLabel
              key={blendMode.id}
              value={blendMode.id}
              control={<Radio />}
              label={blendMode.text}
            />
          );
        })}
      </>
    );
  }, [blendingModes]);

  const handleBlendingModeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setLabellingCurrentBlendingMode(value as LabellingBlendingMode);
    },
    [setLabellingCurrentBlendingMode]
  );

  return (
    <>
      <DockPanelItem>
        <FormControlLabel
          control={ShowBlendingModeCheckbox(
            labellingShouldShowBlendingModes,
            toggleShouldShowBlendingModes
          )}
          label="Show Blending Modes"
        />
        {labellingShouldShowBlendingModes && (
          <>
            <RadioGroup
              aria-labelledby="annotation-mode-radio-buttons-group-label"
              defaultValue={labellingCurrentBlendingMode}
              name="annotation-mode-buttons-group"
              onChange={handleBlendingModeChange}
            >
              {memoizedBlendingModes}
            </RadioGroup>
          </>
        )}
      </DockPanelItem>
    </>
  );
};
