import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import {
  useFocalPointByAssembly,
  useViewpointsByAssembly,
} from '@/components/Analysis/Viewer/hooks';
import { useGetAssemblyDetailsQuery } from '@/components/Analysis/shared/EquipmentsTab/EquipmentDetails/data.graphql';

const useAssemblyDetails = () => {
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const selectedAssemblyName = useRecoilValue(state.selectedAssemblyName);

  const { data, loading } = useGetAssemblyDetailsQuery({
    variables: {
      assemblyId: selectedAssemblyId,
    },
    skip: !selectedAssemblyId,
  });

  useViewpointsByAssembly(selectedAssemblyId, data?.assembly?.seenByViewpoints ?? undefined);
  useFocalPointByAssembly(selectedAssemblyName, data?.assembly?.focalPoint ?? undefined);

  return {
    assembly: data?.assembly,
    loading,
  };
};

export default useAssemblyDetails;
