import { useEffect, useCallback } from 'react';
import { useBenchmarkUploadMutation } from './data.graphql';
import { LatencyBenchmarkProps } from './types';
import { LATENCY_NUMBER_REQUESTS } from './constants';

export const LatencyBenchmark = ({ onProgress, onComplete }: LatencyBenchmarkProps) => {
  const [benchmarkUploadMutation] = useBenchmarkUploadMutation();

  const runLatencyBenchmark = useCallback(async () => {
    let numberErrors = 0;
    const latencies: number[] = [];
    for (let requestCount = 0; requestCount < LATENCY_NUMBER_REQUESTS; requestCount += 1) {
      const startTime = performance.now();
      const data = startTime.toPrecision(2);
      // eslint-disable-next-line no-await-in-loop
      const result = await benchmarkUploadMutation({
        variables: {
          input: {
            data,
          },
        },
      });
      if (result.data?.benchmarkUpload?.size === data.length) {
        const timeElapsed = Math.round(performance.now() - startTime);
        latencies.push(Math.max(1, timeElapsed));
      } else {
        // error
        numberErrors += 1;
      }
      onProgress(Math.round((requestCount / LATENCY_NUMBER_REQUESTS) * 100));
    }
    const sumLatency = latencies.reduce((sumLat, lat) => {
      return sumLat + lat;
    }, 0);

    const minLatency = latencies.reduce((minLat, lat) => {
      return Math.min(minLat, lat);
    }, 999999);

    onComplete({
      avgLatency: latencies.length > 0 ? Math.round(sumLatency / latencies.length) : 0,
      minLatency,
      numberErrors,
      latencies,
    });
  }, [benchmarkUploadMutation, onProgress, onComplete]);

  useEffect(() => {
    runLatencyBenchmark();
  }, [runLatencyBenchmark]);

  return <></>;
};
