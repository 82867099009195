import { Avatar, Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { CreatedByDetails, Maybe, ZoneStatus } from '../data.graphql';
import { listHeadingStyles, listItemStyles, listItemTextStyles } from './styles';
import { ZoneStatusMapper } from '../common';

type Props = { allAssignees: Array<Maybe<CreatedByDetails> | undefined>; status: ZoneStatus };

const avatarStyles = { width: 35, height: 35 };

export const ZoneAssigneeList = ({ allAssignees, status }: Props) => {
  const zoneStatus = ZoneStatusMapper[status];

  const assigneeListMemo = useMemo(
    () => (
      <Box>
        <List>
          <Typography sx={listHeadingStyles} variant="subtitle2">
            {zoneStatus} / Assignees
          </Typography>
          {allAssignees.map((assignee) => {
            return (
              <ListItem sx={listItemStyles} disablePadding>
                <Avatar sx={avatarStyles} src={assignee?.profilePicture} />
                <ListItemText
                  primaryTypographyProps={listItemTextStyles}
                  primary={assignee?.name}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    ),
    [zoneStatus, allAssignees]
  );

  return assigneeListMemo;
};
