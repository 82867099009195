import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  selectedTemplateFiltersState,
  selectedSpherical as selectedSphericalState,
  selectedAssemblyId as selectedAssemblyIdState,
} from '@/components/Analysis/state';

export const useResetSelectedFilters = () => {
  const setSelectedTemplateFilters = useSetRecoilState(selectedTemplateFiltersState);
  const selectedSpherical = useRecoilValue(selectedSphericalState);
  const selectedAssemblyId = useRecoilValue(selectedAssemblyIdState);

  useEffect(() => {
    if (selectedSpherical && selectedAssemblyId) {
      setSelectedTemplateFilters(() => {
        return [];
      });
    }
  }, [selectedAssemblyId, selectedSpherical, setSelectedTemplateFilters]);
};
