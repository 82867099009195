import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { structureRelationship } from '@/components/Analysis/state';

export function usePointCloudClick() {
  const structureRelationships = useRecoilValue(structureRelationship);

  const annotation3dToAssemblyId = useCallback(
    (annotation3d: number) => {
      const annotationId = structureRelationships?.annotation3dToAnnotationId[annotation3d];

      if (!annotationId) return;

      const assemblyId = structureRelationships?.annotationIdToAssemblyId[annotationId];
      // eslint-disable-next-line consistent-return
      return assemblyId;
    },
    [structureRelationships]
  );

  return { annotation3dToAssemblyId };
}
