import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { Tooltip, Skeleton, Typography } from '@mui/material';
import * as state from '@/state';
import { structureNameStyles } from './styles';
import { useGetStructureNameLazyQuery } from './data.graphql';

type Props = {
  inspectionId?: string;
};

export const StructureName = ({ inspectionId }: Props) => {
  const [selectedStructureId, setSelectedStructureId] = useRecoilState(state.selectedStructureId);
  const [getStructureName, { loading, data }] = useGetStructureNameLazyQuery({ fetchPolicy: 'cache-first' });


  useEffect(() => {
    if (!selectedStructureId) {
      setSelectedStructureId(inspectionId);
    }
    if (inspectionId) getStructureName({ variables: { structureId: inspectionId } });

  }, [getStructureName, inspectionId, selectedStructureId, setSelectedStructureId]);

  if (loading) {
    return <Skeleton height={40} />;
  }

  return (
    <Tooltip title={data?.structure?.name}>
      <Typography fontWeight={500} variant="body2" sx={structureNameStyles}>
        {data?.structure?.name}
      </Typography>
    </Tooltip>
  );
};
