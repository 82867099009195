export const humanizeString = (sentenceOrWord: string | undefined, split = ' '): string => {
  if (!sentenceOrWord) return '';

  // Split sentence into words
  const words = sentenceOrWord.split(split).map((word) => word.toLowerCase());

  if (words.length === 0) return '';

  // Join words by ' ', keeping multiple spaces as they are
  const humanizedString = sentenceOrWord
    .split(split)
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(split);

  return humanizedString;
};
