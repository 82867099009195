import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { MouseEvent, useMemo, useCallback, useEffect, useState, ChangeEvent } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { useRouter } from 'next/router';
import { Search } from '@mui/icons-material';
import { matchSorter } from 'match-sorter';
import { Assembly } from './data.graphql';
import { HorizontalBar } from '@/components/Analysis/shared/EquipmentsTab/EquipmentDetails/styles';
import * as analysisState from '@/components/Analysis/state';
import * as localState from '../state';
import { PoiListItem } from './PoiListItem';
import { PoiIcon } from './PoiIcon';
import { AddToReportButton } from './AddToReportButton';
import { useGroupBy } from '@/hooks';
import { GroupByResult, PointOfInterestChecked, PointOfInterestGroupBy } from '@/types';
import { notEmpty } from '@/utils';
import { AllEquipmentBackButton } from '../AllEquipmentBackButton';
import { SelectedPoiContent } from './SelectedPoiContent';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { PoiSuspenseFallback } from './SelectedPoiContent/PoiSuspenseFallback';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

type PointOfInterestTabProps = {
  pois: PointOfInterestChecked[];
  changeTab: (selectedTabIndex: number) => void;
  allPOILoading?: boolean;
};

const EmptyPoiList = ({ name }: { name: string }) => {
  const text = `No ${name} found on selected equipment(s)`;
  return (
    <Box sx={{ my: 1, textAlign: 'center' }}>
      <Typography fontSize="1.4rem">{text}</Typography>
    </Box>
  );
};

export const PointOfInterestTab = ({ pois, changeTab, allPOILoading }: PointOfInterestTabProps) => {
  const allTemplates = useRecoilValue(analysisState.allPointOfInterestTemplates);
  const setSelectedAssemblyId = useSetRecoilState(analysisState.selectedAssemblyId);
  const setSelectedAssemblyName = useSetRecoilState(analysisState.selectedAssemblyName);
  const setPoiFilteredByCheckbox = useSetRecoilState(localState.poiFilteredByCheckbox);
  const arePoisVisible = useRecoilValue(analysisState.arePoisVisible);
  const assemblyId = useRecoilValue(analysisState.selectedAssemblyId);
  const selectedSpherical = useRecoilValue(analysisState.selectedSpherical);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchPoi, setSearchPoi] = useState<string>('');
  const [selectedPointOfInterestId, setSelectedPointOfInterestId] = useRecoilState(
    localState.selectedPointOfInterestId
  );

  const router = useRouter();
  const navbarHeight = useNavbarHeight();

  const isAtInsightsPage = useMemo(() => router.pathname.includes('insights'), [router.pathname]);

  const groupByArgumentsMemo = useMemo(
    () =>
      ((pois as PointOfInterestGroupBy[]) || []).map((poi) => {
        const mapPoi = { ...poi };
        mapPoi.templateId = poi.templateId;
        mapPoi.checked = isAtInsightsPage || arePoisVisible;
        return mapPoi;
      }),
    [pois, isAtInsightsPage, arePoisVisible]
  );

  const groupedPois = useGroupBy<PointOfInterestGroupBy>(groupByArgumentsMemo, 'templateId');

  function clickHandler(poiId: string | undefined, assembly: Assembly | undefined) {
    setSelectedPointOfInterestId(poiId);
    setSelectedAssemblyId(assembly?.id);
    setSelectedAssemblyName(assembly?.tagName);
    return undefined;
  }

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [checkedGroupedPois, setCheckedGroupedPois] =
    useState<GroupByResult<PointOfInterestGroupBy>>(groupedPois);
  const handleCheckedPOI = useCallback(
    (value: PointOfInterestChecked, templateId: string) => () => {
      const updatePois = checkedGroupedPois[templateId].map((poi) => ({
        ...poi,
        checked: poi.id === value.id ? !poi.checked : poi.checked,
      }));
      setCheckedGroupedPois({ ...checkedGroupedPois, [templateId]: updatePois });
    },
    [checkedGroupedPois]
  );

  const handleSearchPoi = (searchTerm: string) => {
    setSearchPoi(searchTerm);

    if (searchTerm === '') {
      setCheckedGroupedPois(groupedPois);
      return;
    }

    const updatedCheckedGroupedPois = Object.keys(groupedPois).reduce((accumulator, templateId) => {
      const filteredPois = groupedPois[templateId].filter((poi) =>
        (poi.poiId ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      );
      return { ...accumulator, [templateId]: filteredPois };
    }, {});
    setCheckedGroupedPois(updatedCheckedGroupedPois);
  };

  useEffect(() => {
    const arrayOfCheckedPoi = Object.values(checkedGroupedPois).map((templateArray) =>
      templateArray.filter((poi) => poi.checked)
    );
    setPoiFilteredByCheckbox(arrayOfCheckedPoi.filter(notEmpty).flat());
  }, [setPoiFilteredByCheckbox, checkedGroupedPois]);

  useEffect(() => {
    setCheckedGroupedPois(groupedPois);
  }, [arePoisVisible, groupedPois, assemblyId]);

  const isAllChecked = useCallback(
    (templateId: string) =>
      checkedGroupedPois[templateId]?.every((item) => item.checked === true) || false,
    [checkedGroupedPois]
  );

  const checkboxHandlerFactory = useCallback(
    (templateId: string) => (event: MouseEvent) => {
      const updatePois = checkedGroupedPois[templateId]?.map((poi) => {
        if (isAllChecked(templateId)) {
          return { ...poi, checked: false };
        }
        return { ...poi, checked: true };
      });
      setCheckedGroupedPois({ ...checkedGroupedPois, [templateId]: updatePois });

      event.stopPropagation();
    },
    [checkedGroupedPois, isAllChecked]
  );

  const closeHandler = useCallback(() => {
    setSelectedPointOfInterestId(undefined);
    return undefined;
  }, [setSelectedPointOfInterestId]);

  const selectedPoiDisplayComponent = useMemo(
    () => (
      <SelectedPoiContent
        selectedPoiId={selectedPointOfInterestId as string}
        closeHandler={closeHandler}
      />
    ),
    [closeHandler, selectedPointOfInterestId]
  );

  return selectedPointOfInterestId && selectedPointOfInterestId.length > 0 ? (
    selectedPoiDisplayComponent
  ) : (
    <>
      <Box
        sx={{
          overflowY: 'auto',
          height: {
            md: `calc(100vh - ${navbarHeight + 102}px)`,
            xl: `calc(100vh - ${navbarHeight + 122}px)`,
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Box sx={{ overflowY: 'auto' }}>
          <AllEquipmentBackButton changeTab={changeTab} retainAssemblyView={!!selectedSpherical} />
          {allPOILoading && <PoiSuspenseFallback />}
          {!allPOILoading &&
            allTemplates.map((template) => (
              <>
                {template && (
                  <Box key={template.id}>
                    <Accordion
                      disableGutters
                      sx={{ boxShadow: 0 }}
                      slotProps={{
                        transition: {
                          unmountOnExit: true,
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ArrowDropDown />}>
                        <Checkbox
                          disabled={!checkedGroupedPois[template.id]?.length}
                          checked={isAllChecked(template.id)}
                          onClick={checkboxHandlerFactory(template.id)}
                        />

                        <Box
                          sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              '& .MuiSvgIcon-root': { color: 'inherit' },
                            }}
                          >
                            <PoiIcon name={template?.name || ''} />
                            <Typography sx={{ paddingLeft: '1rem' }} fontSize="1.4rem">
                              {template.name}
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: '4rem', mr: 2 }}>
                            {checkedGroupedPois[template.id]?.length || 0}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        <Box flex={1} sx={{ width: '100%' }}>
                          <Autocomplete
                            options={[
                              ...new Set(
                                checkedGroupedPois[template.id]?.map((poi) => poi.name) || []
                              ),
                            ]}
                            onInputChange={(event, newValue) => handleSearchPoi(newValue || '')}
                            freeSolo
                            forcePopupIcon
                            clearOnEscape
                            filterOptions={(options, { inputValue }) =>
                              matchSorter(options, inputValue)
                            }
                            renderInput={(parameters) => (
                              <TextField
                                {...parameters}
                                data-testid="search-poi-input"
                                sx={{
                                  width: { xs: '100%', xl: '100%' },
                                }}
                                size="small"
                                value={searchPoi}
                                placeholder="Search a point of interest"
                                variant="outlined"
                                InputProps={{
                                  disableUnderline: true,
                                  startAdornment: (
                                    <InputAdornment position="start" sx={{ marginLeft: '8px' }}>
                                      <Search />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Box>
                        {checkedGroupedPois[template.id] &&
                        checkedGroupedPois[template.id].length > 0 ? (
                          checkedGroupedPois[template.id]
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((poi) => (
                              <PoiListItem
                                key={poi.id}
                                poiId={poi.poiId || NOT_APPLICABLE_LABEL}
                                equipmentName={poi.assembly?.tagName}
                                clickHandler={() => clickHandler(poi.id, poi.assembly)}
                                poi={poi}
                                handleCheckedPOI={handleCheckedPOI}
                                templateId={template.id}
                              />
                            ))
                        ) : (
                          <EmptyPoiList name={template?.name} />
                        )}
                        <TablePagination
                          rowsPerPageOptions={[
                            { label: '10', value: 10 },
                            { label: '25', value: 25 },
                            { label: '100', value: 100 },
                            { label: 'All', value: checkedGroupedPois[template.id]?.length || 0 },
                          ]}
                          count={checkedGroupedPois[template.id]?.length || 0}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage="Rows per page"
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          sx={{
                            '.MuiTablePagination-toolbar': { pl: 1.5 },
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <HorizontalBar style={{ margin: 0 }} />
                  </Box>
                )}
              </>
            ))}
        </Box>
      </Box>
      <AddToReportButton groupedPois={checkedGroupedPois} />
    </>
  );
};
