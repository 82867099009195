import { getAccessToken } from '@auth0/nextjs-auth0';
import { deleteCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import { NextApiRequest, NextApiResponse } from 'next';

export type CloudfrontSignedCookiesOutput = {
  /** ID of the Cloudfront key pair. */
  'CloudFront-Key-Pair-Id': string;
  /** Base64-encoded version of the JSON policy. */
  'CloudFront-Policy': string;
  /** Hashed, signed, and base64-encoded version of the JSON policy. */
  'CloudFront-Signature': string;
  /** The unix date time for when the signed URL or cookie can no longer be accessed. */
  'CloudFront-Expires'?: number;
};

/** remove leading and trailing white spaces and line terminators and also any leading slashes (/) from input string */
const removeLeadingSlashes = (input: string) => (input ?? '').trim().replace(/^\/+/, '');

export const getCloudfrontUrl = (filepath: string) => {
  const relativePathEncoded = removeLeadingSlashes(filepath);

  try {
    return new URL(relativePathEncoded, process.env.CDN_BASE_URL).href;
  } catch (error) {
    console.error('cloudfront.ts -> getCloudfrontUrl() ->', error);
    console.error('cloudfront.ts -> getCloudfrontUrl() -> filePath ->', filepath);
    return `${process.env.CDN_BASE_URL}/${relativePathEncoded}`;
  }
};

const getCloudfrontSignedCookieOptions = (path?: string): OptionsType => ({
  httpOnly: true,
  secure: true,
  sameSite: 'lax',
  // NOTE: must ensure domain for the cookie is a subdomain for both cloudfront CDN & frontend or browser rejects
  // see: https://stackoverflow.com/questions/41990841/how-to-use-cloudfront-signed-cookies-in-the-browser
  // and see https://stackoverflow.com/a/30384487
  domain: process.env.CDN_BASE_URL.split('.').slice(1).join('.'),
  ...(path && { path }),
});

const fetchCloudfrontSignedCookies = async (request: NextApiRequest, response: NextApiResponse) => {
  let cookies: Record<string, CloudfrontSignedCookiesOutput> = {};
  try {
    const { accessToken } = await getAccessToken(request, response);
    cookies = await (
      await fetch(`${process.env.API_URL}/cloudfront-cookies`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      })
    ).json();
    return cookies;
  } catch {
    return {};
  }
};

export const setCloudfrontSignedCookies = async (
  request: NextApiRequest,
  response: NextApiResponse
) => {
  const cookieOptions = (path?: string): OptionsType => ({
    req: request,
    res: response,
    ...getCloudfrontSignedCookieOptions(path),
  });
  const cookies = await fetchCloudfrontSignedCookies(request, response);
  const firstCookie = Object.values(cookies)[0];
  if (firstCookie === undefined) {
    return cookies;
  }
  // CloudFront-Key-Pair-Id cookie scoped for all paths
  setCookie('CloudFront-Key-Pair-Id', firstCookie['CloudFront-Key-Pair-Id'], cookieOptions('/'));
  Object.entries(cookies).forEach(([path, cookie]) => {
    // individual policy / signature cookies scoped per base CDN path
    setCookie('CloudFront-Policy', cookie['CloudFront-Policy'], cookieOptions(path));
    setCookie('CloudFront-Signature', cookie['CloudFront-Signature'], cookieOptions(path));
  });
  return cookies;
};

export const deleteCloudfrontSignedCookies = async (
  request: NextApiRequest,
  response: NextApiResponse
) => {
  const cookieOptions = (path?: string): OptionsType => ({
    req: request,
    res: response,
    ...getCloudfrontSignedCookieOptions(path),
  });
  const cookies = await fetchCloudfrontSignedCookies(request, response);
  if (Object.keys(cookies).length === 0) {
    return;
  }
  // CloudFront-Key-Pair-Id cookie scoped for all paths
  deleteCookie('CloudFront-Key-Pair-Id', cookieOptions('/'));
  Object.entries(cookies).forEach(([path]) => {
    // individual policy / signature cookies scoped per base CDN path
    deleteCookie('CloudFront-Policy', cookieOptions(path));
    deleteCookie('CloudFront-Signature', cookieOptions(path));
  });
};
