import React, { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import * as state from '@/state';
import { LabellingAnnotationMode } from '@/types';

export const AnnotationColorMode = () => {
  const annotationModes = useRecoilValue(state.annotationModes);
  const [labellingCurrentAnnotationMode, setLabellingCurrentAnnotationMode] = useRecoilState(
    state.labellingCurrentAnnotationMode
  );

  const memoizedAnnotationModes = useMemo(() => {
    return (
      <>
        {annotationModes.map((annotationMode) => {
          return (
            <>
              <FormControlLabel
                key={`${annotationMode.id}${annotationMode.text}`}
                value={annotationMode.id}
                control={<Radio />}
                label={annotationMode.text}
              />
            </>
          );
        })}
      </>
    );
  }, [annotationModes]);

  const handleAnnotationModeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setLabellingCurrentAnnotationMode(value as LabellingAnnotationMode);
    },
    [setLabellingCurrentAnnotationMode]
  );

  return (
    <>
      <DockPanelItem>
        <FormControl>
          <FormLabel id="annotation-mode-radio-buttons-group-label">
            Annotation color mode
          </FormLabel>
          <RadioGroup
            aria-labelledby="annotation-mode-radio-buttons-group-label"
            defaultValue={labellingCurrentAnnotationMode}
            name="annotation-mode-buttons-group"
            onChange={handleAnnotationModeChange}
          >
            {memoizedAnnotationModes}
          </RadioGroup>
        </FormControl>
      </DockPanelItem>
    </>
  );
};
