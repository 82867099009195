import { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Slider, { SliderProps } from '@mui/material/Slider';
import { useSetRecoilState } from 'recoil';
import * as state from '@/state';

type PointSizeSliderProps = {
  title: string;
} & SliderProps;

const valueText = (value: number) => {
  return `${value}`;
};

export const BrushSizeSlider = ({ title, ...props }: PointSizeSliderProps) => {
  const setBrushSize = useSetRecoilState<number>(state.brushSize);
  const [unfinishedBrushSize, setUnfinishedBrushSize] = useState(0);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setBrushSize(unfinishedBrushSize);
    }, 500);

    return () => clearTimeout(debounce);
  }, [setBrushSize, unfinishedBrushSize]);

  const handlePointSizeChanged = (event: Event, value: number | number[]) => {
    setUnfinishedBrushSize(value as number);
  };

  return (
    <>
      <FormLabel>{title}</FormLabel>
      <Slider
        {...props}
        aria-label="PointSize"
        getAriaValueText={valueText}
        valueLabelDisplay="auto"
        value={unfinishedBrushSize}
        onChange={handlePointSizeChanged}
      />
    </>
  );
};
