import { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  SxProps,
} from '@mui/material';
import { CustomFieldCommonProps, ReadModeProps } from './types';
import { stringFromDateOrString } from './utils';
import { ReadOnlyTemplateField } from './ReadOnlyTemplateField';

type EditModeProps = {
  options: string[] | undefined;
  onFieldValueChange?: (value: string, id?: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  mode: 'Edit';
  sx?: SxProps;
} & CustomFieldCommonProps;

type Props = EditModeProps | ReadModeProps;

const formControlStyles: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .MuiFormGroup-root': { flexWrap: 'nowrap' },
  '& .MuiFormControlLabel-label': { fontSize: '1.4rem' },
  '& .MuiRadio-root': { padding: '4px' },
};

const EditModeComponent = ({
  id,
  title,
  options,
  value: initialValue,
  sx: styles,
  onFieldValueChange,
  disabled,
  required,
}: EditModeProps) => {
  const stringifiedInitialValue = stringFromDateOrString(initialValue);

  const [value, setValue] = useState<string | undefined>(stringifiedInitialValue);

  useEffect(() => {
    setValue(stringifiedInitialValue);
  }, [stringifiedInitialValue]);

  const handleChange = (event: SelectChangeEvent) => {
    if (onFieldValueChange) {
      onFieldValueChange(event.target.value as string, id);
    }
    setValue(event.target.value as string);
  };

  return (
    <FormControl sx={{ ...formControlStyles, ...styles }} required={required} disabled={disabled}>
      <FormLabel sx={{ fontSize: '1.4rem' }}>{title}</FormLabel>
      <RadioGroup row onChange={handleChange} defaultValue={initialValue} value={value}>
        {options?.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio disableRipple />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const RadioButtonField = ({ ...props }: Props) => {
  switch (props.mode) {
    case 'Edit':
      return <EditModeComponent {...props} />;
    case 'ReadOnly':
      return <ReadOnlyTemplateField {...props} />;
    default:
      return <></>;
  }
};
