import React from 'react';
import MessageIcon from '@mui/icons-material/Message';
import IconButton from '@mui/material/IconButton';
import useDialogState from '../hooks/useDialogState';
import { useRecoilValue } from 'recoil';
import { commentsState, selectedRiskTab } from '../state/atoms';
import { primary } from '@/theme/colors';

interface OpenDialogButtonProps {
  identifer: string;
  title: string;
}

const OpenDialogButton: React.FC<OpenDialogButtonProps> = ({ identifer, title }) => {
  const { handleOpenDialog } = useDialogState();
  const comments = useRecoilValue(commentsState);
  const type = useRecoilValue(selectedRiskTab);
  const commentExist = comments?.comments.some(
    (comment) => comment.key === identifer && comment.type === type
  );
  return (
    <IconButton disableRipple sx={{ height: 'fit-content', padding: '4px' }}>
      <MessageIcon
        sx={{ color: commentExist ? primary.LightBlue : '#c5c5c5' }}
        onClick={() => handleOpenDialog(true, identifer, title)}
      />
    </IconButton>
  );
};

export default OpenDialogButton;
