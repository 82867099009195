import IconButton from '@mui/material/IconButton';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box, Checkbox, FormControlLabel, FormGroup, LinearProgress, Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@/components/shared/Button';
import { systemTest as systemTestDialog } from '../Analysis/state';
import { Logo } from '../shared/Navbar/styles';
import * as state from '@/state/atoms';

type Props = {
  anchorElementSystem: undefined | HTMLElement;
  open: boolean;
  handleCloseSystemMenu: () => void;
};

export const SystemPopover = ({ anchorElementSystem, open, handleCloseSystemMenu }: Props) => {
  const [systemTest, setSystemTest] = useRecoilState(systemTestDialog);

  const [canDisplaySystemPopover, setCanDisplaySystemPopover] = useRecoilState(
    state.canDisplaySystemPopover
  );

  const value = 50; // TODO - will be replaced with dymanic value
  const systemRequirements = useMemo(() => {
    return (
      <Stack sx={{ width: '300px' }} data-testid="system-popover">
        <IconButton onClick={handleCloseSystemMenu} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon />
        </IconButton>
        <Logo src="/assets/abyss-logo-black.svg" alt="Abyss Fabric" />
        <Typography variant="body2" fontWeight={500} sx={{ mt: 4 }}>
          Minimum requirement
        </Typography>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{ mt: 1, height: 9, backgroundColor: '#fff', border: '1px solid #B8CADD' }}
        />
        <Box
          sx={{ display: 'flex', backgroundColor: '#CA8A04', color: '#fff', my: 3, py: 1, px: 2 }}
        >
          <WarningAmberIcon sx={{ mr: 1, width: '22px' }} />
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Minimum System Requirements
            </Typography>

            <Typography variant="body2" fontWeight={500}>
              Abyss is running bit slow you might need to run Abyss minimum system requirements
              test.
            </Typography>
          </Box>
        </Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={!canDisplaySystemPopover}
                onClick={() => setCanDisplaySystemPopover((oldValue) => !oldValue)}
              />
            }
            label="Do not display this message again"
          />
        </FormGroup>
        <Button
          variant="outlined"
          onClick={() => setSystemTest(!systemTest)}
          sx={{ mt: 4, height: '34px', borderRadius: 1 }}
        >
          SYSTEM REQUIREMENT TEST
        </Button>
      </Stack>
    );
  }, [
    value,
    systemTest,
    setSystemTest,
    handleCloseSystemMenu,
    canDisplaySystemPopover,
    setCanDisplaySystemPopover,
  ]);

  return (
    <Popover
      sx={{ mt: '45px', padding: '1%' }}
      anchorEl={anchorElementSystem}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 10, left: 1500 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleCloseSystemMenu}
      PaperProps={{ sx: { borderRadius: '3%' } }}
    >
      <Stack direction="column" spacing={2} p={2}>
        {systemRequirements}
      </Stack>
    </Popover>
  );
};
