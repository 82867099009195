import { styled as muiStyled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';

export const ResponsiveButton = muiStyled(Button)(() => ({
  fontSize: '1.4rem',
  borderRadius: '4px',
  background: '#1A2C41',
  fontWeight: 500,
  padding: '0px 16px',
  height: '40px',
  lineHeight: 'normal',
  textTransform: 'none',
  '& .MuiButton-icon svg': {
    fontSize: '1.4rem',
  },
  '@media (max-width:1728px)': {
    height: '32px',
  },
}));

export const ResponsiveText = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
  height: '40px',
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width:1728px)': { height: '32px' },
}));

interface ToolbarProps {
  offset: number;
}

export const Toolbar = muiStyled(Box)(({ offset }: ToolbarProps) => ({
  position: 'absolute',
  top: 10,
  zIndex: 1,
  left: offset,
}));
