import { Box, Skeleton } from '@mui/material';

const StyledSkeleton = () => <Skeleton variant="text" sx={{ py: 3, height: '5px' }} />;

export const PoiSuspenseFallback = () => {
  return (
    <Box p={3}>
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
    </Box>
  );
};
