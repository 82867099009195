import { Stack } from '@mui/material';
import { CustomSkeleton } from './styles';

export const CommentsLoader = () => {
  return (
    <Stack direction="column" sx={{ height: '100%', px: 1 }}>
      <CustomSkeleton width={90} />
      <CustomSkeleton width={252} sx={{ mt: 4 }} />
      <CustomSkeleton width={155} sx={{ mt: 1 }} />
      <Stack sx={{ flex: '1 1 auto', mt: 1 }}>
        <CustomSkeleton width={103} />
      </Stack>
    </Stack>
  );
};
