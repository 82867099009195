import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { ButtonLabel, textButtonStyle, containedButtonStyle } from '@/theme';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type DialogTitleProps = {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
};

type Props = {
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  onConfirm: Function;
  onCancel?: Function;
  handleClose: () => void;
  isOpen: boolean;
  loading?: {
    state: boolean;
    text: string;
  };
};

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : undefined}
    </DialogTitle>
  );
}

export const ConfirmationDialog = ({
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  handleClose,
  isOpen,
  loading,
}: Props) => {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        sx={{
          '& .MuiDialog-paper': {
            padding: '10px',
            borderRadius: '10px',
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            if (onCancel) onCancel();
            handleClose();
          }}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>{description}</Typography>
        </DialogContent>
        <DialogActions sx={{ gap: 1.5 }}>
          {cancelButtonText && (
            <Button
              sx={textButtonStyle}
              onClick={() => {
                handleClose();
                if (onCancel) onCancel();
              }}
            >
              <ButtonLabel>{cancelButtonText}</ButtonLabel>
            </Button>
          )}

          <Button
            disabled={loading?.state}
            autoFocus
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            variant="contained"
            sx={containedButtonStyle}
          >
            {loading?.state ? (
              <>
                {loading.text}{' '}
                <CircularProgress color="secondary" size={30} style={{ marginLeft: 1 }} />
              </>
            ) : (
              <ButtonLabel>{confirmButtonText}</ButtonLabel>
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
