import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRecoilState } from 'recoil';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { DefectsToggle } from './DefectsToggle';
import { ToggleableDefectLegend } from '@/types';
import * as state from '@/components/Analysis/state';
import { AcceptedShortcutKeys } from '@/hooks';
import { titleCaseSentence } from '../../../../../utils';

// Used to define what shortcut key is reserved for what defect group
const DEFECTS_GROUPNAME_SHORTCUTS_MAP: Record<string, AcceptedShortcutKeys> = {
  CORROSION: 'c',
  'Coating Condition': 'x',
} as const;

type Props = {
  groupName: string;
  defects: ToggleableDefectLegend[];
};

const summaryStyles = {
  padding: '0px',
  margin: '0px',
  marginRight: '15px',
  width: '100%',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
};

export const DefectAccordian = ({ groupName, defects }: Props) => {
  const [selectedStructureDefectsLegends, setSelectedStructureDefectsLegends] = useRecoilState(
    state.selectedStructureDefectsLegends
  );

  const checked = useMemo(() => defects.some((defect) => defect.isEnabled), [defects]);

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // stoping the accordian form expanding when checkbox is clicked
      event.stopPropagation();

      if (!selectedStructureDefectsLegends) return;

      if (checked) {
        // find all defects with groupName and set isEnabled to false
        const newDefects = selectedStructureDefectsLegends.map((defect) => {
          if (defect.groupName === groupName) {
            return {
              ...defect,
              isEnabled: false,
            };
          }
          return defect;
        });

        setSelectedStructureDefectsLegends(newDefects);
      } else {
        // set all defects with groupName to true
        const newDefects = selectedStructureDefectsLegends.map((defect) => {
          if (defect.groupName === groupName) {
            return {
              ...defect,
              isEnabled: true,
            };
          }
          return {
            ...defect,
            isEnabled: false,
          };
        });
        setSelectedStructureDefectsLegends(newDefects);
      }
    },
    [checked, groupName, selectedStructureDefectsLegends, setSelectedStructureDefectsLegends]
  );

  const shortcutKey = useMemo(
    () => DEFECTS_GROUPNAME_SHORTCUTS_MAP[groupName]?.toUpperCase(),
    [groupName]
  );

  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={summaryStyles}
        expandIcon={
          <Tooltip title={`Press "Alt + ${shortcutKey}"`}>
            <ArrowDropDownIcon />
          </Tooltip>
        }
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Stack alignItems="center" flexDirection="row">
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            className="defect-group-checkbox"
          />
          <Typography>{titleCaseSentence(groupName)}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ marginBottom: 1.5 }}>
        {defects.map((defect) => (
          <DefectsToggle key={defect.octreeValue} {...defect} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
