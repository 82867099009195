import { SxProps } from '@mui/material';

export const modalStyles: SxProps = {
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  width: '50%',
  height: '600px',
  top: '15% !important',
  left: '25% !important',
  transform: 'none',
};

const commonButtonStyles: SxProps = {
  borderRadius: '4px',
  textTransform: 'none',
  '@media (max-width: 1728px)': {
    height: '32px',
    padding: '0',
  },
};

export const saveCancelButtonCommonStyles = {
  sx: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    mt: '10px',
    padding: '16px',
    gap: '16px',
    backgroundColor: 'white',
    textTransform: 'none',
  },
  saveButtonStyles: {
    ...commonButtonStyles,
    width: '60%',
  },
  cancelButtonStyles: {
    ...commonButtonStyles,
    width: '40%',
  },
};
