import { SxProps } from '@mui/material';

export const rootStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  gap: '5%',
  padding: '0 24px 0 24px',
};
export const scrolContainerStyles: SxProps = {
  height: '400px',
  overflowY: 'auto',
};
