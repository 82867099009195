import IconButton from '@mui/material/IconButton';
import { styled as muiStyled } from '@mui/material/styles';

const selectedStyle = {
  borderRadius: 0,
  background: '#2196f3',
  '&:hover': { backgroundColor: '#2196f3' },
}; // TODO: color should not be hardcoded like this. should come from mui theme

export const WidgetIconButton = muiStyled(IconButton)<{ isSelected?: boolean }>(({ isSelected }) =>
  isSelected ? selectedStyle : {}
);
