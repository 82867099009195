import { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PointOfInterestDocument } from '@/types';
import { DefectRow } from './DefectRow/DefectRow';
import { NominateDialog } from './NominateDialog/NominateDialog';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { useGetOneAssemblyQuery } from './data.graphql';

export const Defects = ({ defectClassPois }: { defectClassPois: PointOfInterestDocument[] }) => {
  const [defectClassIdToNominate, setDefectClassIdToNominate] = useState<string | undefined>();
  const [isNominateDefectClassOpen, setIsNominateDefectClassOpen] = useState(false);

  const allPointOfInterestTemplates = useRecoilValue(state.allPointOfInterestTemplates);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);

  const defectClassTemplateFields = useMemo(() => {
    return allPointOfInterestTemplates.find((template) => template.name === 'Defect Class')?.fields;
  }, [allPointOfInterestTemplates]);

  const { data: assemblyQueryResponse } = useGetOneAssemblyQuery({
    variables: {
      assemblyId: selectedAssemblyId!,
    },
    skip: !selectedAssemblyId,
  });

  const handleOnNominateDefectClassClicked = useCallback(
    (poi: PointOfInterestDocument) => {
      if (!selectedAssemblyId) {
        return;
      }

      setDefectClassIdToNominate(poi.id);
      setIsNominateDefectClassOpen(true);
    },
    [selectedAssemblyId]
  );

  const handleOnDefectClassRowSelected = useCallback((poi: PointOfInterestDocument) => {
    setDefectClassIdToNominate(poi.id);
  }, []);

  const governingDefectClassId = useMemo(
    () => assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id,
    [assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id]
  );

  const closeNominateDefectClass = useCallback(() => {
    setIsNominateDefectClassOpen(false);
  }, []);

  const fieldValue = useCallback(
    (poi: PointOfInterestDocument, key: string) => {
      const id = defectClassTemplateFields?.find((field) => field.name === key)?.id;
      return poi.templateData?.find((data) => data.id === id)?.value;
    },
    [defectClassTemplateFields]
  );

  const poiName = useMemo(() => {
    const poi = defectClassPois.find(
      (defectClassPoi) => defectClassPoi.id === defectClassIdToNominate
    );
    return poi?.name ?? poi?.poiId ?? NOT_APPLICABLE_LABEL;
  }, [defectClassIdToNominate, defectClassPois]);

  const orderedDefectClassPois = useMemo(
    () =>
      selectedAssemblyId &&
      defectClassPois?.map((poi) => {
        return (
          <DefectRow
            key={poi?.id}
            defectId={poi?.name ?? NOT_APPLICABLE_LABEL}
            defectType={fieldValue(poi, 'Defect Type') ?? NOT_APPLICABLE_LABEL}
            dcClass={fieldValue(poi, 'Priority (Severity)') ?? NOT_APPLICABLE_LABEL}
            pointOfInterest={poi}
            onDefectClassRowClicked={handleOnDefectClassRowSelected}
            onNominateDefectClassClicked={handleOnNominateDefectClassClicked}
            isNominated={poi?.id === governingDefectClassId}
          />
        );
      }),
    [
      defectClassPois,
      fieldValue,
      governingDefectClassId,
      handleOnDefectClassRowSelected,
      handleOnNominateDefectClassClicked,
      selectedAssemblyId,
    ]
  );

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 200 }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Defect Type</TableCell>
              <TableCell>DC Class</TableCell>
              <TableCell>Governing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{orderedDefectClassPois}</TableBody>
        </Table>
      </TableContainer>

      {defectClassIdToNominate && (
        <NominateDialog
          isOpen={isNominateDefectClassOpen}
          onClose={closeNominateDefectClass}
          defectClassId={defectClassIdToNominate}
          poiName={poiName}
        />
      )}
    </>
  );
};
