import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

import { IsolatedPartVisibilityRangeType } from '@/types';

type IsolatedPartVisibilityRangeProps = {
  onChanged?: (visibilityRangeType: IsolatedPartVisibilityRangeType) => void;
  visibilityRange: IsolatedPartVisibilityRangeType;
} & RadioGroupProps;

export const IsolatedPartVisibilityRange = ({
  onChanged,
  visibilityRange,
  ...props
}: IsolatedPartVisibilityRangeProps) => {
  const handleVisibilityRangeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(event.target.value as IsolatedPartVisibilityRangeType);
    }
  };

  return (
    <FormControl>
      <FormLabel>Visibility options</FormLabel>
      <RadioGroup
        {...props}
        value={visibilityRange}
        onChange={handleVisibilityRangeChanged}
        defaultValue="All"
        name="visibility-range-radio-group"
      >
        <FormControlLabel value="All" control={<Radio />} label="Show entire part" />
        <FormControlLabel value="Selected" control={<Radio />} label="Show selected labels only" />
        <FormControlLabel
          value="Unselected"
          control={<Radio />}
          label="Show unselected labels only"
        />
      </RadioGroup>
    </FormControl>
  );
};
