import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import * as state from '@/components/Analysis/state';
import { Inspection3dViewer } from './Inspection3dViewer';
import { InspectionDataLoader } from './InspectionDataLoader';
import { selectedStructureId } from '@/state';
import { Snackbar } from '../../shared/Snackbar';
import { useTriggerPoiEditing } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/SelectedPoiContent/hooks';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';

const Container = styled.div`
  overflow-x: hidden;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Viewer = ({ inspectionId }: { inspectionId: string }) => {
  const [snackbarMessage, setSnackbarMessage] = useRecoilState(state.snackbarMessage);
  const { cancelEditing } = useTriggerPoiEditing();
  const { cancelMeasuring } = useMeasurementTool();
  const setSelectedStructureId = useSetRecoilState(selectedStructureId);

  useEffect(() => {
    setSelectedStructureId(inspectionId);
  }, [setSelectedStructureId, inspectionId]);
  const closeSnack = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage({ shouldShow: false, content: undefined });
  };
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!url.includes('viewer')) {
        cancelEditing();
        cancelMeasuring();
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const { shouldShow, content } = snackbarMessage;

  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const setArePoisVisible = useSetRecoilState(state.arePoisVisible);

  useEffect(() => {
    setAreSphericalsVisible(!!selectedAssemblyId);
    setArePoisVisible(!!selectedAssemblyId);
  }, [selectedAssemblyId, setArePoisVisible, setAreSphericalsVisible]);

  if (!inspectionId || Array.isArray(inspectionId)) {
    return <div>Please specify inspection id, asset id and asset tag</div>;
  }

  return (
    <Wrapper>
      <Container>
        <InspectionDataLoader />
        <Snackbar open={shouldShow} onClose={closeSnack}>
          {content}
        </Snackbar>
        <Inspection3dViewer />
      </Container>
    </Wrapper>
  );
};
