import { useEffect } from 'react';
import { ColorMap } from '@abyss/3d-viewer';
import { useRecoilValue } from 'recoil';
import { NON_DEFECT_COLOR } from '@/constants';

import * as state from '@/components/Analysis/state';
import { convertColorHexStringToNumber } from '@/utils';
import { OctreeKey } from '../../Viewer/InspectionDataLoader/data.graphql';

export const useDefectColorEffects = (
  defectColorMap: ColorMap | undefined,
  degreeOfRustingColorMap: ColorMap | undefined
) => {
  const structureDefects = useRecoilValue(state.selectedStructureDefectsLegends);
  useEffect(() => {
    structureDefects?.forEach(({ isEnabled, color, octreeKey, octreeValue }) => {
      const convertedColor = convertColorHexStringToNumber(color);
      const colorMap =
        octreeKey === OctreeKey.DegreeOfRusting ? degreeOfRustingColorMap : defectColorMap;
      if (colorMap) {
        colorMap.setColor(octreeValue, isEnabled ? convertedColor : NON_DEFECT_COLOR);
      }
    });
  }, [defectColorMap, degreeOfRustingColorMap, structureDefects]);
};
