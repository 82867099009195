import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { Tooltip } from '@mui/material';
import * as state from '@/components/Analysis/state';
import { useRecoilValue } from 'recoil';
import IconButton from '@mui/material/IconButton';
import { styled as muiStyled } from '@mui/material/styles';
import { MouseEventHandler } from 'react';
import * as cuboidState from '@/analysis-cuboids/state';
import { CuboidClickMode } from '@/types';

const selectedStyle = {
  borderRadius: 0,
  background: '#2196f3',
  '&:hover': { backgroundColor: '#2196f3' },
}; // TODO: color should not be hardcoded like this. should come from mui theme

export const WidgetIconButton = muiStyled(IconButton)<{ isSelected?: boolean }>(({ isSelected }) =>
  isSelected ? selectedStyle : {}
);
type Props = {
  onClick: MouseEventHandler | undefined;
};
export const WidgetIcon = ({ onClick }: Props) => {
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const cuboidClickMode = useRecoilValue(cuboidState.cuboidClickMode);

  const isDisabled = !selectedAssemblyId;

  if (isDisabled) return <> </>;

  const isSelected = cuboidClickMode === CuboidClickMode.AddCuboid;

  return (
    <Tooltip
      title="Create volume"
      sx={{
        color: 'white',
      }}
    >
      <WidgetIconButton onClick={onClick} isSelected={isSelected}>
        <HighlightAltIcon />
      </WidgetIconButton>
    </Tooltip>
  );
};
