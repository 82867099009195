import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  BoxProps,
  Cuboid,
  CuboidEditor,
  CuboidProps,
  TransformTarget,
  VisibilityBoxMode,
} from '@abyss/3d-viewer';
import * as state from '@/state';
import * as taggingState from '@/components/Inspection/Tagging/state';
import { CuboidClickMode, CuboidData } from '@/types';
import {
  useIsolatedPartId,
  useSelectCuboid,
  useGetCuboidColor,
  makeCuboidPropsFromCuboidData,
  useCuboids,
} from './hooks';

export const SceneCuboids = () => {
  const cuboids = useRecoilValue(taggingState.isolatedPartCuboids);
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const cuboidControlsType = useRecoilValue(state.cuboidControlsType);
  const cuboidTransformMode = useRecoilValue(state.cuboidTransformMode);
  const cuboidClickMode = useRecoilValue(state.cuboidClickMode);
  const isCuboidEditorEnabled = useRecoilValue(state.isCuboidEditorEnabled);
  const setCurrentCuboidVisibilityBox = useSetRecoilState(state.currentCuboidVisibilityBox);

  const [transformTarget, setTransformTarget] = useState<TransformTarget>();

  const isolatedPartId = useIsolatedPartId();
  const { addUpdateCuboid } = useCuboids(isolatedPartId);
  const selectCuboid = useSelectCuboid();
  const getCuboidColor = useGetCuboidColor();

  const onCuboidClick = useCallback(
    (cuboid: CuboidData) => {
      if (cuboidClickMode !== CuboidClickMode.SelectCuboid) return;
      selectCuboid(cuboid);
    },
    [cuboidClickMode, selectCuboid]
  );

  const saveCuboidOnExit = useCallback(
    (newCuboid: CuboidProps) => {
      if (currentCuboid) {
        const { id, assemblyId, color } = currentCuboid;
        const { position, scale, rotation } = newCuboid;
        addUpdateCuboid({
          id,
          assemblyId,
          position,
          scale,
          rotation,
          color,
        });
        setCurrentCuboid(undefined);
      }
    },
    [addUpdateCuboid, currentCuboid, setCurrentCuboid]
  );

  const onBoxUpdate = useCallback(
    (newBox: BoxProps | undefined) => {
      if (newBox) {
        setCurrentCuboidVisibilityBox((current) => ({
          ...current,
          ...newBox,
          mode: VisibilityBoxMode.Enabled,
        }));
      }
    },
    [setCurrentCuboidVisibilityBox]
  );

  const makeCuboidProps = useCallback(
    (cuboid: CuboidData) => {
      const color = getCuboidColor(cuboid);
      return makeCuboidPropsFromCuboidData(cuboid, color);
    },
    [getCuboidColor]
  );

  const cuboidsComp = useMemo(
    () =>
      cuboids?.map((cuboid) => {
        if (currentCuboid && cuboid.id === currentCuboid.id) return undefined;
        return (
          <Cuboid
            {...makeCuboidProps(cuboid)}
            key={cuboid.id}
            onClick={() => onCuboidClick(cuboid)}
          />
        );
      }),
    [cuboids, currentCuboid, onCuboidClick, makeCuboidProps]
  );

  const currentCuboidProps = useMemo<CuboidProps | undefined>(() => {
    if (!currentCuboid) return undefined;
    return makeCuboidProps(currentCuboid);
  }, [currentCuboid, makeCuboidProps]);

  // const setCurrentCuboidFromViewer: Dispatch<SetStateAction<CuboidProps | undefined>> = useCallback(
  //   (cuboid: CuboidProps | undefined) => {
  //     setCurrentCuboid((current: CuboidData | undefined) => {
  //       if (!cuboid || !current) return undefined;
  //       const { position, scale, rotation } = cuboid;
  //       return { ...current, position, scale, rotation };
  //     });
  //   },
  //   [setCurrentCuboid]
  // );
  const setCurrentCuboidFromViewer: Dispatch<SetStateAction<CuboidProps | undefined>> = useCallback(
    (valueOrFunction: SetStateAction<CuboidProps | undefined>) => {
      setCurrentCuboid((current: CuboidData | undefined) => {
        if (!current) return undefined;
        const cuboid =
          valueOrFunction instanceof Function
            ? valueOrFunction(makeCuboidProps(current))
            : valueOrFunction;
        if (!cuboid) return undefined;
        const { position, scale, rotation } = cuboid;
        return { ...current, position, scale, rotation };
      });
    },
    [setCurrentCuboid, makeCuboidProps]
  );

  return (
    <>
      {cuboidsComp}
      {currentCuboidProps && (
        <CuboidEditor
          isEnabled={isCuboidEditorEnabled}
          cuboid={currentCuboidProps}
          setCuboid={setCurrentCuboidFromViewer}
          controlsType={cuboidControlsType}
          transformMode={cuboidTransformMode}
          transformTarget={transformTarget}
          setTransformTarget={setTransformTarget}
          onExit={saveCuboidOnExit}
          onBoxUpdate={onBoxUpdate}
        />
      )}
    </>
  );
};
