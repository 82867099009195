import { DatePicker } from '@mui/x-date-pickers';
import { RadioGroup, Stack, TextField, alpha, styled as muiStyled } from '@mui/material';
import { abyssColors } from '@/theme/colors';

export const ReportDatePicker = muiStyled(DatePicker)(() => ({
  '& fieldset': {
    border: '0px',
    borderRadius: '0px',
    borderBottom: `1px solid #a7a7a7`,
  },
  '& input': {
    paddingLeft: '0px !important',
    fontSize: '1.6rem',
    color: abyssColors.primary[500],
  },
  '& img': {
    height: '20px !important',
  },
  '& label': {
    marginLeft: '-14px',
    color: '#A3A3A3 !important',
  },
  '& .MuiInputBase-root ': {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}));

export const ReportFormTextField = muiStyled(TextField)(() => ({
  fontSize: '1.6rem',
  color: abyssColors.primary[500],
  '& label': {
    color: `${abyssColors.neutral[400]} !important`,
  },
  '& .MuiInputBase-input': {
    paddingBottom: '8px',
  },
}));

export const ReportFormRadioGroup = muiStyled(RadioGroup)(() => ({
  width: '100%',
  '& .MuiFormControlLabel-label': {
    color: abyssColors.primary[500],
    fontSize: '1.6rem',
  },
  '& .MuiRadio-root': {
    color: alpha('#000', 0.6),
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
  '& .Mui-checked': {
    color: abyssColors.primary[500],
  },
}));

export const ButtonContainer = muiStyled(Stack)(() => ({
  position: 'absolute',
  bottom: '24px',
  right: '24px',
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
}));

export const datePickerSlotProps = {
  textField: {
    size: 'small' as const,
  },
  openPickerButton: {
    disableRipple: true,
    sx: {
      padding: 0,
      margin: 0,
    },
  },
};

export const autocompleteTextFieldStyles = {
  '& label': {
    color: '#A3A3A3',
  },
};

export const buttonStyles = {
  padding: '8px 18px',
  fontSize: '1.4rem',
  fontWeight: 600,
  height: '34px',
  textTransform: 'none',
};

export const outlinedButtonStyles = {
  ...buttonStyles,
  borderColor: abyssColors.primary[500],
};
