import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useMemo } from 'react';
import { SummaryCard } from '@/components/Analysis/Viewer/Panels/AssemblyTable/Asset/SummaryCard';
import { AssemblyTemplateQuery } from '@/types';
import { formatRecommendationHeader, joinByRecommendationKey } from '@/utils';

type AlarmLevelCardProps = {
  template: AssemblyTemplateQuery;
  templateFieldData: {[key: string]: string};
  handleAlarmLevelClicked: () => void;
};

export const RecommendationCard = ({
  template,
  templateFieldData,
  handleAlarmLevelClicked,
}: AlarmLevelCardProps) => {
  const recommendationDisplayInQueryField = useMemo(() => {
    return joinByRecommendationKey(template).find((data) => data.displayInQuery);
  }, [template]);

  const recommendationDisplay = useMemo(() => {
    const onClick = handleAlarmLevelClicked;
    let isHighlighted = false;
    let title = formatRecommendationHeader(template?.recommendation?.header ?? '', isHighlighted);
    let subtitle = '';
    let icon = <AddIcon />;

    if (recommendationDisplayInQueryField) {
      isHighlighted = !!templateFieldData[recommendationDisplayInQueryField.key ?? ''];
      if (isHighlighted) {
        title = formatRecommendationHeader(template?.recommendation?.header ?? '', isHighlighted);
        icon = <ModeEditIcon />;
      }
      subtitle = templateFieldData[recommendationDisplayInQueryField.key ?? ''] ?? '';
    }

    return { title, isHighlighted, subtitle, icon, onClick };
  }, [
    templateFieldData,
    handleAlarmLevelClicked,
    recommendationDisplayInQueryField,
    template?.recommendation?.header,
  ]);

  return (
    <SummaryCard
      title={recommendationDisplay.title}
      subtitle={recommendationDisplay.subtitle}
      onClick={recommendationDisplay.onClick}
    />
  );
};
