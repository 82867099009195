import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';
import { LabellingAnnotationMode, LabellingColors } from '@/types';
import { useUpdateColor } from '../helpers';
import { convertColorHexStringToNumber } from '@/utils';

export const useInitialiseMode = (
  colorMap: ColorMap | undefined,
  { defaultColor, highlightColor }: LabellingColors
) => {
  const labellingStructureRelationships = useRecoilValue(state.labellingStructureRelationships);
  const currentAnnotationMode = useRecoilValue(state.labellingCurrentAnnotationMode);
  const { updateColor } = useUpdateColor();

  const byAnnotation3dReference = useMemo(() => {
    if (!labellingStructureRelationships?.byAnnotation3dReference) return undefined;
    return Object.keys(labellingStructureRelationships.byAnnotation3dReference).map(
      Number
    );
  }, [labellingStructureRelationships?.byAnnotation3dReference]);

  const bySupervoxelClassId = useMemo(() => {
    if (!labellingStructureRelationships?.bySupervoxelClassId) return undefined;
    const bySupervoxelClassIdComputed = Object.keys(
      labellingStructureRelationships.bySupervoxelClassId
    ).map((superVoxelClassId) => {
      const annotations =
        labellingStructureRelationships.bySupervoxelClassId[superVoxelClassId].annotationIds;

      const hexColor =
        labellingStructureRelationships?.bySupervoxelClassId[superVoxelClassId].color;
      const color = convertColorHexStringToNumber(hexColor);
      return {
        annotations,
        color,
      };
    });
    return bySupervoxelClassIdComputed;
  }, [labellingStructureRelationships?.bySupervoxelClassId]);

  useEffect(() => {
    if (!colorMap || !colorMap?.setColor) return;
    if (!byAnnotation3dReference) return;
    if (!bySupervoxelClassId) return;

    if (currentAnnotationMode === LabellingAnnotationMode.SUPERVOXEL) {
      updateColor(colorMap, defaultColor, highlightColor, byAnnotation3dReference);
    } else if (currentAnnotationMode === LabellingAnnotationMode.ASSET_TYPE) {
      bySupervoxelClassId.forEach(({ annotations, color }) => {
        updateColor(colorMap, color, highlightColor, annotations);
      });
    }
  }, [
    colorMap,
    defaultColor,
    byAnnotation3dReference,
    bySupervoxelClassId,
    updateColor,
    currentAnnotationMode,
    highlightColor,
  ]);
};

export const useAnnotationModeEffect = (
  colorMap: ColorMap | undefined,
  allColors: LabellingColors
) => {
  useInitialiseMode(colorMap, allColors);
};
