import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAbyssViewerContext } from '@abyss/3d-viewer';
import * as state from '@/state/atoms';

const DEFAULT_FRAME_TIME_THRESHOLD_MSEC = 100;
const DEFAULT_CHECK_INTERVAL_SEC = 10;
const DEFAULT_DOUBLE_CHECK_INTERVAL_SEC = 3;

export type LowFpsPopupTriggerProps = {
  frameTimeThreshold?: number;
  checkIntervalSec?: number;
  doubleCheckIntervalSec?: number;
};

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [savedCallback, delay]);
};

export const LowFpsPopupTrigger = ({
  frameTimeThreshold = DEFAULT_FRAME_TIME_THRESHOLD_MSEC,
  checkIntervalSec = DEFAULT_CHECK_INTERVAL_SEC,
  doubleCheckIntervalSec = DEFAULT_DOUBLE_CHECK_INTERVAL_SEC,
}: LowFpsPopupTriggerProps) => {
  const { callbacks } = useAbyssViewerContext();

  const hasTriggered = useRef(false);

  const setIsSystemPopoverOpened = useSetRecoilState(state.isSystemPopoverOpened);
  const canDisplaySystemPopover = useRecoilValue(state.canDisplaySystemPopover);

  const checkFrameTime = useCallback(() => {
    const getFrameTimings = callbacks?.getFrameTimings;
    if (!hasTriggered.current && getFrameTimings) {
      const avgTime = getFrameTimings()?.avgFrameRenderingTime3;
      if (avgTime && avgTime > frameTimeThreshold) {
        setTimeout(() => {
          const avgTime2 = getFrameTimings()?.avgFrameRenderingTime3;
          if (
            !hasTriggered.current &&
            avgTime2 &&
            avgTime2 > frameTimeThreshold &&
            canDisplaySystemPopover
          ) {
            hasTriggered.current = true;
            setIsSystemPopoverOpened(true);
          }
        }, doubleCheckIntervalSec);
      }
    }
  }, [
    callbacks,
    frameTimeThreshold,
    doubleCheckIntervalSec,
    hasTriggered,
    setIsSystemPopoverOpened,
    canDisplaySystemPopover,
  ]);

  useInterval(checkFrameTime, checkIntervalSec * 1000);

  return <></>;
};
