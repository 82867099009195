import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';
import { Colors } from '@/types';
import { usePrevious } from '@/hooks';
import { useSetAnnotationColours } from '../helpers';

/*
  This handles switching to Normal viewer mode
  by setting all colors based on assembly or out of scope flag
*/
export const useInitialiseMode = (
  colorMap: ColorMap | undefined,
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  }
) => {
  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const singleAssemblyIdForSelectedParts = useRecoilValue(state.singleAssemblyIdForSelectedParts);

  const { setAnnotationColourByHiddenOrAssembly, setAnnotationColoursForSelectedPart } =
    useSetAnnotationColours(allColors, colorsForAssembly);

  useEffect(() => {
    if (abyssViewerMode !== 'Normal') return;
    if (!colorMap) return;
    const byAnnotationId = structureRelationships?.byAnnotationId;
    if (!byAnnotationId) return;

    byAnnotationId.forEach((_annotation, annotationId) => {
      setAnnotationColourByHiddenOrAssembly(
        annotationId,
        colorMap,
        singleAssemblyIdForSelectedParts
      );
    });

    setAnnotationColoursForSelectedPart(colorMap);
  }, [
    abyssViewerMode,
    colorMap,
    setAnnotationColourByHiddenOrAssembly,
    setAnnotationColoursForSelectedPart,
    singleAssemblyIdForSelectedParts,
    structureRelationships?.byAnnotationId,
  ]);
};

/*
  This handles selection/deselection of part
  by updating colours for annotations related to selected part
  and reverting colours for annotations related to deselected part
*/
export const useAnnotationSelection = (
  colorMap: ColorMap | undefined,
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  }
) => {
  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const annotationIdsForSelectedPartId = useRecoilValue(state.annotationIdsForSelectedPart);
  const previousAnnotationIdsForSelectedPartId = usePrevious(annotationIdsForSelectedPartId);
  const singleAssemblyIdForSelectedParts = useRecoilValue(state.singleAssemblyIdForSelectedParts);

  const { setAnnotationColourByHiddenOrAssembly, setAnnotationColoursForSelectedPart } =
    useSetAnnotationColours(allColors, colorsForAssembly);

  useEffect(() => {
    if (abyssViewerMode !== 'Normal') return;
    if (!colorMap) return;
    const byAnnotationId = structureRelationships?.byAnnotationId;
    if (!byAnnotationId) return;

    previousAnnotationIdsForSelectedPartId?.forEach((annotationId) => {
      setAnnotationColourByHiddenOrAssembly(
        annotationId,
        colorMap,
        singleAssemblyIdForSelectedParts
      );
    });

    setAnnotationColoursForSelectedPart(colorMap);
  }, [
    abyssViewerMode,
    colorMap,
    previousAnnotationIdsForSelectedPartId,
    setAnnotationColourByHiddenOrAssembly,
    setAnnotationColoursForSelectedPart,
    singleAssemblyIdForSelectedParts,
    structureRelationships?.byAnnotationId,
  ]);
};

export const useNormalEffects = (
  colorMap: ColorMap | undefined,
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  }
) => {
  useInitialiseMode(colorMap, allColors, colorsForAssembly);
  useAnnotationSelection(colorMap, allColors, colorsForAssembly);
};
