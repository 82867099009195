import { toHexString } from '@/utils';

let globalCounter = Math.trunc(Math.random() * 0xffffff);

// Generates 12-byte object id (globally unique for practical purposes)
// See more info https://www.mongodb.com/blog/post/generating-globally-unique-identifiers-for-use-with-mongodb
export const generateMongoObjectId = (): string => {
  // 4-bytes timespamp
  const timestamp = toHexString(Date.now() * 0.001, 8);
  // 5-bytes random
  const random = toHexString(Math.random() * 0xffffffffff, 10);
  // 3-bytes counter
  const counter = toHexString(globalCounter, 6);
  // eslint-disable-next-line no-bitwise
  globalCounter = (globalCounter + 1) & 0xffffff;
  return `${timestamp}${random}${counter}`;
};
