import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { EquipmentCard } from './EquipmentCard';
import { EquipmentDetails } from './EquipmentDetails';
import * as state from '@/components/Analysis/state';
import {
  GetAssembliesByTemplateFieldsFiltersQuery,
  GetAssembliesWithTemplateFieldDataQuery,
  useGetAssembliesWithTemplateFieldDataLazyQuery,
} from '@/components/Analysis/shared/EquipmentsTab/data.graphql';
import { AllEquipmentBackButton } from '@/components/Analysis/modules/pointOfInterest';
import { selectedTemplateFiltersState } from '@/components/Analysis/state';
import { EquipmentTabSkeleton } from './EquipmentTabSkeleton';
import { useAssemblyVisibilityBox } from '@/components/Analysis/modules/assembly';
import { ViewpointAccordion } from './EquipmentDetails/ViewpointAccordion';
import { useResetSelectedFilters } from '@/hooks';
import { GetAssemblyDetailsQuery } from './EquipmentDetails/data.graphql';
import { EmptyStatePlaceholder } from '../EmptyStatePlaceholder';
import { IsomtericDownloadButton } from './EquipmentDetails/IsometricDownloadButton';
import { useGetStructureId } from '@/hooks/useGetStructureId';

type Assemblies =
  | GetAssembliesByTemplateFieldsFiltersQuery['getAssembliesByTemplateFieldsFilters']
  | undefined;

type CurrentAssemblies =
  | GetAssembliesWithTemplateFieldDataQuery['getAssembliesByTemplateFieldsFilters']
  | [];

type EquipmentsTabProps = {
  assemblies: Assemblies;
  changeTab: (selectedTabIndex: number) => void;
  loading?: boolean;
  assemblyDetails?: GetAssemblyDetailsQuery['assembly'];
  assemblyDetailsLoading?: boolean;
};
const selectedAssemblyCardStyles = {
  backgroundColor: '#3C3C3C',
  color: 'white',
  mb: '15',
};

export const EquipmentsTab = ({
  assemblies,
  changeTab,
  loading,
  assemblyDetails,
  assemblyDetailsLoading,
}: EquipmentsTabProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);
  const allTemplates = useRecoilValue(state.allAssemblyTemplates);
  const selectedTemplateFilters = useRecoilValue(selectedTemplateFiltersState);
  const [sortBy, setSortBy] = useState('HighToLow');
  const selectedAssemblyInfo = useRecoilValue(state.selectedAssemblyInfo);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const selectedAssemblyTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);
  const structureId = useGetStructureId();
  const [fetchAssembliesData, { data, loading: assembliesDataLoading }] = useGetAssembliesWithTemplateFieldDataLazyQuery();
  const [fetchAssemblyData, { data: selectedAssemblyData }] =
    useGetAssembliesWithTemplateFieldDataLazyQuery();
  const [currentAssemblies, setCurrentAssemblies] = useState<CurrentAssemblies>([]);
  const router = useRouter();
  useAssemblyVisibilityBox(assemblies);
  useResetSelectedFilters();

  const selectedTemplate = useMemo(
    () => allTemplates?.find((template) => template.id === selectedAssemblyTemplateId),
    [allTemplates, selectedAssemblyTemplateId]
  );

  const isAtInsightsPage = useMemo(() => router.pathname.includes('insights'), [router.pathname]);

  const isAtViewerPage = useMemo(() => router.pathname.includes('viewer'), [router.pathname]);

  const assemblyCount = useMemo(() => {
    if (assemblies?.length) {
      return assemblies.length;
    }
    return 0;
  }, [assemblies?.length]);

  const assembliesOnCurrentPage: Assemblies = useMemo(
    () => assemblies?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [assemblies, page, rowsPerPage]
  );

  const isSelectedAssemblyExistOnPointCloud = useMemo(() => {
    if (
      selectedAssemblyInfo &&
      assemblies?.find((assembly) => assembly.id === selectedAssemblyInfo)
    ) {
      return true;
    }
    return false;
  }, [selectedAssemblyInfo, assemblies]);

  useEffect(() => {
    if (selectedAssemblyInfo && selectedAssemblyTemplateId && isSelectedAssemblyExistOnPointCloud) {
      fetchAssemblyData({
        variables: {
          input: {
            structureId,
            templateId: selectedAssemblyTemplateId,
            assemblyIds: [selectedAssemblyInfo],
          },
        },
      });
    }
  }, [
    fetchAssemblyData,
    selectedAssemblyInfo,
    selectedAssemblyTemplateId,
    structureId,
    isSelectedAssemblyExistOnPointCloud,
  ]);

  useEffect(() => {
    setCurrentAssemblies(
      assemblyCount === 0 ? [] : data?.getAssembliesByTemplateFieldsFilters ?? []
    );
  }, [assemblyCount, data?.getAssembliesByTemplateFieldsFilters]);

  useEffect(() => {
    const assemblyIds = assembliesOnCurrentPage?.map((assembly) => assembly.id);
    if (selectedAssemblyTemplateId && assemblyCount > 0) {
      fetchAssembliesData({
        variables: {
          input: {
            structureId,
            templateId: selectedAssemblyTemplateId,
            assemblyIds,
          },
        },
      });
    }
  }, [
    assembliesOnCurrentPage,
    assemblyCount,
    fetchAssembliesData,
    selectedAssemblyTemplateId,
    structureId,
  ]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [selectedTemplateFilters]);

  const handleEquipmentCardClick = useCallback(
    (assemblyId: string, assemblyName: string) => {
      if (!assemblies) return;

      setSelectedAssemblyId(assemblyId);
      setSelectedAssemblyName(assemblyName);
    },
    [assemblies, setSelectedAssemblyId, setSelectedAssemblyName]
  );

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
  };

  const highlightedAssemblyCardInfo = useMemo(() => {
    if (isSelectedAssemblyExistOnPointCloud) {
      return selectedAssemblyData?.getAssembliesByTemplateFieldsFilters?.[0];
    }
    return undefined;
  }, [selectedAssemblyData, isSelectedAssemblyExistOnPointCloud]);

  if (!selectedAssemblyId && (isAtInsightsPage || (isAtViewerPage && selectedSpherical))) {
    return (
      <Box sx={{ marginTop: '60%' }}>
        <EmptyStatePlaceholder type={isAtInsightsPage ? 'equipmentTable' : 'equipmentViewer'} />
      </Box>
    );
  }

  return (
    <>
      {loading && <EquipmentTabSkeleton />}

      {!loading && (
        <Box
          sx={{
            padding: 0,
            overflowY: 'auto',
            height: 'calc(100vh / var(--zoom) - 11rem)',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
          data-testid="equipment-tab-container"
        >
          {selectedAssemblyId ? (
            <>
              <AllEquipmentBackButton
                changeTab={changeTab}
                retainAssemblyView={!!selectedSpherical}
              />
              <EquipmentDetails
                assembly={assemblyDetails}
                isLoadingAssemblyDetails={assemblyDetailsLoading}
              />
              <ViewpointAccordion />
              <IsomtericDownloadButton assembly={assemblyDetails} />
            </>
          ) : (
            <Box
              data-testid="equipment-cards-container"
              sx={{
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              <Box sx={{ margin: 3 }}>
                <Typography sx={{ display: 'inline', fontSize: '4rem' }}>
                  {assemblyCount}
                  <Typography sx={{ display: 'inline', marginLeft: 1, fontSize: '1.4rem' }}>
                    Total components found
                  </Typography>
                </Typography>
              </Box>
              {/* Display is set to none for now, will enable again when data is available for proper functionality */}
              <Box display="none">
                <FormControl variant="standard" sx={{ mx: 4, mb: 4, minWidth: '85%' }}>
                  <InputLabel>Sort by</InputLabel>
                  <Select
                    defaultValue="HighToLow"
                    value={sortBy}
                    label="Sort by"
                    onChange={handleSortChange}
                  >
                    <MenuItem value="HighToLow">Alarm level high to low</MenuItem>
                    <MenuItem value="LowToHigh">Alarm level low to high</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  scrollbarWidth: '2px',
                  maxHeight: '600px',
                  overflowY: 'auto',
                }}
              >
                {highlightedAssemblyCardInfo && (
                  <EquipmentCard
                    sx={selectedAssemblyCardStyles}
                    key={highlightedAssemblyCardInfo.id}
                    assembly={highlightedAssemblyCardInfo}
                    handleClick={() => {
                      handleEquipmentCardClick(
                        highlightedAssemblyCardInfo.id,
                        highlightedAssemblyCardInfo.tagName
                      );
                    }}
                  />
                )}
                {assembliesDataLoading && <EquipmentTabSkeleton />}
                {!assembliesDataLoading && currentAssemblies.map((assembly) => (
                  <EquipmentCard
                    equipmentCardTemplate={selectedTemplate?.equipmentCard}
                    key={assembly.id}
                    assembly={assembly}
                    handleClick={() => handleEquipmentCardClick(assembly.id, assembly.tagName)}
                  />
                ))}
              </Box>
              <TablePagination
                rowsPerPageOptions={[
                  { label: '10', value: 10 },
                  { label: '25', value: 25 },
                  { label: '100', value: 100 },
                  { label: 'All', value: assemblyCount },
                ]}
                count={assemblies?.length ?? 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Rows"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  '.MuiTablePagination-toolbar': { pl: 1.5 },
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
