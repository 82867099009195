import styled from 'styled-components';
import { AnalysisNavbar } from '@/components/Analysis/shared/AnalysisNavbar';
import { Navbar } from '@/components/shared/Navbar';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

type DefaultLayoutProps = {
  children: React.ReactNode;
  variant?: 'analysis' | 'inspection';
};

interface MainProps {
  navbarHeight: number;
}

const Main = styled.main<MainProps>`
  padding-top: ${(props) => props.navbarHeight}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DefaultLayout = ({ variant = 'inspection', children }: DefaultLayoutProps) => {
  const navbarHeight = useNavbarHeight();
  return (
    <Main navbarHeight={navbarHeight}>
      {variant === 'analysis' ? <AnalysisNavbar /> : <Navbar />}
      {children}
    </Main>
  );
};
