import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Inspection/Tagging/state';
import { CuboidData } from '@/types';
import { convertRgbaToRgb } from '@/utils';

export const useGetCuboidColor = () => {
  const colorByAssemblyId = useRecoilValue(state.colorByAssemblyId);

  const getCuboidColor = useCallback(
    (cuboid: CuboidData) =>
      cuboid.assemblyId && colorByAssemblyId
        ? convertRgbaToRgb(colorByAssemblyId[cuboid.assemblyId])
        : cuboid.color,
    [colorByAssemblyId]
  );

  return getCuboidColor;
};
