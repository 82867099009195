import { Number3 } from '@abyss/3d-viewer';

export const calculatePointOnRayWithDistance = (
  rayOrigin: Number3,
  lookAt: Number3,
  distance: number
): Number3 => {
  const [ox, oy, oz] = rayOrigin;
  const [tx, ty, tz] = lookAt;
  const dx = tx - ox;
  const dy = ty - oy;
  const dz = tz - oz;
  const scale = distance / Math.hypot(dx, dy, dz);
  return [ox + dx * scale, oy + dy * scale, oz + dz * scale];
};

export const calculateDistance3d = (a: Number3, b: Number3): number => {
  const [ax, ay, az] = a;
  const [bx, by, bz] = b;
  const dx = ax - bx;
  const dy = ay - by;
  const dz = az - bz;
  const distance = Math.hypot(dx, dy, dz);
  return distance;
};

export const toNumber3 = (array: number[]): Number3 => {
  if (array.length !== 3) {
    throw new Error('toNumber3: array length is not 3');
  }
  return array as Number3;
};
