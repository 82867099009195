import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as anaylsisState from '@/components/Analysis/state';
import * as taggingState from '@/state';
import { CORROSION_GROUPNAMES } from '@/constants';

export type AcceptedShortcutKeys = 'p' | 'x' | 'c' | 'a' | 'v' | 'z';
export type AcceptedModifierKeys = 'altKey';
export type PartialShortcutMap = {
  [K in AcceptedShortcutKeys]?: Function;
};

type Props = {
  modifierKey?: AcceptedModifierKeys;
  shortcuts: PartialShortcutMap;
};

export const useKeyboardShortcuts = ({ shortcuts, modifierKey = 'altKey' }: Props) => {
  useEffect(() => {
    const keys = Object.keys(shortcuts) as AcceptedShortcutKeys[];

    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key as AcceptedShortcutKeys;

      if (event[modifierKey] && keys.includes(key)) {
        const shortcutFunction = shortcuts[key];
        if (typeof shortcutFunction === 'function') {
          shortcutFunction();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modifierKey, shortcuts]);
};

export const useDefaultAnalysisKeyboardShortcuts = () => {
  const setShowPointCloudInSpherical = useSetRecoilState(anaylsisState.showPointCloudInSpherical);
  const setAreSphericalsVisible = useSetRecoilState(anaylsisState.areSphericalsVisible);
  const setArePoisVisible = useSetRecoilState(anaylsisState.arePoisVisible);
  const setSelectedStructureDefectsLegends = useSetRecoilState(
    anaylsisState.selectedStructureDefectsLegends
  );

  const toggleDefectGroup = useCallback(
    (groupName: string | string[]) => {
      setSelectedStructureDefectsLegends((current) =>
        current?.map((legend) => {
          const groupNameMatched = (() => {
            if (Array.isArray(groupName)) {
              return groupName.includes(legend.groupName);
            }

            return legend.groupName === groupName;
          })();

          return {
            ...legend,
            isEnabled: groupNameMatched ? !legend.isEnabled : legend.isEnabled,
          };
        })
      );
    },
    [setSelectedStructureDefectsLegends]
  );

  useKeyboardShortcuts({
    shortcuts: {
      a: () => {
        setShowPointCloudInSpherical((current) => !current);
      },
      p: () => {
        setArePoisVisible((current) => !current);
      },
      c: () => {
        toggleDefectGroup(CORROSION_GROUPNAMES);
      },
      x: () => {
        toggleDefectGroup('Coating Condition');
      },
      v: () => {
        setAreSphericalsVisible((current) => !current);
      },
    },
  });
};

export const useTaggingKeyboardShortcuts = () => {
  const setAreSphericalsVisible = useSetRecoilState(taggingState.areSphericalsVisible);
  const setSphericalPointCloudMode = useSetRecoilState(taggingState.sphericalPointCloudMode);
  const setShouldShowDemarcationOfZones = useSetRecoilState(
    taggingState.shouldShowDemarcationOfZones
  );
  const inSphericalView = useRecoilValue(taggingState.inSphericalView);
  const selectedZone = useRecoilValue(taggingState.selectedZone);
  const [relatedPointCloudSwitch, setRelatedPointCloudSwitch] = useState(false);
  useKeyboardShortcuts({
    shortcuts: {
      v: () => {
        setAreSphericalsVisible((previous) => !previous);
      },
      a: () => {
        setSphericalPointCloudMode((previous) => {
          if (previous === 'Related' || previous === 'All') {
            setRelatedPointCloudSwitch(previous === 'Related');
            return 'None';
          }
          return relatedPointCloudSwitch
            ? previous === 'None'
              ? 'Related'
              : 'None'
            : previous === 'None'
            ? 'All'
            : 'None';
        });
      },
      z: () => {
        if (!selectedZone && !inSphericalView)
          setShouldShowDemarcationOfZones((previous) => !previous);
      },
    },
  });
};
