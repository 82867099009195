import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import * as state from '@/components/Analysis/state';
import * as poiState from '../state';
import { SelectedTab } from '@/types';

type AllEquipmentBackButtonProps = {
  changeTab: (selectedTabIndex: number) => void;
  retainAssemblyView?: boolean;
};

export const AllEquipmentBackButton = ({
  changeTab,
  retainAssemblyView = false,
}: AllEquipmentBackButtonProps) => {
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const setHoveredAssemblyId = useSetRecoilState(state.hoveredAssemblyId);
  const setSelectedAssemblyInfo = useSetRecoilState(state.selectedAssemblyInfo);
  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);

  const clickHandler = useCallback(() => {
    if (!retainAssemblyView) {
      setSelectedSpherical(undefined);
      setHoveredAssemblyId(undefined);
    }
    setSelectedAssemblyName(undefined);
    setCameraTarget(undefined);
    cancelPOIAdd(undefined);
    setSelectedAssemblyId(undefined);
    setSelectedAssemblyInfo(undefined);
    changeTab(SelectedTab.COMPONENT);
  }, [
    retainAssemblyView,
    setSelectedAssemblyName,
    setCameraTarget,
    cancelPOIAdd,
    setSelectedAssemblyId,
    setSelectedAssemblyInfo,
    changeTab,
    setSelectedSpherical,
    setHoveredAssemblyId,
  ]);

  if (!selectedAssemblyId) return <></>;

  return (
    <Box
      data-testid="equipment-back-button"
      onClick={clickHandler}
      sx={{ display: 'flex', alignItem: 'center', m: 3, cursor: 'pointer' }}
    >
      <ArrowBack fontSize="small" />
      <Typography fontSize="1.4rem" sx={{ ml: 1 }}>
        All Components
      </Typography>
    </Box>
  );
};
