import { selector } from 'recoil';
import * as poiState from './atoms';

export const waitingForPoiClick = selector({
  key: 'waitingForPoiClick',
  get: ({ get }) =>
    get(poiState.editPointOfInterest)?.state === 'WaitingForPoint' ||
    get(poiState.blisterToAdd)?.state === 'WaitingForCenter' ||
    get(poiState.blisterToAdd)?.state === 'WaitingForEdge',
});

export const cancelPOIAdd = selector({
  key: 'cancelPOIAdd',
  get: () => {
    return undefined;
  },
  set: ({ set }) => {
    set(poiState.blisterToAdd, undefined);
    set(poiState.editPointOfInterest, { state: 'Inactive', pointOfInterest: undefined });
  },
});
