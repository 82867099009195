import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';
import { structureRelationship } from '@/components/Analysis/state';

export function useHasAssemblyBeenTagged() {
  const structureRelationships = useRecoilValue(structureRelationship);

  const hasAssemblyBeenTagged = useCallback(
    (assemblyId: string) =>
      structureRelationships?.assemblyIdToAnnotation3dArray[assemblyId] &&
      structureRelationships?.assemblyIdToAnnotation3dArray[assemblyId]?.length > 0,
    [structureRelationships]
  );

  return { hasAssemblyBeenTagged };
}
