import React, { useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { METERS_TO_FEET_FACTOR } from '@abyss/3d-viewer';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import {
  InputAdormentIconTypeEnum,
  DisplayConfig,
  Maybe,
} from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { useInputAdornment } from '@/hooks';
import * as state from '@/components/Analysis/state';
import { UnitSystemEnum } from '../Breadcrumb/data.graphql';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';

type Props = {
  title?: string;
  displayConfig?: Maybe<DisplayConfig>;
  onFieldValueChange?: (value: string) => void;
  inputAdormantHandlerMap?: {
    [InputAdormentIconTypeEnum.MeasurementTool]?: () => void;
    [InputAdormentIconTypeEnum.Reload]?: () => void;
    ''?: () => void;
  };
};

const emptyHandler = () => {};

export const ProposedPaintLength = ({
  title,
  displayConfig,
  onFieldValueChange,
  inputAdormantHandlerMap,
}: Props) => {
  const rows = displayConfig?.textAreaRows || 4;
  const inputAdorment = displayConfig?.inputAdorment || undefined;
  const iconType = displayConfig?.inputAdorment?.iconType || '';

  const selectedSpherical = useRecoilValue(state.selectedSpherical);

  const RenderInputAdormant: JSX.Element = useInputAdornment({
    inputAdorment,
    iconType,
    inputAdormantHandlerMap,
    displayConfig,
    selectedSpherical,
  });

  const { measurementDistance } = useMeasurementTool();
  const unitSystem = useRecoilValue(unitSystemState);

  const computedPaintLengthValue = useMemo(() => {
    const proposedLength =
      unitSystem === UnitSystemEnum.Imperial
        ? Number(measurementDistance) * METERS_TO_FEET_FACTOR
        : measurementDistance;

    if (onFieldValueChange !== undefined) {
      onFieldValueChange(String(measurementDistance));
      return proposedLength;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementDistance, unitSystem]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          '& .MuiTextField-root': { width: '100%' },
        }}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={`TextArea-${title}`}
            label={`${title} (${unitSystem === UnitSystemEnum.Imperial ? 'ft' : 'm'})`}
            multiline={false}
            rows={rows}
            value={computedPaintLengthValue}
            onChange={emptyHandler}
            InputProps={{
              endAdornment: RenderInputAdormant,
            }}
          />
        </div>
      </Box>
    </>
  );
};
