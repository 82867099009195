export const rootStyles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
};

export const paperStyles = { height: '100%' };

export const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: 3,
};

export const closeIconStyles = {
  background: '#777777',
  borderRadius: '50%',
  color: '#ffffff',
};
