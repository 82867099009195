import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import * as state from '@/state';
import { SelectedVoxelItems } from './SelectedVoxelItems';
import { DockPanel } from '@/components/shared/DockPanel';
import { DockPanelItem } from '@/components/shared/DockPanelItem';

export const SelectedVoxel = () => {
  const supervoxels = useRecoilValue(state.selectedSuperVoxels);

  return supervoxels.length > 0 ? (
    <DockPanel>
      <SelectedVoxelItems />
    </DockPanel>
  ) : (
    <DockPanel>
      <DockPanelItem>
        <Typography> Click a part in 3D viewer to select it</Typography>
      </DockPanelItem>
    </DockPanel>
  );
};
