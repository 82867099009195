import styled from 'styled-components';
import React from 'react';

import NextLink, { LinkProps } from 'next/link';

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<LinkProps, 'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'> {
  to: LinkProps['href'];
  linkAs?: LinkProps['as'];
}

const Anchor = styled('a')({});

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  function NextLinkComposed(props, reference) {
    const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } = props;

    return (
      <NextLink
        href={to}
        prefetch={prefetch}
        as={linkAs}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref
        locale={locale}
      >
        <Anchor ref={reference} {...other} />
      </NextLink>
    );
  }
);

// Usage example:
// https://mui.com/material-ui/guides/routing/#link
/*
        <MuiLink
          component={NextLinkComposed}
          underline="hover"
          color="inherit"
          to={{
            pathname: '/about',
            query: { name: 'test' },
          }}
        />
*/
