import { atom } from 'recoil';
import { AssemblyId, CuboidData, PartId } from '@/types';
import { StateMap } from '@/utils/stateMap';

export const colorByAssemblyId = atom<{ [assemblyId: AssemblyId]: number } | undefined>({
  default: undefined,
  key: 'ColorByAssemblyId',
});

export const splitCuboidsByPartId = atom<StateMap<PartId, CuboidData[]>>({
  key: 'SplitCuboidsByPartId',
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const isolatedPartCuboids = atom<CuboidData[]>({
  default: [],
  key: 'IsolatedPartCuboids',
});
