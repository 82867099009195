import { Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { useMeasurementTool } from './hooks';

export const MeasurementToolMenu = () => {
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);

  // Only enable the Done and Cancel buttons if the user has the POI edit/create form opened up
  // If the state is Inactive, the user is not creating or editing a POI
  const isMeasuringFromPoiForm = editPointOfInterest?.state !== 'Inactive';

  const { cancelMeasuring, doneMeasuring } = useMeasurementTool();

  const containerStyles: SxProps<Theme> = useMemo(
    () => ({
      color: 'white',
      // Hard coding 50rem since this component only shows up
      // when the drawer is open, so no need to check for the
      // drawer's visibility when calculating the width
      width: `calc(100vw / var(--zoom) - 50rem)`,
    }),
    []
  );

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={containerStyles}>
      <Typography
        variant="body1"
        sx={{
          marginRight: 'auto',
        }}
      >
        Measuring tool enabled
      </Typography>
      <InfoIcon
        sx={{
          fontSize: '1.2rem',
        }}
      />
      {isMeasuringFromPoiForm && (
        <>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ mt: 1, marginLeft: '55% !important' }}
            onClick={cancelMeasuring}
          >
            Cancel
          </Button>
          <Button color="secondary" variant="contained" sx={{ mt: 1 }} onClick={doneMeasuring}>
            <SquareFootIcon />
            Done
          </Button>
        </>
      )}
    </Stack>
  );
};
