import { useCallback, useMemo, useState } from 'react';
import { ViewerPerformanceAlert } from '@/components/shared/ViewerPerformanceAlert';

export const useViewerPerformanceAlert = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onMajorPerformanceCaveat = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const performanceAlert = useMemo(() => {
    return isOpen && <ViewerPerformanceAlert onClose={() => setIsOpen(false)} />;
  }, [isOpen, setIsOpen]);

  return { performanceAlert, onMajorPerformanceCaveat };
};
