import { useCallback, useMemo, useReducer } from 'react';
import { Blister, Maybe, PointOfInterest, TemplateField } from '../data.graphql';
import { BLISTER_TYPE, PIT_TYPE } from '@/constants';

export type TemplateDataHookKeys = { [key: string]: string };

type Action =
  | { type: 'UPDATE_FIELD'; payload: { key: string; value: string } }
  | { type: 'REMOVE_FIELD'; payload: { key: string; value: '' } }
  | { type: 'ADD_FIELD'; payload: { key: string; value: string } };

const reducer = (state: TemplateDataHookKeys, action: Action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'REMOVE_FIELD':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'ADD_FIELD':
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};

export type UseTemplateFieldsHookType = {
  type: string;
  blister: Maybe<Blister> | undefined;
  state: TemplateDataHookKeys;
  handleChange: (key: string, value: string) => void;
  handleRemove: (key: string) => void;
  handleAdd: (key: string, value: string) => void;
};

type Props = {
  templateFields: TemplateField[] | undefined;
  selectedPoiInfo: PointOfInterest | undefined;
  fieldType?: 'value' | 'verifiedValue';
  poiType?: 'BLISTER' | 'PIT';
};

export const useTemplateFields = ({
  templateFields,
  selectedPoiInfo,
  fieldType = 'value',
  poiType = BLISTER_TYPE,
}: Props): UseTemplateFieldsHookType => {
  const initialState = useMemo(
    () =>
      (templateFields || []).reduce((accumulator, templateField) => {
        const templateData = selectedPoiInfo?.templateData?.find(
          (td) => td.id === templateField.id
        );

        return {
          ...accumulator,
          [templateField.id]: templateData?.[fieldType] || templateData?.value || '',
        };
      }, {}),
    [fieldType, selectedPoiInfo?.templateData, templateFields]
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = useCallback((key: string, value: string) => {
    dispatch({ type: 'UPDATE_FIELD', payload: { key, value } });
  }, []);

  const handleRemove = useCallback((key: string) => {
    dispatch({ type: 'REMOVE_FIELD', payload: { key, value: '' } });
  }, []);

  const handleAdd = useCallback((key: string, value: string) => {
    dispatch({ type: 'ADD_FIELD', payload: { key, value } });
  }, []);

  return {
    state: poiType === PIT_TYPE ? initialState : state,
    handleChange,
    handleRemove,
    handleAdd,
    type: selectedPoiInfo?.type ?? '',
    blister: selectedPoiInfo?.blister,
  };
};
