import { useCallback, ChangeEvent, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/material';
import { useRecoilValue } from 'recoil';
import {
  DisplayConfig,
  InputAdormentIconTypeEnum,
  Maybe,
} from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { useInputAdornment } from '@/hooks';
import * as state from '@/components/Analysis/state';
import { CustomFieldCommonProps, ReadModeProps } from './types';
import { ReadOnlyTemplateField } from './ReadOnlyTemplateField';

type EditModeProps = {
  disabled?: boolean;
  multiline?: boolean;
  displayConfig?: Maybe<DisplayConfig>;
  onFieldValueChange?: (value: string, id?: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  mode: 'Edit';
  inputAdormantHandlerMap?: {
    [InputAdormentIconTypeEnum.MeasurementTool]?: () => void;
    [InputAdormentIconTypeEnum.Reload]?: () => void;
    ''?: () => void;
  };
  sx?: SxProps;
} & CustomFieldCommonProps;

type Props = EditModeProps | ReadModeProps;

const EditModeComponent = ({
  numeric = false,
  disabled = false,
  id,
  title,
  multiline = false,
  value: initialValue,
  displayConfig,
  onFieldValueChange,
  inputAdormantHandlerMap,
  sx: styles,
  ...rest
}: EditModeProps) => {
  const rows = displayConfig?.textAreaRows ?? 4;
  const inputAdorment = displayConfig?.inputAdorment ?? undefined;
  const iconType = displayConfig?.inputAdorment?.iconType ?? '';

  const [value, setValue] = useState(initialValue);

  const selectedSpherical = useRecoilValue(state.selectedSpherical);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const RenderInputAdormant: JSX.Element = useInputAdornment({
    inputAdorment,
    iconType,
    inputAdormantHandlerMap,
    displayConfig,
    selectedSpherical,
  });

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onFieldValueChange) {
        onFieldValueChange(event.target.value, id);
      }
      setValue(event.target.value);
    },
    [id, onFieldValueChange]
  );

  return (
    <TextField
      variant="standard"
      label={title}
      disabled={disabled}
      id={`TextArea-${title}`}
      multiline={multiline}
      rows={rows}
      defaultValue={initialValue}
      value={value}
      onChange={handleTextFieldChange}
      InputProps={{
        endAdornment: RenderInputAdormant,
      }}
      fullWidth
      sx={{
        '& .MuiInputBase-input': { px: 0 },
        '& .MuiInputLabel-root': { fontSize: '1.4rem' },
        ...styles,
      }}
      {...rest}
      {...(numeric ? { type: 'number' } : {})}
    />
  );
};

export const CustomTextField = ({ ...props }: Props) => {
  switch (props.mode) {
    case 'Edit':
      return <EditModeComponent {...props} />;
    case 'ReadOnly':
      return <ReadOnlyTemplateField {...props} />;
    default:
      return <></>;
  }
};
