import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useMemo } from 'react';
import { Viewpoint } from '@/types';
import { convertMetresToFeetDisplay, getMetresDisplay } from '@/utils/unitSystem';
import * as state from '@/components/Analysis/state';
import { UnitSystemEnum } from './data.graphql';

export const useViewpointsByAssembly = (
  assemblyId: string | undefined,
  seenByViewpoints: Viewpoint[] | undefined
) => {
  const unitSystem = useRecoilValue(state.unitSystem);
  const [viewpointsByAssemblyId, setViewpointsByAssemblyId] = useRecoilState(
    state.viewpointsByAssemblyId
  );

  useEffect(() => {
    const assemblyViewpoints = seenByViewpoints?.map((viewpoint: Viewpoint) => {
      return {
        ...viewpoint,
        distanceDisplayText:
          unitSystem === UnitSystemEnum.Imperial
            ? convertMetresToFeetDisplay(viewpoint.distance)
            : getMetresDisplay(viewpoint.distance),
      };
    });
    if (assemblyViewpoints && assemblyId) {
      setViewpointsByAssemblyId((current) => ({
        ...current,
        [assemblyId]: assemblyViewpoints,
      }));
    }
  }, [assemblyId, seenByViewpoints, setViewpointsByAssemblyId, unitSystem]);

  const viewpoints = useMemo(() => {
    return assemblyId ? viewpointsByAssemblyId[assemblyId] : undefined;
  }, [viewpointsByAssemblyId, assemblyId]);

  return viewpoints;
};
