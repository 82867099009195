import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import * as globalState from '@/components/Analysis/state';

const calculateContainerWidth = (isLeftDraweOpen: Boolean, isRightDrawerOpen: Boolean) => {
  let subtractedWidth = 0;

  if (isLeftDraweOpen) {
    subtractedWidth += 650;
  }

  if (isRightDrawerOpen) {
    subtractedWidth += 200;
  }
  return subtractedWidth;
};

export const FindViewpointsToolMenu = () => {
  const drawerLeft = useRecoilValue(globalState.drawerLeft);
  const drawerRight = useRecoilValue(globalState.drawerRight);

  const containerStyles: SxProps<Theme> = useMemo(
    () => ({
      color: 'white',
      width: `calc(100vw / var(--zoom) - ${calculateContainerWidth(
        drawerLeft.visibility,
        drawerRight.equipment
      )}px)`,
    }),
    [drawerLeft.visibility, drawerRight.equipment]
  );

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={containerStyles}>
      <Typography
        variant="body1"
        sx={{
          marginRight: 'auto',
        }}
      >
        Find Viewpoints Tool is enabled
      </Typography>
      <InfoIcon
        sx={{
          fontSize: '1.2rem',
        }}
      />
    </Stack>
  );
};
