import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

type Props = {
  onClose: () => void;
};

export const ViewerPerformanceAlert = ({ onClose }: Props) => {
  return (
    <Box sx={{ margin: 'auto', width: '40%' }}>
      <Alert
        severity="error"
        variant="filled"
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>
          {'{'}Software acceleration{'}'}
        </AlertTitle>
        <p>
          Oops! 3D isn&apos;t working - enable sofrware acceleration. Need help? Contact support or
          check the{' '}
          <Link
            href="https://help.scoot.app/how-do-i-enable-my-browsers-hardware-acceleration"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            User Guide
          </Link>{' '}
          for solutions
        </p>
      </Alert>
    </Box>
  );
};
