export enum AlarmLevels {
  CLEAN = 'Clean',
  ALARM_1 = 'Alarm level 1',
  ALARM_2 = 'Alarm level 2',
  ALARM_3 = 'Alarm level 3',
  ALARM_1_OR_2 = 'Alarm level 1 or 2',
}

type AlarmLevelMapperProps = {
  [key in AlarmLevels]: {
    alarmLevel: string;
    color:
      | 'inherit'
      | 'action'
      | 'disabled'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  };
};

export const AlarmLevelMapper: AlarmLevelMapperProps = {
  [AlarmLevels.CLEAN]: { alarmLevel: '-', color: 'disabled' },
  [AlarmLevels.ALARM_1]: { alarmLevel: '1', color: 'success' },
  [AlarmLevels.ALARM_1_OR_2]: { alarmLevel: '1/2', color: 'info' },
  [AlarmLevels.ALARM_2]: { alarmLevel: '2', color: 'warning' },
  [AlarmLevels.ALARM_3]: { alarmLevel: '3', color: 'error' },
};
