import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  color: white;
  z-index: 10;
  width: 4rem;
  height: 4rem;
  border-radius: 5px;
`;
