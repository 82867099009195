import { useCallback, Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { selectedAssemblyTemplateId, allAssemblyTemplates } from '@/components/Analysis/state';

type TemplateSelectorProps = {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
};

export const TemplateSelector = ({ value, setValue }: TemplateSelectorProps) => {
  const allTemplates = useRecoilValue(allAssemblyTemplates);
  const setSelectedTemplateId = useSetRecoilState(selectedAssemblyTemplateId);

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
      setSelectedTemplateId(allTemplates[newValue]?.id);
    },
    [allTemplates, setSelectedTemplateId, setValue]
  );

  return (
    <Stack data-testid="template-selector">
      {allTemplates && allTemplates.length > 1 && (
        <Stack sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabsChange}
            sx={{ '& button': { textTransform: 'none' } }}
          >
            {allTemplates?.map((template) => (
              <Tab sx={{ flex: 1 }} label={template.name} key={template.id} />
            ))}
          </Tabs>
        </Stack>
      )}
    </Stack>
  );
};
