import { Point3d, PolygonClickEvent, PolygonProps } from '@abyss/3d-viewer';
import { makeUniqueId } from '@apollo/client/utilities';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Color } from 'three';
import { useEffect } from 'react';
import {
  structurePois,
  blisterPolygons,
  editPointOfInterest,
} from '../../modules/pointOfInterest/state';
import { poiState } from '../../modules/pointOfInterest';
import { selectedAssemblyId as selectedAssembly } from '../../state';
import { NOT_APPLICABLE_LABEL } from '@/constants';

const makePolygonPoints = (
  name: string,
  poiId: string,
  locationId: string | null | undefined,
  borderPoints: Point3d[],
  onClickEvent: (event: PolygonClickEvent) => void
): PolygonProps => ({
  name,
  // different ids
  id: makeUniqueId(`${poiId}-${name}${locationId}`),
  isVisible: false,
  borderPoints,
  onClicked: (event: PolygonClickEvent) => {
    onClickEvent(event);
  },
});
export const useBlisterPolygons = () => {
  const blisterToAddValue = useRecoilValue(poiState.blisterToAdd);
  const editPointOfInterestValue = useRecoilValue(editPointOfInterest);
  const allPointOfInterestsValue = useRecoilValue(structurePois);
  const selectedAssemblyId = useRecoilValue(selectedAssembly);

  const [polygons, setPolygons] = useRecoilState(blisterPolygons);

  useEffect(() => {
    if (blisterToAddValue?.state === 'UpdatingBlister') return;
    if (blisterToAddValue?.state === 'Loading') return;
    if (!selectedAssemblyId) setPolygons(undefined);
    if (!blisterToAddValue) setPolygons(undefined);
    const blisterPointOfInterest = editPointOfInterestValue?.pointOfInterest?.blister;
    if (blisterPointOfInterest?.borders3d && blisterPointOfInterest?.borders3d.length > 0) {
      /* blisterToAdd is only defined when the user is creating a new blister, because of
         this, it should always be visible regardless of blisterPolygonVisible */
      const selectedBlisterPolygons = blisterPointOfInterest?.borders3d.map(({ points }) => {
        const data = makePolygonPoints(
          editPointOfInterestValue.pointOfInterest?.name || 'unnamed blister',
          editPointOfInterestValue?.pointOfInterest?.id || '',
          editPointOfInterestValue.pointOfInterest?.location?.id,
          points,
          () => {}
        );
        return {
          ...data,
          styles: {
            normal: {
              // orange
              color: new Color('#ff8c00'),
              emissiveIntensity: 0.5,
              opacity: 0.5,
              size: 0.5,
            },
            highlighted: {
              color: new Color('#ff8c00'),
              emissiveIntensity: 0.5,
              opacity: 0.8,
              size: 0.5,
            },
          },
        };
      });
      setPolygons(selectedBlisterPolygons);
    }

    const allBlisterPolygons = allPointOfInterestsValue
      .filter((poi) => poi.blister)
      .flatMap(({ name, locationId, blister, id }) => {
        return (blister?.borders3d || []).map(({ points }) =>
          makePolygonPoints(name || NOT_APPLICABLE_LABEL, id, locationId, points, () => {})
        );
      });
    setPolygons(allBlisterPolygons);
  }, [
    allPointOfInterestsValue,
    blisterToAddValue,
    editPointOfInterestValue,
    selectedAssemblyId,
    setPolygons,
  ]);

  return polygons;
};
