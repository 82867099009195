import { IconButton, InputAdornment } from '@mui/material';
import { useMemo } from 'react';
import { SphericalImageProps } from '@abyss/3d-viewer';
import {
  Maybe,
  InputAdormentType,
  InputAdormentIconTypeEnum,
  DisplayConfig,
} from '@/components/Analysis/Viewer/InspectionDataLoader/data.graphql';
import { INPUT_ADORMENT_ICON_MAP } from '@/constants';

export type Props = {
  inputAdorment: InputAdormentType | undefined;
  iconType: InputAdormentIconTypeEnum | undefined | '';
  displayConfig: Maybe<DisplayConfig> | undefined;
  inputAdormantHandlerMap?: {
    [InputAdormentIconTypeEnum.MeasurementTool]?: () => void;
    [InputAdormentIconTypeEnum.Reload]?: () => void;
  };
  selectedSpherical: SphericalImageProps | undefined;
};

export const useInputAdornment = ({
  inputAdorment,
  iconType,
  displayConfig,
  inputAdormantHandlerMap,
}: Props) => {
  const RenderInputAdormant: JSX.Element = useMemo(() => {
    if (inputAdorment && iconType) {
      const position = displayConfig?.inputAdorment?.position?.toLowerCase() || 'end';
      const emptyHandler = () => {};
      const onClickhandler = inputAdormantHandlerMap?.[iconType] || emptyHandler;

      return (
        <>
          <InputAdornment position={position as 'end' | 'start'}>
            <IconButton onClick={onClickhandler} edge="end">
              {INPUT_ADORMENT_ICON_MAP[iconType]}
            </IconButton>
          </InputAdornment>
        </>
      );
    }
    return <></>;
  }, [displayConfig?.inputAdorment?.position, iconType, inputAdormantHandlerMap, inputAdorment]);

  return RenderInputAdormant;
};
