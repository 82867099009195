import queryString from 'query-string';
import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CameraTargetProps, SphericalImageProps } from '@abyss/3d-viewer';
import * as state from '@/state';
import { AbyssViewerMode, AbyssViewerState, NormalVisibilityRange, PartId } from '@/types';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { useFeatureFlag } from '@/hooks';

const updateUrl = (key: string, value: string | undefined | null) => {
  const parsed = queryString.parse(window.location.search);
  if (value) {
    parsed[key] = value;
  } else if (parsed[key]) {
    delete parsed[key];
  }
  const newUrl = `${window.location.pathname}?${queryString.stringify(parsed)}`;
  window.history.replaceState({}, 'Abyss Fabric', newUrl);
};

export const updateCameraTargetUrlParameters = (cameraTarget: CameraTargetProps) => {
  const [lx, ly, lz] = cameraTarget.lookAt;
  updateUrl('look.x', lx.toString());
  updateUrl('look.y', ly.toString());
  updateUrl('look.z', lz.toString());
  const [px, py, pz] = cameraTarget.position ? cameraTarget.position : [0, 0, 0];
  updateUrl('position.x', px.toString());
  updateUrl('position.y', py.toString());
  updateUrl('position.z', pz.toString());
  updateUrl('fov', cameraTarget.fov ? cameraTarget.fov.toString() : '0');
};

export const useQueryParametersFromState = () => {
  const normalVisibilityRange = useRecoilValue(state.normalVisibilityRange);
  const selectedZone = useRecoilValue(state.selectedZone);
  const pointSize = useRecoilValue(state.pointSize);
  const abyssViewerState = useRecoilValue(state.abyssViewerState);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const sphericalPointCloudMode = useRecoilValue(state.sphericalPointCloudMode);
  const cameraTarget: CameraTargetProps | undefined = useRecoilValue(state.cameraTarget);
  const selectedParts = useRecoilValue(state.selectedPartSummaries);
  const shouldHighlightReviewedParts = useRecoilValue(state.shouldHighlightReviewedParts);
  const areSphericalsVisible = useRecoilValue(state.areSphericalsVisible);
  const shouldHighlightAllPartsWithSameTag = useRecoilValue(
    state.shouldHighlightAllPartsWithSameTag
  );
  const statesLoaded = useRecoilValue(state.statesLoaded);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const isBetaUser = useFeatureFlag('beta-user');

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('range', normalVisibilityRange);
    }
  }, [statesLoaded, isBetaUser, normalVisibilityRange]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('zone', selectedZone?.name);
    }
  }, [statesLoaded, isBetaUser, selectedZone]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('points', pointSize.toString());
    }
  }, [statesLoaded, isBetaUser, pointSize]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('highlightReviewed', shouldHighlightReviewedParts.toString());
    }
  }, [statesLoaded, isBetaUser, shouldHighlightReviewedParts]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('highlightTag', shouldHighlightAllPartsWithSameTag.toString());
    }
  }, [statesLoaded, isBetaUser, shouldHighlightAllPartsWithSameTag]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('mode', abyssViewerState.mode);
      if (selectedSpherical?.name) {
        updateUrl('spherical', selectedSpherical?.name);
      } else {
        updateUrl('spherical', undefined);
      }
    }
  }, [statesLoaded, isBetaUser, abyssViewerState, selectedSpherical]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('sphericalMode', sphericalPointCloudMode);
    }
  }, [statesLoaded, isBetaUser, sphericalPointCloudMode]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      updateUrl('sphericalVisible', areSphericalsVisible.toString());
    }
  }, [statesLoaded, isBetaUser, areSphericalsVisible]);

  useEffect(() => {
    if (statesLoaded && isBetaUser) {
      const partIds = selectedParts.map((part) => part.id);
      const selectedAssemblies = selectedParts.map((part) => part.assemblyTagName);
      const uniqueAssemblies = [...new Set(selectedAssemblies)];
      const selectedAssemblyIds = selectedParts.map((part) => part.assemblyId);
      const uniqueAssemblyIds = [...new Set(selectedAssemblyIds)];
      if (uniqueAssemblies.length === 1 && structureRelationships?.byPartId) {
        // TODO: assembly.parts can be precalculated
        const assemblyParts: PartId[] = [];
        structureRelationships?.byPartId.forEach((part, partId) => {
          if (part.assemblyId === uniqueAssemblyIds[0]) {
            assemblyParts.push(partId);
          }
        });
        if (selectedParts.length === assemblyParts.length) {
          updateUrl('assembly', uniqueAssemblies[0]);
        } else {
          updateUrl('parts', JSON.stringify(partIds.slice(0, 5)));
        }
      } else {
        updateUrl('parts', JSON.stringify(partIds.slice(0, 5)));
      }
    }
  }, [statesLoaded, isBetaUser, selectedParts, structureRelationships?.byPartId]);

  useEffect(() => {
    if (statesLoaded && isBetaUser && cameraTarget) {
      updateCameraTargetUrlParameters(cameraTarget);
    }
  }, [statesLoaded, isBetaUser, cameraTarget]);
};

export const useSetStateFromQueryParameters = (isInitialPointCloudLoaded: boolean) => {
  const setNormalVisibilityRange = useSetRecoilState(state.normalVisibilityRange);
  const setSelectedZone = useSetRecoilState(state.selectedZone);
  const setStatesLoaded = useSetRecoilState(state.statesLoaded);
  const setPointSize = useSetRecoilState(state.pointSize);
  const setAbyssViewerState = useSetRecoilState(state.abyssViewerState);
  const setCurrentSpherical = useSetRecoilState(state.selectedSpherical);
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);
  const setSphericalPointCloudState = useSetRecoilState(state.sphericalPointCloudState);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setPanelsMode = useSetRecoilState(state.panelsMode);
  const setSelectedPartIds = useSetRecoilState(state.selectedPartIds);
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const setShouldHighlightAllPartsWithSameTag = useSetRecoilState(
    state.shouldHighlightAllPartsWithSameTag
  );
  const setShouldHighlightReviewedParts = useSetRecoilState(state.shouldHighlightReviewedParts);
  const structureLocations = useRecoilValue(state.structureLocations);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const structureId = useRecoilValue(state.selectedStructureId);
  const zones = useRecoilValue(state.zones);

  const isBetaUser = useFeatureFlag('beta-user');

  const isDone = useRef<boolean>(false);

  useEffect(() => {
    if (!isDone.current && isInitialPointCloudLoaded && isBetaUser) {
      isDone.current = true;
      const parsed = queryString.parse(window.location.search);
      if (parsed.range) {
        setNormalVisibilityRange(parsed.range as NormalVisibilityRange);
      }
      if (parsed.zone) {
        zones.forEach((zone) => {
          if (zone.name === parsed.zone) {
            setSelectedZone(zone);
          }
        });
      }
      if (parsed.points) {
        setPointSize(Number.parseFloat(parsed.points as string));
      }
      if (parsed.sphericalVisible) {
        setAreSphericalsVisible(parsed.sphericalVisible === 'true');
      }
      if (parsed.assembly && structureRelationships?.byAssemblyId) {
        // TODO: assemblyByTagName can be precomputed
        const [, assemblyId] = structureRelationships.byAssemblyId.find((currentAssembly) => {
          return currentAssembly.tagName === parsed.assembly;
        });
        if (assemblyId) {
          // TODO: assembly.parts can be precomputed
          const byPartId = structureRelationships?.byPartId;
          const partIds: string[] = [];
          byPartId.forEach((part, partId) => {
            if (part.assemblyId === assemblyId) {
              partIds.push(partId);
            }
          });
          setSelectedPartIds(partIds);
        }
      } else if (parsed.parts) {
        setSelectedPartIds(JSON.parse(parsed.parts as string));
      }
      setTimeout(() => {
        const parsedMode =
          typeof parsed.mode === 'string' &&
          ['Normal', 'IsolatePart', 'Spherical'].includes(parsed.mode)
            ? parsed.mode
            : 'Normal';

        let currentSpherical: SphericalImageProps | undefined;

        if (parsedMode !== 'Normal') {
          const abyssViewerState: AbyssViewerState = {
            mode: parsedMode as AbyssViewerMode,
          };
          if (parsedMode === 'IsolatePart') {
            setPanelsMode('IsolatePart');
          }
          if (parsedMode === 'Spherical') {
            const location = structureLocations?.find(
              (currentLocation) => currentLocation.name === parsed.spherical
            );
            if (location && location.pose) {
              const filesBaseUrl = localStorage.getItem('files-base-url');
              currentSpherical = {
                name: location.name,
                url: filesBaseUrl
                  ? `${filesBaseUrl}/${structureId}/images/${location.name}.jpg`
                  : getCloudfrontUrl(location.resourcePath!),
                position: [location.pose.x, location.pose.y, location.pose.z],
                rotation: [location.pose.roll, location.pose.pitch, location.pose.yaw],
                imageYawOffset: location.pose?.yawOffset,
              };
            }
          }
          setTimeout(() => {
            setAbyssViewerState(abyssViewerState);
            setCurrentSpherical(currentSpherical);
          }, 1000);
        }
        if (
          parsed['look.x'] &&
          parsed['look.y'] &&
          parsed['look.z'] &&
          parsed['position.x'] &&
          parsed['position.y'] &&
          parsed['position.z'] &&
          parsed.fov
        ) {
          const fov = Number.parseFloat(parsed.fov as string);
          const cameraTarget: CameraTargetProps = {
            lookAt: [
              Number.parseFloat(parsed['look.x'] as string),
              Number.parseFloat(parsed['look.y'] as string),
              Number.parseFloat(parsed['look.z'] as string),
            ],
            position: [
              Number.parseFloat(parsed['position.x'] as string),
              Number.parseFloat(parsed['position.y'] as string),
              Number.parseFloat(parsed['position.z'] as string),
            ],
            fov: fov > 0 ? fov : undefined,
          };
          setCameraTarget(cameraTarget);
        }
        if (parsed.highlightTag) {
          setShouldHighlightAllPartsWithSameTag(parsed.highlightTag === 'true');
        }
        if (parsed.highlightReviewed) {
          setShouldHighlightReviewedParts(parsed.highlightReviewed === 'true');
        }
        setStatesLoaded(true);
      }, 1000);
    }
  }, [
    isInitialPointCloudLoaded,
    isBetaUser,
    isDone,
    setNormalVisibilityRange,
    setSelectedZone,
    setStatesLoaded,
    setPointSize,
    setSphericalPointCloudMode,
    setSphericalPointCloudState,
    setAbyssViewerState,
    structureRelationships?.byAssemblyId,
    structureRelationships?.byPartId,
    zones,
    setAreSphericalsVisible,
    setSelectedPartIds,
    setPanelsMode,
    structureLocations,
    structureId,
    setCameraTarget,
    setShouldHighlightAllPartsWithSameTag,
    setShouldHighlightReviewedParts,
    setCurrentSpherical,
  ]);
};
