import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GenericAccordionProps } from '@/types';
import { accordianRootStyles, containerStyles, indicatorStyles } from './styles';

export const GenericAccordion = ({
  summary,
  children,
  numericIndicator,
  openByDefault,
  summaryStyles,
}: GenericAccordionProps) => {
  return (
    <Accordion elevation={0} sx={accordianRootStyles} defaultExpanded={openByDefault}>
      <AccordionSummary sx={accordianRootStyles} expandIcon={<ArrowDropDownIcon />}>
        <Stack sx={containerStyles}>
          <Typography sx={summaryStyles}>{summary}</Typography>
          {numericIndicator && <Chip sx={indicatorStyles} label={numericIndicator} />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
