import { createSvgIcon } from '@mui/material/utils';

export const BlisterIcon = createSvgIcon(
  <>
    <path d="M11.9798 7H12.0098C12.5598 7 12.9998 6.56 12.9998 6.02V2.98C12.9998 2.44 12.5598 2 12.0198 2H11.9898C11.4398 2 10.9998 2.44 10.9998 2.98V6.01C10.9998 6.56 11.4398 7 11.9798 7ZM16.8998 9.11C17.2898 9.5 17.9098 9.5 18.2998 9.11C18.9198 8.48 19.8198 7.57 20.4498 6.94C20.8398 6.56 20.8398 5.93 20.4498 5.55C20.0698 5.17 19.4398 5.17 19.0598 5.55L16.8898 7.7C16.4998 8.08 16.4998 8.71 16.8898 9.09L16.8998 9.11ZM5.70982 9.1C6.08982 9.49 6.71982 9.49 7.10982 9.1C7.48982 8.72 7.48982 8.09 7.10982 7.71L4.95982 5.54C4.57982 5.15 3.94982 5.15 3.56982 5.54L3.54982 5.55C3.15982 5.94 3.15982 6.56 3.54982 6.94C4.17982 7.56 5.08982 8.47 5.70982 9.1Z" />
    <path
      d="M1.5 17H5.5C6 15.3333 8 12 12 12C16 12 18 15.3333 18.5 17H22.5H1.5Z"
      strokeWidth="1.75"
      strokeLinejoin="round"
    />
    <path d="M5.5 17H18.5C18 15.3333 16 12 12 12C8 12 6 15.3333 5.5 17Z" />
  </>,
  'Blister'
);

export const CupsIcon = createSvgIcon(
  <>
    <path d="M9.7583 10.4383H14.2447V20H9.7583V10.4383Z" />
    <path d="M1 4.60712H7.84009V9.52932H1V4.60712Z" />
    <path d="M8.12012 4H15.3841V10.1336H8.12012V4Z" />
    <path d="M15.6606 4.60712H22.9996V9.52932H15.6606V4.60712Z" />
  </>,
  'CUPS'
);

export const DeadlegIcon = createSvgIcon(
  <path d="M4 16H7C7.55 16 8 15.55 8 15C8 14.45 7.55 14 7 14H4C3.45 14 3 14.45 3 15C3 15.55 3.45 16 4 16ZM10.5 16H13.5C14.05 16 14.5 15.55 14.5 15C14.5 14.45 14.05 14 13.5 14H10.5C9.95 14 9.5 14.45 9.5 15C9.5 15.55 9.95 16 10.5 16ZM17 16H20C20.55 16 21 15.55 21 15C21 14.45 20.55 14 20 14H17C16.45 14 16 14.45 16 15C16 15.55 16.45 16 17 16ZM4 20C4.55 20 5 19.55 5 19C5 18.45 4.55 18 4 18C3.45 18 3 18.45 3 19C3 19.55 3.45 20 4 20ZM8 20C8.55 20 9 19.55 9 19C9 18.45 8.55 18 8 18C7.45 18 7 18.45 7 19C7 19.55 7.45 20 8 20ZM12 20C12.55 20 13 19.55 13 19C13 18.45 12.55 18 12 18C11.45 18 11 18.45 11 19C11 19.55 11.45 20 12 20ZM16 20C16.55 20 17 19.55 17 19C17 18.45 16.55 18 16 18C15.45 18 15 18.45 15 19C15 19.55 15.45 20 16 20ZM20 20C20.55 20 21 19.55 21 19C21 18.45 20.55 18 20 18C19.45 18 19 18.45 19 19C19 19.55 19.45 20 20 20ZM4 12H10C10.55 12 11 11.55 11 11C11 10.45 10.55 10 10 10H4C3.45 10 3 10.45 3 11C3 11.55 3.45 12 4 12ZM14 12H20C20.55 12 21 11.55 21 11C21 10.45 20.55 10 20 10H14C13.45 10 13 10.45 13 11C13 11.55 13.45 12 14 12ZM3 5V7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7V5C21 4.45 20.55 4 20 4H4C3.45 4 3 4.45 3 5Z" />,
  'Deadleg'
);

export const HotboltingIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M23 11.9858C21.1531 15.3238 19.3061 18.6618 17.4865 22L6.48634 22C4.66659 18.662 2.81955 15.324 1 11.9858C2.81976 8.64775 4.63951 5.30974 6.48634 2L17.4865 2C19.3063 5.33801 21.1533 8.64754 23 11.9858ZM16.6444 11.9858C16.6444 14.6961 14.553 16.8645 11.9998 16.8645C9.41955 16.8645 7.3552 14.6963 7.3552 11.9858C7.3552 9.30396 9.41935 7.10702 11.9998 7.10702C14.5529 7.10702 16.6444 9.30388 16.6444 11.9858Z"
  />,
  'Hotbolting'
);

export const OtherIcon = createSvgIcon(
  <path d="M3 17.46V20.5C3 20.78 3.22 21 3.5 21H6.54C6.67 21 6.8 20.95 6.89 20.85L17.81 9.94L14.06 6.19L3.15 17.1C3.05 17.2 3 17.32 3 17.46ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04V7.04Z" />,
  'Other'
);

export const PaintDamageIcon = createSvgIcon(
  <path d="M18 4V3C18 2.45 17.55 2 17 2H5C4.45 2 4 2.45 4 3V7C4 7.55 4.45 8 5 8H17C17.55 8 18 7.55 18 7V6H19V10H10C9.45 10 9 10.45 9 11V21C9 21.55 9.45 22 10 22H12C12.55 22 13 21.55 13 21V12H20C20.55 12 21 11.55 21 11V5C21 4.45 20.55 4 20 4H18Z" />,
  'Paint Damage'
);

export const PitIcon = createSvgIcon(
  <>
    <path d="M22.6672 19.0002H1V12.5H4.79176C6.97785 12.5 8.04184 15.2084 11.8336 15.2084C16.167 15.2084 17.1545 12.5 19.4171 12.5H22.6672V19.0002Z" />
    <path d="M12.27 8.6112H12.2916C12.6889 8.6112 13.0066 8.29342 13.0066 7.90341V5.7078C13.0066 5.31779 12.6889 5 12.2988 5H12.2772C11.8799 5 11.5622 5.31779 11.5622 5.7078V7.89618C11.5622 8.29342 11.8799 8.6112 12.27 8.6112ZM15.8234 10.1351C16.1051 10.4168 16.5528 10.4168 16.8345 10.1351C17.2823 9.68012 17.9323 9.02288 18.3873 8.56787C18.669 8.29342 18.669 7.8384 18.3873 7.56395C18.1129 7.2895 17.6579 7.2895 17.3834 7.56395L15.8162 9.11677C15.5345 9.39122 15.5345 9.84623 15.8162 10.1207L15.8234 10.1351ZM7.74151 10.1279C8.01596 10.4096 8.47097 10.4096 8.75265 10.1279C9.0271 9.85346 9.0271 9.39844 8.75265 9.12399L7.19983 7.55673C6.92538 7.27506 6.47037 7.27506 6.19591 7.55673L6.18147 7.56395C5.8998 7.84563 5.8998 8.29342 6.18147 8.56787C6.63648 9.01566 7.29372 9.6729 7.74151 10.1279Z" />
  </>,
  'Pit'
);

export const SubseaIcon = createSvgIcon(
  <>
    <path d="M12.0166 13.8973C13.2687 13.8973 14.2837 12.8823 14.2837 11.6303C14.2837 10.3783 13.2687 9.36328 12.0166 9.36328C10.7646 9.36328 9.74963 10.3783 9.74963 11.6303C9.74963 12.8823 10.7646 13.8973 12.0166 13.8973Z" />
    <path d="M8.86639 7.33274C8.54253 7.00888 8.04202 7.00888 7.71816 7.33274C5.36283 9.68808 5.36283 13.5449 7.71816 15.9297C7.86537 16.0769 8.07146 16.1653 8.27756 16.1653C8.48365 16.1653 8.68974 16.0769 8.83695 15.9297C9.16081 15.6059 9.16081 15.1054 8.83695 14.7815C7.09989 13.0444 7.09989 10.218 8.83695 8.48096C9.16081 8.15711 9.16081 7.6566 8.86639 7.33274Z" />
    <path d="M16.3149 7.33274C15.9911 7.00888 15.4906 7.00888 15.1667 7.33274C14.8429 7.6566 14.8429 8.15711 15.1667 8.48096C16.9038 10.218 16.9038 13.0444 15.1667 14.7815C14.8429 15.1054 14.8429 15.6059 15.1667 15.9297C15.3139 16.0769 15.52 16.1653 15.7261 16.1653C15.9322 16.1653 16.1383 16.0769 16.2855 15.9297C18.6703 13.5449 18.6703 9.71752 16.3149 7.33274Z" />
    <path d="M17.2278 5.27024C16.9039 5.5941 16.9039 6.09461 17.2278 6.41847C20.113 9.27431 20.113 13.985 17.2278 16.8703C16.9039 17.1941 16.9039 17.6946 17.2278 18.0185C17.375 18.1657 17.5811 18.254 17.7872 18.254C17.9932 18.254 18.1993 18.1657 18.3465 18.0185C21.8796 14.4855 21.8796 8.7738 18.376 5.27024C18.0521 4.94638 17.5516 4.94638 17.2278 5.27024Z" />
    <path d="M6.7759 6.39112C7.09976 6.06726 7.09976 5.56675 6.7759 5.24289C6.45204 4.91904 5.95153 4.91904 5.62767 5.24289C2.12411 8.74646 2.12411 14.4582 5.62767 17.9912C5.77488 18.1384 5.98098 18.2267 6.18707 18.2267C6.39316 18.2267 6.59925 18.1384 6.74646 17.9912C7.07032 17.6673 7.07032 17.1668 6.74646 16.8429C3.89061 13.9871 3.89061 9.27641 6.7759 6.39112Z" />
  </>,
  'Subsea'
);

export const MeasurementToolIcon = createSvgIcon(
  <path d="M17.66 18.16L16.95 18.87C16.75 19.07 16.44 19.07 16.24 18.87C16.04 18.67 16.04 18.36 16.24 18.16L16.95 17.45L15.01 15.51L14.3 16.22C14.1 16.42 13.79 16.42 13.59 16.22C13.39 16.02 13.39 15.71 13.59 15.51L14.3 14.8L12.36 12.86L11.65 13.57C11.45 13.77 11.14 13.77 10.94 13.57C10.74 13.37 10.74 13.06 10.94 12.86L11.65 12.15L9.7 10.2L8.99 10.91C8.79 11.11 8.48 11.11 8.28 10.91C8.08 10.71 8.08 10.4 8.28 10.2L8.99 9.49L7.05 7.55L6.34 8.26C6.14 8.46 5.83 8.46 5.63 8.26C5.43 8.06 5.43 7.75 5.63 7.55L6.34 6.84L4.85 5.35C4.54 5.04 4 5.26 4 5.71V18.5C4 19.6 4.9 20.5 6 20.5H18.79C19.24 20.5 19.46 19.96 19.14 19.65L17.66 18.16ZM7 16.5V11.74L12.76 17.5H8C7.45 17.5 7 17.05 7 16.5Z" />,
  'Measurement Tool'
);

export const ReplayIcon = createSvgIcon(
  <path d="M12 5.50003V2.71003C12 2.26003 11.46 2.04003 11.15 2.36003L7.35 6.15003C7.15 6.35003 7.15 6.66003 7.35 6.86003L11.14 10.65C11.46 10.96 12 10.74 12 10.29V7.50003C15.73 7.50003 18.68 10.92 17.86 14.79C17.39 17.06 15.55 18.89 13.29 19.36C9.72 20.11 6.54 17.66 6.06 14.35C5.99 13.87 5.57 13.5 5.08 13.5C4.48 13.5 4 14.03 4.08 14.63C4.7 19.02 8.88 22.27 13.61 21.35C16.73 20.74 19.24 18.23 19.85 15.11C20.84 9.98003 16.94 5.50003 12 5.50003Z" />,
  'Replay Icon'
);
