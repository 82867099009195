/*
This function is used to get images in correct state to compile Image Set or Spherical Image set based on the type of image.
Spherical Image is for Normal Sphericals
Images are for images that has a sensor like drone images or Rectangular Images
*/

import {
  BaseImageProps,
  ImageProps,
  SphericalImageAnchorStyle,
  SphericalImageProps,
} from '@abyss/3d-viewer';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as state from '@/state';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { DEFAULT_COLORS } from '@/constants';

const searchedSphericalStyle: SphericalImageAnchorStyle = {
  color: DEFAULT_COLORS.colors[0],
  opacity: 1,
  size: 0.7,
};
export const useImageSets = (structureId: string) => {
  const recoilStructureLocations = useRecoilValue(state.structureLocations);
  const searchedSphericalId = useRecoilValue(state.searchedSphericalId);

  return useMemo(() => {
    const filesBaseUrl = localStorage.getItem('files-base-url');

    const rectilinearImages: ImageProps[] = [];
    const sphericalImages: SphericalImageProps[] = [];

    if (
      recoilStructureLocations &&
      Array.isArray(recoilStructureLocations) &&
      recoilStructureLocations.length > 0
    ) {
      recoilStructureLocations.reduce((accumulator, location) => {
        if (!location.resourcePath) return accumulator;

        const baseImage: BaseImageProps = {
          id: location.id,
          name: location.name,
          url: filesBaseUrl
            ? `${filesBaseUrl}/${structureId}/images/${location.name}.jpg`
            : getCloudfrontUrl(location.resourcePath),
          position: [location.pose?.x ?? 0, location.pose?.y ?? 0, location.pose?.z ?? 0],
          rotation: [location.pose?.roll ?? 0, location.pose?.pitch ?? 0, location.pose?.yaw ?? 0],
        };
        const styles =
          searchedSphericalId === location.id
            ? { normal: searchedSphericalStyle, highlighted: searchedSphericalStyle }
            : undefined;
        if (location?.imageSensor) {
          rectilinearImages.push({
            ...baseImage,
            width: location.imageSensor?.width,
            height: location.imageSensor?.height,
            focalLength: location.imageSensor?.focalLength,
            cx: location.imageSensor?.cx,
            cy: location.imageSensor?.cy,
            distance: location?.distance ?? undefined,
            styles,
          });
        } else {
          sphericalImages.push({
            ...baseImage,
            imageYawOffset: location.pose?.yawOffset,
            styles,
          });
        }
        return accumulator;
      });
    }

    return { sphericalImages, rectilinearImages };
  }, [recoilStructureLocations, searchedSphericalId, structureId]);
};
