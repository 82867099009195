import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import {
  GetStructureQuery,
  GetStructureRelationshipsQuery,
} from '../../Viewer/InspectionDataLoader/data.graphql';
import { AnalysisStructureRelationships } from '@/types';
import { auth0TokenState } from '../../../../state';

const structureRelationshipsQueryText = `
  query getStructureRelationships($structureId: String!) {
    allAnnotations(structureId: $structureId) {
      id
      annotationId
      partId
      locationId
    }
    allParts(structureId: $structureId) {
      id
      assemblyId
      class
    }
    allAssemblies(structureId: $structureId) {
      id
      tagName
      integerId
    }
  }
`;
// Tested doing query with Apollo. Overall performance  significantly reduced.
// I im guessing its because of the size of the query.
async function getRelationshipViaDB(
  token: string | undefined,
  structureId: string
): Promise<{ data: GetStructureRelationshipsQuery | undefined }> {
  try {
    const response = await fetch(`${process.env.API_URL}/graphql`, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: structureRelationshipsQueryText,
        variables: {
          structureId,
        },
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    throw new Error(`Failed to fetch structure from DB (HTTP ${response.status})`);
  } catch (error) {
    console.error('Failed to fetch structure from DB ->', error);
    return { data: undefined };
  }
}

async function getRelationshipViaS3(srPath: string) {
  try {
    const url = getCloudfrontUrl(srPath);
    const response = await fetch(url, { credentials: 'include' });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    throw new Error(`Failed to fetch structure from CDN (HTTP ${response.status})`);
  } catch (error) {
    console.error('Failed to fetch structure from CDN ->', error);
    return undefined;
  }
}
export const useSetStructureRelationship = (
  structureId: string,
  structure: GetStructureQuery['structure']
) => {
  const [structureRelationship, setStructureRelationships] = useRecoilState(
    state.structureRelationship
  );
  const setFilteredAssemblyIds = useSetRecoilState(state.filteredAssemblyIds);
  const token = useRecoilValue(auth0TokenState);

  useEffect(() => {
    const fetchData = async () => {
      let relationships: AnalysisStructureRelationships = {
        annotationIdToAssemblyId: {},
        annotationIdToAnnotation3d: {},
        annotation3dToAnnotationId: {},
        assemblyIdToAnnotation3dArray: {},
        assemblyIdAndLocationIdToAnnotation3dArray: {},
        assemblyIdToAssembly3d: {},
        partsByAnnotationId: {},
        parts: {},
      };

      try {
        if (structure?.structureRelationshipPath) {
          relationships = await getRelationshipViaS3(structure?.structureRelationshipPath);
        } else {
          const { data } = await getRelationshipViaDB(token, structureId);
          const allParts = data?.allParts ?? [];
          const allAssemblies = data?.allAssemblies ?? [];
          const allAnnotations = data?.allAnnotations ?? [];

          const partsById = allParts.reduce(
            (accumulator, current) => {
              accumulator[current.id] = current;
              return accumulator;
            },
            {} as { [key: string]: GetStructureRelationshipsQuery['allParts'][0] }
          );

          allAssemblies.forEach(({ id, integerId }) => {
            relationships.assemblyIdToAnnotation3dArray[id] = [];
            relationships.assemblyIdAndLocationIdToAnnotation3dArray[id] = {};
            if (integerId !== undefined && integerId !== null) {
              relationships.assemblyIdToAssembly3d[id] = integerId;
            }
          });

          allAnnotations.forEach((annotation) => {
            const { id, annotationId: annotation3dReference, partId, locationId } = annotation;

            relationships.annotationIdToAnnotation3d[id] = annotation3dReference;
            relationships.annotation3dToAnnotationId[annotation3dReference] = id;

            if (partId) {
              relationships.partsByAnnotationId[partId] =
                relationships.partsByAnnotationId[partId] ?? [];
              relationships.partsByAnnotationId[partId].push(annotation3dReference);

              relationships.parts[partId] = relationships.parts[partId] ?? {};

              if (relationships.parts[partId].annotations) {
                relationships.parts[partId].annotations.push(annotation3dReference);
              } else {
                relationships.parts[partId] = {
                  annotations: [annotation3dReference],
                  class: partsById[partId].class,
                };
              }

              const { assemblyId } = partsById[partId];
              if (assemblyId) {
                relationships.annotationIdToAssemblyId[id] = assemblyId;
                relationships.assemblyIdToAnnotation3dArray[assemblyId]?.push(
                  annotation3dReference
                );

                if (locationId) {
                  const assemblyEntry =
                    relationships.assemblyIdAndLocationIdToAnnotation3dArray[assemblyId];
                  if (!Object.hasOwn(assemblyEntry, locationId)) {
                    assemblyEntry[locationId] = [];
                  }
                  assemblyEntry[locationId].push(annotation3dReference);
                }
              }
            }
          });
        }

        if (relationships) {
          setStructureRelationships(relationships);
        }
      } catch (error) {
        console.error(`Error Fetching Structure Relationships - ${error}`);
      }
    };

    if (structureId && structure) {
      fetchData();
    }
  }, [structureId, structure, setStructureRelationships, token, setFilteredAssemblyIds]);

  return structureRelationship;
};
