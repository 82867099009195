import { Box, Typography } from '@mui/material';
import { alpha, styled as muiStyled } from '@mui/material/styles';
import { abyssColors } from '@/theme/colors';

export const PoiFormHeading = muiStyled(Typography)(() => ({
  fontSize: '1.6rem',
  lineHeight: 2.4,
  fontWeight: 700,
}));

export const PoiFormSubHeading = muiStyled(Typography)(() => ({
  fontSize: '1.6rem',
  lineHeight: 2.4,
  fontWeight: 500,
}));

export const ErrorChip = muiStyled(Box)(() => ({
  backgroundColor: alpha(abyssColors.error[50], 0.5),
  borderRadius: '4px',
  height: '22px',
  padding: '0px 10px',
  color: abyssColors.error[600],
  fontSize: '1.2rem',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
}));
