import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';
import { TRANSPARENT_COLOR } from '@/constants';
import { usePrevious } from '@/hooks';
import { Colors } from '@/types';
import { getRandomItem } from '@/utils';

/*
    This handles switching to isolated part mode
    by hiding unselected parts
*/
export const useInitialiseMode = (colorMap: ColorMap | undefined) => {
  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const selectedPartIds = useRecoilValue(state.selectedPartIds);

  useEffect(() => {
    if (abyssViewerMode !== 'IsolatePart') return;
    if (selectedPartIds.length > 1) return;
    if (colorMap) {
      const byAnnotationId = structureRelationships?.byAnnotationId;
      if (!byAnnotationId) return;
      byAnnotationId.forEach((annotation) => {
        const { annotation3dReference, partId } = annotation;
        if (partId !== selectedPartIds[0]) {
          colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
        }
      });
    }
  }, [abyssViewerMode, colorMap, selectedPartIds, structureRelationships?.byAnnotationId]);
};

/*
    This handles selection/deselection of annotations
    by updating colours for selected annotations
    and reverting colours for deselected annotations based on the current visibility option
*/
export const useAnnotationSelection = (
  colorMap: ColorMap | undefined,
  colors: number[],
  selectedColour: number
) => {
  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const structureRelationships = useRecoilValue(state.structureRelationships);

  const selectedAnnotationIds = useRecoilValue(state.selectedAnnotationIds);
  const previousSelectedAnnotationIds = usePrevious(selectedAnnotationIds);

  const isolatedPartVisibilityRange = useRecoilValue(state.isolatedPartVisibilityRange);

  useEffect(() => {
    if (abyssViewerMode !== 'IsolatePart') return;
    const byAnnotationId = structureRelationships?.byAnnotationId;
    if (!byAnnotationId) return;
    if (!colorMap) return;

    const deselectedAnnotationIds = previousSelectedAnnotationIds?.filter(
      (annotationId) => !selectedAnnotationIds.includes(annotationId)
    );

    const newlySelectedAnnotationIds = selectedAnnotationIds.filter(
      (annotationId) => !previousSelectedAnnotationIds?.includes(annotationId)
    );

    switch (isolatedPartVisibilityRange) {
      case 'Selected':
        deselectedAnnotationIds?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
          }
        });
        newlySelectedAnnotationIds.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(annotation3dReference, selectedColour);
          }
        });
        break;

      case 'Unselected':
        deselectedAnnotationIds?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(annotation3dReference, getRandomItem(colors));
          }
        });
        newlySelectedAnnotationIds.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
          }
        });
        break;

      case 'All':
        deselectedAnnotationIds?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(annotation3dReference, getRandomItem(colors));
          }
        });
        newlySelectedAnnotationIds
          .filter((annotationId) => !previousSelectedAnnotationIds?.includes(annotationId))
          .forEach((annotationId) => {
            const annotation = byAnnotationId.get(annotationId);
            if (annotation) {
              const { annotation3dReference } = annotation;
              colorMap.setColor(annotation3dReference, selectedColour);
            }
          });
        break;

      default:
        break;
    }
  }, [
    abyssViewerMode,
    colorMap,
    colors,
    isolatedPartVisibilityRange,
    previousSelectedAnnotationIds,
    selectedAnnotationIds,
    selectedColour,
    structureRelationships?.byAnnotationId,
  ]);
};

/*
    This handles switching between the different isolate part visibility options
    by updating colours for all annotations of a selected part based on current visibility option.
    Each visible annotation is given a different random colour.
*/
export const useVisibilityOptions = (
  colorMap: ColorMap | undefined,
  colors: number[],
  selectedColour: number
) => {
  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const previousAbyssViewerMode = usePrevious(abyssViewerMode);
  const structureRelationships = useRecoilValue(state.structureRelationships);

  const annotationIdsForSelectedPartId = useRecoilValue(state.annotationIdsForSelectedPart);
  const selectedAnnotationIds = useRecoilValue(state.selectedAnnotationIds);

  const isolatedPartVisibilityRange = useRecoilValue(state.isolatedPartVisibilityRange);

  const previousIsolatedPartVisibilityRange = usePrevious(isolatedPartVisibilityRange);

  useEffect(() => {
    if (abyssViewerMode !== 'IsolatePart') return;
    const byAnnotationId = structureRelationships?.byAnnotationId;

    // Because this side effect updates the colors of all annotations for a part,
    // don't continue if user is not switching between visibility options
    // e.g. when user just changed the selection.
    // Need this validation because selection is a dependency of this useEffect
    // since we need to keep the selected colors when switching between visibility options.

    if (
      previousAbyssViewerMode === abyssViewerMode &&
      previousIsolatedPartVisibilityRange === isolatedPartVisibilityRange
    ) {
      return;
    }

    if (!byAnnotationId) return;
    if (!colorMap) return;

    const isAnnotationIdSelected = (annotationId: string): boolean =>
      selectedAnnotationIds.includes(annotationId);

    switch (isolatedPartVisibilityRange) {
      case 'Selected':
        annotationIdsForSelectedPartId?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(
              annotation3dReference,
              isAnnotationIdSelected(annotationId) ? selectedColour : TRANSPARENT_COLOR
            );
          }
        });

        break;

      case 'Unselected':
        annotationIdsForSelectedPartId?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(
              annotation3dReference,
              isAnnotationIdSelected(annotationId) ? TRANSPARENT_COLOR : getRandomItem(colors)
            );
          }
        });

        break;

      case 'All':
        annotationIdsForSelectedPartId?.forEach((annotationId) => {
          const annotation = byAnnotationId.get(annotationId);
          if (annotation) {
            const { annotation3dReference } = annotation;
            colorMap.setColor(
              annotation3dReference,
              isAnnotationIdSelected(annotationId) ? selectedColour : getRandomItem(colors)
            );
          }
        });

        break;

      default:
        break;
    }
  }, [
    abyssViewerMode,
    annotationIdsForSelectedPartId,
    colorMap,
    colors,
    isolatedPartVisibilityRange,
    previousAbyssViewerMode,
    previousIsolatedPartVisibilityRange,
    selectedAnnotationIds,
    selectedColour,
    structureRelationships?.byAnnotationId,
  ]);
};

export const useIsolatePartEffects = (colorMap: ColorMap | undefined, allColors: Colors) => {
  useInitialiseMode(colorMap);
  useAnnotationSelection(colorMap, allColors.colors, allColors.selectedColour);
  useVisibilityOptions(colorMap, allColors.colors, allColors.selectedColour);
};
