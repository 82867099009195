import { Typography, styled as muiStyled, SxProps, Theme } from '@mui/material';

export const AutoPopulatedTypography = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
}));

export const AutoPopulatedBoxStyles: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  gap: '10px',
  '& .MuiTextField-root': { width: '100%' },
};
