import { useCallback, useMemo } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { SphericalImageProps, SvgMarkerSetProps } from '@abyss/3d-viewer';
import * as analysisState from '@/components/Analysis/state';
import * as poiState from '../state';
import {
  pointOfInterestIcon,
  pointOfInterestHoveredIcon,
} from '@/components/shared/PointOfInterestIcon';
import { Point3d, PointOfInterestQuery } from '@/types';
import { uncertainBlisterColor } from '@/theme/colors';
import { upperSnakeCase } from '@/utils';
import { BLISTER_TYPE } from '@/constants';

const SCALE_ADJUSTMENT_FACTOR = 0.05;

const point3dToArray = (point: Point3d) =>
  [point.x, point.y, point.z] as [x: number, y: number, z: number];

export function usePointOfInterestMarkers(selectedSpherical?: SphericalImageProps) {
  const selectedAssemblyId = useRecoilValue(analysisState.selectedAssemblyId);
  const poiFilteredByCheckbox = useRecoilValue(poiState.poiFilteredByCheckbox);
  const setSelectedPoiId = useSetRecoilState(poiState.selectedPointOfInterestId);
  const [blisterToAdd, setBlisterToAdd] = useRecoilState(poiState.blisterToAdd);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const arePoisVisible = useRecoilValue(analysisState.arePoisVisible);
  const poiTemplateIcons = useRecoilValue(analysisState.poiTemplateIcons);

  const scale = useMemo(() => {
    return SCALE_ADJUSTMENT_FACTOR * (selectedSpherical ? 0.3 : 0.5);
  }, [selectedSpherical]);

  const handleMarkerClick = useCallback(
    (poi: PointOfInterestQuery[0]) => {
      setSelectedPoiId(poi.id);
      setBlisterToAdd((current) => ({
        ...current,
        state: 'Reviewing',
      }));
    },
    [setBlisterToAdd, setSelectedPoiId]
  );

  const poiToExclude = useMemo(() => {
    if (blisterToAdd?.state !== 'UpdatingBlister') return undefined;
    return editPointOfInterest.pointOfInterest?.id;
  }, [blisterToAdd, editPointOfInterest]);

  const filteredPois = useMemo(() => {
    if (!arePoisVisible) return [];

    if (selectedAssemblyId) {
      return poiFilteredByCheckbox.filter(
        (poi) => poi.assembly.id === selectedAssemblyId && poi.id !== poiToExclude
      );
    }
    return poiToExclude
      ? poiFilteredByCheckbox.filter((poi) => poi.id !== poiToExclude)
      : poiFilteredByCheckbox;
  }, [poiFilteredByCheckbox, arePoisVisible, selectedAssemblyId, poiToExclude]);

  const poiMarkerSets: SvgMarkerSetProps[] = useMemo(() => {
    const poisByType = new Map<
      string,
      { type: string; isUncertain: boolean; pois: PointOfInterestQuery }
    >();
    filteredPois.forEach((poi) => {
      const isUncertain = poi.type === BLISTER_TYPE && !poi?.blister?.maxHeight;
      const key = `${poi.type.toLowerCase()}-${isUncertain ? '1' : '0'}`;
      let entry = poisByType.get(key);
      if (!entry) {
        entry = { type: poi.type, isUncertain, pois: [] };
        poisByType.set(key, entry);
      }
      entry.pois.push(poi);
    });
    const markerSets: SvgMarkerSetProps[] = [];
    poisByType.forEach(({ type, isUncertain, pois }) => {
      const poiIcon =
        poiTemplateIcons.find((poiTemplate) => poiTemplate.name === upperSnakeCase(type))?.icon ??
        undefined;
      const poiIconColor =
        poiTemplateIcons.find((poiTemplate) => poiTemplate.name === upperSnakeCase(type))?.color ??
        undefined;
      const markerSet: SvgMarkerSetProps = {
        id: `poi-markers-${type.toLowerCase()}-${isUncertain ? '1' : '0'}`,
        normalSvg: {
          url: pointOfInterestIcon({
            type,
            poiIcon,
            selectedPoi: false,
            iconColor: poiIconColor,
            ...(isUncertain ? { overrideColor: uncertainBlisterColor } : {}),
          }),
          renderOrder: 2,
          zStep: 0.1,
          renderOrderStep: 0,
          depthWrite: true,
        },
        hoveredSvg: {
          url: pointOfInterestHoveredIcon({
            type: type,
            poiIcon,
            selectedPoi: false,
            iconColor: poiIconColor,
            ...(isUncertain ? { overrideColor: uncertainBlisterColor } : {}),
          }),
          renderOrder: 2,
          zStep: 0.1,
          renderOrderStep: 0,
          depthWrite: true,
        },

        instances: pois.map((poi) => ({
          id: poi.id,
          position: point3dToArray(poi.centerPoint3d),
          normalScale: scale,
          hoveredScale: scale,
          onClick: () => handleMarkerClick(poi),
        })),
      };

      markerSets.push(markerSet);
    });
    return markerSets;
  }, [filteredPois, poiTemplateIcons, scale, handleMarkerClick]);

  return poiMarkerSets;
}
