import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ColorMap, PointCloudMaterialProps } from '@abyss/3d-viewer';

import { useColors } from './colors';
import { useNormalEffects, useImageViewpointEffects } from './ViewerModes';
import { pointSize as pointSizeAtom } from '@/state';
import {
  selectedAssemblyId as selectedAssemblyIdAtom,
  hasRgb,
  selectedStructureDefectsLegends,
} from '@/components/Analysis/state';
import { PICKABLE_COLOR, TRANSPARENT_COLOR } from '@/constants';
import { useDefectColorEffects } from './useDefectColorEffects';
import { OctreeKey } from '../../Viewer/InspectionDataLoader/data.graphql';

export function usePointCloudMaterials() {
  const pointSize = useRecoilValue(pointSizeAtom);

  const selectedAssemblyId = useRecoilValue(selectedAssemblyIdAtom);
  const hasColor = useRecoilValue(hasRgb);
  const defectLegends = useRecoilValue(selectedStructureDefectsLegends);

  const maxId = 1024 * 1024 - 1; // should be same as `maxId` of `annotation` field in octree dataformat

  const annotationPickingMap = useMemo(() => {
    return new ColorMap(maxId, undefined, PICKABLE_COLOR);
  }, [maxId]);

  // This sets the initial color map for points WITHOUT actually setting the colors themselves.
  // Color setting is handled by the various effects per viewer mode (see /ViewerModes/*/effects.ts)
  //
  // Doing it this way is a performance optimisation
  // - changing pointCloudMaterials is expensive,
  // - changing colors by using an existing pointCloudMaterial's colorMap.set is much faster
  const pointCloudMaterials = useMemo(() => {
    const colorMap = new ColorMap(maxId, undefined, TRANSPARENT_COLOR);
    const annotationMaterial = {
      colorMap,
      attributeName: 'annotation',
      visibilityMapForPicking: annotationPickingMap,
      pointSize,
      hasColor,
    };

    // NOTE: if no defect colours are defined and retrieved from the database an asset will not be visible
    //  when selected because of TRANSPARENT_COLOR
    const defectsColorMap = new ColorMap(maxId, undefined, TRANSPARENT_COLOR);
    const degreeOfRustingColorMap = new ColorMap(maxId, undefined, TRANSPARENT_COLOR);

    if (defectLegends && defectLegends?.length > 0) {
      const defectsMaterial = {
        colorMap: defectsColorMap,
        attributeName: 'defect',
        visibilityAttributeName: 'annotation',
        visibilityMap: colorMap,
        visibilityMapForPicking: annotationPickingMap,
        pointSize,
      };

      const degreeOfRustingMaterial = {
        colorMap: degreeOfRustingColorMap,
        attributeName: 'degree_of_rusting',
        visibilityAttributeName: 'annotation',
        visibilityMap: colorMap,
        visibilityMapForPicking: annotationPickingMap,
        pointSize,
      };

      return new Map<string, PointCloudMaterialProps>([
        ['annotation', annotationMaterial],
        [OctreeKey.Defect, defectsMaterial],
        [OctreeKey.DegreeOfRusting, degreeOfRustingMaterial],
      ]);
    }

    return new Map<string, PointCloudMaterialProps>([['annotation', annotationMaterial]]);
  }, [maxId, annotationPickingMap, pointSize, hasColor, defectLegends]);

  // Define when to use which material
  const material = useMemo(() => {
    const selectedDefect = defectLegends?.find((defect) => defect.isEnabled);
    return selectedDefect?.octreeKey ?? 'annotation';
  }, [defectLegends]);

  const colorMap: ColorMap | undefined = useMemo(() => {
    const colorMapCheck = pointCloudMaterials.get('annotation')?.colorMap;
    return colorMapCheck && 'setColor' in colorMapCheck ? colorMapCheck : undefined;
  }, [pointCloudMaterials]);

  const defectColorMap: ColorMap | undefined = useMemo(() => {
    const colorMapCheck = pointCloudMaterials.get(OctreeKey.Defect)?.colorMap;
    return colorMapCheck && 'setColor' in colorMapCheck ? colorMapCheck : undefined;
  }, [pointCloudMaterials]);

  const degreeOfRustingColorMap: ColorMap | undefined = useMemo(() => {
    const colorMapCheck = pointCloudMaterials.get(OctreeKey.DegreeOfRusting)?.colorMap;
    return colorMapCheck && 'setColor' in colorMapCheck ? colorMapCheck : undefined;
  }, [pointCloudMaterials]);

  const { allColors } = useColors();

  useNormalEffects(colorMap, annotationPickingMap, allColors, selectedAssemblyId);
  useImageViewpointEffects(colorMap, annotationPickingMap, allColors, selectedAssemblyId);
  useDefectColorEffects(defectColorMap, degreeOfRustingColorMap);

  return { pointCloudMaterials, material };
}
