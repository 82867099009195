import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useMemo, useState, useCallback, ChangeEvent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import * as state from '@/components/Analysis/state';
import { Permissions, Viewpoint } from '@/types';
import { ViewpointRow } from './ViewpointRow';
import { CORROSION_LEVEL_ORDER } from '@/constants';
import { DockPanel } from '@/components/shared/DockPanel';
import { useGetAssemblyDataForInsightsQuery } from './data.graphql';

import { useViewpointsByAssembly } from '@/components/Analysis/Viewer/hooks';
import { mapLocationToCurrentImage } from '../../hooks/useImageFromLocationId';
import { useHavePermission } from '@/hooks';
import { TablePaginationContainer } from '@/components/Analysis/Insights/EquipmentTable/styles';
import {
  isSphericalAnalysedRelatedToAssembly,
  useAssemblySeenByViewpoints,
} from '../../hooks/useAssemblySeenByViewpoints';

type SortDirection = 'asc' | 'desc';

const distanceComparator = (sortDirection: SortDirection) => {
  return sortDirection === 'desc'
    ? (a: Viewpoint, b: Viewpoint) => (b.distance ?? 0) - (a.distance ?? 0)
    : (a: Viewpoint, b: Viewpoint) => (a.distance ?? 0) - (b.distance ?? 0);
};

const corrosionComparator = (sortDirection: SortDirection) => {
  return sortDirection === 'desc'
    ? (a: Viewpoint, b: Viewpoint) =>
        CORROSION_LEVEL_ORDER[b.corrosion?.primaryCategory ?? 'CLEAN'] -
        CORROSION_LEVEL_ORDER[a.corrosion?.primaryCategory ?? 'CLEAN']
    : (a: Viewpoint, b: Viewpoint) =>
        CORROSION_LEVEL_ORDER[a.corrosion?.primaryCategory ?? 'CLEAN'] -
        CORROSION_LEVEL_ORDER[b.corrosion?.primaryCategory ?? 'CLEAN'];
};

const SortAccessibleLabel = ({ isAsc }: { isAsc: boolean }) => {
  return (
    <Box component="span" sx={visuallyHidden}>
      {isAsc ? 'sorted ascending' : 'sorted descending'}
    </Box>
  );
};

export const Viewpoints = ({
  setViewpointTotal,
  viewpointTotal,
}: {
  setViewpointTotal?: (total: number) => void;
  viewpointTotal?: number;
}) => {
  const assemblyId = useRecoilValue(state.selectedAssemblyId);
  const allViewpoints = useRecoilValue(state.structureLocations);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);

  const [selectedSpherical, setSelectedSpherical] = useRecoilState(state.selectedSpherical);

  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);

  const assemblySeenByViewpoint = useAssemblySeenByViewpoints(selectedAssemblyId);

  const { data: assemblyViewpointsData } = useGetAssemblyDataForInsightsQuery({
    variables: {
      assemblyId,
    },
    skip: !assemblyId,
    errorPolicy: 'all',
  });

  const assemblyViewpoints = useViewpointsByAssembly(
    selectedAssemblyId,
    assemblyViewpointsData?.assembly?.seenByViewpoints ?? undefined
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  useEffect(() => {
    if (setViewpointTotal && assemblyViewpointsData?.assembly?.seenByViewpoints)
      setViewpointTotal(assemblyViewpointsData?.assembly?.seenByViewpoints.length);
  }, [setViewpointTotal, assemblyViewpointsData?.assembly?.seenByViewpoints]);

  const goToViewpoint = useCallback(
    (viewpointId: string) => {
      const selectedViewpoint = allViewpoints?.find((viewpoint) => viewpoint.id === viewpointId);
      if (selectedViewpoint?.pose) {
        setSelectedSpherical(mapLocationToCurrentImage(selectedViewpoint));
      }
    },
    [allViewpoints, setSelectedSpherical]
  );

  const [sortByField, setSortByField] = useState('corrosion');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const sortBy = (field: 'corrosion' | 'distance') => {
    const isAsc = sortByField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortByField(field);
  };

  const viewpointsForDisplay = useMemo(() => {
    const comparator =
      sortByField === 'corrosion'
        ? corrosionComparator(sortDirection)
        : distanceComparator(sortDirection);
    const sortedViewpoints = assemblyViewpoints?.slice().sort(comparator);
    return sortedViewpoints?.slice(startIndex, endIndex);
  }, [sortDirection, sortByField, assemblyViewpoints, startIndex, endIndex]);

  const ViewPointRows = useMemo(() => {
    if (viewpointsForDisplay) {
      return viewpointsForDisplay.map((viewpoint) => {
        const isSelected = viewpoint.location.id === selectedSpherical?.id;
        const isAnalysed = isSphericalAnalysedRelatedToAssembly({
          locationId: viewpoint.location.id,
          seenByViewpoints: assemblySeenByViewpoint,
        });

        return (
          <ViewpointRow
            key={viewpoint.location.id}
            viewpoint={viewpoint}
            isSelected={isSelected}
            isAnalysed={isAnalysed}
            onViewpointSelected={goToViewpoint}
            canReadDefects={canReadDefects}
          />
        );
      });
    }
    return <></>;
  }, [canReadDefects, goToViewpoint, selectedSpherical?.id, viewpointsForDisplay]);

  return (
    <DockPanel data-testid="viewpoints">
      {viewpointsForDisplay && (
        <TableContainer sx={{ width: 'fit-content' }}>
          <Table sx={{ width: 'fit-content' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: 1 }}>Viewpoint</TableCell>
                {canReadDefects && (
                  <TableCell sx={{ p: 1 }}>
                    <TableSortLabel
                      active={sortByField === 'corrosion'}
                      direction={sortDirection}
                      onClick={() => sortBy('corrosion')}
                    >
                      Corrosion
                      {sortByField === 'corrosion' && (
                        <SortAccessibleLabel isAsc={sortDirection === 'asc'} />
                      )}
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell sx={{ p: 1 }}>
                  <TableSortLabel
                    active={sortByField === 'distance'}
                    direction={sortDirection}
                    onClick={() => sortBy('distance')}
                  >
                    Distance to asset
                    {sortByField === 'distance' && (
                      <SortAccessibleLabel isAsc={sortDirection === 'asc'} />
                    )}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{ViewPointRows}</TableBody>
          </Table>
          <TablePaginationContainer>
            <TablePagination
              slotProps={{
                select: { sx: { m: 0, p: 0 } },
              }}
              sx={{
                '& .MuiTablePagination-toolbar': { p: 0 },
                '& .MuiTablePagination-actions': { m: 0 },
                '& .MuiTablePagination-actions button': { p: 0 },
              }}
              count={viewpointTotal ?? 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[
                { label: '10', value: 10 },
                { label: '25', value: 25 },
                { label: '100', value: 100 },
                { label: 'All', value: viewpointTotal ?? 0 },
              ]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TablePaginationContainer>
        </TableContainer>
      )}
    </DockPanel>
  );
};
