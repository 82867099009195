import { styled as muiStyled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { primary } from '@/theme/colors';

export const Container = muiStyled(Box)<{ toolbarPosition: number | string }>(
  ({ toolbarPosition }) => ({
    position: 'absolute',
    top: '35%',
    right: toolbarPosition,
    zIndex: 1,
    transition: 'ease 0.5s',
    backgroundColor: primary.main,
  })
);
