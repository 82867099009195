import { atom } from 'recoil';
import { FindViewpointsToolState } from '@/types';
import { Number3 } from '@abyss/3d-viewer';
import { GetStructureLocationsQuery } from '../../../Viewer/InspectionDataLoader/data.graphql';

export const findViewpointsToolState = atom<FindViewpointsToolState>({
  default: FindViewpointsToolState.INACTIVE,
  key: 'AnalysisFindViewpointsToolState',
});

export const currentVoxelPoint = atom<Number3 | undefined>({
  default: undefined,
  key: 'AnalysisCurrentVoxelPoint',
});

export const currentVoxel = atom<
  | {
      voxelKey: string;
      largeVoxelKey: string;
    }
  | undefined
>({
  default: undefined,
  key: 'AnalysisCurrentVoxel',
});

export const largeVoxelData = atom<
  | {
      largeVoxelKey: string;
      voxels: {
        [voxelKey: string]: string[];
      };
    }
  | undefined
>({
  default: undefined,
  key: 'AnalysisLargeVoxelData',
});

export type FoundViewpoints = GetStructureLocationsQuery['allLocations'];

export const foundViewpoints = atom<FoundViewpoints>({
  default: [],
  key: 'AnalysisFoundViewpoints',
});

export const showPointMarker = atom<boolean>({
  default: true,
  key: 'AnalysisFindViewPointsShowPointMarker',
});
