import { Box, Typography, styled as muiStyled } from '@mui/material';

export const TablePaginationContainer = muiStyled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const TableHeadCell = muiStyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const ColumnTitleTypography = muiStyled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '1.4rem',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  color: '#425265',
}));
