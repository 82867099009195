import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';
import { Alert } from '@mui/material';
import * as state from '@/state';
import { useUpdateSupervoxelsMutation, UpdateSupervoxelsMutationVariables } from './data.graphql';
import { LoadingAlert } from '@/components/shared/LoadingAlert';
import Button from '@/components/shared/Button';
import { useLabellingStructureRelationship } from './helper';

export const SelectedVoxelItems = () => {
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const [updateSupervoxelsMutation] = useUpdateSupervoxelsMutation();
  const shouldDeselectAfterSetsupervoxelClass = useRecoilValue(
    state.shouldDeselectAfterSetsupervoxelClass
  );
  const [selectedSupervoxels, setSelectedSupervoxels] = useRecoilState(state.selectedSuperVoxels);
  const structureId = useRecoilValue(state.selectedStructureId);

  const { supervoxelClasses, changeSupervoxelClass } = useLabellingStructureRelationship();

  const handleSetAssetType = useCallback(
    async (assetType: { id: string; color: string; name: string }) => {
      if (!structureId) return;
      const payload: UpdateSupervoxelsMutationVariables = {
        input: {
          structureId,
          supervoxelClassId: assetType.id,
          supervoxel3DReference: [...selectedSupervoxels],
        },
      };
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <div>
            <LoadingAlert message="Updating Supervoxels class" />
          </div>
        ),
      });
      try {
        await updateSupervoxelsMutation({
          variables: { ...payload },
        });
        changeSupervoxelClass(assetType.id);
        if (shouldDeselectAfterSetsupervoxelClass) {
          setSelectedSupervoxels([]);
        }
        setSnackbarMessage({
          shouldShow: true,
          content: (
            <div>
              <Alert severity="success">Supervoxels class changed</Alert>
            </div>
          ),
        });
      } catch {
        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="error">Error updating Supervoxels</Alert>,
        });
      }
    },
    [
      structureId,
      setSnackbarMessage,
      changeSupervoxelClass,
      updateSupervoxelsMutation,
      setSelectedSupervoxels,
      selectedSupervoxels,
      shouldDeselectAfterSetsupervoxelClass,
    ]
  );

  const memorizedAssetTypes = useMemo(() => {
    if (!supervoxelClasses || supervoxelClasses.length === 0) return <></>;
    return (
      <>
        {supervoxelClasses.map((supervoxelClass, key) => (
          <div
            style={{
              marginLeft: '1px',
              border: '0px',
              clear: 'both',
              float: 'left',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            key={supervoxelClass.id}
          >
            <div
              style={{
                margin: '5px',
                width: '15px',
                height: '15px',
                background: `${supervoxelClass.color}`,
              }}
            />

            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => handleSetAssetType(supervoxelClass)}
              key={`"asset-type-${supervoxelClass.id}"`}
              sx={{
                margin: '4px',
              }}
            >
              {`[${key + 1}] ${supervoxelClass.name}`}
            </Button>
          </div>
        ))}
      </>
    );
  }, [supervoxelClasses, handleSetAssetType]);

  return <>{memorizedAssetTypes}</>;
};
