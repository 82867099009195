import { Typography, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';

export const HorizontalBar = styled.div`
  content: '';
  height: 1px;
  background-color: #000;
  min-width: 100%;
  margin: 10px 0 10px 0;
`;

export const EquipmentDetailsTypography = muiStyled(Typography)(() => ({
  fontSize: '1.2rem',
}));

export const BoxStyles = { my: 3 };
export const ViewpointsAccordianStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export const EquipmentRowStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  my: 1,
};
