type IconProps = {
  style?: React.CSSProperties;
};

export const DownloadIcon = ({ style = {} }: IconProps) => {
  return (
    <img
      src="/assets/icons/download-icon.svg"
      alt="Download"
      style={{ marginRight: '8px', height: '1.6rem', ...style }}
    />
  );
};
