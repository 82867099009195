import styled from 'styled-components';
import { Accordion } from '@mui/material';
import { abyssColors } from '@/theme/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const AccordionStyle = styled(Accordion)({
  border: `1px solid ${abyssColors.primary[200]}`,
  backgroundColor: `${abyssColors.primary[50]} !important`,
  borderRadius: '4px !important',
  padding: '16px 20px',
  marginBottom: '18px',
  marginTop: '32px !important',
  '&.MuiAccordion-root::before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
  },
});

export const scrollDesign = {
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    background: '#F6F8FB',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: '#c7c7c7',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#b3b3b3',
  },
  overflowX: 'scroll',
};
