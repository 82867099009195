import { atom } from 'recoil';

import {
  CameraTargetProps,
  ControlsType,
  SphericalImageProps,
  TransformMode,
  VisibilityBoxProps,
} from '@abyss/3d-viewer';
import { StructureStatus } from '@/components/Platforms/data.graphql';
import {
  GetStructureRelationshipsQuery,
  GetStructureLocationsQuery,
} from '@/components/Inspection/Tagging/InspectionDataLoader/data.graphql';
import { History } from '@/components/Inspection/Tagging/InspectionExplorer/Panels/Navigation/data.graphql';

import {
  IsolatedPartVisibilityRangeType,
  NormalVisibilityRange,
  PanelsMode,
  SnackbarMessage,
  SphericalPointCloudMode,
  SphericalPointCloudState,
  StructureRelationships,
  Zone,
  AbyssViewerState,
  LabellingStructureRelationships,
  LabellingBlendingMode,
  LabellingAnnotationMode,
  ToggleableDefectLegend,
  AllStructures,
  CuboidClickMode,
  CuboidData,
} from '@/types';

import { DEFAULT_VISIBILITY_BOX } from '@/constants';
import {
  getSessionStorageValue,
  removeSessionStorageItem,
  setSessionStorageValue,
} from '@/utils/sessionLocalStorage';

export const auth0TokenState = atom<string | undefined>({
  key: 'Auth0Token',
  default: undefined,
});

export const structureStatus = atom<StructureStatus | undefined>({
  default: undefined,
  key: 'StructureStatus',
});

export const structures = atom<AllStructures>({
  default: [],
  key: 'Structures',
});

export const selectedStructureId = atom<string | undefined>({
  default: undefined,
  key: 'SelectedStructureId',
});

export const inspectionMetadata = atom<GetStructureRelationshipsQuery['structure'] | undefined>({
  key: 'InspectionMetadata',
  default: undefined,
});

export const structureLocations = atom<GetStructureLocationsQuery['allLocations'] | undefined>({
  key: 'StructureLocations',
  default: undefined,
});

export const viewerCurrentSpherical = atom<SphericalImageProps | undefined>({
  default: undefined,
  key: 'ViewerCurrentSpherical',
});

export const viewerNextCurrentSpherical = atom<SphericalImageProps | undefined>({
  default: undefined,
  key: 'ViewerMextCurrentSpherical',
});

export const searchedSphericalId = atom<string | undefined>({
  key: 'SearchedSphericalId',
  default: undefined,
});

export const areSphericalsVisible = atom<boolean>({
  key: 'AreSphericalsVisible',
  default: true,
});

export const structureRelationships = atom<StructureRelationships | undefined>({
  key: 'StructureRelationships',
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const selectedPartIds = atom<string[]>({
  default: [],
  key: 'SelectedPartIds',
});

export const snackbarMessage = atom<SnackbarMessage>({
  key: 'SnackbarMessage',
  default: { shouldShow: false },
});

export const currentVisibilityBox = atom<VisibilityBoxProps>({
  default: DEFAULT_VISIBILITY_BOX,
  key: 'CurrentVisibilityBox',
});

export const cameraTarget = atom<CameraTargetProps | undefined>({
  default: undefined,
  key: 'CameraTarget',
});

export const abyssViewerState = atom<AbyssViewerState>({
  default: { mode: 'Normal' },
  key: 'AbyssViewerMode',
});

export const sphericalPointCloudState = atom<SphericalPointCloudState>({
  default: 'Loaded',
  key: 'SphericalPointCloudState',
});

export const sphericalPointCloudMode = atom<SphericalPointCloudMode>({
  default: 'All',
  key: 'SphericalPointCloudMode',
});

export const pointSize = atom<number>({
  default: 1,
  key: 'PointSize',
});

export const edlStrength = atom<number>({
  default: 0,
  key: 'EdlStrength',
});

export const selectedZone = atom<Zone | null>({
  // eslint-disable-next-line unicorn/no-null
  default: null,
  key: 'ZoneType',
});

export const selectedAnnotationIds = atom<string[]>({
  default: [],
  key: 'SelectedAnnotationIds',
});

export const isolatedPartVisibilityRange = atom<IsolatedPartVisibilityRangeType>({
  default: 'All',
  key: 'IsolatedPartVisibilityRange',
});

export const normalVisibilityRange = atom<NormalVisibilityRange>({
  default: 'All',
  key: 'NormalVisibilityRange',
});

// Have this as a separate part of state to abyss viewer mode,
// otherwise InspectorExplorer re-renders whenever abyss viewer mode change
// and the way rc-dock controlled mode seems to work (or how we are using it) means that
// a re-render re-mounts panel content
// (looks like react reconciliation can't figure out e.g ZonePanel is the same
// when switching between Normal and Spherical mode)
// TODO:
// revisit this atom later, current `Default` is used as default panel for all workflows
// we might need to have different modes for different workflow
// eg: `Tagging` when start tagging, `Finder` when start analysis
export const panelsMode = atom<PanelsMode>({
  default: 'Default',
  key: 'PanelsMode',
});

export const shouldHighlightReviewedParts = atom<boolean>({
  key: 'ShouldHighlightReviewedParts',
  default: false,
});

export const shouldHighlightAllPartsWithSameTag = atom<boolean>({
  default: false,
  key: 'ShouldHighlightAllPartsWithSameTag',
});

export const searchedHighlightedTag = atom<{
  id: string;
  tagName: string;
} | null>({
  // eslint-disable-next-line unicorn/no-null
  default: null,
  key: 'SearchedHighlightedTag',
});

export const zones = atom<Zone[]>({
  default: [],
  key: 'Zones',
});

export const shouldShowDemarcationOfZones = atom<boolean>({
  key: 'ShouldShowDemarcationOfZones',
  default: false,
});

export const zoneSegmentsHeight = atom<number>({
  default: 0,
  key: 'ZoneSegmentsHeight',
});

export const zonesSearchTerm = atom<string>({
  default: '',
  key: 'ZonesSearchTerm',
});

// used when user select supervoxels using pointClick or boxSelection
export const selectedSuperVoxels = atom<number[]>({
  key: 'selectedSuperVoxels',
  default: [],
});

export const labellingStructureRelationships = atom<LabellingStructureRelationships | undefined>({
  key: 'LabellingStructureRelationships',
  default: undefined,
});

// shared global state that can be reused in tagging/analysis/labelling
// used for increasing or decreasing brush size of cursor
export const brushSize = atom<number>({
  key: 'brushSize',
  default: 0,
});

export const labellingCurrentAnnotationMode = atom<LabellingAnnotationMode>({
  key: 'labellingCurrentAnnotationMode',
  default: LabellingAnnotationMode.SUPERVOXEL,
});

export const labellingShouldShowBlendingModes = atom<boolean>({
  key: 'labellingShouldShowBlendingModes',
  default: false,
});

export const labellingCurrentBlendingMode = atom<LabellingBlendingMode>({
  key: 'labellingCurrentBlendingMode',
  default: LabellingBlendingMode.SUPERVOXEL,
});

export const blendingModes = atom<
  Array<{
    id: LabellingBlendingMode;
    text: string;
  }>
>({
  key: 'blendingModes',
  default: [
    {
      id: LabellingBlendingMode.SUPERVOXEL,
      text: 'Annotation Colors',
    },
    {
      id: LabellingBlendingMode.SUPERVOXEL_RGB,
      text: 'RGB With Annotation Colors',
    },
    {
      id: LabellingBlendingMode.COLOUR,
      text: 'RGB Colors',
    },
  ],
});

export const annotationModes = atom<
  Array<{
    id: LabellingAnnotationMode;
    text: string;
  }>
>({
  key: '',
  default: [
    {
      id: LabellingAnnotationMode.SUPERVOXEL,
      text: 'Super voxel id',
    },
    {
      id: LabellingAnnotationMode.ASSET_TYPE,
      text: 'Asset type id',
    },
  ],
});

export const shouldDeselectAfterSetsupervoxelClass = atom<boolean>({
  key: 'shouldDeselectAfterSet',
  default: false,
});

export const displayAnimationEditor = atom<boolean>({
  default: false,
  key: 'DisplayAnimationEditor',
});

export const zoneHistories = atom<
  Array<{
    id: string;
    histories: Array<History>;
  }>
>({
  default: [],
  key: 'ZoneHistories',
});

export const selectedStructureDefectsLegends = atom<Array<ToggleableDefectLegend> | undefined>({
  default: undefined,
  key: 'SelectedStructureDefectsLegends',
});

export const pointBudget = atom<number>({
  default: 20_000_000,
  key: 'pointBudget',
});

export const rgbBlendLevel = atom<number>({
  default: 1,
  key: 'rgbBlendLevel',
});

export const isStructureCorrosionReady = atom<boolean>({
  default: false,
  key: 'isStructureCorrosionReady',
});

export const statesLoaded = atom<boolean>({
  default: false,
  key: 'statesLoaded',
});

export const isSubscriptionsEnabled = atom<boolean>({
  default: true,
  key: 'IsSubscriptionsEnabled',
});

export const areUnselectedEquipmentHidden = atom<boolean>({
  default: false,
  key: 'AreUnselectedEquipmentHidden',
});

export const hideTaggedPoints = atom<boolean>({
  default: false,
  key: 'HideTaggedPoints',
});

export const isSystemPopoverOpened = atom<boolean>({
  default: false,
  key: 'IsSystemPopoverOpened',
});

export const canDisplaySystemPopover = atom<boolean>({
  default: true,
  key: 'CanDisplaySystemPopover',
  effects: [
    ({ setSelf, onSet }) => {
      const storageKey = 'CanDisplaySystemPopover';
      const savedValue = getSessionStorageValue(storageKey, true);
      setSelf(savedValue);

      onSet((newValue, _, isReset) => {
        if (isReset) {
          removeSessionStorageItem(storageKey);
        } else {
          setSessionStorageValue(storageKey, newValue);
        }
      });
    },
  ],
});

export const cuboidControlsType = atom<ControlsType>({
  default: ControlsType.AlignedBox,
  key: 'CuboidControlsType',
});

export const cuboidTransformMode = atom<TransformMode>({
  default: TransformMode.Translate,
  key: 'CuboidTransformMode',
});

export const currentCuboid = atom<CuboidData | undefined>({
  default: undefined,
  key: 'CurrentCuboid',
});

export const cuboidClickMode = atom<CuboidClickMode>({
  default: CuboidClickMode.Default,
  key: 'CuboidClickMode',
});

export const isCuboidEditorEnabled = atom<boolean>({
  default: false,
  key: 'IsCuboidEditorEnabled',
});

export const currentCuboidVisibilityBox = atom<VisibilityBoxProps>({
  default: DEFAULT_VISIBILITY_BOX,
  key: 'CurrentCuboidVisibilityBox',
});

export const isCuboidVisibilityBoxEnabled = atom<boolean>({
  default: false,
  key: 'IsCuboidVisibilityBoxEnabled',
});
