import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { Box, Typography } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import * as state from '@/components/Analysis/state';
import { poiState } from '@/components/Analysis/modules/pointOfInterest';

export const BackToAssemblyTableButton = () => {
  const setSelectedAssemblyId = useSetRecoilState(state.selectedAssemblyId);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const setHoveredAssemblyId = useSetRecoilState(state.hoveredAssemblyId);

  const clickCB = useCallback(() => {
    cancelPOIAdd(undefined);
    setSelectedAssemblyId(undefined);
    setSelectedAssemblyName(undefined);
    setSelectedSpherical(undefined);
    setCameraTarget(undefined);
    setHoveredAssemblyId(undefined);
  }, [
    cancelPOIAdd,
    setSelectedAssemblyId,
    setSelectedAssemblyName,
    setSelectedSpherical,
    setCameraTarget,
    setHoveredAssemblyId,
  ]);

  return (
    <Box onClick={clickCB} sx={{ display: 'flex', alignItem: 'center', mb: 3, cursor: 'pointer' }}>
      <ArrowBack fontSize="small" />
      <Typography fontSize="1.4rem" sx={{ ml: 1 }}>
        All Components
      </Typography>
    </Box>
  );
};
