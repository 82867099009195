import { ErrorInfo, PropsWithChildren, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/nextjs';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

type Props = {
  logToSentry?: boolean;
} & PropsWithChildren;

const refreshPage = () => {
  window.location.reload();
};

const FallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Box sx={{ margin: 'auto', width: '50%' }}>
      <Alert severity="error" onClose={resetErrorBoundary}>
        <AlertTitle>Error</AlertTitle>
        {error.message}
        <Button variant="contained" onClick={refreshPage}>
          Refresh page
        </Button>
      </Alert>
    </Box>
  );
};

export const FabricErrorBoundary = ({ children, logToSentry = false }: Props) => {
  const [hide, setHide] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const logErrorMessage = (error: Error, info: ErrorInfo) => {
    if (logToSentry) {
      Sentry.captureMessage(error.message);
    }
    console.error(error);
  };

  const hideChildren = () => {
    setHide(true);
  };

  return (
    <>
      {!hide && (
        <ErrorBoundary
          fallbackRender={FallbackRender}
          onReset={hideChildren}
          onError={logErrorMessage}
        >
          {children}
        </ErrorBoundary>
      )}
    </>
  );
};
