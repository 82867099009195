import { useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { isStructureCorrosionReady } from '@/state';
import * as state from '@/components/Analysis/state';
import { poiState } from '@/components/Analysis/modules/pointOfInterest';
import {
  useGetStructureQuery,
  useGetAllDecksQuery,
  useGetStructureLocationsQuery,
  useGetAllPointOfInterestTemplatesQuery,
  useAllPointOfInterestsForDataLoaderQuery,
} from './data.graphql';

import { useSetStructureRelationship } from '../../modules/pointCloud/useSetStructureRelationships';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useLocalStorage } from '@/hooks';
import { LocationsData } from '@/types';
import { sizeOfAnObjectInMb } from '@/utils/sizeOfAnObject';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { upperSnakeCase, urlToSVG } from '@/utils';

export const InspectionDataLoader = () => {
  const structureId = useGetStructureId();
  const [inspectionMetadata, setInspectionMetadata] = useRecoilState(state.inspectionMetadata);
  const [structureLocations, setStructureLocations] = useRecoilState(state.structureLocations);
  const setStructureDecks = useSetRecoilState(state.structureDecks);
  const setSelectedStructureDefectsLegends = useSetRecoilState(
    state.selectedStructureDefectsLegends
  );
  const setPoiTemplateIcons = useSetRecoilState(state.poiTemplateIcons);
  const setPartLegends = useSetRecoilState(state.partLegends);

  const setStructureLocationsMap = useSetRecoilState(state.structureLocationsMap);
  const setAllPointOfInterestTemplates = useSetRecoilState(state.allPointOfInterestTemplates);
  const setAllPOI = useSetRecoilState(poiState.structurePois);
  const setIsStructureCorrosionReady = useSetRecoilState(isStructureCorrosionReady);
  const setHasRgb = useSetRecoilState(state.hasRgb);
  const [locations, setLocations] = useLocalStorage<LocationsData | undefined>(
    `locations`,
    undefined
  );

  const structureLocationsQueryResult = useGetStructureLocationsQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
    skip: !!locations?.[structureId],
  });

  useEffect(() => {
    if (locations && locations[structureId]) {
      const structureLocationsMap = locations[structureId]?.reduce((map, location) => {
        map.set(location.id, location);
        return map;
      }, new Map());

      setStructureLocationsMap(structureLocationsMap);
    }
  }, [locations, structureId, setStructureLocationsMap]);

  useEffect(() => {
    const allLocations = structureLocationsQueryResult.data?.allLocations;
    if (allLocations) {
      if (sizeOfAnObjectInMb(allLocations) <= 2.5) {
        setLocations({ [structureId]: allLocations });
        return;
      }
      setStructureLocations(allLocations);
    }
  }, [
    setLocations,
    structureId,
    structureLocationsQueryResult.data?.allLocations,
    setStructureLocations,
  ]);

  useEffect(() => {
    if (locations?.[structureId] && !structureLocations)
      setStructureLocations(locations?.[structureId]);
  }, [locations, setStructureLocations, structureId, structureLocations]);

  const allPoiTemplatesQueryResult = useGetAllPointOfInterestTemplatesQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
  });

  const { data: pointOfInterests } = useAllPointOfInterestsForDataLoaderQuery({
    variables: {
      input: {
        structureId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const structureDecksQueryResult = useGetAllDecksQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
  });

  const structureQueryResult = useGetStructureQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
    skip: !!inspectionMetadata,
  });

  useSetStructureRelationship(structureId, structureQueryResult.data?.structure);

  useEffect(() => {
    if (structureDecksQueryResult?.data?.allDecks) {
      setStructureDecks(structureDecksQueryResult?.data?.allDecks);
    }
  }, [setStructureDecks, structureDecksQueryResult?.data?.allDecks]);

  useEffect(() => {
    const allTemplates = allPoiTemplatesQueryResult.data?.allPointOfInterestTemplate;
    if (allTemplates) {
      const fetchData = async () => {
        const unresolvedSvgs = allTemplates?.map((template) => {
          return urlToSVG(getCloudfrontUrl(template?.icon?.name ?? ''));
        });

        try {
          const downloadedSvgs = await Promise.all(unresolvedSvgs);
          const svgData = allTemplates
            .map((template, index) => ({
              name: upperSnakeCase(template.name),
              icon: downloadedSvgs[index],
              color: template.icon?.color ?? '',
            }))
            .filter((template) => template.icon);
          setPoiTemplateIcons(svgData);
        } catch (error) {
          console.log(error);
        }
      };
      setAllPointOfInterestTemplates(allTemplates);
      fetchData();
    }
  }, [
    setAllPointOfInterestTemplates,
    allPoiTemplatesQueryResult.data?.allPointOfInterestTemplate,
    setPoiTemplateIcons,
  ]);

  useEffect(() => {
    if (pointOfInterests?.pointOfInterests) {
      setAllPOI(pointOfInterests?.pointOfInterests);
    }
  }, [setAllPOI, pointOfInterests?.pointOfInterests]);

  useEffect(() => {
    const structureInfo = structureQueryResult.data?.structure;
    if (structureInfo) {
      setInspectionMetadata(structureInfo);
      setPartLegends(
        structureInfo.partLegends?.reduce<{ [key: string]: string }>((accumulator, item) => {
          accumulator[item.name.toLowerCase()] = item.color;
          return accumulator;
        }, {}) ?? {}
      );

      // Use Defect Legend from structure and add isEnabled flag in order to toggle the visibility of the defect
      setSelectedStructureDefectsLegends(
        structureInfo?.defectLegends?.map((defect) => {
          return {
            ...defect,
            isEnabled: false,
            legendText: defect.legendText ?? '',
          };
        })
      );

      // flag that indicates that this deployment octree has rgb data
      setHasRgb(structureQueryResult.data?.structure?.hasRGB || false);
    }
  }, [
    setIsStructureCorrosionReady,
    setInspectionMetadata,
    structureQueryResult.data?.structure,
    setSelectedStructureDefectsLegends,
    setHasRgb,
    setPartLegends,
  ]);

  return <></>;
};
