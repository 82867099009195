import React, { useCallback, useMemo, KeyboardEvent, useEffect, useState } from 'react';
import {
  AbyssViewerNoContext as AbyssViewerComponent,
  PointCloudMaterialProps,
  PointPickResult,
  SphericalImageProps,
  SegmentProps,
  ControlsType,
  useAbyssViewerContext,
  ImageSetProps,
  ImageProps,
} from '@abyss/3d-viewer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuBar } from './MenuBar';
import { useViewerPerformanceAlert } from '@/hooks/useViewerPerformanceAlert';
import * as state from '@/state';
import {
  useSetStateFromQueryParameters,
  useQueryParametersFromState,
  updateCameraTargetUrlParameters,
} from '@/components/Inspection/state';

type Props = {
  octreePointCloudsJsonPath: {
    path: string;
    name?: string | string[];
    contents: {
      'part-id'?: boolean;
      'corrosion-id'?: boolean;
      'rusting-id'?: boolean;
      color?: boolean;
    }[];
  }[];
  onPointSelect?: (pickResult: PointPickResult) => void;
  onPointCloudSelection?: (pointPickResults: PointPickResult[]) => void;
  pointCloudMaterials: Map<string, PointCloudMaterialProps>;
  sphericalImages: SphericalImageProps[];
  rectilinearImages: ImageProps[];
  selectionColors: { shiftKeyColor: number; altKeyColor: number };
  segments: SegmentProps[];
  children: React.ReactNode;
} & JSX.IntrinsicAttributes;

const keyCheck = (event: KeyboardEvent) => event.altKey && event.code === 'KeyA';

export const AbyssViewer = ({
  octreePointCloudsJsonPath,
  onPointSelect,
  onPointCloudSelection,
  pointCloudMaterials,
  sphericalImages,
  rectilinearImages,
  selectionColors,
  segments,
  children,
}: Props) => {
  const { callbacks } = useAbyssViewerContext();

  const [currentVisibilityBox, setCurrentVisibilityBox] = useRecoilState(
    state.currentVisibilityBox
  );

  const currentCuboid = useRecoilValue(state.currentCuboid);
  const [currentCuboidVisibilityBox, setCurrentCuboidVisibilityBox] = useRecoilState(
    state.currentCuboidVisibilityBox
  );
  const isCuboidVisibilityBoxEnabled = useRecoilValue(state.isCuboidVisibilityBoxEnabled);
  const cuboidControlsType = useRecoilValue(state.cuboidControlsType);

  const [cameraTarget, setCameraTarget] = useRecoilState(state.cameraTarget);
  const areSphericalsVisible = useRecoilValue(state.areSphericalsVisible);
  const pointBudget = useRecoilValue(state.pointBudget);
  const edlStrength = useRecoilValue(state.edlStrength);
  const [{ mode: currentAbyssViewerMode }, setAbyssViewerState] = useRecoilState(
    state.setAbyssViewerState
  );
  const [viewerCurrentSpherical, setViewerCurrentSpherical] = useRecoilState(
    state.viewerCurrentSpherical
  );
  const [viewerNextCurrentSpherical, setViewerNextCurrentSpherical] = useRecoilState(
    state.viewerNextCurrentSpherical
  );

  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);
  const [isInitialPointCloudLoaded, setIsInitialPointCloudLoaded] = useState(false);

  const resetCameraTarget = useCallback(() => {
    if (callbacks?.getDefaultCameraPosition) {
      setCameraTarget(callbacks.getDefaultCameraPosition());
    }
  }, [setCameraTarget, callbacks]);

  useSetStateFromQueryParameters(isInitialPointCloudLoaded);
  useQueryParametersFromState();

  useEffect(() => {
    // Entering image viewpoint
    if (!selectedSpherical && currentAbyssViewerMode !== 'IsolatePart') {
      // Exiting image viewpoint
      setAbyssViewerState({ mode: 'Normal' });
    }
  }, [setAbyssViewerState]);

  const pointClouds = useMemo(
    () =>
      octreePointCloudsJsonPath?.map(({ path, name }) => ({
        name: `Line ${name}`,
        id: `Octree Point Cloud - ${path}`,
        url: path,
        material: 'annotation',
        isVisible: true,
      })),
    [octreePointCloudsJsonPath]
  );

  const centerOnLoad = useCallback(() => {
    setIsInitialPointCloudLoaded(true);
    if (!cameraTarget) {
      resetCameraTarget();
    }
  }, [cameraTarget, resetCameraTarget]);

  const toggleVisiblilityRangeSpherical = useCallback(
    (event: KeyboardEvent) => {
      if (inSphericalView && event.key === 'l') {
        setSphericalPointCloudMode((lastValue) => {
          if (lastValue === 'None') {
            return 'All';
          }
          return 'None';
        });
      }
    },
    [inSphericalView, setSphericalPointCloudMode]
  );

  const imageSets = useMemo<ImageSetProps[]>(() => {
    return [
      {
        id: 'imageSet',
        name: 'imageSet',
        images: rectilinearImages ?? [],
        isVisible: rectilinearImages && areSphericalsVisible,
      },
    ];
  }, [rectilinearImages, areSphericalsVisible]);

  const sphericalImageSets = useMemo(() => {
    return [
      {
        id: 'sphericalImageSet',
        name: 'sphericalImageSet',
        sphericalImages,
        isVisible: areSphericalsVisible,
      },
    ];
  }, [sphericalImages, areSphericalsVisible]);

  const navigationControls = useMemo(() => {
    return {
      lookAround: {
        minFov: 1.5,
        maxFov: 70,
      },
    };
  }, []);

  const [visibilityBox, setVisibilityBox] = useMemo(() => {
    if (
      currentCuboid &&
      isCuboidVisibilityBoxEnabled &&
      cuboidControlsType === ControlsType.AlignedBox
    ) {
      return [currentCuboidVisibilityBox, setCurrentCuboidVisibilityBox];
    }
    return [currentVisibilityBox, setCurrentVisibilityBox];
  }, [
    currentCuboid,
    isCuboidVisibilityBoxEnabled,
    cuboidControlsType,
    currentCuboidVisibilityBox,
    setCurrentCuboidVisibilityBox,
    currentVisibilityBox,
    setCurrentVisibilityBox,
  ]);

  const abyssViewerComponentProps = useMemo(
    () => ({
      onInitialPointCloudLoad: centerOnLoad,
      cameraTarget,
      navigationControls,
      visibilityBox,
      setVisibilityBox,
      pointCloudMaterials,
      pointClouds,
      pointBudget,
      edlStrength,
      enableEDL: !!edlStrength,
      viewCube: true,
      selectionColors,
      showPointCloudsInSphericalView: true,
      imageSets,
      sphericalImageSets,
      // Todo: Retrieve sphereRadius from the backend
      sphereRadius: 50,
      fileLoaderOptions: { withCredentials: true },
      enableLoaderRetry: true,
      onPointCloudSelection,
      onPointCloudClick: onPointSelect,
      currentImage: viewerCurrentSpherical,
      setCurrentImage: setViewerCurrentSpherical,
      nextCurrentImage: viewerNextCurrentSpherical,
      setNextCurrentImage: setViewerNextCurrentSpherical,
      segments,
    }),
    [
      imageSets,
      cameraTarget,
      centerOnLoad,
      visibilityBox,
      setVisibilityBox,
      onPointCloudSelection,
      onPointSelect,
      pointCloudMaterials,
      pointClouds,
      pointBudget,
      edlStrength,
      viewerCurrentSpherical,
      setViewerCurrentSpherical,
      viewerNextCurrentSpherical,
      setViewerNextCurrentSpherical,
      selectionColors,
      sphericalImageSets,
      segments,
      navigationControls,
    ]
  );

  const canvasProps = useMemo(
    () => ({
      style: {
        background: '#263238',
        width: '100%',
        height: '100%',
      },
      gl: { antialias: true, preserveDrawingBuffer: true },
    }),
    []
  );

  const handleResetCameraClicked = useCallback(() => {
    if (callbacks?.resetCameraPositionToDefault) {
      callbacks?.resetCameraPositionToDefault();
    }
  }, [callbacks]);

  const getHtmlCanvas = useCallback(() => {
    return callbacks?.getHTMLCanvas && callbacks.getHTMLCanvas();
  }, [callbacks]);

  const updateCameraTarget = useCallback(() => {
    if (callbacks?.getCameraPosition) {
      const currentCameraTarget = callbacks?.getCameraPosition();
      if (currentCameraTarget) {
        updateCameraTargetUrlParameters(currentCameraTarget);
      }
    }
  }, [callbacks]);

  const [displayAnimationEditor, setDisplayAnimationEditor] = useRecoilState(
    state.displayAnimationEditor
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (keyCheck(event)) {
        setDisplayAnimationEditor((oldValue) => !oldValue);
      } else {
        toggleVisiblilityRangeSpherical(event);
      }
    },
    [setDisplayAnimationEditor, toggleVisiblilityRangeSpherical]
  );

  const { performanceAlert, onMajorPerformanceCaveat } = useViewerPerformanceAlert();

  return (
    <div style={{ width: '100%', height: '100%' }} tabIndex={1} onKeyPress={handleKeyPress}>
      <MenuBar
        onResetCameraClicked={handleResetCameraClicked}
        getHtmlCanvas={getHtmlCanvas}
        updateCameraTarget={updateCameraTarget}
      />
      {performanceAlert}
      <AbyssViewerComponent
        canvasProps={canvasProps}
        abyssViewerComponentProps={abyssViewerComponentProps}
        displayAnimationEditor={displayAnimationEditor}
        onMajorPerformanceCaveat={onMajorPerformanceCaveat}
      >
        {children}
      </AbyssViewerComponent>
    </div>
  );
};
