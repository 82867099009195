import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import { SegmentProps, SphericalImageProps, VisibilityBoxMode } from '@abyss/3d-viewer';
import { AbyssViewerState, PartSummary } from '@/types';
import {
  abyssViewerState,
  areSphericalsVisible,
  selectedPartIds,
  structureRelationships,
  structures,
  panelsMode,
  currentVisibilityBox,
  zones,
  zoneSegmentsHeight,
  shouldShowDemarcationOfZones,
  viewerCurrentSpherical,
  viewerNextCurrentSpherical,
} from '../atoms';
import { DEFAULT_COLORS, DELETED_TAG_ASSEMBLY_NAME, NO_TAG_ASSEMBLY_NAME } from '@/constants';
import { notEmpty } from '@/utils';

export const structureNameByStructureId = selectorFamily({
  key: 'StructureNameByStructureId',
  get:
    (structureId: string | undefined) =>
    ({ get }) => {
      if (structureId === undefined) {
        return undefined;
      }
      return get(structures).find((structure) => structure.id === structureId)?.name;
    },
});

export const selectedSpherical = selector<SphericalImageProps | undefined>({
  get: ({ get }) => get(viewerCurrentSpherical),
  set: ({ set }, newValue) => set(viewerNextCurrentSpherical, newValue),
  key: 'SelectedSpherical',
});

export const annotationIdsForSelectedPart = selector({
  key: 'AnnotationIdsForSelectedPart',
  get: ({ get }) => {
    const partIds = get(selectedPartIds);
    if (partIds.length === 0) {
      return [];
    }
    const annotationIds = partIds.flatMap((partId) => {
      const annotationIdsForPart = get(structureRelationships)
        ?.byPartId?.get(partId)
        ?.annotationIds.values();
      return annotationIdsForPart ? [...annotationIdsForPart] : [];
    });
    return annotationIds;
  },
});

export const selectedPartSummaries = selector<PartSummary[]>({
  key: 'SelectedPartSummaries',
  get: ({ get }) => {
    const result: PartSummary[] = [];
    const partIds = get(selectedPartIds);
    const relationships = get(structureRelationships);

    if (!relationships) return [];

    const partById = relationships.byPartId;
    const assemblyById = relationships.byAssemblyId;

    partIds.forEach((partId) => {
      const part = partById.get(partId);
      if (!part) return;
      const assembly = part.assemblyId ? assemblyById.get(part.assemblyId) : undefined;

      result.push({
        id: partId,
        assemblyId: part.assemblyId,
        assemblyTagName: assembly?.tagName,
        partClassName: part.class,
        isHidden: part.isHidden,
        isReviewed: part.isReviewed,
      });
    });
    return result;
  },
});

export const inSphericalView = selector<boolean>({
  key: 'inSphericalView',
  get: ({ get }) => get(selectedSpherical) !== undefined,
});

const previousSphericalForAbyssMode = atom<SphericalImageProps | undefined>({
  key: 'previousSphericalForAbyssMode',
  default: undefined,
});

const previousAreSphericalsVisibleForAbyssMode = atom<boolean>({
  key: 'previousAreSphericalsVisibleForAbyssMode',
  default: true,
});

export const setAbyssViewerState = selector<AbyssViewerState>({
  key: 'SetAbyssViewerState',
  get: ({ get }) => get(abyssViewerState),

  set: ({ set, get, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(panelsMode);
      reset(abyssViewerState);
      return;
    }

    const { mode: currentMode } = get(abyssViewerState);

    // Entering/exiting isolate part mode should update the panel mode appropriately
    if (currentMode === 'IsolatePart') {
      set(panelsMode, 'Default');
      set(selectedSpherical, get(previousSphericalForAbyssMode));
      set(areSphericalsVisible, get(previousAreSphericalsVisibleForAbyssMode));
    } else if (newValue.mode === 'IsolatePart') {
      set(panelsMode, 'IsolatePart');
      set(previousSphericalForAbyssMode, get(selectedSpherical));
      set(previousAreSphericalsVisibleForAbyssMode, get(areSphericalsVisible));
      set(areSphericalsVisible, false);
    }

    // Switching abyss viewer mode should force any custom visibility box to finish editing
    if (get(currentVisibilityBox).mode === VisibilityBoxMode.Adjusting) {
      set(currentVisibilityBox, (current) => ({ ...current, mode: VisibilityBoxMode.Enabled }));
    }

    set(abyssViewerState, {
      ...newValue,
      previousMode: currentMode,
    });
  },
});

export const assemblyIdsForSelectedParts = selector<string[]>({
  key: 'AssemblyIdsForSelectedParts',
  get: ({ get }) => {
    const partIds = get(selectedPartIds);
    if (partIds.length === 0) {
      return [];
    }

    const assemblyIds = partIds
      .map((partId) => get(structureRelationships)?.byPartId?.get(partId)?.assemblyId)
      .filter(notEmpty);
    return assemblyIds;
  },
});

// Returns an assembly id if all selected parts have the same assembly id, otherwise returns undefined
export const singleAssemblyIdForSelectedParts = selector<string | undefined>({
  key: 'SingleAssemblyIdForSelectedParts',
  get: ({ get }) => {
    const assemblyIds = get(assemblyIdsForSelectedParts);
    if (assemblyIds.every((assemblyId) => assemblyId === assemblyIds[0])) {
      return assemblyIds[0];
    }
    return undefined;
  },
});

export const singleAssemblyInfoForSelectedParts = selector({
  key: 'singleAssemblyInfoForSelectedParts',
  get: ({ get }) => {
    const selectedAssemblyId = get(singleAssemblyIdForSelectedParts);
    const byAssemblyId = get(structureRelationships)?.byAssemblyId;
    return selectedAssemblyId && byAssemblyId ? byAssemblyId.get(selectedAssemblyId) : undefined;
  },
});

export const allAssemblies = selector({
  key: 'AllAssemblies',
  get: ({ get }) => {
    const byAssemblyId = get(structureRelationships)?.byAssemblyId;
    if (byAssemblyId) {
      return byAssemblyId.map(({ tagName, isNewlyAdded }, assemblyId) => ({
        id: assemblyId,
        tagName,
        isNewlyAdded,
      }));
    }
    return [];
  },
});

export const getSegmentsByCurrentZones = selector<SegmentProps[]>({
  key: 'GetSegmentsByCurrentZones',
  get: ({ get }) => {
    const zonesForStructure = get(zones);
    const zoneSegmentsHeightState = get(zoneSegmentsHeight);
    const shouldShowDemarcationOfZonesState = get(shouldShowDemarcationOfZones);
    if (shouldShowDemarcationOfZonesState && zonesForStructure?.length > 0) {
      return zonesForStructure.map((zone) => ({
        name: zone.id,
        label: zone.name,
        bounds: {
          min: [zone.box.min.x, zone.box.min.y, zone.box.min.z],
          max: [zone.box.max.x, zone.box.max.y, zone.box.min.z + zoneSegmentsHeightState],
        },
        fillColor: DEFAULT_COLORS.segmentsFillColour,
        fillOpacity: 0.05,
        lineColor: DEFAULT_COLORS.segmentsLineColour,
        lineOpacity: 0.4,
        lineWidth: 1,
      }));
    }
    return [];
  },
});

export const assemblyWithDeletedTag = selector({
  key: 'AssemblyWithDeletedTag',
  get: ({ get }) => {
    const structureRelationshipsState = get(structureRelationships);
    const assemblies = structureRelationshipsState?.byAssemblyId || [];
    const selected = assemblies.find((assembly) => {
      return assembly.tagName === DELETED_TAG_ASSEMBLY_NAME;
    });
    return selected?.[0];
  },
});

export const assemblyWithUntaggedTag = selector({
  key: 'AssemblyWithUntaggedTag',
  get: ({ get }) => {
    const structureRelationshipsState = get(structureRelationships);
    const assemblies = structureRelationshipsState?.byAssemblyId || [];

    const selected = assemblies.find((assembly) => {
      return assembly.tagName === NO_TAG_ASSEMBLY_NAME;
    });
    return selected?.[0];
  },
});
