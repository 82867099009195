import { useSetRecoilState, useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Alert, { alertClasses } from '@mui/material/Alert';
import * as poiState from '../state';
import { useDrawerOpen } from '@/hooks/useDrawerOpen';
import { PoiWidget } from './PoiWidget';
import { PointOfInterestTemplateDocument } from '@/types';
import { Can } from '@/components/Can';
import { PoiEditingForms } from '../PointOfInterestTab/SelectedPoiContent/PoiEditingForms/PoiEditingForms';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';

const Inspection3dViewerAlert = muiStyled(Alert)(() => ({
  [`&.${alertClasses.root}`]: {
    position: 'absolute',
    bottom: 0,
    width: 'inherit',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiAlert-icon': {
      padding: 0,
    },
    '@media (max-width: 1728px)': {
      // padding: '0px 16px',
      fontSize: '1.2rem',
      '& .MuiAlert-icon': {
        fontSize: '1.8rem',
      },
    },
  },
}));

export const CreatePoiWidget = () => {
  const [blisterToAdd, setBlisterToAdd] = useRecoilState(poiState.blisterToAdd);
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const [selectedTemplate, setSelectedTemplate] = useState<
    PointOfInterestTemplateDocument | undefined
  >();

  const isLeftDrawerOpen = useDrawerOpen('left');
  const isRightDrawerOpen = useDrawerOpen('right');
  const { leftDrawerWidth, rightDrawerWidth } = useDrawerWidths();
  const widgetDistanceFromRight = useMemo(() => {
    return isRightDrawerOpen ? rightDrawerWidth + 15 : 15;
  }, [rightDrawerWidth, isRightDrawerOpen]);

  const handleClick = useCallback(
    (template: PointOfInterestTemplateDocument | undefined, isBlister: boolean) => {
      if (template) {
        setEditPointOfInterest({
          state: 'WaitingForPoint',
          template,
        });
        setSelectedTemplate(template);
        if (isBlister) {
          setBlisterToAdd({ state: 'WaitingForCenter' });
        }
      } else {
        cancelPOIAdd(undefined);
      }
    },
    [cancelPOIAdd, setBlisterToAdd, setEditPointOfInterest]
  );

  useEffect(() => {
    // if the workflow of the selected option was canceled by another component, deselect current option
    if (!blisterToAdd && editPointOfInterest.state === 'Inactive') {
      setSelectedTemplate(undefined);
    }
  }, [blisterToAdd, editPointOfInterest.state]);

  const createPoiComponent = useMemo(() => {
    return <PoiEditingForms open={editPointOfInterest.state === 'WaitingForDetail'} />;
  }, [editPointOfInterest.state]);

  return (
    <>
      <Can I="read" a="PointOfInterest">
        <PoiWidget
          distanceFromRight={widgetDistanceFromRight}
          template={selectedTemplate}
          handleClick={handleClick}
        />
      </Can>
      {editPointOfInterest?.pointOfInterest && createPoiComponent}
      {blisterToAdd && blisterToAdd.state === 'WaitingForCenter' && (
        <Inspection3dViewerAlert
          variant="filled"
          severity="info"
          style={{ left: isLeftDrawerOpen ? `${leftDrawerWidth + 1}px` : '0' }}
        >
          {'Select the '}
          <strong>centre</strong>
          {' of the blister'}
        </Inspection3dViewerAlert>
      )}
      {blisterToAdd && blisterToAdd.state === 'WaitingForEdge' && (
        <Inspection3dViewerAlert
          variant="filled"
          severity="info"
          style={{ left: isLeftDrawerOpen ? `${leftDrawerWidth}px` : '0' }}
        >
          {'Select the '}
          <strong>edge</strong>
          {' of the blister'}
        </Inspection3dViewerAlert>
      )}
      {!blisterToAdd && editPointOfInterest.state === 'WaitingForPoint' && (
        <Inspection3dViewerAlert
          variant="filled"
          severity="info"
          style={{ left: isLeftDrawerOpen ? `${leftDrawerWidth}px` : '0' }}
        >
          Please click a point you would like to mark as a point of interest
        </Inspection3dViewerAlert>
      )}
    </>
  );
};
