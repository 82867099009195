import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { visuallyHidden } from '@mui/utils';
import { useRouter } from 'next/router';

import * as state from '@/components/Analysis/state';
import * as poiState from '../../state';
import { BlisterRow } from './BlisterRow';
import { BlisterToAdd, PointOfInterestDocument } from '@/types';
import { useGetOneAssemblyQuery, useGetRecommendationsForAssemblyQuery } from './data.graphql';

import { NominateDialog } from './NominateDialog';
import { EmptyBlisters } from './styles';
import { useImageFromLocationId } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import { UnitSystemEnum } from '../../createOrEdit/data.graphql';

export const Blisters = ({ blisterPoi }: { blisterPoi: PointOfInterestDocument[] }) => {
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const [blisterToAdd, setBlisterToAdd] = useRecoilState(poiState.blisterToAdd);
  const [nominatedBlister, setNominatedBlister] = useRecoilState(state.governingValueByAssemblyId);

  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);

  const [isAscOrder, setIsAscOrder] = useState<boolean>(true);
  const [isNominateBlisterOpen, setIsNominateBlisterOpen] = useState(false);

  const unitSystem = useRecoilValue(unitSystemState);

  const { data } = useGetRecommendationsForAssemblyQuery({
    variables: {
      assemblyId: selectedAssemblyId!,
    },
    fetchPolicy: 'network-only',
    skip: !selectedAssemblyId,
  });

  const { data: assemblyQueryResponse } = useGetOneAssemblyQuery({
    variables: {
      assemblyId: selectedAssemblyId!,
    },
    skip: !selectedAssemblyId,
  });

  const governingBlisterId = useMemo(
    () => assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id,
    [assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id]
  );

  const handleTableSortLabelClicked = useCallback(() => {
    setIsAscOrder((old) => !old);
  }, [setIsAscOrder]);

  const [blisterIdToNominate, setBlisterIdToNominate] = useState<string | undefined>();

  const handleOnNominateBlisterClicked = useCallback(
    (blister: PointOfInterestDocument) => {
      if (!selectedAssemblyId) {
        return;
      }

      setBlisterIdToNominate(blister.id);
      setIsNominateBlisterOpen(true);
    },
    [selectedAssemblyId]
  );

  const closeNominateBlister = useCallback(() => {
    setIsNominateBlisterOpen(false);
  }, []);

  const allViewpoints = useRecoilValue(state.structureLocations);

  // TODO: move to selector, this is similar to Viewpoints.tsx ln 61
  const getSphericalFromId = useImageFromLocationId(allViewpoints);

  const handleOnBlisterRowSelected = useCallback(
    (poi: PointOfInterestDocument) => {
      const { blister, location, centerPoint3d } = poi;
      if (blister && location?.id && blister.centerPoint) {
        const selectedSpherical = getSphericalFromId(location?.id);

        if (selectedSpherical) {
          setCameraTarget({
            position: selectedSpherical.position,
            lookAt: [centerPoint3d.x, centerPoint3d.y, centerPoint3d.z],
          });

          setSelectedSpherical({ ...selectedSpherical, lookAt: centerPoint3d });
        }

        if (blister.radiusPoint && blister.centerPoint) {
          const updatedBlisterToAdd: BlisterToAdd = {
            ...blisterToAdd,
            state: 'Loading',
            centerPoint: {
              x: blister.centerPoint.bearing,
              y: blister.centerPoint.elevation,
            },
            edgePoint: {
              x: blister.radiusPoint.bearing,
              y: blister.radiusPoint.elevation,
            },
          };
          setBlisterToAdd(updatedBlisterToAdd);
        }
      }
    },
    [getSphericalFromId, blisterToAdd, setBlisterToAdd, setSelectedSpherical, setCameraTarget]
  );

  useEffect(() => {
    const governingValueForAssembly = data?.assembly?.recommendation?.governingValue;
    if (governingValueForAssembly && selectedAssemblyId) {
      setNominatedBlister((current) => {
        return {
          ...current,
          [selectedAssemblyId]: governingValueForAssembly,
        };
      });
    }
  }, [data?.assembly?.recommendation?.governingValue, selectedAssemblyId, setNominatedBlister]);

  const orderedBlisters: PointOfInterestDocument[] = useMemo(() => {
    if (!blisterPoi) {
      return [];
    }

    return blisterPoi.sort((a: PointOfInterestDocument, b: PointOfInterestDocument) => {
      const aHeight = a.blister!.estimatedHeight;
      const bHeight = b.blister!.estimatedHeight;
      return isAscOrder ? aHeight - bHeight : bHeight - aHeight;
    });
  }, [blisterPoi, isAscOrder]);

  const orderedBlistersComp = useMemo(
    () =>
      selectedAssemblyId &&
      orderedBlisters &&
      orderedBlisters.map((poi) => {
        return (
          <BlisterRow
            key={poi?.name}
            poiId={poi?.poiId || NOT_APPLICABLE_LABEL}
            pointOfInterest={poi}
            onBlisterRowClicked={handleOnBlisterRowSelected}
            onNominateBlisterClicked={handleOnNominateBlisterClicked}
            isNominated={poi?.id === governingBlisterId}
          />
        );
      }),
    [
      governingBlisterId,
      selectedAssemblyId,
      orderedBlisters,
      handleOnBlisterRowSelected,
      handleOnNominateBlisterClicked,
    ]
  );

  const router = useRouter();

  useEffect(() => {
    const { governingValue } = router.query;
    if (
      selectedAssemblyId &&
      governingValue === 'true' &&
      nominatedBlister[selectedAssemblyId]?.blister
    ) {
      // find the governing value by id
      const findBlisterPoi = blisterPoi?.find(
        (poi) => poi.id === nominatedBlister[selectedAssemblyId].blister!.id
      );
      // if it has a center point and location id, enter the spherical
      if (findBlisterPoi && findBlisterPoi.location?.id && findBlisterPoi.blister!.centerPoint) {
        const selectedSpherical = getSphericalFromId(findBlisterPoi.location?.id);
        if (selectedSpherical) {
          setSelectedSpherical({
            ...selectedSpherical,
            lookAt: findBlisterPoi.centerPoint3d,
          });
        }
        // to clear out of BlisterMeasurement mode, if in it
        setBlisterToAdd(undefined);
      }
    }
  }, [
    blisterPoi,
    getSphericalFromId,
    nominatedBlister,
    router.query,
    selectedAssemblyId,
    setSelectedSpherical,
    setBlisterToAdd,
  ]);

  return (
    <>
      {orderedBlisters.length > 0 ? (
        <TableContainer>
          <Table sx={{ minWidth: 200 }}>
            <TableHead>
              <TableRow>
                <TableCell>Blister</TableCell>
                <TableCell>
                  <TableSortLabel
                    active
                    direction={isAscOrder ? 'asc' : 'desc'}
                    onClick={handleTableSortLabelClicked}
                  >
                    Height
                    <Box component="span" sx={visuallyHidden}>
                      {isAscOrder ? 'sorted ascending' : 'sorted descending'}
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active
                    direction={isAscOrder ? 'asc' : 'desc'}
                    onClick={handleTableSortLabelClicked}
                  >
                    Wall Loss
                    {unitSystem === UnitSystemEnum.Imperial ? ' (Iin)' : ' (mm)'}
                    <Box component="span" sx={visuallyHidden}>
                      {isAscOrder ? 'sorted ascending' : 'sorted descending'}
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>Is Governing?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="blister-tbody">{orderedBlistersComp}</TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyBlisters data-testid="empty-blister">
          <Typography>Add blisters using the spatial viewer</Typography>
        </EmptyBlisters>
      )}

      {selectedAssemblyId && data?.assembly?.tagName && blisterIdToNominate && (
        <NominateDialog
          isOpen={isNominateBlisterOpen}
          onClose={closeNominateBlister}
          assemblyId={selectedAssemblyId}
          assemblyTagName={data?.assembly.tagName}
          blisterId={blisterIdToNominate}
          pipeSpec={data?.assembly?.pipeSpec}
        />
      )}
    </>
  );
};
