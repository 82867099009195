type IconProps = {
  style?: React.CSSProperties;
};

export const DatePickerIcon = ({ style = {} }: IconProps) => {
  return (
    <img
      src="/assets/icons/date-picker-icon.svg"
      alt="Calendar"
      style={{ height: '1.4rem', ...style }}
    />
  );
};
