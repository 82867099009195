import { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { SummaryCard } from '@/components/Analysis/Viewer/Panels/AssemblyTable/Asset/SummaryCard';

import { GetAssemblyPipeSpecQuery } from '../data.graphql';

import { CardContainer } from '../styles';

type PipeSpecCardProps = {
  data: GetAssemblyPipeSpecQuery | undefined;
  isLoading: boolean;
  handlePipeSpecClicked: () => void;
};

export const PipeSpecCard = ({ data, isLoading, handlePipeSpecClicked }: PipeSpecCardProps) => {
  const pipeSpecDisplay = useMemo(() => {
    const spec = data?.assembly?.pipeSpec?.spec;
    const material = data?.assembly?.pipeSpec?.material;

    const title = spec ? `Pipe spec ${spec}` : 'Pipe spec';
    const subtitle = material || 'Unknown';
    const onClick = spec && material ? handlePipeSpecClicked : undefined;

    return { title, subtitle, onClick };
  }, [data?.assembly?.pipeSpec?.material, data?.assembly?.pipeSpec?.spec, handlePipeSpecClicked]);

  if (isLoading) {
    return (
      <CardContainer>
        <Skeleton variant="rectangular" width="100%" height={94} />
      </CardContainer>
    );
  }

  return <SummaryCard title={pipeSpecDisplay.title} onClick={pipeSpecDisplay.onClick} />;
};
