import { useRecoilState, useSetRecoilState } from 'recoil';
import * as state from '../state';
import {
  AnalysisCuboid,
  CuboidClickMode,
  CuboidTransformAction,
  PointOfInterestWithCuboids,
} from '@/types';
import { CUBOID_CONTROL_MODES } from '@/constants';
import * as poiState from '../../pointOfInterest/state';
import { StateMap } from '@/utils/stateMap';

export const useAnalysisCuboidActions = () => {
  const [cuboidClickMode, setCuboidClickMode] = useRecoilState(state.cuboidClickMode);
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const setIsCuboidEditorEnabled = useSetRecoilState(state.isCuboidEditorEnabled);
  const setCuboidTransformMode = useSetRecoilState(state.cuboidTransformMode);
  const setCuboidControlsType = useSetRecoilState(state.cuboidControlsType);
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const setCuboids = useSetRecoilState(state.cuboids);

  const startOrStopAddingCuboid = () => {
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
    setCuboidClickMode((mode) =>
      mode === CuboidClickMode.AddCuboid ? CuboidClickMode.Default : CuboidClickMode.AddCuboid
    );

    // When starting
    if (cuboidClickMode === CuboidClickMode.Default) {
      //
    }

    // When cancelling
    if (cuboidClickMode === CuboidClickMode.AddCuboid) {
      cancelPOIAdd(undefined);
    }
  };

  const toggleTransformMode = (action: CuboidTransformAction) => {
    const [controlsType, transformMode] = CUBOID_CONTROL_MODES[action];
    setCuboidControlsType(controlsType);
    if (transformMode !== undefined) {
      setCuboidTransformMode(transformMode);
    }
  };

  const cancelAddingCuboid = () => {
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
    // clearing out the poi editing state
    cancelPOIAdd(undefined);
  };

  const exitEditing = () => {
    setIsCuboidEditorEnabled(false);
  };

  const removeCurrentCuboidFromCuboids = () => {
    if (!currentCuboid) return;

    setCuboids((current) => {
      const copy = current.asMutableStateMap();
      copy?.delete(currentCuboid.id);
      return copy.asStateMap();
    });
  };

  const initializeCuboids = (allPois: PointOfInterestWithCuboids[]) => {
    const vois = allPois.filter((poi) => poi.type === 'PAINT_RECOMMENDATION');
    const voidsCuboidsFlatMap = vois.flatMap((poi) => poi.cuboids).filter((cuboid) => !!cuboid);
    const voiMap = new StateMap<string, AnalysisCuboid>().asMutableStateMap();

    voidsCuboidsFlatMap.map((voi) => {
      if (!voi) return;

      voiMap.set(voi.id, {
        id: voi.id,
        position: voi.position as [number, number, number],
        rotation: voi.rotation as [number, number, number],
        scale: voi.scale as [number, number, number],
      });
    });

    setCuboids(voiMap.asStateMap());
  };

  return {
    startOrStopAddingCuboid,
    toggleTransformMode,
    cancelAddingCuboid,
    exitEditing,
    removeCurrentCuboidFromCuboids,
    initializeCuboids,
  };
};
