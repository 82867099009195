import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FilterIcon from '@mui/icons-material/Filter';
import { ReactNode, useState } from 'react';
import { ResponsiveButton } from '@/components/Analysis/Viewer/Inspection3dViewer/AbyssViewer/MenuBar/styles';

type Props = {
  icon: ReactNode;
  options: string[];
  title: string;
  onSelect: (index: number) => void;
  selectedIndex?: number;
};
export const FabDropdown = ({ icon, options, title, onSelect, selectedIndex }: Props) => {
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>(undefined);

  const open = Boolean(anchorElement);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    onSelect(index);
    setAnchorElement(undefined);
  };

  const handleClose = () => {
    setAnchorElement(undefined);
  };

  return (
    <div>
      <ResponsiveButton
        variant="contained"
        className="responsive-btn"
        sx={{ mr: 2, mb: 2 }}
        onClick={handleClickMenu}
        startIcon={icon || <FilterIcon sx={{ mr: 1 }} />}
      >
        {title}
      </ResponsiveButton>

      <Menu
        id="lock-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
