import { abyssColors } from '@/theme/colors';

export const NavbarLinks = {
  my: 1,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderBottomWidth: '3px',
  borderStyle: 'solid',
  borderRadius: 0,
  textTransform: 'none',
  fontSize: '1.4rem',
  '&.Mui-disabled': {
    color: abyssColors.neutral[500],
  },
};

export const structureInfoBoxStyles = {
  width: '180px',
  backgroundColor: '#15283e',
  color: '#fff',
  borderRadius: '4px',
  textTransform: 'none',
};

export const menuListStyles = {
  marginTop: '-4px',
  '& .MuiMenu-list': {
    padding: '4px 0px',
    width: { lg: '128px', md: '104px' },
  },
  '& li': {
    fontSize: '1.2rem',
    color: abyssColors.primary[500],
    paddingY: '4px',
    height: '22px',
    paddingLeft: '8px',
  },
};
