import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import * as state from '@/state';

export const useToggleSuperVoxelSelection = () => {
  const [selectedSuperVoxels, setSelectedSuperVoxels] = useRecoilState(state.selectedSuperVoxels);

  const toggleSuperVoxelSelection = useCallback(
    (annotationId: number, isMultiSelectKeyDown: boolean, isDeselectDown: boolean) => {
      if (isMultiSelectKeyDown) {
        setSelectedSuperVoxels([...new Set([...selectedSuperVoxels, annotationId])]);
      } else if (isDeselectDown) {
        setSelectedSuperVoxels([
          ...new Set(selectedSuperVoxels.filter((aid) => aid !== annotationId)),
        ]);
      } else {
        setSelectedSuperVoxels([]);
      }
    },
    [selectedSuperVoxels, setSelectedSuperVoxels]
  );

  const toggleMultiSuperVoxelSelection = useCallback(
    (annotationIds: number[], isMultiSelectKeyDown: boolean) => {
      if (isMultiSelectKeyDown) {
        setSelectedSuperVoxels((currentSelectedIds) => {
          return [...new Set([...currentSelectedIds, ...annotationIds])];
        });
      } else {
        // deselect key held down (most likely)
        // deselect the list of annotation ids (that were passed to this handler)
        setSelectedSuperVoxels((currentSelectedIds) => {
          const newSelectedIds = new Set<number>();

          currentSelectedIds.forEach((currentSelectedId) => {
            // dont include the ids that were selected
            if (!annotationIds.includes(currentSelectedId)) {
              newSelectedIds.add(currentSelectedId);
            }
          });
          return [...newSelectedIds];
        });
      }
    },
    [setSelectedSuperVoxels]
  );

  return {
    toggleSuperVoxelSelection,
    toggleMultiSuperVoxelSelection,
  };
};
