import { useCallback, useRef, useEffect } from 'react';
import { useBenchmarkUploadMutation } from './data.graphql';
import { UploadBenchmarkProps } from './types';
import { UPLOAD_PARALLEL_REQUESTS, UPLOAD_NUMBER_REQUESTS, UPLOAD_REQUEST_SIZE } from './constants';

const makePseudoRandomString = (length: number): string => {
  let data = '';
  // eslint-disable-next-line no-bitwise
  let current = Math.floor(Math.random() * (1 << 52));
  while (data.length < length) {
    // eslint-disable-next-line no-bitwise, unicorn/prefer-math-trunc
    const a = (current >> 0) & 0xff;
    // eslint-disable-next-line no-bitwise
    const b = (current >> 8) & 0xff;
    // eslint-disable-next-line no-bitwise
    const c = (current >> 16) & 0xff;
    // eslint-disable-next-line no-bitwise
    const d = (current >> 24) & 0xff;
    // eslint-disable-next-line no-bitwise
    current = (current * 103) & 0xffff_ffff;
    data += String.fromCodePoint(a, b, c, d);
  }
  return data;
};

export const UploadBenchmark = ({ onProgress, onComplete }: UploadBenchmarkProps) => {
  const [benchmarkUploadMutation] = useBenchmarkUploadMutation();

  const shared = useRef({ completeCount: 0, numberErrors: 0, totalBytes: 0, startTime: 0 });

  const runUploadBenchmarkBatch = useCallback(
    async (batchSize: number, expectedCompleteCount: number) => {
      const data = makePseudoRandomString(UPLOAD_REQUEST_SIZE);
      for (let requestCount = 0; requestCount < batchSize; requestCount += 1) {
        // eslint-disable-next-line no-await-in-loop
        const result = await benchmarkUploadMutation({
          variables: {
            input: {
              data,
            },
          },
        });
        if (result.data?.benchmarkUpload?.size === data.length) {
          shared.current.totalBytes += data.length;
        } else {
          // error
          shared.current.numberErrors += 1;
        }
        shared.current.completeCount += 1;
        if (shared.current.completeCount < expectedCompleteCount) {
          onProgress(Math.round((shared.current.completeCount / expectedCompleteCount) * 100));
        } else {
          onProgress(100);
          const { startTime, totalBytes, numberErrors } = shared.current;
          const timeElapsed = (performance.now() - startTime) * 0.001;
          const speedMbitPerSec = Math.round((totalBytes * (8 * 0.000001)) / timeElapsed);

          onComplete({
            speedMbitPerSec,
            bytesTransfered: totalBytes,
            numberErrors,
            timeElapsed,
          });
        }
      }
    },
    [shared, benchmarkUploadMutation, onProgress, onComplete]
  );

  const runAll = useCallback(async () => {
    shared.current.startTime = performance.now();
    const batchSize = Math.floor(UPLOAD_NUMBER_REQUESTS / UPLOAD_PARALLEL_REQUESTS);
    await Promise.all(
      // eslint-disable-next-line unicorn/new-for-builtins
      [...Array(UPLOAD_PARALLEL_REQUESTS)].map(() =>
        runUploadBenchmarkBatch(batchSize, batchSize * UPLOAD_PARALLEL_REQUESTS)
      )
    );
  }, [shared, runUploadBenchmarkBatch]);

  useEffect(() => {
    runAll();
  }, [runAll]);

  return <></>;
};
