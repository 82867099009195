import { useSetRecoilState } from 'recoil';
import { commentsState, openState, selectedField, titleState } from '../state/atoms';

const useDialogState = () => {
  const setOpen = useSetRecoilState(openState);
  const setSelectedField = useSetRecoilState(selectedField);
  const setTitle = useSetRecoilState(titleState);
  const setCommentsState = useSetRecoilState(commentsState);

  const handleOpenDialog = (isOpen: boolean, key: string, title: string) => {
    setOpen(isOpen);
    setSelectedField(key);
    setTitle(title);
  };

  const resetDialogState = () => {
    setOpen(false);
    setSelectedField('');
    setTitle('');
  };

  const resetCommentState = () => {
    setCommentsState(undefined);
  };
  return { handleOpenDialog, resetCommentState, resetDialogState };
};

export default useDialogState;
