import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import * as state from '@/state';

import { DockPanel } from '@/components/shared/DockPanel';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import { SelectedPartItem } from './SelectedPartItem';

export const SelectedPart = () => {
  const selectedParts = useRecoilValue(state.selectedPartSummaries);

  return selectedParts.length > 0 ? (
    <DockPanel>
      <SelectedPartItem parts={selectedParts} />
    </DockPanel>
  ) : (
    <DockPanel>
      <DockPanelItem>
        <Typography> Click a part in 3D viewer to select it</Typography>
      </DockPanelItem>
    </DockPanel>
  );
};
