import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState, useEffect, useCallback } from 'react';
import { FormControl, IconButton, InputLabel, SxProps, Typography } from '@mui/material';
import { formatDropdownValue, isStringFalsy } from '@/utils';
import { CustomFieldCommonProps, ReadModeProps } from './types';
import { stringFromDateOrString } from './utils';
import { ReadOnlyTemplateField } from './ReadOnlyTemplateField';
import { TextFormat } from '@/types';
import { AutoCompleteField } from './AutoCompleteField';
import { ClearIcon } from '@mui/x-date-pickers';

type EditModeProps = {
  valueFormat?: TextFormat;
  options: string[] | undefined;
  onFieldValueChange?: (value: string, id?: string) => void;
  mode: 'Edit';
  placeholder?: string;
  sx?: SxProps;
} & CustomFieldCommonProps;

type Props = EditModeProps | ReadModeProps;

const EditModeComponent = ({
  id,
  title,
  options,
  valueFormat = TextFormat.TITLE_CASE,
  value: initialValue,
  onFieldValueChange,
  placeholder = 'Select',
  sx: styles,
  ...rest
}: EditModeProps) => {
  const stringifiedInitialValue = stringFromDateOrString(stringFromDateOrString(initialValue));

  const [value, setValue] = useState<string | undefined>(stringifiedInitialValue);

  useEffect(() => {
    setValue(stringifiedInitialValue);
  }, [stringifiedInitialValue]);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const newValue = event.target.value;

      if (!newValue) {
        return;
      }

      if (onFieldValueChange) {
        onFieldValueChange(newValue, id);
      }
      setValue(newValue);
    },
    [id, onFieldValueChange]
  );

  const handleClear = useCallback(() => {
    if (onFieldValueChange) {
      onFieldValueChange('', id);
    }

    setValue('');
  }, [id, onFieldValueChange]);

  const renderValue = useCallback(() => {
    const isValueUndefined = isStringFalsy(value);
    return (
      <Typography
        sx={{
          mr: isValueUndefined ? 3 : 0,
        }}
      >
        {isValueUndefined ? placeholder : formatDropdownValue(value, valueFormat)}
      </Typography>
    );
  }, [placeholder, value, valueFormat]);

  return (
    <>
      {Array.isArray(options) && options.length > 16 ? (
        <AutoCompleteField
          id={`poiAutocomplete-${title}`}
          title={title}
          options={options}
          initValue={value}
          onAutoCompleteFieldChange={onFieldValueChange}
          sx={{
            marginTop: '16px',
            '& .Mui-focused': {
              fontSize: '1.4rem !important',
            },
          }}
        />
      ) : (
        <FormControl variant="standard" fullWidth sx={styles} style={{ marginTop: '30px' }}>
          <InputLabel id={`poiDropDown-${title}`} sx={{ fontSize: '1.4rem', marginTop: '-15px' }}>
            {title}
          </InputLabel>
          <div style={{ display: 'flex', alignItems: 'center', width: value ? '95%' : '100%' }}>
            <Select
              variant="standard"
              label={title}
              renderValue={renderValue}
              labelId={`poiDropDown-${title}`}
              id={`poiDropDown-${title}`}
              onChange={handleChange}
              defaultValue={stringFromDateOrString(initialValue)}
              value={value}
              sx={{ flex: 1 }}
              {...rest}
            >
              {Array.isArray(options) &&
                options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {formatDropdownValue(option, valueFormat)}
                  </MenuItem>
                ))}
            </Select>
            {value && (
              <IconButton
                onClick={handleClear}
                size="small"
                aria-label="clear"
                sx={{
                  marginLeft: '-5rem',
                }}
              >
                <ClearIcon sx={{ fontSize: '2rem' }} />
              </IconButton>
            )}
          </div>
        </FormControl>
      )}
    </>
  );
};

export const DropDownField = ({ ...props }: Props) => {
  switch (props.mode) {
    case 'Edit':
      return <EditModeComponent {...props} />;
    case 'ReadOnly':
      return <ReadOnlyTemplateField {...props} />;
    default:
      return <></>;
  }
};
