import React, { ChangeEventHandler, useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import { useRecoilState } from 'recoil';
import { Checkbox, FormControlLabel } from '@mui/material';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import * as state from '@/state';

const ShowDemarcationZonesCheckbox = (isChecked: boolean, toggleSelection: ChangeEventHandler) => (
  <Checkbox checked={isChecked} onChange={toggleSelection} />
);

export const DemarcationOfZones = () => {
  const [zoneSegmentsHeight, setZoneSegmentsHeight] = useRecoilState<number>(
    state.zoneSegmentsHeight
  );

  const [unfinishedZoneSegmentsHeight, setUnfinishedZoneSegmentsHeight] =
    useState(zoneSegmentsHeight);

  const [shouldShowDemarcationOfZones, setShouldShowDemarcationOfZones] = useRecoilState(
    state.shouldShowDemarcationOfZones
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setZoneSegmentsHeight(unfinishedZoneSegmentsHeight);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [setZoneSegmentsHeight, unfinishedZoneSegmentsHeight]);

  const toggleShouldShowDemarcationOfZones = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldShowDemarcationOfZones(event.target.checked);
  };

  const handleZoneSegmentsHeightChange = (event: Event, value: number | number[]) => {
    setUnfinishedZoneSegmentsHeight(value as number);
  };

  return (
    <>
      <DockPanelItem>
        <FormControlLabel
          control={ShowDemarcationZonesCheckbox(
            shouldShowDemarcationOfZones,
            toggleShouldShowDemarcationOfZones
          )}
          label="Show demarcation of zones"
        />
        {shouldShowDemarcationOfZones && (
          <DockPanelItem>
            <FormLabel>Demarcations Height</FormLabel>
            <Slider
              valueLabelDisplay="auto"
              value={unfinishedZoneSegmentsHeight}
              onChange={handleZoneSegmentsHeightChange}
            />
          </DockPanelItem>
        )}
      </DockPanelItem>
    </>
  );
};
