import { SyntheticEvent, useCallback } from 'react';
import { VisibilityBoxMode } from '@abyss/3d-viewer';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PointSizeSlider } from '@/components/shared/PointSizeSlider';
import { NormalVisibilityRange, SphericalPointCloudMode } from '@/types';
import * as state from '@/state';
import { DockPanel } from '@/components/shared/DockPanel';
import { PointBudgetSlider } from '@/components/shared/PointBudgetSlider';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import { DemarcationOfZones } from './DemarcationOfZones';
import HideUnhideEquipment from './HideUnhideEquipment';

/**
 * Inspection explorer panel for visibility options
 * Includes visibility range of point cloud as well as visibility of specific points when inside an image viewpoint
 */
export const Visibility = () => {
  const [areSphericalsVisible, setAreSphericalsVisible] = useRecoilState(
    state.areSphericalsVisible
  );
  const [shouldHighlightReviewedParts, setShouldHighlightReviewedParts] = useRecoilState(
    state.shouldHighlightReviewedParts
  );

  const [hideTaggedPoints, setHideTaggedPoints] = useRecoilState(
    state.hideTaggedPoints
  );

  const inSphericalView = useRecoilValue(state.inSphericalView);
  const [normalVisibilityRange, setNormalVisibilityRange] = useRecoilState(
    state.normalVisibilityRange
  );
  const [sphericalPointCloudMode, setSphericalPointCloudMode] = useRecoilState(
    state.sphericalPointCloudMode
  );
  const [currentVisibilityBox, setCurrentVisibilityBox] = useRecoilState(
    state.currentVisibilityBox
  );

  const sphericalPointCloudState = useRecoilValue(state.sphericalPointCloudState);
  const selectedZone = useRecoilValue(state.selectedZone);

  const [isSubscriptionsEnabled, setIsSubscriptionsEnabled] = useRecoilState(
    state.isSubscriptionsEnabled
  );

  const showSubscriptions = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsSubscriptionsEnabled(event.target.checked);
    },
    [setIsSubscriptionsEnabled]
  );

  const toggleShouldHighlightReviewedParts = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldHighlightReviewedParts(event.target.checked);
  };

  const toggleSphericalVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreSphericalsVisible(event.target.checked);
  };

  const handleNormalVisibilityRangeChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setNormalVisibilityRange(value as NormalVisibilityRange);
  };

  const handleViewpointVisibilityChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSphericalPointCloudMode(value as SphericalPointCloudMode);
  };

  const toggleEditCustomVisibilityRange = () => {
    if (currentVisibilityBox.mode === VisibilityBoxMode.Adjusting) {
      setCurrentVisibilityBox({
        ...currentVisibilityBox,
        mode: VisibilityBoxMode.Enabled,
      });
    } else {
      setCurrentVisibilityBox({
        ...currentVisibilityBox,
        mode: VisibilityBoxMode.Adjusting,
      });
    }
  };

  const toggleTaggedPoints = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHideTaggedPoints(event.target.checked);
  }, [setHideTaggedPoints]);

  const VisibilityRange3D = ({ isDisabled }: { isDisabled?: boolean }) => (
    <DockPanelItem>
      <FormControl>
        <FormLabel>Visibility range</FormLabel>
        <RadioGroup
          value={normalVisibilityRange}
          onChange={handleNormalVisibilityRangeChanged}
          defaultValue="ZoneOnly"
          name="visibility-range-radio-group"
        >
          <FormControlLabel
            value="ZoneOnly"
            control={<Radio />}
            label="Zone only"
            disabled={isDisabled}
          />
          <FormControlLabel value="All" control={<Radio />} label="All" disabled={isDisabled} />
          <div>
            <FormControlLabel
              value="Custom"
              control={<Radio />}
              label="Custom"
              disabled={isDisabled}
            />
            {normalVisibilityRange === 'Custom' && !isDisabled && (
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={toggleEditCustomVisibilityRange}
              >
                {currentVisibilityBox.mode === VisibilityBoxMode.Adjusting
                  ? 'Done Editing'
                  : 'Edit'}
              </Button>
            )}
          </div>
        </RadioGroup>
      </FormControl>
    </DockPanelItem>
  );

  const VisibilityRangeSpherical = () => (
    <DockPanelItem>
      {sphericalPointCloudState === 'Loading' ? (
        <>Loading...</>
      ) : (
        <FormControl>
          <FormLabel>Viewpoint visibility</FormLabel>
          <RadioGroup
            value={sphericalPointCloudMode}
            onChange={handleViewpointVisibilityChanged}
            defaultValue="All"
            name="visibility-range-radio-group"
          >
            <FormControlLabel value="All" control={<Radio />} label="Show all points in range" />
            <FormControlLabel
              value="Related"
              control={<Radio />}
              label="Hide points outside viewpoint"
            />
            <FormControlLabel value="None" control={<Radio />} label="Hide all points" />
          </RadioGroup>
        </FormControl>
      )}
    </DockPanelItem>
  );

  return (
    <DockPanel>
      <DockPanelItem>
        <FormControlLabel
          // prettier-ignore
          control={<Checkbox checked={shouldHighlightReviewedParts} onChange={toggleShouldHighlightReviewedParts} />}
          label="Highlight reviewed parts"
        />
        <FormControlLabel
          control={<Checkbox checked={areSphericalsVisible} onChange={toggleSphericalVisibility} />}
          label="Show image viewpoints"
        />
      </DockPanelItem>

      <DockPanelItem>
        <PointBudgetSlider title="Point budget" step={100_000} min={20_000_000} max={200_000_000} />
      </DockPanelItem>

      <DockPanelItem>
        <PointSizeSlider title="Point size" step={0.5} min={1} max={8} />
      </DockPanelItem>

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <FormControlLabel
          control={<Switch checked={hideTaggedPoints} onChange={toggleTaggedPoints} />}
          label="Hide tagged points"
          labelPlacement="start"
        />

        <FormControlLabel
          control={<Switch checked={isSubscriptionsEnabled} onChange={showSubscriptions} />}
          label="Subscriptions"
          labelPlacement="start"
        />
      </Box>

      {selectedZone ? <VisibilityRange3D isDisabled={inSphericalView} /> : <DemarcationOfZones />}
      {inSphericalView && <VisibilityRangeSpherical />}
      <HideUnhideEquipment />
    </DockPanel>
  );
};
