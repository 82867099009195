import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as state from '@/state';
import { BySupervoxelClassIdEntity, LabellingStructureRelationships } from '@/types';

export const useLabellingStructureRelationship = () => {
  const [labellingStructureRelationships, setLabellingStructureRelations] = useRecoilState(
    state.labellingStructureRelationships
  );

  const selectedSuperVoxels = useRecoilValue(state.selectedSuperVoxels);

  const supervoxelClasses = useMemo(() => {
    if (!labellingStructureRelationships?.bySupervoxelClassId) return undefined;
    return Object.keys(labellingStructureRelationships.bySupervoxelClassId).map(
      (supervoxelClassId) => {
        return {
          name: labellingStructureRelationships.bySupervoxelClassId[supervoxelClassId].name,
          color: labellingStructureRelationships.bySupervoxelClassId[supervoxelClassId].color,
          id: supervoxelClassId,
        };
      }
    );
  }, [labellingStructureRelationships]);

  const removeAnnotationFromSupervoxelClass = useCallback(
    (annotation3DReference: number[], superVoxelClassId: string) => {
      setLabellingStructureRelations(
        (previousRelationship: LabellingStructureRelationships | undefined) => {
          if (!previousRelationship) return undefined;

          const superVoxelClassIdCopy = {
            ...previousRelationship.bySupervoxelClassId[superVoxelClassId],
          };

          const previousAnnotations: number[] = [...superVoxelClassIdCopy.annotationIds];

          // React does not direct mutation i.e splice, slice directly on the state object. To perform
          // mutation we have to copy the state then perform mutations. Below we are using the same pattern
          // relationship is entire copy of the state but with specific key
          // in bySupervoxelClassId override.
          annotation3DReference.forEach((annotation) => {
            const index = previousAnnotations.indexOf(annotation);
            if (index !== -1) {
              previousAnnotations.splice(index, 1);
            }
          });

          superVoxelClassIdCopy.annotationIds = [...new Set(previousAnnotations)];

          const relationship = {
            ...previousRelationship,
            bySupervoxelClassId: {
              ...previousRelationship.bySupervoxelClassId,
            },
          };

          relationship.bySupervoxelClassId[superVoxelClassId] = { ...superVoxelClassIdCopy };

          return relationship;
        }
      );
    },
    [setLabellingStructureRelations]
  );

  const addAnnotationToSupervoxelClass = useCallback(
    (annotation3DReference: number[], superVoxelClassId: string) => {
      setLabellingStructureRelations(
        (previousRelationship: LabellingStructureRelationships | undefined) => {
          if (!previousRelationship) return undefined;

          const superVoxelClassIdCopy: BySupervoxelClassIdEntity = {
            ...previousRelationship.bySupervoxelClassId[superVoxelClassId],
          };

          const previousAnnotations: number[] = [...superVoxelClassIdCopy.annotationIds];

          superVoxelClassIdCopy.annotationIds = [
            ...new Set([...previousAnnotations, ...annotation3DReference]),
          ];

          // reconstructing the state
          const relationship: LabellingStructureRelationships = {
            ...previousRelationship,
            bySupervoxelClassId: {
              ...previousRelationship.bySupervoxelClassId,
            },
          };

          relationship.bySupervoxelClassId[superVoxelClassId] = { ...superVoxelClassIdCopy };
          return relationship;
        }
      );
    },
    [setLabellingStructureRelations]
  );

  const changeSupervoxelClass = useCallback(
    (superVoxelClass: string) => {
      if (!labellingStructureRelationships?.bySupervoxelClassId) return;
      // removing annotations
      Object.keys(labellingStructureRelationships.bySupervoxelClassId).forEach(
        (superVoxelClassFromIteration) => {
          removeAnnotationFromSupervoxelClass(selectedSuperVoxels, superVoxelClassFromIteration);
        }
      );
      // recreating state because react does not allow mutation.
      // we need to create a clone of it and set it to state
      addAnnotationToSupervoxelClass(selectedSuperVoxels, superVoxelClass);
    },
    [
      selectedSuperVoxels,
      addAnnotationToSupervoxelClass,
      removeAnnotationFromSupervoxelClass,
      labellingStructureRelationships,
    ]
  );

  return {
    supervoxelClasses,
    changeSupervoxelClass,
  };
};
