import styled from 'styled-components';
import { AnalysisNavbar } from '@/components/Analysis/shared/AnalysisNavbar';
import { Navbar } from '@/components/shared/Navbar';

type DefaultLayoutProps = {
  children: React.ReactNode;
  variant?: 'analysis' | 'inspection';
};

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FluidLayout = ({ variant = 'inspection', children }: DefaultLayoutProps) => {
  return (
    <Main>
      {variant === 'analysis' ? <AnalysisNavbar /> : <Navbar />}
      {children}
    </Main>
  );
};
