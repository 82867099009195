import { renderToString } from 'react-dom/server';
import { POI_DETAILS_DEFAULT, POI_DETAILS_FALLBACK } from '@/constants';

type PointofInterestIconProps = {
  type: string;
  size?: number;
  borderSize?: number;
  opacity?: number;
  selectedPoi?: boolean;
  poiId?: string;
  poiIconDetail?: Array<{
    __typename?: 'PointOfInterestTemplate';
    id: string;
    name: string;
    icon?: {
      __typename?: 'TemplateIcon';
      name: string;
      color?: string | null;
    } | null;
  } | null> | null;
  overrideColor?: string;
  poiIcon: string | undefined;
  iconColor?: string | undefined;
};

const defaultIconSize = 8; // px

//const defaultAvatarBorderSize = 2.1; // px
const defaultOpacity = 0.8;

export const pointOfInterestIcon = ({
  type,
  //selectedPoi,
  size = defaultIconSize,
  //borderSize = defaultAvatarBorderSize, TO Be removed later
  opacity = defaultOpacity,
  iconColor,
  overrideColor,
  poiIcon,
}: PointofInterestIconProps): string => {
  const fallbackIcon = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.icon;

  const fallbackIconColor = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.iconColor;
  const iconComp =
    poiIcon && poiIcon.length > 0
      ? poiIcon
      : renderToString(fallbackIcon) ?? renderToString(POI_DETAILS_DEFAULT.icon);
  const color = overrideColor ?? iconColor ?? fallbackIconColor ?? POI_DETAILS_DEFAULT.iconColor;
  const fillString = `%23${color.replace('#', '')}`; // %23 is escape code for #
  const transformString = `scale(0.3) translate(-${size * 1.5} -${size * 4.5})`;
  const svg = `<svg>
  <g transform="translate(-6,-12.5)">
    <path fill-opacity="${opacity}" stroke="black" stroke-width="0.9" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>
    <path
      d="M 7.392 10.127 C 7.405 10.516 6.591 11.39 6.202 11.404 C 5.813 11.417 4.94 10.603 4.926 10.213 C 4.913 9.824 5.764 10.009 6.153 9.994 C 6.541 9.981 7.377 9.738 7.392 10.127 Z"
      fill="white" stroke="black" stroke-width="0.2"
    />
      <path fill="${fillString}" fill-opacity="${opacity}" stroke="white" stroke-width="0.5" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>

  </g>
  <g transform="${transformString}" fill="white">${iconComp}</g>
</svg>`;
  return svg;
};

const defaultHoveredOpacity = 1;
export const pointOfInterestHoveredIcon = ({
  type,
  size = defaultIconSize,
  //  borderSize = defaultAvatarBorderSize, TODO
  opacity = defaultHoveredOpacity,
  //  selectedPoi, TO be removed later
  iconColor,
  overrideColor,
  poiIcon,
}: PointofInterestIconProps): string => {
  const fallbackIcon = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.icon;
  const iconComp =
    poiIcon ?? renderToString(fallbackIcon) ?? renderToString(POI_DETAILS_DEFAULT.icon);
  const fallbackIconColor = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.iconColor;
  const color = overrideColor ?? iconColor ?? fallbackIconColor ?? POI_DETAILS_DEFAULT.iconColor;
  const fillString = `%23${color.replace('#', '')}`; // %23 is escape code for #
  const transformString = `scale(0.3) translate(-${size * 1.5} -${size * 4.5})`;
  return `<svg>
  <g transform="translate(-6,-12.5)">
   <path fill-opacity="${opacity}" stroke="black" stroke-width="0.9" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>
    <path
      d="M 7.392 10.127 C 7.405 10.516 6.591 11.39 6.202 11.404 C 5.813 11.417 4.94 10.603 4.926 10.213 C 4.913 9.824 5.764 10.009 6.153 9.994 C 6.541 9.981 7.377 9.738 7.392 10.127 Z"
      fill="white"
      stroke="black" stroke-width="0.2"
    />
      <path fill="${fillString}" fill-opacity="${opacity}" stroke="white" stroke-width="0.5" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>

  </g>
  <g transform="${transformString}" fill="white">${iconComp}</g>
</svg>`;
};
