import Avatar, { AvatarProps } from '@mui/material/Avatar';

type UserAvatarProps = {
  name?: string | null;
  picture?: string | null;
} & AvatarProps;

export const UserAvatar = ({ name, picture, ...props }: UserAvatarProps) => {
  return (
    <Avatar
      {...props}
      alt={name ?? undefined}
      src={picture ?? undefined}
      imgProps={{
        crossOrigin: 'anonymous',
      }}
    />
  );
};
